import { Box, IconButton, Stack, styled } from '@mui/material';
import { useRef, useState } from 'react';
import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';
import styles from './swiperstyles.module.css';

// NAVIGATION ARROW
const ArrowSwitcher = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  fill: theme.palette.secondary.main,
  color: theme.palette.secondary.main
}));

interface SwiperButtonProps {
  swiper: Swiper | null;
  type: 'slidePrev' | 'slideNext';
  className?: string;
}

const SwiperButton: React.FC<SwiperButtonProps> = ({ swiper, type, ...props }) => {
  return (
    <ArrowSwitcher
      disableRipple
      onClick={() => {
        swiper && swiper[type]();
      }}
      {...props}>
      {type === 'slidePrev' ? (
        <img src="/icons/carousel_previous.svg" />
      ) : (
        <img src="/icons/carousel_next.svg" />
      )}
    </ArrowSwitcher>
  );
};

const VideoCarousel = () => {
  const [swiperIns, setSwiperIns] = useState<Swiper | null>(null);

  const videoRefs = useRef<HTMLVideoElement[]>([]);

  const handleSliderChange = (swiper: any) => {
    videoRefs.current.forEach((vid, index) => {
      if (index !== swiper.activeIndex) {
        vid.pause();
      }
    });
  };

  const addVideoRef = (el: HTMLVideoElement) => {
    if (el && !videoRefs.current.includes(el)) {
      videoRefs.current.push(el);
    }
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '100%', position: 'relative' }}>
      <Box display={'inline-block'} position="absolute" left={20} zIndex={10}>
        <SwiperButton className="custom-arrow" type="slidePrev" swiper={swiperIns} />
      </Box>
      <SwiperReact
        onSlideChange={handleSliderChange}
        // navigation={true}
        modules={[Navigation]}
        onInit={(swiper: any) => setSwiperIns(swiper)}
        className={styles.swiper}>
        <SwiperSlide className={styles.swiper_slide}>
          <video
            ref={addVideoRef}
            autoPlay={false}
            controls
            playsInline
            poster="imgs/thumbnail/fruitbound_thumb.jpg"
            style={{
              width: '100%',
              maxHeight: '100%'
            }}>
            <source
              src={`${process.env.REACT_APP_STORAGE}/static/movies/cutdown_fruit_bound_rs_v2.mp4`}
              type="video/mp4"
            />
          </video>
        </SwiperSlide>
        <SwiperSlide className={styles.swiper_slide}>
          <video
            ref={addVideoRef}
            autoPlay={false}
            controls
            playsInline
            poster="imgs/thumbnail/jello_thumb.jpg"
            style={{
              width: '100%',
              maxHeight: '100%'
            }}>
            <source
              src={`${process.env.REACT_APP_STORAGE}/static/movies/jello_boom_rs_v2.mp4`}
              type="video/mp4"
            />
          </video>
        </SwiperSlide>
        <SwiperSlide className={styles.swiper_slide}>
          <video
            ref={addVideoRef}
            autoPlay={false}
            controls
            playsInline
            poster="imgs/thumbnail/porjao_thumb.jpg"
            style={{
              width: '100%',
              maxHeight: '100%'
            }}>
            <source
              src={`${process.env.REACT_APP_STORAGE}/static/movies/projao_rs_v2.mp4`}
              type="video/mp4"
            />
          </video>
        </SwiperSlide>
      </SwiperReact>
      <Box display={'inline-block'} position="absolute" right={20} zIndex={10}>
        <SwiperButton className="custom-arrow" type="slideNext" swiper={swiperIns} />
      </Box>
    </Stack>
  );
};

export default VideoCarousel;
