import _ from 'lodash'
import axiosClient from 'api/axiosClient';
import ILicense from 'types/License';

class LicenseService {
  createLicense = (body: ILicense, companyId: number) => {
    return axiosClient.post('licenses', {
      ...licenseService.formatLicenseForClient(body),
      company: companyId
    });
  };

  updateLicense = (body: ILicense, licenseId: number) => {
    return axiosClient.put(`licenses/${licenseId}`, {
      ...licenseService.formatLicenseForClient(body)
    });
  };

  formatLicenseForClient = (body: ILicense) => {
    const { farming_certification, manufacturing_certification, sales_channels, certificates } = body;
    const formatBody = {
      ...body,
      farming_certification: JSON.stringify(farming_certification),
      manufacturing_certification: JSON.stringify(manufacturing_certification),
      sales_channels: Array.isArray(sales_channels) ? JSON.stringify(_.filter(_.compact(sales_channels), channel => channel.selected)) : JSON.stringify(sales_channels),
      certificates: { ...certificates, fda: certificates?.fdaManufacturing }
    };

    return formatBody;
  };
}

const licenseService = new LicenseService();
export default licenseService;
