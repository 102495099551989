import { Grid, Link, Typography } from '@mui/material';
import Controls from 'components/shared/Form/FormControl';
import { DocumentTypes } from 'constants/documents';
import _ from 'lodash';

const getRenderCondition = (step: any, item: any) => {
  if (!step) {
    return true;
  }

  return !_.isEmpty(item);
};

export const renderFacilitySubmission = (
  facilities: any,
  index: number,
  setLink: any,
  handleOpenModal: any,
  t: any,
  currentStep: number | undefined = undefined
) => {
  return (
    <>
      {facilities[index].has_facility === 'farm' && (
        <>
          {getRenderCondition(currentStep, facilities[index].farm.entrance) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>{t('facility.farm.farmEntrance.title')}</Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.farm.farmEntrance.subtitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Farm+entrance+photo.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].farm.entrance`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
          {getRenderCondition(currentStep, facilities[index].farm.plot) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>{t('facility.farm.farmPlot.title')}</Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.farm.farmPlot.subtitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Farming+Plot_Greenhouse+Photo+1.jpg`
                      },
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Farming+Plot_Greenhouse+Photo+2.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].farm.plot`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
          {getRenderCondition(currentStep, facilities[index].farm.animal_stall) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>{t('facility.farm.livestock.title')}</Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.farm.livestock.subtitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/livestock .pdf`,
                        pdf: true
                      },
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/livestock2.pdf`,
                        pdf: true
                      },
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/livestock3.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].farm.animal_stall`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
        </>
      )}
      {facilities[index].has_facility === 'production' && (
        <>
          {getRenderCondition(currentStep, facilities[index].production_facility.front) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>
                {t('facility.productionFacility.front.title')}
              </Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.productionFacility.front.subTitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Front+of+Production+Facility+Photo+1.jpg`
                      },
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Front+of+Production+Facility+Photo+2.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].production_facility.front`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
          {getRenderCondition(currentStep, facilities[index].production_facility.sorting_area) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>
                {t('facility.productionFacility.sortingOrGrading.title')}
              </Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.productionFacility.sortingOrGrading.subTitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Sorting+and+grading.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].production_facility.sorting_area`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
          {getRenderCondition(currentStep, facilities[index].production_facility.packing_area) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>
                {t('facility.productionFacility.packingArea.title')}
              </Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.productionFacility.packingArea.subTitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Packing+area+photo.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].production_facility.packing_area`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
          {getRenderCondition(
            currentStep,
            facilities[index].production_facility.cold_storage_area
          ) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>
                {t('facility.productionFacility.coldStorageArea.title')}
              </Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.productionFacility.coldStorageArea.subTitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Cold+Storage+Area+Photo.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].production_facility.cold_storage_area`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
        </>
      )}
      {facilities[index].has_facility === 'fishery' && (
        <>
          {getRenderCondition(currentStep, facilities[index].fishery.fishery_photo) && (
            <Grid item xs={12} pt={3}>
              <Typography fontWeight={700}>{t('facility.fishery.fisheryPhoto.title')}</Typography>
              <Typography variant="body2" component="span" pb={3}>
                {`${t('facility.fishery.fisheryPhoto.subtitle')} `}
              </Typography>
              <Link>
                <Typography
                  variant="body2"
                  component="span"
                  pb={3}
                  onClick={() => {
                    setLink([
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Fishery+Photo+1.png`
                      },
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/facilities/Fishery+Photo+2.jpg`
                      }
                    ]);
                    handleOpenModal(true);
                  }}>
                  {t('facility.example')}
                </Typography>
              </Link>
              <Controls.Upload
                name={`facilities[${index}].fishery.fishery_photo`}
                destDir={DocumentTypes.FACILITY}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
};
