import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Container,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import reviewService from 'api/services/ReviewService';
import { useAuth } from 'auth';
import useStyles from 'components/Layout/layoutStyles';
import { getDetails } from 'components/UpdateReviewTable';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Navigate } from 'react-router-dom';
import { StatusValue, getLabelColor } from 'utils/renderCell';

const renderStatusClient = (status: string, t: any) => {
  switch (status) {
    case StatusValue.REJECTED:
      return t('application.status.rejected');
    case StatusValue.SELECTED:
      return t('application.status.selected');
    default:
      return t('application.status.in_review');
  }
};

const ApplicationStatusPage = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const { t } = useTranslation();

  if (!user) {
    return <Navigate to="/404" />;
  }

  if (!user.registration) {
    return <Navigate to="/" />;
  }

  const { data, isLoading } = useQuery(
    ['review', user.registration],
    () => reviewService.getReviewById(user.registration),
    {
      select: (data) => {
        const { application_status, id, created_at, status } = data;
        delete application_status.id;

        const mapData = [];
        for (const key in application_status) {
          const { field, details } = getDetails(key, created_at, t);
          mapData.push({
            key,
            field,
            details,
            value: application_status[key]
          });
        }

        return {
          id,
          reviews: mapData,
          application_status,
          status
        };
      }
    }
  );

  if (!isLoading && !data) {
    return <Navigate to="/404" />;
  }

  return (
    <Container className={classes.layout__content} disableGutters>
      <Box className={classes.layout__header}>
        <Typography textAlign="center" component="h1" variant="h1">
          {t('application.title')}
        </Typography>
        <Typography textAlign="center" component="p" variant="body1">
          {t('application.subtitle')}
        </Typography>
        <Typography textAlign="center" component="p" variant="body1">
          {t('application.subtitle2')}
        </Typography>
        {isLoading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress color="primary" />
          </Box>
        ) : (
          <>
            <Typography textAlign="center" component="h3" variant="h3" pt={2}>
              {t('application.application_status')}
            </Typography>
            <Box textAlign="center">
              <Chip
                label={
                  <Typography color="#fff" fontSize={16}>
                    {renderStatusClient(data.status, t).toUpperCase()}
                  </Typography>
                }
                color={getLabelColor(data.status)}
                size="medium"
              />
            </Box>
            <Box p={3}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={30} align="center">
                        {t('application.PROCESS')}
                      </TableCell>
                      <TableCell width={30} align="center">
                        {t('application.STATUS')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.reviews.map((row) => (
                      <TableRow key={row.key}>
                        <TableCell align="left">{row.field}</TableCell>
                        <TableCell align="center">
                          <Checkbox checked={row.value} disabled />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {data.status === StatusValue.SELECTED && (
                <Alert severity="success" sx={{ display: 'flex', alignItems: 'center' }}>
                  {t('application.congrat')} <br />
                  {t('application.congrat2')}
                </Alert>
              )}
              {data.status === StatusValue.REJECTED && (
                <Alert severity="error" sx={{ display: 'flex', alignItems: 'center' }}>
                  {t('application.reject')}
                </Alert>
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ApplicationStatusPage;
