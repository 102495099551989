import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  layout__container: {
    width: '100%',
    height: '100%',
    background: '#fff'
  },
  layout__header: {
    padding: `${theme.spacing(8)} ${theme.spacing(0)} ${theme.spacing(2)}`
  },
  layout__content: {
    paddingTop: theme.spacing(6),
    backgroundColor: theme.palette.white.main,
    borderRadius: '10px',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  }
}));

export default useStyles;
