import licenseService from 'api/services/LicenseService';
import { useMutation } from 'react-query';
import ILicense from 'types/License';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
}

export const useUpdateLicense = ({ onSuccess, onError }: Props) => {
  return useMutation(
    (body: ILicense & { id: number }) => licenseService.updateLicense(body, body.id),
    {
      onError,
      onSuccess
    }
  );
};
