import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';
import { useAuth } from 'auth';
import Layout from 'components/Layout';
import { ModalProvider } from 'contexts/modal';
import {
  changeLanguage,
  configureLocalization,
  getLanguage,
  initialLanguage,
  setLanguageToStorage
} from 'localization';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'routes/Routes';
import theme from 'utils/theme';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GA_TAG || ''
};

TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // automatically requests fresh data in the background
      retry: false, // failed queries will retry if true
      staleTime: 60 * 60 * 1000 //  the time in milliseconds after data is considered stale
    }
  }
});

const App: React.FC = () => {
  const { setMe, isAuthReady } = useAuth();

  useEffect(() => {
    (async () => {
      await setMe();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await configureLocalization();
      initialLanguage();
      setLanguageToStorage();
      await changeLanguage(getLanguage());
    })();
  }, []);

  if (!isAuthReady) return <></>;

  // SEO
  const title =
    'Tops Tongtin Thai SME Program ท็อปส์ ท้องถิ่น SMEไทย ธุรกิจSME ธุรกิจชุมชน ธุรกิจขนาดเล็ก เอสเอ็มอี อาหาร เครื่องดื่ม';
  const description =
    'Tops Tongtin Thai SME Program ท็อปส์ ท้องถิ่น SMEไทย ธุรกิจSME ธุรกิจชุมชน ธุรกิจขนาดเล็ก เอสเอ็มอี อาหาร เครื่องดื่ม';
  const imageMetaURL = '/branding/apple-icon.png';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={
            'Tops Tongtin Thai SME Program ท็อปส์ ท้องถิ่น SMEไทย ธุรกิจSME ธุรกิจชุมชน ธุรกิจขนาดเล็ก เอสเอ็มอี อาหาร เครื่องดื่ม'
          }
        />
        <link rel="icon" href={'/branding/favicon.ico'} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageMetaURL} />
        <meta name="twitter:image" content={imageMetaURL} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="theme-color" content="#000000" />
        <meta name="apple-mobile-web-app-title" content={description} />
        <meta name="application-name" content={description} />

        <link rel="manifest" href="/branding/manifest.json" />
        <link rel="shortcut icon" href="/branding/favicon.ico" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ThemeProviderLegacy theme={theme}>
            <Layout>
              <ModalProvider>
                <Routes />
              </ModalProvider>
            </Layout>
            <ToastContainer
              position="top-right"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover={false}
            />
          </ThemeProviderLegacy>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default App;
