import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FastField, FieldProps } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface ICheckboxProps {
  label: string;
  name: string;
  options?: any;
  isGrid?: boolean;
  showError?: boolean;
}

export const useStyles = makeStyles(() => ({
  input__label: {
    fontSize: '1.5rem!important',
    fontWeight: '700!important',
    marginTop: '-2px!important'
  },
  input__text: {
    fontSize: '1.5rem!important',
    fontWeight: '700!important'
  },
  error_text: {
    fontSize: '20px!important',
    fontWeight: '700!important'
  },
  options__field: {
    width: '100%'
  }
}));

const CheckboxForm = ({
  label,
  name,
  options,
  isGrid = false,
  showError = true
}: ICheckboxProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  /**
   * value = [
   *  { selected: true, detail: '' },
   *  { }
   * ]
   */
  return (
    <FastField name={name}>
      {({ form, field, meta }: FieldProps) => {
        const errorText = meta.error && meta.touched ? meta.error : '';
        return (
          <FormControl error={!!errorText} fullWidth={true}>
            <FormLabel error={!!errorText} id={name}>
              {t(label)}
            </FormLabel>
            <FormGroup>
              <Grid container spacing={2}>
                {options &&
                  options.map((item: any, index: number) => {
                    const checked = _.get(field, `value.${index}.selected`, false);
                    const detailErrorMessage = _.get(meta, `error.${index}.detail`);
                    return (
                      <Grid item xs={12} md={isGrid ? 6 : 12} key={item.value}>
                        <div>
                          <FormControlLabel
                            key={item.value}
                            label={t(item.label)}
                            name={name}
                            control={
                              <Checkbox
                                value={item.value}
                                checked={checked}
                                onChange={(e) => {
                                  form.setFieldValue(
                                    `${field.name}.${index}.selected`,
                                    e.target.checked
                                  );
                                }}
                                onBlur={form.handleBlur}
                                sx={{ alignSelf: 'start' }}
                              />
                            }
                          />
                        </div>
                        {checked && (
                          <Box sx={{ pl: 4 }}>
                            <TextField
                              name={`${field.name}.${index}.detail` || ``}
                              value={field.value[index].detail}
                              onChange={form.handleChange}
                              fullWidth
                              size="small"
                              label={t('form.pleaseSpecify')}
                              error={!!detailErrorMessage && showError}
                              InputLabelProps={{
                                className: classes.input__label
                              }}
                              InputProps={{
                                className: classes.input__text
                              }}
                            />
                            {!!detailErrorMessage && showError && (
                              <FormHelperText className={classes.error_text}>
                                {t(`form.error_msg.${detailErrorMessage}`)}
                              </FormHelperText>
                            )}
                          </Box>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            </FormGroup>
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default CheckboxForm;
