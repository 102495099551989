import axiosClient from 'api/axiosClient';
import { FileUpload } from 'components/Upload';
import { useMutation } from 'react-query';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
}

const uploadMedia = (body: FormData): Promise<FileUpload[]> => {
  return axiosClient.post('upload', body);
};

export const useUploadMutation = ({ onSuccess, onError }: Props) => {
  return useMutation((body: FormData) => uploadMedia(body), {
    onError,
    onSuccess
  });
};
