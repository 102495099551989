import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FastField, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

interface ICheckboxProps {
  label: string;
  name: string;
  options?: any;
  isGrid?: boolean;
  showError?: boolean;
}

export const useStyles = makeStyles(() => ({
  input__label: {
    fontSize: '1.5rem!important',
    fontWeight: '700!important',
    marginTop: '-2px!important'
  },
  input__text: {
    fontSize: '1.5rem!important',
    fontWeight: '700!important'
  },
  error_text: {
    fontSize: '20px!important',
    fontWeight: '700!important'
  },
  options__field: {
    width: '100%'
  }
}));

const CheckboxForm = ({
  label,
  name,
  options,
  isGrid = false,
  showError = true
}: ICheckboxProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FastField name={name}>
      {({ form, field, meta }: FieldProps) => {
        const errorText = meta.error && meta.touched ? meta.error : '';

        return (
          <FormControl error={!!errorText} fullWidth={true}>
            <FormLabel error={!!errorText} id={name}>
              {t(label)}
            </FormLabel>
            {!!errorText && showError && (
              <FormHelperText className={classes.error_text}>
                {t(`form.error_msg.${errorText}`)}
              </FormHelperText>
            )}
            <FormGroup>
              <Grid container>
                {options &&
                  options.map((item: any) => (
                    <Grid item xs={12} md={isGrid ? 6 : 12} key={item.value}>
                      <FormControlLabel
                        key={item.value}
                        label={t(item.label)}
                        name={name}
                        control={
                          <Checkbox
                            value={item.value}
                            checked={field.value.includes(item.value)}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            sx={{ alignSelf: 'start' }}
                          />
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </FormGroup>
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default CheckboxForm;
