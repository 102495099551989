import { Grid } from '@mui/material';
import UploadWithHint from 'components/RegistrationForm/UploadWithHint';
import { DocumentTypes } from 'constants/documents';
import { useModal } from 'contexts/modal';

import { t } from 'i18next';

export const renderTypeOfBusiness = (
  type_of_business: any,
  currentStep: number | undefined = undefined
) => {
  const { value } = type_of_business;
  const { handleOpenModal, setLink } = useModal();

  switch (value) {
    case 'Company (Listed or Limited Liability)':
      return (
        <Grid item xs={12}>
          {/* <Typography variant="body1" component="p">
            {t('submission.company.type_of_business.company_affidavit')}
          </Typography>
          <Link>
            <Typography
              variant="body2"
              component="p"
              onClick={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/company/Company+Affidavit.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}>
              {t('form.example')}
            </Typography>
          </Link> */}
          {/* <Controls.Upload name="companyDocument.type_of_business.company_affidavit.photo" /> */}
          <UploadWithHint
            label={t('type_of_business.company.label')}
            name={'companyDocument.type_of_business.company_affidavit.photo'}
            destDir={DocumentTypes.COMPANY}
            hints={
              !currentStep
                ? [t('type_of_business.company.text'), t('form.info_msg.upload')]
                : [t('type_of_business.company.text')]
            }
            onClickExample={() => {
              setLink([
                {
                  url: `${process.env.REACT_APP_STORAGE}/examples/company/Registered+and+Limited+Partnership+Affidavit.jpg`
                }
              ]);
              handleOpenModal(true);
            }}
            exampleText={t('type_of_business.company.example')}
          />
        </Grid>
      );
    case 'Unregistered Partnership':
      return (
        <Grid item xs={12}>
          {/* <Typography variant="body1" component="p">
            {t('submission.company.type_of_business.unregistered')}
          </Typography>
          <Link>
            <Typography
              variant="body2"
              component="p"
              onClick={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/company/ID+Card.jpg`
                  }
                ]);
                handleOpenModal(true);
              }}>
              {t('form.example')}
            </Typography>
          </Link>
          <Controls.Upload name="companyDocument.type_of_business.unregistered.id_card_upload" /> */}
          <UploadWithHint
            label={t('type_of_business.unregistered.idCardUpload.label')}
            name={'companyDocument.type_of_business.unregistered.id_card_upload'}
            destDir={DocumentTypes.COMPANY}
            hints={
              !currentStep
                ? [t('type_of_business.unregistered.idCardUpload.text'), t('form.info_msg.upload')]
                : [t('type_of_business.unregistered.idCardUpload.text')]
            }
            onClickExample={() => {
              setLink([
                {
                  url: `${process.env.REACT_APP_STORAGE}/examples/company/ID+Card.jpg`
                }
              ]);
              handleOpenModal(true);
            }}
            exampleText={t('type_of_business.unregistered.idCardUpload.example')}
          />
        </Grid>
      );
    case 'Registered Partnership':
      return (
        <Grid item xs={12}>
          <UploadWithHint
            label={t('type_of_business.registered.registrationUpload.label')}
            name={'companyDocument.type_of_business.registered.upload'}
            destDir={DocumentTypes.COMPANY}
            hints={
              !currentStep
                ? [
                    t('type_of_business.registered.registrationUpload.text'),
                    t('form.info_msg.upload')
                  ]
                : [t('type_of_business.registered.registrationUpload.text')]
            }
            onClickExample={() => {
              setLink([
                {
                  url: `${process.env.REACT_APP_STORAGE}/examples/company/Registered+and+Limited+Partnership+Affidavit.jpg`
                }
              ]);
              handleOpenModal(true);
            }}
            exampleText={t('type_of_business.registered.registrationUpload.example')}
          />
        </Grid>
      );
    case 'Limited Partnership':
      return (
        <Grid item xs={12}>
          <UploadWithHint
            label={t('type_of_business.limited.upload.label')}
            name={'companyDocument.type_of_business.limited.upload'}
            destDir={DocumentTypes.COMPANY}
            hints={
              !currentStep
                ? [t('type_of_business.limited.upload.text'), t('form.info_msg.upload')]
                : [t('type_of_business.limited.upload.text')]
            }
            onClickExample={() => {
              setLink([
                {
                  url: `${process.env.REACT_APP_STORAGE}/examples/company/Registered+and+Limited+Partnership+Affidavit.jpg`
                }
              ]);
              handleOpenModal(true);
            }}
            exampleText={t('type_of_business.limited.upload.example')}
          />
        </Grid>
      );
    case 'SMCE (small and micro community enterprise)':
      return (
        <>
          <Grid item xs={12}>
            <UploadWithHint
              label={t('type_of_business.smce.registrationCertificate.label')}
              name={'companyDocument.type_of_business.smce.registration_certificate'}
              destDir={DocumentTypes.COMPANY}
              hints={
                !currentStep
                  ? [
                      t('type_of_business.smce.registrationCertificate.text'),
                      t('form.info_msg.upload')
                    ]
                  : [t('type_of_business.smce.registrationCertificate.text')]
              }
              onClickExample={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/company/SMCE+Tor+Vor+Chor+2.jpg`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t(t('type_of_business.smce.registrationCertificate.example'))}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadWithHint
              label={t('type_of_business.smce.commercialCertificate.label')}
              name={'companyDocument.type_of_business.smce.commerial_certificate'}
              destDir={DocumentTypes.COMPANY}
              hints={
                !currentStep
                  ? [
                      t('type_of_business.smce.commercialCertificate.text'),
                      t('form.info_msg.upload')
                    ]
                  : [t('type_of_business.smce.commercialCertificate.text')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/company/SMCE+Tor+Vor+Chor+3.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t(t('type_of_business.smce.commercialCertificate.example'))}
            />
          </Grid>
        </>
      );
    case 'Individual':
      return (
        <Grid item xs={12}>
          <UploadWithHint
            label={t('type_of_business.individual.idCardUpload.label')}
            name={'companyDocument.type_of_business.individual.upload'}
            destDir={DocumentTypes.COMPANY}
            hints={
              !currentStep
                ? [t('type_of_business.individual.idCardUpload.text'), t('form.info_msg.upload')]
                : [t('type_of_business.individual.idCardUpload.text')]
            }
            onClickExample={() => {
              setLink([
                {
                  url: `${process.env.REACT_APP_STORAGE}/examples/company/ID+Card.jpg`
                }
              ]);
              handleOpenModal(true);
            }}
            exampleText={t('type_of_business.individual.idCardUpload.example')}
          />
        </Grid>
      );

    default:
      break;
  }
};
