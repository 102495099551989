import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';
import { getToken } from 'utils/token';

const EXCEPTION_PATH = [
  '/auth/local',
  '/auth/local/register',
  '/auth/forgot-password',
  '/auth/reset-password'
];

const axiosClient = axios.create({
  timeout: 5 * 60 * 1000, // 5 min
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json'
  },
  paramsSerializer: (params) => queryString.stringify(params)
});

axiosClient.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
  if (requestConfig.url && EXCEPTION_PATH.includes(requestConfig.url)) {
    return requestConfig;
  }
  requestConfig.headers = {
    Authorization: `Bearer ${getToken()}`
  };

  return requestConfig;
});

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error.response;
  }
);

export const clearToken = () => {
  delete axiosClient.defaults.headers.common.Authorization;
};

export default axiosClient;
