import { GridFilterItem } from '@mui/x-data-grid-pro';
import { HeaderField, Operator, Table } from './../constants/reviewTable';

export const handleFilterChange = (filters: GridFilterItem[]) => {
  const iniitalFilter: any = {
    company: [],
    license: [],
    user: [],
    registration: [],
    product: []
  };

  filters.forEach((filter) => {
    const { columnField, value, operatorValue } = filter;
    const { operator, value: convertValue } = convertOperator(operatorValue, value);
    const { table, field } = converField(columnField);

    if (!value && (field === 'created_at' || field === 'updated_at')) {
      return iniitalFilter[table].push({
        field,
        operator,
        value: new Date()
      });
    }

    iniitalFilter[table].push({
      field,
      operator,
      value: convertValue
    });
  });

  return iniitalFilter;
};

const convertOperator = (operator: string | undefined, value: any) => {
  if (value === undefined) {
    value = '';
  }
  switch (operator) {
    case Operator.CONTAINS:
      return {
        operator: 'like',
        value: `%${value}%`
      };
    case Operator.EQUAL:
      return {
        operator: '=',
        value
      };
    case Operator.IS:
      if (value === 'false') {
        value = 0;
      }

      if (value === 'true') {
        value = 1;
      }

      return {
        operator: '=',
        value
      };
    case Operator.IS_NOT:
      return {
        operator: '!=',
        value
      };
    case Operator.IS_ANY_OF:
      if (value === '') {
        value = [];
      }

      return {
        operator: 'in',
        value
      };
    case Operator.AFTER:
      return {
        operator: '>=',
        value
      };
    case Operator.BEFORE:
      return {
        operator: '<=',
        value
      };
    default:
      return {
        operator: 'like',
        value: `%${value}%`
      };
  }
};

export const converField = (field: string) => {
  switch (field) {
    case HeaderField.COMPANY_NAME:
    case HeaderField.PROVINCE:
    case HeaderField.REVENUE:
    case HeaderField.PRODUCT_TYPE:
      return {
        table: Table.COMPANY,
        field: converSpecialField(field)
      };
    case HeaderField.DATE_SUBMITTED:
    case HeaderField.MODIFIED_DATE:
    case HeaderField.STATUS:
      return {
        table: Table.REGISTRAION,
        field: converSpecialField(field)
      };
    case HeaderField.USER_NAME:
    case HeaderField.EMAIL_ADDRESS:
    case HeaderField.PHONE:
      return {
        table: Table.USER,
        field: converSpecialField(field)
      };
    case HeaderField.FDA:
      return {
        table: Table.PRODUCT,
        field: converSpecialField(field)
      };
    default:
      return {
        table: 'registration',
        field: 'status'
      };
  }
};

const converSpecialField = (field: string) => {
  switch (field) {
    case HeaderField.COMPANY_NAME:
      return 'name';
    case HeaderField.USER_NAME:
      return 'CONCAT(`first_name`, " ", `last_name`)';
    case HeaderField.DATE_SUBMITTED:
      return 'created_at';
    case HeaderField.MODIFIED_DATE:
      return 'updated_at';
    case HeaderField.REVENUE:
      return 'annual_revenue';
    default:
      return field;
  }
};
