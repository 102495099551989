import axiosClient from 'api/axiosClient';
import { IAspiration } from 'types/Aspiration';

class AspirationService {
  createAspiration = (body: IAspiration, companyId: number) => {
    return axiosClient.post('aspirations', {
      ...aspirationService.formatAspirationForClient(body),
      company: companyId
    });
  };

  updateAspiration = (body: IAspiration, aspirationId: number) => {
    return axiosClient.put(`aspirations/${aspirationId}`, {
      ...aspirationService.formatAspirationForClient(body)
    });
  };

  formatAspirationForClient = (body: IAspiration) => {
    const { selling_places, assistance } = body;
    const formatBody = {
      ...body,
      selling_places: JSON.stringify(selling_places),
      assistance: JSON.stringify(assistance)
    };

    return formatBody;
  };
}

const aspirationService = new AspirationService();
export default aspirationService;
