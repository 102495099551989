export enum StatusValue {
  NEED_REVIEW = 'NEEDS REVIEW',
  HOLD_DEVELOP = 'HOLD / DEVELOP',
  IN_REVIEW = 'IN REVIEW',
  PRICES_WAITING = 'PRICES / SAMPLES (waiting)',
  PRICES_STARTED = 'PRICES / SAMPLES (started)',
  QA_WAITING = 'QA / LS (waiting)',
  QA_STARTED = 'QA / LS (started)',
  FINAL_WAITING = 'FINAL PANEL (waiting)',
  FINAL_STARTED = 'FINAL PANEL (started)',
  REJECTED = 'NOT SELECTED',
  SELECTED = 'SELECTED',
  REDIRECTED = 'REDIRECTED'
}

export const menuStatuses = [
  {
    label: 'application.status.need_review',
    value: StatusValue.NEED_REVIEW
  },
  {
    label: 'application.status.in_review',
    value: StatusValue.IN_REVIEW
  },
  {
    label: 'application.status.price_samples',
    value: StatusValue.PRICES_WAITING
  },
  {
    label: 'application.status.price_samples',
    value: StatusValue.PRICES_STARTED
  },
  {
    label: 'application.status.qa',
    value: StatusValue.QA_WAITING
  },
  {
    label: 'application.status.qa',
    value: StatusValue.QA_STARTED
  },
  {
    label: 'application.status.final_panel',
    value: StatusValue.FINAL_WAITING
  },
  {
    label: 'application.status.final_panel',
    value: StatusValue.FINAL_STARTED
  },
  {
    label: 'application.status.hold_develop',
    value: StatusValue.HOLD_DEVELOP
  },
  {
    label: 'application.status.selected',
    value: StatusValue.SELECTED
  },
  {
    label: 'application.status.rejected',
    value: StatusValue.REJECTED
  },
  {
    label: 'application.status.redirected',
    value: StatusValue.REDIRECTED
  }
];

export const getLabelColor = (label: string) => {
  switch (label) {
    case StatusValue.NEED_REVIEW:
    case StatusValue.REJECTED:
    case StatusValue.REDIRECTED:
      return 'error';
    case StatusValue.SELECTED:
    case StatusValue.IN_REVIEW:
    case StatusValue.PRICES_STARTED:
    case StatusValue.QA_STARTED:
    case StatusValue.FINAL_STARTED:
      return 'success';
    case StatusValue.PRICES_WAITING:
    case StatusValue.QA_WAITING:
    case StatusValue.FINAL_WAITING:
      return 'warning';
    case StatusValue.HOLD_DEVELOP:
      return 'primary';
    default:
      return 'primary';
  }
};
