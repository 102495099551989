import { PopperUnstyled } from '@mui/base';
import SelectUnstyled, { SelectUnstyledProps } from '@mui/base/SelectUnstyled';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { changeLanguage, getLanguage, LANGUAGES } from 'localization';
import { StyledButton, StyledListbox, StyledOption } from './langStyles';

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function CustomSelect(props: SelectUnstyledProps<string>) {
  const components: SelectUnstyledProps<string>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components
  };

  return <SelectUnstyled {...props} components={components} />;
}

const Language = () => {
  const flags = {
    en: '/imgs/flags/gb.png',
    thai: '/imgs/flags/th.png'
  };

  const selectedLang = getLanguage();

  const handleChangeLanguage = (language: string | null) => {
    changeLanguage(language!);
    window.location.reload();
  };

  return (
    <CustomSelect value={selectedLang} onChange={handleChangeLanguage}>
      <StyledOption value={LANGUAGES.ENGLISH}>
        <img
          loading="lazy"
          width="20"
          src={`${flags.en}`}
          srcSet={`${flags.en} 2x`}
          alt="English"
        />
        <Box component="span" sx={{ padding: '0 4px' }}>
          EN
        </Box>
      </StyledOption>
      <StyledOption value={LANGUAGES.THAI}>
        <img
          loading="lazy"
          width="20"
          src={`${flags.thai}`}
          srcSet={`${flags.thai} 2x`}
          alt={`Thai`}
        />
        <Box component="span" sx={{ padding: '0 4px' }}>
          TH
        </Box>
      </StyledOption>
    </CustomSelect>
  );
};

export default Language;
