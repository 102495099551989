import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  about__page__container: {
    background: theme.palette.gray_bg.main,
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(6)} !important`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(14)} ${theme.spacing(0)} !important`
    }
  },
  about__box__container: {
    padding: `${theme.spacing(0)} !important`
  },
  unique__box__container: {
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(6)} ${theme.spacing(0)} ${theme.spacing(0)} !important`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(10)} ${theme.spacing(0)} ${theme.spacing(0)} !important`
    }
  },
  about__container: {
    background: '#fff',
    boxShadow: theme.boxShadow.static,
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(6)} ${theme.spacing(2)} !important`,
      borderRadius: '20px !important'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '64px !important',
      padding: `${theme.spacing(8)} ${theme.spacing(6)} !important`
    }
  },
  about__container_2: {
    background: '#fff',
    boxShadow: '0px 10px 16px 2px rgba(82, 45, 132, 0.12), 0px 0px 4px -1px rgba(82, 45, 132, 0.2)',
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(6)} ${theme.spacing(2)} !important`,
      borderRadius: '20px !important'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '64px !important',
      padding: `${theme.spacing(8)} !important`
    }
  },
  banner_img: {
    borderRadius: '20px',
    width: '100%',
    height: 'auto'
  },
  title_text: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'left !important',
      fontSize: `${theme.spacing(6)} !important`,
      lineHeight: `${theme.spacing(5)} !important`
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center !important',
      fontSize: `${theme.spacing(8)} !important`,
      lineHeight: `${theme.spacing(8)} !important`
    }
  }
}));

export default useStyles;
