import { IReviewResponse } from './../../types/Review';
import axiosClient from 'api/axiosClient';
import axios from 'axios';
import { getToken } from 'utils/token';

class ReviewService {
  getReviews = (body: any): Promise<IReviewResponse> => {
    return axiosClient.post('/registrations/list', body);
  };

  getReviewById = (id: any | undefined): Promise<any> => {
    return axiosClient.get(`/registrations/${id}`);
  };

  updateStatusById = (id: any, body: any) => {
    return axiosClient.put(`registrations/${id}`, body);
  };

  downloadDocument = (companyId: number, userId: number) => {
    const axiosTest = axios.create({
      timeout: 5 * 60 * 1000, // 5 min
      baseURL: process.env.REACT_APP_API_URL,
      responseType: 'arraybuffer',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${getToken()}`
      }
    });
    return axiosTest.post(`registrations/download`, {
      companyId,
      userId
    });
  };
}

const reviewService = new ReviewService();
export default reviewService;
