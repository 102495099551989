import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import {
  assistanceAreaOptions,
  interestedPlaceOptions
} from 'components/RegistrationWrapper/initialValues/aspiration';
import { useStyles } from 'components/shared/Form/Checkbox';
import Controls from 'components/shared/Form/FormControl';
import { FastField, Field, FieldProps, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAspiration } from 'types/Aspiration';
import { checkArrayContain } from 'utils/checkArrayContain';

const retailOptions = [
  {
    id: 1,
    label: 'aspiration.assistance.place.retails.local',
    value: 'In your local Community'
  },
  {
    id: 2,
    label: 'aspiration.assistance.place.retails.province',
    value: 'In your Province'
  },
  {
    id: 3,
    label: 'aspiration.assistance.place.retails.region',
    value: 'In your Region'
  },
  {
    id: 4,
    label: 'aspiration.assistance.place.retails.nationwide',
    value: 'nationwide'
  }
];

const retailValues = retailOptions.map((retail) => retail.value);

const Aspiration = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<IAspiration>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [retailChecked, setRetailChecked] = useState(false);

  useEffect(() => {
    const { selling_places } = formik.values;
    const checkRetail = checkArrayContain(retailValues, selling_places);
    if (checkRetail) {
      setRetailChecked(true);
    } else {
      setRetailChecked(false);
    }
  }, [formik.values]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3">
          {t('aspiration.title')}
        </Typography>
        <Box>
          <FormLabel>{t('aspiration.place.label')}</FormLabel>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="body2_temp"
          component="h3"
          fontSize={'1.5rem'}
          color={'#1F2937'}
          lineHeight={'1.5'}>
          {t('aspiration.assistance.place.retails.label')}
        </Typography>
        <Box ml={3}>
          <Controls.Checkbox name="selling_places" label="" options={retailOptions} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Controls.Checkbox name="selling_places" label="" options={interestedPlaceOptions} />
        <FastField name="other_selling_places.is_checked">
          {({ field }: FieldProps) => {
            return (
              <FormControl>
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event);
                        if (!event.target.checked) {
                          formik.setFieldValue('other_selling_places.value', '');
                        }
                      }}
                    />
                  }
                  sx={{ mr: 0 }}
                />
              </FormControl>
            );
          }}
        </FastField>
        <Field name="other_selling_places.value">
          {({ field, meta }: FieldProps) => {
            const errorText = meta.error && meta.touched ? meta.error : '';
            return (
              <>
                <TextField
                  focused={!!field.value}
                  disabled={!formik.values.other_selling_places.is_checked}
                  margin="none"
                  label={t('form.other')}
                  variant="outlined"
                  size="small"
                  error={!!errorText}
                  InputLabelProps={{
                    className: classes.input__label
                  }}
                  InputProps={{
                    className: classes.input__text
                  }}
                  {...field}
                />
                {!!errorText && (
                  <FormHelperText className={classes.error_text} error={!!errorText}>
                    {errorText}
                  </FormHelperText>
                )}
              </>
            );
          }}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>{t('aspiration.question.label')}</FormLabel>
        <Typography variant="body2" component="p">
          {t('aspiration.question.question1')}
        </Typography>
        <Typography variant="body2" component="p">
          {t('aspiration.question.question2')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controls.TextArea name="aspirations" placeHolder="" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3">
          {t('aspiration.heardFrom.label')}
        </Typography>
        <Box>
          <FormLabel>{t('aspiration.heardFrom.text')}</FormLabel>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Controls.TextArea name="heard_from" placeHolder="" />
      </Grid>
      <Grid item xs={12} mt={3}>
        <Typography variant="h3" component="h3">
          {t('aspiration.assistance.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controls.Checkbox
          name="assistance"
          label="aspiration.assistance.place.label"
          options={assistanceAreaOptions}
          isGrid={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FastField name="other_assistance.is_checked">
          {({ field }: FieldProps) => {
            return (
              <FormControl>
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event);
                        if (!event.target.checked) {
                          formik.setFieldValue('other_assistance.value', '');
                        }
                      }}
                    />
                  }
                  sx={{ mr: 0 }}
                />
              </FormControl>
            );
          }}
        </FastField>
        <Field name="other_assistance.value">
          {({ field, meta }: FieldProps) => {
            const errorText = meta.error && meta.touched ? meta.error : '';
            return (
              <>
                <TextField
                  focused={!!field.value}
                  disabled={!formik.values.other_assistance.is_checked}
                  margin="none"
                  label={t('form.other')}
                  variant="outlined"
                  size="small"
                  error={!!errorText}
                  InputLabelProps={{
                    className: classes.input__label
                  }}
                  InputProps={{
                    className: classes.input__text
                  }}
                  {...field}
                />
                {!!errorText && (
                  <FormHelperText className={classes.error_text} error={!!errorText}>
                    {errorText}
                  </FormHelperText>
                )}
              </>
            );
          }}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{t('aspiration.assistance.feedback.label')}</Typography>
        <Controls.TextArea name="questions" placeHolder="" />
      </Grid>
    </Grid>
  );
};

export default Aspiration;
