export default {
  questionnaire: {
    title: 'Partnership Prerequiste Verification',
    subTitle:
      'Thanks for your interest in becoming a Tops Tongtin exclusive partner.  Before we get started, please answer the following questions to confirm your eligibility. <a target="_blank" href="/how-it-works">Learn More</a> ',
    q1: {
      question: 'What product type are you interested in selling?',
      label: 'Select Product Type',
      option1: 'Fresh or Perishable (< 6 months shelf life)',
      option2: 'Shelf Stable (> 6 months shelf life)',
      option3: 'Frozen (>12 months shelf life)'
    },
    q2: {
      question: 'How many employees do you currently have?',
      label: 'Select Number of Employees',
      option1: '0-5',
      option2: '6-10',
      option3: '10-20',
      option4: '21-50',
      option5: '>50',
      validation:
        'Sorry, your number of employees exceeds our eligibility requirements; however, we are still interested in learning more about your products. Please go to this link to propose your products for sale through our normal contact channels: https://cgbpm.central.co.th/cfgworkflow/portal'
    },
    q3: {
      question: 'What was your total revenue in 2023?',
      option1: 'less than 100,000 bht',
      option2: '100,001 - 500,000 bht',
      option3: '500,001 - 1,000,000 bht',
      option4: '1,000,001 - 10,000,000 bht',
      option5: '10,000,001 - 50,000,000 bht',
      option6: '50,000,001 - 100,000,000 bht',
      option7: 'more than 100,000,000 bht',
      validation:
        'Sorry, your revenue exceeds our eligibility requirements; however, we are still interested in learning more about your products. Please go to this link to propose your products for sale through our normal contact channels: https://cgbpm.central.co.th/cfgworkflow/portal'
    },
    congrat: `Congratulations! You are eligible to apply to be a Tops Tongtin exclusive partner! Please fill out as much information as possible so we can obtain the fullest picture of your business and products. Don't be discouraged if you don't have all the answers. Remember that our objective is to find fantastic, unique SME food producers; and we can help develop those who need assistance. Thank you for sharing your products with us. We are excited to see you!
      <br />                                                                                                                                                                                                                                                                                                                              
      Please see <a target="_blank" href="/how-it-works">here</a> for a helpful list of documents to prepare before you begin the application.`,
    contact: 'Contact Us',
    next: 'Next'
  },
  account_registration: {
    partnership: 'Partnership Registration',
    account_infor: 'Account Information',
    company_info: 'Company Information',
    licenses: 'Sales Channel', // TODO: Rename variable to match the new title
    sale_channels: 'Sales Channel',
    products: 'Products',
    products2: 'Products Overview',
    aspiration: 'Aspirations & Assistance',
    document: 'Document Submission Review',
    alert: `
      <ul style="padding-left:40px">
        <li>It is not necessary for you to have all the documents listed in the application form.</li>
        <li>You need only provide those documents that are relevant to you.</li>
        <li><strong>For example, if you have not obtained any certification including FDA, YOU CAN STILL APPLY.</strong></li>
        <li>We are looking for great tasting products and are prepared to help you develop them.</li>
      </ul>
    `,
    file_size:
      '<strong>Please upload image files (JPG, PNG) and each file size is not over than 2mb</strong>',
    account_representative: {
      login: 'Login',
      welcome: 'Welcome to Tongtin',
      password: 'Password',
      forgotPassword: 'Forgot Password?',
      title: 'Account Representative Information',
      subTitle: 'Please provide all required information (*) in the entry fields below',
      firstName: 'First Name',
      lastName: 'Last Name',
      phoneNumber: 'Mobile Phone Number',
      email: 'Email',
      lineId: 'LINE ID',
      createPassword: 'Create Password',
      confirmPassword: 'Confirm Password',
      createAccount: 'Create Account'
    }
  },
  company_information: {
    title: 'Company Information',
    name: 'Company / Business Name',
    subtitle: 'Please provide all required information (*) in the entry fields below',
    section1: {
      title: 'Company / Head Office Address *'
    },
    section2: {
      title: 'Company Information *',
      numberOfEmployees: 'Number of Employees',
      totalRevenue: 'Total Revenue 2023',
      typeOfBussiness: 'Type of Business',
      selectBussiness: {
        label: 'Select type of business',
        option1: 'Company (Listed or Limited Liability)',
        option2: 'Unregistered Partnership',
        option3: 'Registered Partnership',
        option4: 'Limited Partnership',
        option5: 'SMCE (small and micro community enterprise)',
        option6: 'Individual'
      },
      vat: {
        label: 'Registered for VAT',
        yes: 'Yes',
        no: 'No'
      },
      sme: {
        label: 'SME Registered',
        yes: 'Yes',
        no: 'No'
      },
      registrationType: {
        label: 'Company Registration Type <span class="asterisk">*</span>',
        commercial: 'Commercial Registration',
        taxId: 'Tax ID',
        uploadRegistration: {
          label: 'Company Registration Upload <span class="asterisk">*</span>',
          li1: 'A photo or scan of your Company Registration MUST be submitted for verification.',
          li2: 'If needed, you can submit your documents in the last step of the application, but proof of documentation is required for all selected fields.',
          li3: '(Company Affidavit Example)',
          li4: '(Partnership Affidavit Example)'
        },
        uploadCardId: {
          label: 'ID Card Upload <span class="asterisk">*</span>',
          li1: 'A photo or scan of your Personal ID Card MUST be submitted for verification',
          li2: 'If needed, you can submit your documents in the last step of the application, but proof of documentation is required for all selected fields.',
          li3: '(ID Card Example)'
        },
        vatUpload: {
          label: 'VAT Registration Upload <span class="asterisk">*</span>',
          li1: 'A photo or scan of your PP20 VAT Registration MUST be submitted for verification',
          li2: 'If needed, you can submit your documents in the last step of your application, but proof of documentation is required for all selected fields.',
          li3: '(PP20 VAT Registration Example)'
        },
        smeUpload: {
          label: 'SME Registration Upload <span class="asterisk">*</span>',
          li1: 'A photo or scan of your OSMEP Membership Certificate MUST be submitted for verification',
          li2: 'If needed, you can submit your documents in the last step of the application, but proof of documentation is required for all selected fields.',
          li3: '(OSMEP Membership Certificate Example)',
          li4: '(Registration Certificate (Tor Wor Chor 2) Example)',
          li5: '(SMCE Commercial Registration (Tor Wor Chor 3) Example)'
        }
      }
    }
  },
  facility: {
    label: 'Production Facility / Farm / Fishery Information *',
    name: 'Production Facility / Farm / Fishery Name',
    address: 'Production Facility / Farm / Fishery Address',
    sameAsCompany: 'Same as Company / Head Office Address',
    title: 'Production Facility / Farm / Fishery Photos',
    notices:
      '(If available, we encourage you to upload any photos you have. However, these are not required for application submission)',
    selection: {
      text: 'If available, we encourage you to upload any photos you have. However, these are not required for application submission'
    },
    farm: {
      label: 'Farm',
      li1: 'You selected "Farm." Please upload the following photos (if available).',
      li2: 'For in-depth instructions Learn More here.',
      li3: 'If needed, you can submit your photos in the last step of the application',
      farmEntrance: {
        title: 'Farm Entrance Photo',
        subtitle: 'A photo of the entrance to your farm with company signage in view.'
      },
      farmPlot: {
        title: 'Farming Plot / Greenhouse Photo',
        subtitle: 'A photo of planting plots with productive crops and/or cultivation greenhouses.'
      },
      livestock: {
        title: 'Livestock Stalls / Animals Photo',
        subtitle: 'A photo of animal stalls including livestock / poultry.'
      }
    },
    productionFacility: {
      label: 'Production Facility',
      li1: 'You selected "Production Facility." Please upload the following photos (if available).',
      li2: 'For in-depth instructions Learn More here.',
      li3: 'If needed, you can submit your photos in the last step of the application',
      front: {
        title: 'Front of Production Facility Photo',
        subTitle: 'A photo of the front of the production facility with company signage in view.'
      },
      sortingOrGrading: {
        title: 'Sorting or Grading Area Photo',
        subTitle: 'A photo of the sorting or grading area with staff and equipment in view.'
      },
      packingArea: {
        title: 'Packing Area Photo',
        subTitle: 'A photo of the packing area with staff and equipment in view.'
      },
      coldStorageArea: {
        title: 'Cold Storage Area Photo',
        subTitle: 'A photo of the cold storage area.'
      }
    },
    fishery: {
      label: 'Fishery',
      li1: 'You selected "Fishery." Please upload the following photos (if available).',
      li2: 'For in-depth instructions Learn More here.',
      li3: 'If needed, you can submit your photos in the last step of the application',
      fisheryPhoto: {
        title: 'Fishery Photo',
        subtitle: 'A photo of aquatic farming. '
      }
    },
    morePhoto: {
      more1: 'Do you have more photos that you think can support you application?',
      more2: 'You can add them or Learn More about our application photo guide'
    },
    addMore: 'Add Another Production Facility / Farm / Fishery Location',
    otherPhoto: {
      title: 'Other Photos',
      subtitle: `Do you have more photos that you think can support you application? Please add them here.`
    },
    example: '(Photo Examples)'
  },
  license: {
    title: 'Licenses & Certificates',
    subtitle:
      'Please tick any licences and certificates you have obtained and upload proof of documentation.',
    upload: {
      subtitle: 'Upload at least 1 certification',
      info: {
        farming: 'Upload at least 1 farming certification',
        manufacture: 'Upload at least 1 manufacturing certification'
      }
    },
    farmingCertification: {
      title: 'Farming Certification *',
      organic: {
        label: 'Organic',
        option1: 'Organic Thailand',
        option2: 'IFOAM Programme',
        option3: 'European Union Programme',
        option4: 'Canada Programme',
        option5: 'US Programme',
        upload: {
          organicThailand: {
            label: 'Organic Thailand Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your Organic Thailand Certificate MUST be submitted for verification',
            example: '(Organic Thailand Certificate Example)'
          },
          organicUSprogramme: {
            label: 'US Programme  Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your US Programme  Certificate MUST be submitted for verification',
            example: '(US Programme  Certificate Example)'
          },
          organicIFOAM: {
            label: 'IFOAM Programme Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your IFOAM Programme Certificate MUST be submitted for verification',
            example: '(IFOAM Programme Certificate Example)'
          },
          organicEUprogramme: {
            label: 'European Union Programme Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              ' A photo or scan of your European Union Programme Certificate MUST be submitted for verification',
            example: '(European Union Programme Certificate Example)'
          },
          organicCAprogramme: {
            label: 'Canada Programme Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your Canada Programme Certificate MUST be submitted for verification',
            example: '(Canada Programme Certificate Example)'
          },
          organicLiveStock: {
            label: 'Organic Livestock Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your Organic Livestock Certificate MUST be submitted for verification',
            example: '(Organic Livestock Certificate Example)'
          }
        }
      },
      GAP: {
        label: 'Good Agricultural Practices',
        option1: 'GAP (Vegetable & Fruits)',
        option2: 'Thai GAP',
        option3: 'Global GAP',
        upload: {
          gap: {
            label: 'GAP Vegetables & Fruits Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your GAP Vegetables & Fruits Certificate MUST be submitted for verification',
            example: '(GAP Vegetables & Fruits Certificate Example)'
          },
          gap_thai: {
            label: 'Thai GAP Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your Thai GAP Certificate MUST be submitted for verification',
            example: '(Thai GAP Certificate Example)'
          },
          gap_global: {
            label: 'Global GAP Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your Global GAP Certificate MUST be submitted for verification',
            example: '(Global GAP Certificate Example)'
          },
          gap_for_livestock: {
            label:
              'Good Agricultural Practices for Farm (Livestock and Layer Farm) Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your GAP for Farm Certificate MUST be submitted for verification',
            example: '(GAP for Farm (Livestock and Layer) Certificate Example)'
          },
          gap_for_fisheries: {
            label:
              'Good Agricultural Practices for Farm (Fisheries) Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your GAP for Farm (Fisheries) Certificate MUST be submitted for verification',
            example: '(GAP for Farm (Fisheries) Certificate Example)'
          }
        }
      },
      PGS: {
        label: 'Participatory Guarantee System (PGS)',
        option1: 'PGS Standard',
        option2: 'SDGsPGS',
        upload: {
          pgs_sdg: {
            label: 'SDGsPGS Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your SDGsPGS Certificate MUST be submitted for verification',
            example: '(SDGsPGS Certificate Example)'
          },
          pgs_standard: {
            label: 'PGS Standard Certificate Upload <span class="asterisk">*</span>',
            subtitle:
              'A photo or scan of your PGS Standard Certificate MUST be submitted for verification',
            example: '(PGS Standard Certificate Example)'
          }
        }
      },
      RSPO: {
        label: 'Roundtable on Sustainable Palm Oil (RSPO)',
        upload: {
          label:
            'Roundtable on Sustainable Palm Oil (RSPO) Certificate Upload <span class="asterisk">*</span>',
          subtitle: 'A photo or scan of your RSPO Certificate MUST be submitted for verification',
          example: '(RSPO Certificate Example)'
        }
      },
      livestockAndLayerFarm: {
        label: 'Good Agricultural Practices for Farm  (Livestock and Layer Farm)'
      },
      fisheries: {
        label: 'Good Agricultural Practices for Farm  (Fisheries)',
        upload: {
          label: 'GAP Fisheries Certificate Upload <span class="asterisk">*</span>',
          subtitle:
            'Photos of your GAP Fisheries Certificate MUST be submitted for verification (Certificate Example)'
        }
      },
      organicLivestock: {
        label: 'Organic Livestock'
      },
      other: {
        label: 'Other (Please Specify)'
      },
      noLicense: {
        label: 'No License or Certification'
      },
      hasLicense: {
        label: 'Yes, I have license/certification'
      }
    },
    manufacturingCertification: {
      label: 'Manufacturing Certification *',
      FDA: 'FDA Manufacturing License',
      slaughter: 'Slaughterhouse License (Pork / Chicken)',
      establishment: 'Establishment Approved for Export Manufacture',
      GMP_FDA: 'GMP FDA',
      HALAL: 'HALAL',
      KOSHER: 'KOSHER',
      GHPs: 'Good Hygiene Practices (GHPs)',
      HACCP: 'Hazard Analysis Critical Control Point (HACCP)',
      ISO: 'ISO 22000:2005',
      FSSC: 'FSSC 22000',
      BRCGS: 'Global Standard for Food Safety (BRCGS)',
      IFS: 'International Featured Standard (IFS)',
      RSPO: 'Roundtable on Sustainable Palm Oil (RSPO)',
      other: 'Other (Please Specify)',
      no: 'No License or Certification',
      upload: {
        HACCP: {
          label: 'HACCP Certificate Upload <span class="asterisk">*</span>',
          subtitle:
            'Photos of your HACCP Certificate MUST be submitted for verification Certificate Example'
        },
        ISO: {
          label: 'ISO 22000:2018 Certificate Upload <span class="asterisk">*</span>',
          subtitle:
            'Photos of your ISO 22000:2018 Certificate MUST be submitted for verification (Certificate Example)'
        }
      }
    },
    currentSaleChannel: {
      label: 'Current Sales Channels *',
      small: 'Mom & Pop Shops / Small Grocery Stores',
      convenience: 'Convenience Stores / Mini Marts',
      supermarket: 'Supermarkets / Hypermarkets',
      wetMarket: 'Wet Market / Flea Market',
      OTOP: 'Provincial OTOP Shops',
      cashCarry: 'Cash & Carry Stores',
      regional: 'Regional Wholesale Shopping Centers',
      horeca: 'HoReCa / Wholesale Distribution',
      online: 'Online',
      jingJai: 'Jing Jai Market',
      export: 'Export',
      other: 'Other (Please Specify)',
      notSelling: 'Not Currently Selling',
      selling: 'Yes, I have sales channels'
    },
    distribution: {
      title: 'Distribution Reach *',
      local: 'Local',
      provincial: 'Provincial',
      regional: 'Regional',
      national: 'National'
    }
  },
  product: {
    detail: {
      label: 'Product Details *',
      subtitle:
        'Please provide the product details and photos for <span class="emphasis">ALL PRODUCTS</span> you would like to propose for sale (At least 1 product required)',
      productType: {
        label: 'Product Type',
        option1: 'Perishable (< 6 months shelf life)',
        option2: 'Shelf Stable (> 6 months shelf life)',
        option3: 'Frozen (>12 months shelf life)'
      },
      productName: {
        label: 'Product Name'
      },
      brandName: {
        label: 'Brand Name'
      },
      capacity: {
        label: 'Average Daily Production Capacity (pieces)'
      },
      productCategory: {
        label: 'Product Category',
        snack: 'Snack',
        packaged: 'Packaged',
        cooking: 'Cooking',
        milk: 'Milk & Baby Food',
        beverage: 'Beverage',
        freshPackaged: 'Fresh Packaged',
        deli: 'Deli',
        bakery: 'Bakery',
        freshMeat: 'Fresh Meat ',
        freshSeafood: 'Fresh Seafood',
        produce: 'Produce'
      },
      productStorageTemp: {
        label: 'Product Storage Temperature',
        normal: 'Normal / Ambient temperature',
        chilled: 'Chilled',
        iced: 'Iced',
        frozen: 'Frozen',
        other: 'Other (Please Specify temperature)'
      },
      productBarcode: {
        label: 'Product has a Barcode',
        yes: 'Yes',
        no: 'No'
      },
      productBarcodeValue: {
        label: 'Input Barcode (13 digits)'
      },
      productExipration: {
        label: 'Product has an Expiry Date',
        yes: 'Yes',
        no: 'No'
      },
      productTesting: {
        label: 'Product has Testing / Analysis',
        yes: 'Yes',
        no: 'No'
      },
      productDistribution: {
        label: 'Product has Distribution System in Place',
        yes: 'Yes',
        no: 'No'
      },
      productDistributionType: {
        label: 'Distribution System Type',
        owned: 'Owned / Internal',
        external: 'External'
      },
      productWeight: {
        label: 'Product Weight / Volume (e.g. ml, g ...)'
      },
      shelfLife: {
        label: 'Shelf Life in Days'
      },
      topsBuyingPrice: {
        label: 'Selling Price to Tops ex. VAT'
      },
      retailSellingPrice: {
        label: 'Retail Selling Price at present'
      },
      productCertification: {
        label: 'Product Certification *',
        subtitle: 'Product Certification <span class="asterisk">*</span>',
        fda: 'FDA',
        organic: 'Organic',
        GI: 'GI',
        OTOP: 'OTOP',
        Copyright: 'Copyright',
        Halal: 'Halal',
        Kosher: 'Kosher',
        QCertificate: '“Q” Certificate',
        PGS: 'PGS',
        SDG: 'SDGs PGS',
        healthier: 'Healthier Choice',
        standard: 'Thai Community Product Standards',
        greenLabel: 'Green Label',
        rspo: 'Roundtable on Sustainable Palm Oil (RSPO)',
        other: 'Other (Please Specify)'
      }
    },
    upload: {
      label: 'Product Photos Upload <span class="asterisk">*</span>',
      subtitle: 'Upload at least 1 product image <span class="asterisk">*</span>',
      list1:
        'If needed, you can submit your photos in the last step of the application, but at least 1 product photo is required to submit your application.',
      list2: '(Product Photo Examples)',
      list3:
        'If needed, you can submit your photos in the last step of your application, but at least 1 photo is required to submit your application'
    },
    uploadDocument: {
      label: 'Upload Documents',
      info: 'Upload at least 1 product certification',
      nameLabel: 'Other {{name}} Certificate Upload <span class="asterisk">*</span>',
      subtitle:
        'If available, please upload your Company Profile and/or Product Catalog in order to give us a broad view of your business and product assortment.',
      note: 'All your data is protected by our personal data protection policy'
    },
    saveProduct: 'Save Product',
    addProduct: 'Add Another Product',
    link_faq:
      'If you are unsure of your Product Category click <a target="_blank" href="/contact">here</a> for more guidance'
  },
  aspiration: {
    title: 'Aspirations *',
    place: {
      label:
        'Where are you interested in selling your products with Central Food Retail Co., Ltd? <span class="asterisk">*</span>',
      topsMarket: 'Tops Market',
      centrall: 'Central Food Hall',
      topsDaily: 'Tops Daily',
      familyMart: 'Family Mart',
      topsOnline: 'Tops Online',
      wholeSale: 'Wholesale / HoReCa',
      privateLabel: 'Private Label',
      export: 'Export',
      other: 'Other (Please Specify)'
    },
    question: {
      label: 'Please tell us more about:',
      question1:
        '1. What are the 3 most important things we should know about your products? <span class="asterisk">*</span>',
      question2:
        '2. What are your aspirations in working with Central Food Retail Co., Ltd and why you think we should choose to partner with you? <span class="asterisk">*</span>'
    },
    reason: {
      label:
        'Please tell us what your aspirations are in working with Central Food Retail Co., Ltd and why you think we should choose to partner with you? <span class="asterisk">*</span>'
    },
    assistance: {
      title: 'Assistance',
      place: {
        label:
          'Please indicate any areas where you would need assistance from Central Food Retail Co., Ltd?',
        finane: 'Finance / Loan',
        license: 'Licensing for Production',
        fda: 'FDA Registration / Quality Assurance',
        other: 'Other (Please Specify)',
        retails: {
          label: 'Retail Stores',
          local: 'In your local Community',
          province: 'In your Province',
          region: 'In your Region',
          nationwide: 'Nationwide'
        }
      },
      feedback: {
        label: 'Have anything else to add? Please let us know here!'
      }
    },
    heardFrom: {
      label: 'How did you hear about us? *',
      text: 'Please let us know how you heard about Tops Tongtin <span class="asterisk">*</span>'
    },
    heardFromOptions: {
      tongtin_facebook: 'Tops Tongtin Facebook ads',
      tongtin_instagram: 'Tops Tongtin Instagram ads',
      tongtin_media: 'Tops Tongtin Media in Tops Market/ Central Food Hall stores',
      online_website: 'Tops Online website',
      facebook: 'Tops Thailand Facebook',
      instagram: 'Tops Thailand Instagram',
      google: 'Google ads',
      friends: 'Friends/ Word of mouth',
      event: 'Events in Central Department store area',
      roadShows: 'Roadshows in your area ',
      radio: 'Radio',
      news: 'Newspaper/ Magazine',
      netWorking: 'Networking events, e.g. governmental events, Thai Chamber of Commerce events',
      tops_local_sourcing: 'Tops Local Sourcing Team'
    }
  },
  document: {
    title: 'Document Submission Review',
    subtitle:
      'Please review all your document uploads carefully. All required documents as well as documents for selected fields must be uploaded before your application can be submitted. Once you have submitted, your application cannot be edited.',
    note: 'Note - Delays in document submission will delay the timeliness of your application review',
    companyDocument: {
      label: 'Company Documents',
      idCard: 'Applicant ID Card Upload (Document Example)',
      registration: 'Company Registration Upload (Document Example)',
      pp20: 'PP20 Document Upload (Document Example)'
    },
    licenseDocument: {
      label: 'Licenses & Certificates',
      haccap: 'HACCAP Certificate Upload  (Document Example)',
      iso: 'ISO Certificate Upload  (Document Example)'
    },
    productDocument: {
      label: 'Product Documents',
      fda: 'FDA Certificate Upload  (Document Example)',
      haccap: 'HACCAP Certificate Upload (Document Example)',
      iso: 'ISO Certificate Upload  (Document Example)'
    }
  },
  upload: {
    attach: 'Drag here or click to attach a file',
    product: 'Upload Product Photos <br /> Drag here or click to browse a file'
  },
  form: {
    addressNumber: 'Address Number',
    building: 'Building',
    floor: 'Floor',
    moo: 'Moo',
    soi: 'Soi',
    road: 'Road',
    zipCode: 'Zip Code',
    province: 'Province',
    district: 'District',
    subDistrict: 'Subdistrict',
    saveDraft: 'Save Draft',
    next: 'Next',
    previous: 'Previous',
    submit: 'Submit',
    register: 'Register',
    other: 'Other (Please Specify)',
    confirm: 'Confirm',
    pleaseSpecify: 'Please Specify',
    registration: 'Registration',
    sign_out: 'Sign Out',
    cancel: 'Cancel',
    example: 'Example',
    info_msg: {
      upload:
        'If needed, you can submit your documents in the last step of the application, but proof of documentation is required for all selected fields.'
    },
    error_msg: {
      required: 'Required',
      invalid_email: 'Invalid email',
      max_characters_1: 'Max 225 characters',
      max_characters_2: 'Max 100 characters',
      max_characters_3: 'Max 20 characters',
      select_product: 'Please select your product category!',
      number_employees: 'Please select your number of employees!',
      first_name: 'First name must be text',
      last_name: 'Last name must be text',
      phone_number: 'Phone number must be number',
      password: 'Password must contains at least 6 characters',
      confirm_password: 'Password does not match',
      only_digits: 'Must be only digits',
      exactly_digits: 'Must be exactly 5 digits',
      choose_option: 'Please select one of these options',
      choose_option_or_other: 'Please select one of these options of specify other',
      required_photo: 'Required at least 1 photo',
      question_validation_2:
        'Sorry, your number of employees exceeds our eligibility requirements; however, we are still interested in learning more about your products. Please go to this link to propose your products for sale through our normal contact channels: https://cgbpm.central.co.th/cfgworkflow/portal',
      question_validation_3:
        'Sorry, your revenue exceeds our eligibility requirements; however, we are still interested in learning more about your products. Please go to this link to propose your products for sale through our normal contact channels: https://cgbpm.central.co.th/cfgworkflow/portal',
      password_required_6: 'Required (minimum 6 characters)'
    },
    save: 'Save'
  },
  subTitle: {
    company: 'Please provide all required information (*) in the entry fields below',
    license: '', // TODO: Remove the section entirely or find new text for new title "Sales Channel"
    sale_channels: 'Please provide all required information (*) in the entry fields below',
    product:
      'Please provide product information and photos of your products highlights (At least 1 product required)',
    aspiration: 'Please provide all required information (*) in the entry fields below',
    document:
      'Please review all your document uploads carefully. All required documents as well as documents for selected fields must be uploaded before your application can be submitted. Once you have submitted, your application cannot be edited.'
  },
  type_of_business: {
    company: {
      label: 'Company Affidavit Upload <span class="asterisk">*</span>',
      text: 'A photo or scan of your Company Affidavit MUST be submitted for verification.',
      companyRegistrationNumber: 'Company Registration Number <span class="asterisk">*</span>',
      example: '(Company Affidavit Example)'
    },
    unregistered: {
      idCardNumber: 'ID Card number (Unregistered Partnership) <span class="asterisk">*</span>',
      idCardUpload: {
        label: 'ID Card Upload (Unregistered Partnership) <span class="asterisk">*</span>',
        text: 'A photo or scan of your ID Card MUST be submitted for verification.',
        example: '(ID Card Example)'
      }
    },
    registered: {
      registrationNumber:
        'Registration Number (Registered Partnership) <span class="asterisk">*</span>',
      registrationUpload: {
        label:
          'Partnership Affidavit Upload (Registered Partnership) <span class="asterisk">*</span>',
        text: 'A photo or scan of your Partnership Affidavit MUST be submitted for verification.',
        example: '(Partnership Affidavit Example)'
      }
    },
    limited: {
      registrationNumber:
        'Registration Number (Limited Partnership) <span class="asterisk">*</span>',
      upload: {
        label: 'Partnership Affidavit Upload (Limited Partnership) <span class="asterisk">*</span>',
        text: 'A photo or scan of your Partnership Affidavit MUST be submitted for verification.',
        example: '(Partnership Affidavit Example)'
      }
    },
    smce: {
      code: 'SMCE Code <span class="asterisk">*</span>',
      registrationCertificate: {
        label:
          'SMCE Registration Certificate (Tor Wor Chor 2) Upload <span class="asterisk">*</span>',
        text: 'A photo or scan of your Registration Certificate (Tor Wor Chor 2) MUST be submitted for verification.',
        example: '(Registration Certificate (Tor Wor Chor 2) Example)'
      },
      commercialCertificate: {
        label: 'SMCE Commercial Registration (Tor Wor Chor 3) Upload (optional)',
        text: 'A photo or scan of your Commercial Registration (Tor Wor Chor 3) is OPTIONAL.',
        li1: 'A photo or scan of your Commercial Registration (Tor Wor Chor 3) is OPTIONAL.',
        li2: 'If needed, you can submit your documents in the last step of the application.',
        example: '(SMCE Commercial Registration (Tor Wor Chor 3) Document Example)'
      }
    },
    individual: {
      idCardNumber: 'ID Card Number (Individual) <span class="asterisk">*</span>',
      idCardUpload: {
        label: 'ID Card Upload (Individual) <span class="asterisk">*</span>',
        text: 'A photo or scan of your ID Card MUST be submitted for verification.',
        li1: 'A photo or scan of your ID Card MUST be submitted for verification.',
        li2: 'If needed, you can submit your documents in the last step of the application, but proof of documentation is required for all selected fields.',
        example: '(ID Card Example)'
      }
    }
  },
  submission: {
    company: {
      vat: 'VAT Registration Upload <span class="asterisk">*</span>',
      sme: 'SME Registration Upload <span class="asterisk">*</span>',
      type_of_business: {
        company_affidavit: 'Company Affidavit Upload',
        unregistered: 'ID Card Upload (Unregistered Partnership)',
        registered: 'Partnership Affidavit Document Upload (Registered Partnership)',
        limited: 'Partnership Affidavit Document Upload (Limited Partnership)',
        smce: 'SMCE Registration Certificate / Tor Wor Chor 2  Upload',
        smce2: 'SMCE Commercial Registration / Tor Wor Chor 3 Upload (optional)',
        individual: 'ID Card Upload (Individual) <span class="asterisk">*</span>'
      }
    },
    facility: {
      facility_label: 'Photos Facility {{name}}',
      farm_label: 'Photos Farm {{name}}',
      fishery_label: 'Photos Fishery {{name}}',
      farm: {
        farmEntrance: {
          title: 'Farm Entrance Photo'
        },
        farmPlot: {
          title: 'Farming Plot / Greenhouse Photo'
        },
        livestock: {
          title: 'Livestock Stalls / Animals Photo'
        }
      },
      productionFacility: {
        front: {
          title: 'Front of Production Facility Photo'
        },
        sortingOrGrading: {
          title: 'Sorting or Grading Area Photo'
        },
        packingArea: {
          title: 'Packing Area Photo'
        },
        coldStorageArea: {
          title: 'Cold Storage Area Photo'
        }
      },
      fishery: {
        fisheryPhoto: {
          title: 'Fishery Photo'
        }
      }
    },
    license: {
      gap: 'GAP Certificate Upload <span class="asterisk">*</span>',
      pgs: 'PGS Certificate Upload <span class="asterisk">*</span>',
      organic: 'Organic Certificate Upload <span class="asterisk">*</span>',
      rspo: 'RSPO Certificate Upload <span class="asterisk">*</span>',
      gapForFarm: 'GAP for Farm Certificate Upload <span class="asterisk">*</span>',
      fisheries: 'Fisheries Certificate Upload <span class="asterisk">*</span>',
      fda: 'FDA Certificate Upload <span class="asterisk">*</span>',
      slaughterhouse:
        'Slaughterhouse License (Pork / Chicken) Certificate Upload <span class="asterisk">*</span>',
      export_manufacture:
        'Establishment Approved for Export Manufacture Certificate Upload <span class="asterisk">*</span>',
      gmp_fda: 'GMP FDA Certificate Upload <span class="asterisk">*</span>',
      halal: 'HALAL Certificate Upload <span class="asterisk">*</span>',
      kosher: 'KOSHER Certificate Upload <span class="asterisk">*</span>',
      ghps: 'Good Hygiene Practices (GHPs) Certificate Upload <span class="asterisk">*</span>',
      haccp:
        'Hazard Analysis Critical Control Point (HACCP) Certificate Upload <span class="asterisk">*</span>',
      iso: 'ISO 22000:2005 Certificate Upload <span class="asterisk">*</span>',
      fssc: 'FSSC 22000 Certificate Upload <span class="asterisk">*</span>',
      brcgs:
        'Global Standard for Food Safety (BRCGS) Certificate Upload <span class="asterisk">*</span>',
      ifs: 'International Featured Standard (IFS) Certificate Upload <span class="asterisk">*</span>',
      rspoManufacturing:
        'Roundtable on Sustainable Palm Oil (RSPO) Certificate Upload <span class="asterisk">*</span>',
      organicLiveStock: 'Organic Live Stock Certificate Upload <span class="asterisk">*</span>',
      other: 'Other (Please Specify) Certificate Upload <span class="asterisk">*</span>',
      fdaManufacturing: 'FDA Manufacturing License Upload <span class="asterisk">*</span>'
    },
    product: {
      label: 'Product Documents',
      productUploadCatalog: 'Upload Product Catalog / Product Photos',
      productUploadProfile: 'Upload Company Profile',
      fdaManufacturing: 'FDA Manufacturing License Upload <span class="asterisk">*</span>',
      fda: 'FDA Product Registration (Sorbor.7) Upload <span class="asterisk">*</span>',
      fda_label: 'FDA Product Label Upload <span class="asterisk">*</span>',
      organic: 'Organic Certificate Upload <span class="asterisk">*</span>',
      gi: 'GI Certificate Upload <span class="asterisk">*</span>',
      otop: 'OTOP Certificate Upload <span class="asterisk">*</span>',
      copyright: 'Copyright Certificate Upload <span class="asterisk">*</span>',
      halal: 'Halal Certificate Upload <span class="asterisk">*</span>',
      kosher: 'Kosher Certificate Upload <span class="asterisk">*</span>',
      q_certification: '“Q” Certificate Upload <span class="asterisk">*</span>',
      pgs: 'PGS Certificate Upload <span class="asterisk">*</span>',
      sdg: 'SDGs PGS Certificate Upload <span class="asterisk">*</span>',
      healthier: 'Healthier Choice Certificate Upload <span class="asterisk">*</span>',
      thai_community:
        'Thai Community Product Standards Certificate Upload <span class="asterisk">*</span>',
      green_label: 'Green Label Certificate Upload <span class="asterisk">*</span>',
      rspo: 'Roundtable on Sustainable Palm Oil (RSPO) Certificate Upload <span class="asterisk">*</span>',
      other: 'Other (Please Specify) Certificate Upload <span class="asterisk">*</span>'
    },
    guidance: {
      fda: 'A photo or scan of your FDA Product Registration (Sorbor.7) MUST be submitted for verification.',
      fda_label: 'A photo or scan of your FDA Product Label MUST be submitted for verification.',
      slaughterhouse:
        'A photo or scan of your Slaughterhouse License (Pork / Chicken) Certificate MUST be submitted for verification.',
      export_manufacture:
        'A photo or scan of your Establishment Approved for Export Manufacture Certificate MUST be submitted for verification.',
      gmp_fda: 'A photo or scan of your GMP FDA Certificate MUST be submitted for verification.',
      ghps: 'A photo or scan of your Good Hygiene Practices (GHPs) Certificate MUST be submitted for verification.',
      haccp:
        'A photo or scan of your Hazard Analysis Critical Control Point (HACCP) Certificate MUST be submitted for verification.',
      iso: 'A photo or scan of your ISO 22000:2005 Certificate MUST be submitted for verification.',
      fssc: 'A photo or scan of your FSSC 22000 Certificate MUST be submitted for verification.',
      brcgs:
        'A photo or scan of your Global Standard for Food Safety (BRCGS) Certificate MUST be submitted for verification.',
      ifs: 'A photo or scan of your International Featured Standard (IFS) Certificate MUST be submitted for verification.',
      rspoManufacturing:
        'A photo or scan of your Roundtable on Sustainable Palm Oil (RSPO) Certificate MUST be submitted for verification.',
      organic: 'A photo or scan of your Organic Certificate MUST be submitted for verification.',
      gi: 'A photo or scan of your GI Certificate MUST be submitted for verification.',
      otop: 'A photo or scan of your OTOP Certificate MUST be submitted for verification.',
      copyright:
        'A photo or scan of your Copyright Certificate MUST be submitted for verification.',
      halal: 'A photo or scan of your Halal Certificate MUST be submitted for verification.',
      kosher: 'A photo or scan of your Kosher Certificate MUST be submitted for verification.',
      q_certification: 'A photo or scan of your Q Certificate MUST be submitted for verification.',
      pgs: 'A photo or scan of your PGS Certificate MUST be submitted for verification.',
      sdg: 'A photo or scan of your SDGs PGS Certificate MUST be submitted for verification.',
      healthier:
        'A photo or scan of your Healthier Choice Certificate MUST be submitted for verification.',
      thai_community:
        'A photo or scan of your Thai Community Product Standards MUST be submitted for verification.',
      green_label:
        'A photo or scan of your Green Label Certificate MUST be submitted for verification.',
      rspo: 'A photo or scan of your Roundtable on Sustainable Palm Oil (RSPO) Certificate MUST be submitted for verification.',
      other: 'A photo of your Other Certificate MUST be submitted for verification.',
      fdaManufacturing:
        'A photo or scan of your FDA Manufacturing License MUST be submitted for verification.'
    },
    example_link_label: {
      fda: 'FDA Product Registration (Sorbor.7)',
      fda_label: 'FDA Product Label Example',
      fdaManufacturing: 'FDA Manufacturing License Example',
      organic: 'Organic Certificate Example',
      gi: 'GI Certificate Example',
      otop: 'OTOP Certificate Example',
      copyright: 'Copyright Certificate Example',
      halal: 'Halal Certificate Example',
      kosher: 'Kosher Certificate Example',
      q_certification: '“Q” Certificate Example',
      pgs: 'PGS Certificate Example',
      sdg: 'SDGs PGS Certificate Example',
      healthier: 'Healthier Choice Certificate Example',
      thai_community: 'Thai Community Product Standards Certificate Example',
      green_label: 'Green Label Certificate Example',
      rspo: 'Roundtable on Sustainable Palm Oil (RSPO) Certificate Example',
      gap: 'GAP Certificate Example',
      gapForFarm: 'Good Agricultural Practices for Farm (Livestock and LAYER FARM) Example',
      fisheries: 'Good Agricultural Practices for Farm (Fisheries) Example',
      slaughterhouse: 'Slaughterhouse License (Pork / Chicken) Certificate Example',
      export_manufacture: 'Establishment Approved for Export Manufacture Certificate Example',
      gmp_fda: 'GMP FDA Certificate Example',
      ghps: 'Good Hygiene Practices (GHPs) Certificate Example',
      haccp: 'Hazard Analysis Critical Control Point (HACCP) Certificate Example',
      iso: 'ISO 22000:2005 Certificate Example',
      fssc: 'FSSC 22000 Certificate Example',
      brcgs: 'Global Standard for Food Safety (BRCGS) Certificate Example',
      ifs: 'International Featured Standard (IFS) Certificate Example',
      rspoManufacturing: 'Roundtable on Sustainable Palm Oil (RSPO) Certificate Example'
    }
  },
  application: {
    title: 'Partnership Application Status',
    subtitle: 'Your Tops Tongtin Application is currently under review',
    subtitle2:
      'Please refer back to this page periodically to see the status of your application below.',
    application_status: 'Application Status',
    application_status_value: {
      received: 'Application received',
      review: 'Application Review and Document Screening',
      samples: 'Preliminary Samples and Pricing',
      validation: 'On site supplier validation',
      final: 'Final Selection Panel and Decision'
    },
    status: {
      selected: 'SELECTED',
      rejected: 'NOT SELECTED',
      need_review: 'NEEDS REVIEW',
      in_review: 'IN REVIEW',
      price_samples: 'SAMPLES',
      qa: 'QA / LS',
      final_panel: 'FINAL PANEL',
      hold_develop: 'HOLD / DEVELOP',
      redirected: 'REDIRECTED'
    },
    PROCESS: 'PROCESS',
    DETAILS: 'DETAILS',
    STATUS: 'STATUS',
    congrat: 'Congratulations! Your application has been approved!',
    congrat2:
      'Welcome to the Top’s TongTin Family. We will be in contact soon for supplier registration and order planning.',
    reject:
      'We thank you for taking the time to apply to Tops Tongtin, but we regret to inform you that your application has not been approved.',
    reject2: 'Below you can see the reason that you have not been selected.'
  },
  terms: {
    title: 'Terms Of Use',
    content: `
    <p>Welcome to Tops Tongtin Terms and Conditions page. Tops Tongtin (<a target="_blank" href="https://topstongtin.com">www.topstongtin.com</a>) is a new program by Central Food Retail Co., Ltd. to recruit and partner with the best micro and small SME Food and Beverage producers in Thailand. The micro and small SME Food and Beverage producers in Thailand who apply to the program will go through a selection process as explained in How it works (link to the page), and will be notified of the selection result by Tops Tongtin.
    To comply with the law, you hereby confirm that your age is above 10. If you are under the age of 10, you acknowledge that you must get permission from a parent or legal guardian to open an account and that parent or legal guardian must agree to this Terms and Conditions. If you do not understand this section, please do not create an account until you have asked your parent or legal guardian for a minor who is creating an account, you must accept this Terms and Conditions on the minor’s behalf and you will be responsible for all use of the account or company services using such account, whether such account is currently open or created later.
    </p>
    <h2>Terms and Conditions</h2>
    <p>Please read the terms of the use of this website carefully. The use of website and its functions means that you accept these terms. Do not use this website if you do not agree to be bound by such terms as such. Tops Tongtin reserve the right to change, limit, modify or cancel Terms and Conditions in whole or part at any time. You will be bound by any such changes. You should check such details at the time of use. By continually using this website you are agreeing to any and all changes that may occur. Although we have used our best efforts to ensure information about Central Food Retail Co., Ltd. (herein referred to as "Tops Tongtin") is correct and appropriate in this website. You should not conclude that the information provided is the most updated, or that this website will contain all the available information about Tops Tongtin. Furthermore, if you are making a decision to invest in a commercial agreement with Tops Tongtin, we urge you to consult all available sources thoroughly first.
    </p>
    <h2>Copyrights and Trademarks</h2>
    <p>All information in this website are covered in the form of the copyrighted work Copyright, Trademark and/or other intellectual property belonging to Tops Tongtin and/or its subsidiaries and affiliated brands or other parties that have allowed Tops Tongtin to use their intellectual property as its own. Tops Tongtin also reserve the right in any property and/or all other intellectual properties that may have not been clearly permitted for use in this website.</p>
    <h2>Private Use</h2>
    <p>You agree that you will not disclose your username and password of your account to a third party. You are solely responsible for maintaining the confidentiality of your Account and for all activities that occur on or through your Account. Tops Tongtin shall not be responsible for any losses arising out of the unauthorized use of your Account. You agree and undertake not to impersonate any person or to falsely state or otherwise misrepresent your affiliation with any person.</p>
    <h2>Personal Use</h2>
    <p>The information in this website is used for personal purposes only. You agree and accept not to distribute, transmit, alter or recreate or use the content that appears on this website and in its functionalities, in any way. You must not copy, distribute or display the information displayed in this website, in whole or in part, to a third party, you must not connect or link or repeat/imitate (mirror) any part of the website in any length. Please note that Tops Tongtin does not allow you to use, reproduce or distribute any information without the permission in writing from Tops Tongtin. Violation of these rights will result in legal proceedings as appropriate. You agree to indemnify, stand in legal defense, and keep Tops Tongtin from being damaged by the use of any data from this website without permission. You agree that the use of the contents and messages or the figures that appear in this website without permission may have detrimental effect to the highest degree and in the case of usage of information without permission Tops Tongtin is entitled to receive compensation for legal or equitable remedies as the owner, including restriction not to commit such violations.</p>
    <h2>Comments and Feedback</h2>
    <p>You agree that you are solely responsible and will be responsible for any content you send to the website in any length. You must not transmit any illegal information that may cause damage to reputation, obscene or pornographic material. You also agree that you will not send anything in a website that would violate the rights of any third parties, including any copyright, trademark, personal, private, confidential or any proprietary information to Tops Tongtin. We appreciate your interest in Tops Tongtin but we do not intend, and cannot accept any ideas or information regarding the design, technology to produce or other suggestions you may have or develop and that are owned by or are properties belonging to you, so if you submit any information and/or content to this website or its functionalities you are thereby allowing us the right to use this information and/or content without any charge; to use, reproduce, modify, transmit, display, translate, create derivative works and to disseminate this information/content without being limited. Through any means, and any channels of distribution and display either known presently or that may be developed in the future, in addition you certify that you hereby relinquish any credentials (Moral Rights) in the information/content you submit.
    </p>
    <h2>Waiver and Exemption to Damage for the contents</h2>
    <p>Tops Tongtin shall have no liability towards you and any third parties if any failures or delays in the contents of this website or for any actions arising from the use of such content. And to the extent that applicable by law, Tops Tongtin will not be liable for any damages directly or indirectly caused by accident, any consequential damages, special damages, or punitive damages arising as a result of the use of the contents of the website and its functionalities even though Tops Tongtin may have been advised of the possibility of damage. Tops Tongtin may be held liable for damages arising out of the show or present the information in bad faith by Tops Tongtin itself, in the event of death or personal damage caused by the negligence of Tops Tongtin. Tops Tongtin will use reasonable skill and care in the preparation of this website but does not guarantee the accuracy of the website, access to this website at any and all times, the reliability of the website to any length, or warrant that all functions within this website will be uninterrupted or error free. Tops Tongtin does not warrant that such defects will be corrected even if it has been made aware of said defects.
    </p>
    <h2>Events outside our control</h2>
    <p>Tops Tongtin will not be liable or responsible for any failure to perform or delay in performance of, any of our obligations that is caused by events outside our reasonable control (Force Majeure Event) or Acts of God. A Force Majeure Event includes any act, event, non-happening events or accident beyond our reasonable control and includes in particular (without limitation) the following:</p>
    <p>Civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war. Impossibility of the use of public or private telecommunications networks. Strikes, lock-outs or other industrial action. Storm, explosion, fire, flood, earthquake, subsidence, epidemic or other natural disaster. The acts, decrees, legislation, regulations or restrictions of any government. Impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport. During or in the event of an act of God.</p>
    <p>If our performance is deemed to be suspended for the period that the Force Majeure Event continues, we will have an extension of time for performance for the duration of that period. If we cannot find a solution to resolve the issue occurred, we do not take any responsibility for this.
    </p>
    <h2>Breaches of these terms and conditions</h2>
    <p>Without prejudice to Tops Tongtin's other rights under these terms and conditions, if you breach these terms and conditions in any way, Tops Tongtin may take such action as Tops Tongtin deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website and/or bringing court proceedings against you.</p>
    <h2>Assignment</h2>
    <p>Tops Tongtin may transfer, sub-contract or otherwise deal with Tops Tongtin's affiliates under these terms and conditions without notifying you or obtaining your consent. </p>
    <p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>
    <h2>Applicable Laws</h2>
    <p>These terms and conditions shall apply, and construed in accordance with Thai law. Any dispute relating to these Terms and Conditions shall be in accordance within the jurisdiction of the Thai courts only.</p>
    <h2>Privacy Policy</h2>
    <p>
    <strong>Central Food Retail Company Limited</strong> (the <strong>“Company,”</strong> <strong>“we,”</strong> <strong>“us,”</strong> or <strong>“our”</strong>) recognizes the importance of the protection of personal data for you of our products and services. We know you care how information about you is collected, used, disclosed, and transferred outside of Thailand. The information you share with us allows us to provide the products and services you need and want appropriately tailored for you, not only from us, but also those within Central Group's data ecosystem. We appreciate your trust that we will carefully and sensibly handle your personal data while giving you the very best personalized experience and customer services. 
    </p>
    <p>
    This privacy policy (this <strong>"Privacy Policy"</strong>) applies to our retail stores, websites, mobile applications, call center, social networking sites, online communication channels, and other locations where we collect your personal data. However, please read this Privacy Policy in conjunction with the terms and conditions of particular service that you use. (This may be provide separately according to the type of Personal Data that we collect from you)
    </p>
    <p>
    For the purpose of this Privacy Policy, <strong>“Personal Data”</strong> means any information relating to an identified or identifiable natural person.
    </p>
    <p>
    We reserve the right to modify this Privacy Policy from time to time, so please review it frequently to see when this Privacy Policy was last revised. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on our website or application. We will provide additional notice of significant updates. In case any modification deprives your rights of sensitive data in relation to this Privacy Policy, the Company will first obtain your consent, except as otherwise permitted by law.
    </p>
    
    <h3>1. What Personal Data we collect</h3>
    <p>We may collect or obtain the following types of information which may include your Personal Data directly or indirectly from you or other sources or through our affiliates, subsidiaries, business partners or other companies. The specific type of Personal Data collected will depend on the context of your interactions with us, and the services or products you need or want from us and companies in Central Group's data ecosystem. </p>
    <ol class="padding-left-3">
    <li>
    <strong>Personal details</strong>, such as title, full name, gender, age, occupation, qualifications, job title, position, business type, company name, nationality, country of residence, date of birth, marital status, number of family members and children, ages of children, information on government-issued cards (e.g., national identification number, photograph of the national identification, information on the national identification, control number on the reverse side of the national identification (Laser ID), social security number, passport number, tax identification number, driver's license details or similar identifiers), immigration details such as arrival and departure date, signature, voice, voice record, photograph, facial features for recognition, CCTV records, work place, education, insurance details, license plate details, house registration, household income, salary and personal income; 
    </li>
    <li>
    <strong>Contact details</strong>, such as postal address, delivery details, billing address, residential address, workplace address, address shown in the national identification card, telephone number, fax number, email address, LINE ID, Facebook account, Facebook ID, Google ID, Twitter ID, and other ID from social networking sites, your contact person's contact details (e.g., telephone number, contact data on any correspondence (e.g. written communication with you), and any other contact details you provided to us;
    </li>
    <li>
    <strong>Membership details</strong>, such as account details, member card number, reward points, member ID (e.g. The 1 member card number, The 1 ID, Siebel ID, member ID, customer ID), member type, customer type, member join/registration date and month, membership period, bank account and payment details, service and product applications (e.g. membership application, insurance application);
    </li>
    <li>
    <strong>Financial details</strong>, such as debit/credit card or bank information, credit/debit card number, credit card type, issuance/expiration date, cycle cut, account details, bank account details, prompt pay number payment details and records, your information regarding the risk profile for the business partner, credit rating and solvency, information in accordance with the declaration of suitability, suitability of   transaction and any other financial details;
    </li>
    <li>
    <strong>Transaction details</strong>, such as details about payment to and from you, payment date and/or time, payment amount, details about refund, refund amount, points, date and location of purchase, purchase/order number, appointment date for service, address/date and time for pick up or delivery, acknowledgement of receipt, recipient email’s signature, warranty details, complaints and claims, booking details, rental details, transaction, transaction history, location, transaction status, past sales transaction, status, transaction status, purchasing behaviour, and any other details of products and services you have purchased, including but not limited to any information incurring from using of products or services provided on our platform, such as e-pocket, digital asset, lending product, insurance product and the product related to wealth management, etc.;
    </li>
    <li>
    <strong>Technical details</strong>, such as Internet Protocol (IP) address, cookies, media access control (MAC) address, web beacon, log, device ID (such as international mobile equipment identifier (IMEI), electronic serial number (ESN), mobile equipment identifier (MEID) and serial number (SN)), device model and type, formats of software and hardware of the device when it is activated in the system, network, connection details, access details, single sign-on (SSO), login log, access time and location, time spent on the page, login data, GPS, latitude, longitude and time spent on each webpage, login information, applications downloaded on a communication devices,  search history, browsing details, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on devices you use to access the platform, including any other technical information arising from the use of our platform and systems;
    </li>
    <li>
    <strong>Behaviour details</strong>, such as information about your purchasing behavior and data supplied through the use of our products and services; 
    </li>
    <li>
    <strong>Profile details</strong>, such as your username and password, profile details and picture, purchases, historical orders, past orders, purchase history, items bought, item quantity, orders or product recalls made by you, orders via website, Cash On Delivery details, order ID, financial records, PIN, your interests, preferences, feedback and survey responses, satisfaction survey, social media engagement, participation details, loyalty programs, your use of discount codes and promotions, customer order description, customer service, attendance to trade exhibitions and events, trade exhibitions, litigation, testing and trials;
    </li>
    <li>
    <strong>Usage details</strong>, such as information on how you browse or use our websites, platform, application products and services, products in customer's cart, wish list record, remind me flash sale record, follow-shop record, and timestamp of last click and Q&A record;
    </li>
    <li>
    <strong>Marketing and communication details</strong>, such as your preference in receiving marketing from us, our affiliates, subsidiaries, business partners or other companies, and your communication preferences; and/or
    </li>
    <li>
    <strong>Sensitive data</strong>, such as race, religion, political opinions, fingerprints, facial recognition, person identity information (biometrics), face, information from the iris recognition, physical or mental health or condition, genetic data, medical history, disability and criminal records. 
    </li>
    </ol>
    <p>
    If you provide Personal Data of any third party to us, e.g., their name, family name, address details, and telephone number for emergency contact, family member income, or if you use the service on any of our platforms with your consent, we can access and collect third party personal information relating to you, such as  information on name, picture and/or phone number, as well as personal and contact information of family, friends, emergency contact persons, recommended persons or referrals accessible from your mobile number, etc.; please provide this Privacy Policy for their acknowledgement and/or obtaining consents if necessary.  
    </p>
    <p>
    We will only collect, use, or disclose sensitive data on the basis of your explicit consent or where permitted by law.
    </p>
    <p>
    We only collect the information of children, quasi-incompetent persons, and incompetent persons where their parent or guardian has given their consent. We do not knowingly collect information from customers under the age of 20 without their parental consent when it is required, or from quasi-incompetent persons and incompetent persons without their legal guardian's consent. In the event we learn that we have unintentionally collected personal information from anyone under the age of 20 without parental consent when it is required, or from quasi-incompetent persons and incompetent persons without their legal guardians, we will delete it immediately or process only if we can rely on other legal bases apart from consent.  
    </p>
    <h3>2. What Personal Data we collect</h3>
    <h4 class="padding-left-2" >2.1. The purpose for which your consent would be required</h4>
    <p>
    <strong>Marketing and Communications:</strong> We collect, use and disclose your Personal Data to provide privileges and promotions, discounts, special offers, advertisements, notices, news, information and any marketing and communications about the products and services from us, Central Group, our affiliates, subsidiaries and business partners which we cannot rely on other legal bases.
    </p>
    <p>
    Please contact us to manage your consent for marketing and communications. 
    </p>
    <h4 class="padding-left-2">2.2. The purposes we may rely on and other legal grounds for processing your Personal Data</h4>
    <p class="padding-left-3">
    We may also rely on (1) contractual basis, for our initiation or fulfilment of a contract with you; (2) legal obligation, for the fulfilment of the legal obligations; (3) legitimate interest, for the purpose of our legitimate interests and the legitimate interests of third parties; (4) vital interest, for preventing or suppressing a danger to a person’s life, body, or health; and/or (5) public interest, for the performance of a task carried out in the public interest or for the exercising of official authorities.
    </p>
    <p class="padding-left-3">
    We may collect, use and disclose your Personal Data for the following purposes
    </p>
    <ol class="padding-left-5">
    <li>
    <strong>To provide products and services to you:</strong> To enter into a contract and manage our contractual relationship with you; to support and perform other activities related to such services or products; to complete and manage bookings and to carry out financial transaction and services related to the payments including transaction checks, verification, and cancellation; to process your orders, delivery, and collections and returns; refund and exchange of products or services; to provide updates and on the delivery of the products, and to perform warehouse internal activities, including picking, packing, and labelling of packages; to verify warranty period; to provide aftersales services, including maintenance and facility reservation; 
    </li>
    <li>
    <strong>Marketing and Communications:</strong> To provide privileges, offers, updates, sales, special offers, promotions, advertisements, notices, news, information and any marketing and communications about the products and services from us, Central Group, affiliates, subsidiaries and business partners.
    </li>
    <li>
    <strong>Promotions, special offer, loyalty programs, reward programs, prize draws, competitions, and other offer promotions:</strong> To allow you to participate or earn promotions, special offers, loyalty programs, reward program, sweepstakes, privilege, prize draws, competitions, and other offer/promotions (e.g., sending you reminder emails and transferring your Personal Data to business partners ) to participate in activities and seminars, and all services related to advertising. This includes to process and administer your account registration, gift registration, event registration; for processing, collection, addition, exchange, earning, redemption, payment, and transfer of points; to examine your entire user history, both online and offline; to provide and issue gift vouchers, gift cards, and invoices;
    </li>
    <li>
    <strong>Registration and Authentication:</strong> To register, verify, prove, affirm, identify, and/or authenticate you or your identity;
    </li>
    <li>
    <strong>To manage our relationship with you:</strong> To contact and communicate with you as requested by you or in relation to the products and services you obtain from us, those within Central Group's data ecosystem, affiliates, subsidiaries and business partners; to handle customer service-related queries, request, feedback, complains, claims, disputes or indemnity; to provide technical assistance and deal with technical issues; to process and update your information; to facilitate your use of the products and services;
    </li>
    <li>
    <strong>Personalization, profiling and data analytics:</strong> To recommend products and services that might be of interest to you, identify your preferences and personalize your experience; to learn more about you, the products and services you receive and other products and services you may be interested in receiving; to measure your engagement with the products and services, undertake data analytics, data cleansing, data profiling, market research, surveys, assessments, behaviour, statistics and segmentation, consumption trends and patterns;   profiling based on the processing of your Personal Data, for instance by looking at the types of products and services that you use, how you like to be contacted; to know you better; to improve business performance; to better adapt our content to the identified preferences; to determine the effectiveness of the promotional campaigns, identify and resolve of issues with existing products and services; qualitative information development. For this purpose, we will collect, use and disclose your Personal Data for your interest and benefit and for legitimate interest and businesses of Central Group, affiliates, subsidiaries and our business partners where such interests and businesses are not overridden by your fundamental rights to personal data. We will request your consent where consent is required from time to time;
    </li>
    <li>
    <strong>To improve business operations, products, and services:</strong> To evaluate, develop, manage, and improve, research and develop the services, products, system, and business operations for you and all of our customers within Central Group's data ecosystem, including but not limited to our business partners; to identify and resolve issues; to create aggregated and anonymized reports, and measure the performance of our physical products, digital properties, physical measurement of products performance, digital features and marketing campaigns as well as developing business models, model for loan consideration, insurance and debt collection model;
    </li>
    <li>
    <strong>To learn more about you:</strong> To learn more about the products and services you receive, and other products and services you may be interested in receiving, including profiling based on the processing of your Personal Data, for instance by looking at the types of products and services that you use from us, how you like to be contacted and so on;
    </li>
    <li>
    <strong>Functioning of the sites, mobile application, and platform:</strong> To administer, operate, track, monitor, and manage the sites and platform to facilitate and ensure that they function properly, efficiently, and securely; to facilitate your experience on the sites and platform; improve layout, and content of the sites and platform;
    </li>
    <li>
    <strong>IT Management:</strong> For business management purpose including for IT operations, management of communication system, operation of IT security and IT security audit; internal business management for internal compliance requirements, policies, and procedures;
    </li>
    <li>
    <strong>Compliance with regulatory and compliance obligations:</strong> To comply with legal obligations, legal proceedings, or government authorities' orders which can include orders from government authorities outside Thailand, and/or cooperate with court, regulators, government authorities, and law enforcement bodies when we reasonably believe we are legally required to do so, and when disclosing your Personal Data is strictly necessary to comply with the said legal obligations, proceedings, or government orders. This includes to provide and handle VAT refund service; issue tax invoices or full tax forms; record and monitor communications; make disclosures to tax authorities, financial service regulators, and other regulatory and governmental bodies, and investigating or preventing crime;
    </li>
    <li>
    <strong>Protection of our interests:</strong> To protect the security and integrity of our business; to exercise our rights or protect our interest where it is necessary and lawfully to do so, for example to detect, prevent, and respond to fraud claims, intellectual property infringement claims, or violations of law; to manage and prevent loss of our assets and property; to secure the compliance of our terms and conditions; to detect and prevent misconduct within our premises which includes our use of CCTV; to follow up on incidents; to prevent and report criminal offences and to protect the security and integrity of our business;
    </li>
    <li>
    <strong>Fraud detection:</strong> To verify your identity, and to conduct legal and other regulatory compliance checks (for example, to comply with anti-money laundering regulations, and prevent fraud). This includes to perform sanction list checking, internal audits and records, asset management, system, and other business controls;
    </li>
    <li>
    <strong>Corporate transaction:</strong> in the event of sale, transfer, merger, reorganization, or similar event we may transfer your Personal Data to one or more third parties as part of that transaction;
    </li>
    <li>
    <strong>Risks:</strong> To perform risk management, audit performance, and risk assessments; and/or
    </li>
    <li>
    <strong>Life:</strong> To prevent or suppress a danger to a person’s life, body, or health.
    </li>
    </ol>
    <p>If you fail to provide your Personal Data when requested, we may not be able to provide our products and services to you. 
    </p>

    <h3>3. To whom we may disclose or transfer your Personal Data</h3>
    <p>
    We may disclose or transfer your Personal Data to the following third parties who collect, use and disclose Personal Data in accordance with the purposes under this Privacy Policy. These third parties may be located in Thailand and areas outside Thailand. You can visit their privacy policies to learn more details on how they collect, use and disclose your personal data as you could also be subject to their privacy policies.
    </p>
    <h4 class="padding-left-2" >3.1 The company within Central Group's data ecosystem</h4>
    <p class="padding-left-3">
    As Central Food Retail Company Limited is part of data ecosystem of Central Group which all collaborate and partially share customer services, products and systems including website-related platforms and systems, we may need to disclose or transfer your Personal Data to, or otherwise allow access to such Personal Data by other companies within Central Group's data ecosystem for the purposes set out in this Privacy Policy. Please see list of companies for further details here. 
    </p>
    <h4 class="padding-left-2" >3.2 Our service providers</h4>
    <p class="padding-left-3">
    We may use other companies, agents or contractors to perform services on behalf or to assist with the provision of products and services to you. We may share your Personal Data to our service providers or third-party suppliers including, but not limited to (1) infrastructure, internet, infrastructure technical, software and website, and IT service providers; (2) warehouse and logistic service providers; (3) payment service providers; (4) research agencies; (5) analytics service providers; (6) survey agencies; (7) auditors; (8) marketing, advertising media, and communications agencies; (9) call center; (10) campaign and event organizers; (11) sale representative agencies; (12) telecommunications and communication service providers; (13) payment, payment system, authentication, and dip chip service providers and agents; (14) outsourced administrative service providers; (15) data storage and cloud service providers; (16) verifying and data checking (Netbay and Department of Provincial Administration) service providers; (17) dispatchers; and/or (18) printing service providers.
    </p>
    <p class="padding-left-3">
    In the course of providing such services, the service providers may have access to your Personal Data. However, we will only provide our service providers with the Personal Data that is necessary for them to perform the services, and we ask them not to use your information for any other purposes. 
    </p>
    <h4 class="padding-left-2" >3.3 Our business partners</h4>
    <p class="padding-left-3">
    We may transfer your Personal Data to our business partners in the businesses of banking, finance, credit, loan, asset management, investment, insurance, credit cards, telecommunications, marketing, retail, e-commerce, warehouse, logistics, wellness, lifestyle products and services, spa and fitness, reward and loyalty program, and data analytics, including platform sellers or providers whom we may jointly offer products or services, or whose products or services may be offered to you. Data shared in this way will be governed by the third party’s privacy policy and not this Privacy Policy.  
    </p> 
    <h4 class="padding-left-2" >3.4 Social networking sites</h4>
    <p class="padding-left-3">
    We allow you to login on our sites and platforms without the need to fill out a form. If you log in using the social network login system, you explicitly authorize us to access and store public data on your social network accounts (e.g. Facebook, Google, Instagram), as well as other data mentioned during use of such social network login system. In addition, we may also communicate your email address to social networks in order to identify whether you are already a user of the concerned social network and in order to post personalized, relevant adverts on your social network account if appropriate.
    </p>
    <p class="padding-left-3">
    We also partner with certain third parties that allow you to enroll in their services or participate in their promotions. For example, certain companies allow you to use your loyalty program number or online services login to receive or register for their services. Additionally, your social network account provider allows you to connect your social network account to your online services account or log into your online services account from your social network account. When you enroll in those services, we will share your Personal Data to those third parties. If you do not want to share your Personal Data in this way, do not provide your loyalty or reward program number to third parties, do not use your online services account to register for third-party promotions and do not connect your online services account with accounts on third-party services. Data shared in this way will be governed by the third party’s privacy policy and not this Privacy Policy.   
    </p>
    <h4 class="padding-left-2" >3.5. Third parties required by law</h4>
    <p class="padding-left-3">
    In certain circumstances, we may be required to disclose or share your Personal Data in order to comply with a legal or regulatory obligations. This includes any law enforcement agency, court, regulator, government authority or other third party where we believe it is necessary to comply with a legal or regulatory obligation, or otherwise to protect our rights, the rights of any third party or individuals’ personal safety, or to detect, prevent, or otherwise address fraud, security, or safety issues. 
    </p>
    <h4 class="padding-left-2" >3.6. Professional advisors </h4>
    <p class="padding-left-3">
    This includes lawyers, technicians and auditors who assist in running our business, and defending or bringing any legal claims.
    </p>
    <h4 class="padding-left-2" >3.7. Associations </h4>
    <p class="padding-left-3">
    We may transfer your Personal Data to other member associations, such as Thailand E-Payment Association (TEPA), Electronic Transactions Development Agency (ETDA), the Association of Confederation of Consumer Organization, Thailand (ACCOT), Foundation for consumers, the Thai Chamber of Commerce, Thai E-Commerce Association, Thai Retailers Association, Thai Shopping Center Association and the Ratchaprasong Intersection Group. 
    </p>
    <h4 class="padding-left-2" >3.8 Assignee of rights and/or obligations</h4>
    <p class="padding-left-3">
    Third parties as our assignee, in the event of any reorganization, merger, business transfer, whether in whole or in part, sale, purchase, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock or similar transaction; will comply with this Privacy Policy to respect your Personal Data. 
    </p>

    <h3>4. International transfers of your Personal Data</h3>
    <p>
    We may disclose or transfer your Personal Data to third parties or servers located overseas, which the destination countries may or may not have the same equivalent level of protection for Personal Data protection standards. We take steps and measures to ensure that your Personal Data is securely transferred and that the receiving parties have in place an appropriate level of protection standards or other derogations as allowed by laws. We will request your consent where consent to cross-border transfer is required by law. 
    </p>
    <h3>5. How long do we keep your Personal Data</h3>
    <p>
    We retain your Personal Data for as long as is reasonably necessary to fulfil purpose for which we obtained it, and to comply with our legal and regulatory obligations. However, we may have to retain your Personal Data for a longer duration, as required by applicable law.
    </p>
    <h3>6. Security of your Personal Data</h3>
    <p>
    The Company recognizes the importance of maintaining the security of your Personal Data. Therefore, the Company endeavours to protect your information by establishing security measures for your Personal Data appropriately and in accordance with the confidentiality safeguard of Personal Data, to prevent loss, unauthorized or unlawful access, destruction, use, alteration, rectification or disclosure; provided, however, that the Company will ensure that the method of collecting, storing and processing of your Personal Data, including physical safety measures follow the information technology security policies and guidelines of the Company.
    </p>
    <h3>7. Cookies and how they are used</h3>
    <p>
    If you visit our websites, we will gather certain information automatically from you by using cookies. <br />

Cookies are small pieces of information or text issued to your computer when you visit a website and are used to store or track information about your use of a website and used in analyzing trends, administering our websites, tracking users’ movements around the websites, or to remember users’ settings. Some cookies are strictly necessary because otherwise the site is unable to function properly. Other Cookies allow us to enhance your browsing experience, tailor content to your preferences, and make your interactions with the site more convenient: they remember your username in a secure way, as well as your language preferences. <br />

Most Internet browsers allow you to decide whether or not to accept cookies. If you reject, remove or block Cookies can affect your user experience and without cookies, your ability to use some or all of the features or areas of our websites may be limited. <br />

In addition, some third parties may issue Cookies through our websites to serve ads that are relevant to your interests based on your browsing activities. These third parties may also collect your browser history or other information to determine how you reached our websites and the pages you visit when you leave our websites. Information gathered through these automated means may be associated with the Personal Data you previously submitted on our website.

    </p>
    <h3>8. Your rights as a data subject</h3>
    <p>
    Subject to applicable laws and exceptions thereof, you may have the following rights to:
    </p>
    <ul class="padding-left-3">
    <li>
    <strong>Access:</strong> You may have the right to access or request a copy of the Personal Data we are collecting, using or disclosing about you. For your own privacy and security, we may require you to prove your identity before providing the requested information to you. 
    </li>
    <li>
    <strong>Rectification:</strong>  You may have the right to have incomplete, inaccurate, misleading, or or not up-to-date Personal Data that we collect, use or disclose about you rectified.
    </li>
    <li>
    <strong>Data Portability:</strong> You may have the right to obtain Personal Data we hold about you, in a structured, electronic format, and to send or transfer such data to another data controller, where this is (a) Personal Data which you have provided to us, and (b) in the case where we are collecting, using or disclosing such data on the basis of your consent or to perform a contract with you. 
    </li>
    <li>
    <strong>Objection:</strong> You may have the right to object to certain collection, use or disclosure of your Personal Data such as objecting to direct marketing. 
    </li>
    <li>
    <strong>Restriction:</strong> You may have the right to restrict the use of your Personal Data in certain circumstances. 
    </li>
    <li>
    <strong>Withdraw Consent:</strong> For the purposes you have consented to our collecting, using or disclosing of your Personal Data, you have the right to withdraw your consent at any time. 
    </li>
    <li>
    <strong>Deletion:</strong> You may have the right to request that we delete or de-identity Personal Data that we collect, use or disclose about you, except we are not obligated to do so if we need to retain such data in order to comply with a legal obligation or to establish, exercise, or defend legal claims.
    </li>
    <li>
    <strong>Lodge a complaint:</strong> You may have the right to lodge a complaint to the competent authority where you believe our collection, use or disclosure of your Personal Data is unlawful or noncompliant with applicable data protection law.
    </li>
    </ul>
    <h3>9. Our Contact Details </h3>
    <p>
    If you wish to contact us to exercise the rights relating to your Personal Data or if you have any queries about your Personal Data under this Privacy Policy, please contact us or our Data Protection Officer at:
    </p>
    <strong>
    Central Food Retail Company Limited
    </strong>
    <ul class="padding-left-3">
    <li>
    Customer Service Department
    </li>
    <li>
    99/9 Central Plaza Cheangwattana Office Tower Building, 12,15-18 Floor, Moo 2, Cheangwattana Road, Bangtalad Sub-District, Pak Kret District, Nonthaburi 11120 Thailand
    </li>
    <li>
    Email: <a href="mailto:cusserv@tops.co.th">cusserv@tops.co.th</a>
    </li>
    <li>
    Tel: <a href="tel:026601000">02-660-1000</a>
    </li>
    </ul>
    <strong>
    Data Protection Officer
    </strong>
    <ul class="padding-left-3">
    <li>
    Data Protection Officer
    </li>
    <li>
    Data Protection Office, Central Group
22 Soi Somkid Ploenchit Road, Lumpini, Pathumwan, Bangkok, 10330 Thailan
    </li>
    <li>
    Email: <a href="mailto:dpo@central.co.th">email: dpo@central.co.th</a>
    </li>
    </ul>

    <strong>
    Cookie Policy
    </strong>
    <i>Last Update 27 May 2020</i>
    <p>
    <strong>Central Food Retail Company Limited</strong> (the <strong>“Company,”</strong> <strong>“we,”</strong> <strong>“us,”</strong> or <strong>“our”</strong>) recognizes the importance of the protection of personal data for you of our products and services. The goal of this Cookie Policy is to give you a better understanding of how your data is collected, used, transferred, and disclosed in both of our web-based applications and mobile applications which run external to a browser (“Website”). We want to ensure you also know how to check what cookies we are using, allowing you to more control. <br />
Below we categorize the types of cookies we use to give you a better understanding of why we use these cookies, or work with services which use these cookies. <br />
The information you share with us or with our partners through cookies allows us to provide the products and services you need and want, tailored for you. We appreciate your trust that we will carefully and sensibly handle your personal data while giving you the very best personalized experience and customer services. <br />
    </p>
    <h4>1. What are cookies and why do we use them? </h4>
<p>Cookies are small pieces of information or text placed on your computer when you visit a website. Some cookies are strictly necessary to enable the site to function properly. Other cookies allow us to enhance your browsing experience, gain a better understanding about your use of our Website, tailor content to your preferences, and make your interactions with the site more convenient. <br />
In addition, cookies are used to help personalize your interactions with our content on other websites (translation: advertising) based on your behavior on our site.
</p>
    <h4>2. What types of cookies do we use?</h4>
<p>
We use the following types of 1st Party Cookies and 3rd Party Cookies, which are described below. To understand better about 1st and 3rd Party Cookies:
</p>
<ul class="padding-left-3">
<li>
1st Party Cookies are cookies which are created by us, for reasons listed in the table below. The data will only be sent back to our Website and servers.
</li>
<li>
3rd Party Cookies are cookies which are created by the partners we use. Cookie data will be shared with those partners to enable the features and services they provide.
</li>
</ul>

<table class="terms-table">
  <tr>
    <th>Type of cookie</th>
    <th>What does it do?</th>
  </tr>
  <tr>
    <td colspan=2 style="text-align:center;" >1st Party Cookies</td>
  </tr>
  <tr>
    <td>Strictly necessary cookies</td>
    <td>These cookies are essential to enable you to move around the Website and use its features, such as accessing secure areas of the Website. We use strictly necessary cookies to make sure our digital services work securely, correctly and perform their basic functions. 
    </td>
  </tr>
  <tr>
    <td>Security cookies</td>
    <td>
    We use security cookies to make your interaction with our services faster and more secure. These cookies will help identify and prevent potential security risks.
    </td>
  </tr>
  <tr>
    <td>Functionality cookies</td>
    <td>
    These cookies allow the Website to recognize you and remember your preferences (such as your language of choice or the region you are in) and provide a more personalized experience. These cookies can also be used to remember changes you have made to text size, fonts and other parts of the web pages that you can customise. 
    </td>
  </tr>
  <tr>
    <td colspan=2 style="text-align:center;" >3rd Party Cookies</td>
  </tr>
  <tr>
    <td>Tracking cookies</td>
    <td>
    We use tracking cookies to measure your behavior on our site. Behavior can be defined by such things as how you arrived at the Website, what products you viewed, how deep in the Website did you browse, how long did you browse, among other things. <br />
    We use this information to enhance the performance of the Website, improve our display of products and services and overall improve your experience on the site.  
    </td>
  </tr>
  <tr>
    <td>Advertising cookies</td>
    <td>
    These cookies are used to deliver relevant advertising to you based on your behavior on our Website. They are also used to limit the number of times you see an advertisement, as well as help measure the effectiveness of our advertising campaigns. We share anonymised information about your browsing activity with our advertising and creative partners. 
    </td>
  </tr>
  <tr>
    <td>3rd Party Functionality </td>
    <td>
    Some partners enable us to significantly improve website experience with minimal software development on our side. For example, partners may help with enabling chat functions on the Website or enable coupons or messages to appear on the right pages. These partners will also need to place cookies to perform these services properly.
    </td>
  </tr>
</table>

<h4>2. What types of cookies do we use?</h4>
<p>
Most Internet browsers allow you to control whether to accept cookies, or you can download 3rd party “extensions” which give you precise control of your cookies. Due to the nature of some cookies, rejecting, removing, or blocking these cookies can affect your user experience and may block your ability to use some or all of the features or areas of our Website. If you would like to delete any cookies or if you change your mind, please refer to your browser’s settings or options page, or search for instructions using your favorite search engine. For major Internet browsers settings, please refer to these links:
</p>
<ul class="padding-left-3">
<li>
<a>Google Chrome</a>
</li>
<li>
<a>Safari</a>
</li>
<li>
<a>Microsoft Edge</a>
</li>
<li>
<a>Mozilla Firefox</a>
</li>
</ul>
<p>
Because applications do not run in a browser, the way to control the data shared is different, and different by your phone brand, model, or software. The two most common phone software, iOS and Android, have tools to enable you to control what is shared by apps, at an individual app level. Please refer to the settings of your phone to understand what control is available to you.
</p>
<h4>4. Our Contact Details </h4>
<p>
If you have any questions about our use of cookies, please contact us at:
</p>
<strong>
Central Food Retail Company Limited
</strong>
<ul class="padding-left-3">
<li>
Customer Service Department
</li>
<li>
99/9 Central Plaza Cheangwattana Office Tower Building, 12,15-18 Floor, Moo 2, Cheangwattana Road, Bangtalad Sub-District, Pak Kret District, Nonthaburi 11120 Thailand
</li>
<li>
99/9 Central Plaza Cheangwattana Office Tower Building, 12,15-18 Floor, Moo 2, Cheangwattana Road, Bangtalad Sub-District, Pak Kret District, Nonthaburi 11120 Thailand
</li>
<li>
    Email: <a href="mailto:cusserv@tops.co.th">cusserv@tops.co.th</a>
    </li>
    <li>
    Tel: <a href="tel:026601000">02-660-1000</a>
    </li>
</ul>
    `
  },
  how_it_work: {
    title: 'How it Works?',
    days: 'days',
    things_to_prepare: {
      title: 'Things to prepare',
      describe:
        'Before you begin the online application, it would be useful to prepare the following information and documents. It is NOT necessary for you to have all the documents listed below; but please supply as much information as possible to give us the fullest picture of your business.',
      alert: `
        You need only provide those documents that are relevant to you.<br />
        <strong>For example, if you have not obtained any certification including FDA, YOU CAN STILL APPLY.</strong><br />
        We are looking for great tasting products and are prepared to help you develop them.<br />
      `,
      company_doc: 'Company Documents',
      license_cert: 'Production Certifications',
      product_doc: 'Product Documents',
      content: {
        content_01_1: 'Number of Employees, 2021 Revenue Information and Sales Channel Information',
        content_01_2: 'Company and Facilities Address Information',
        content_01_3: 'Identification Card',
        content_01_4: 'Relevant Company Documents, for example',
        example_01: 'a. Company Affidavit',
        example_02: 'b. Partnership Affidavit Documents',
        example_03:
          'c. SMCE Registration Certificate and Registration (Tor Vor Chor 2 and Tor Vor Chor 3)',
        example_04: 'd. VAT Registration (PP20)',
        example_05: 'e. SME registration (OSMEP)',
        content_01_5: 'Production Facility / Farm / Fishery Photos',
        content_02_01: 'Farming Certifications (e.g., Organic, GAP, etc.)',
        content_02_02: 'Manufacturing Certifications (e.g., GMP, HACCP, etc.)',
        content_03_01: 'Company Profile and Catalogs',
        content_03_02: 'Product Photos, Product labels and Product Specifications',
        content_03_03: 'Product Licenses and Certificates (e.g., FDA, OTOP, etc.)'
      }
    },
    subtitle_1: 'Check if you qualify',
    answer1: `
    <div class="text-align-left">
      • Tops Tongtin is intended for micro and small Food and Beverage SME's with <span class="font-size-small">&le;</span> 50 employees and <span class="font-size-small">&le;</span> 100 million baht annual revenue. <br />
      • Tops Tongtin is reserved for SME's who produce wholly or partially in Thailand. Our program is not intended for importers. <br />
      • Tops Tongtin SME's are NOT required to be registered with OSMEP (Office of SMEs Promotion).
    </div>
    `,
    subtitle_2: 'Fill in and submit your online application form',
    answer2:
      'Once you have registered your account, you can edit your application as you need before final submission.',
    subtitle_3: 'Application Review and suppliers contacted by Tops Tongtin Team',
    answer3_timeline: 'Approximate timeline',
    answer3_timeline_remark:
      'The Timeline below depends on the number of applications and responsiveness of individual suppliers',
    answer3_01: 'A) Initial review of online application',
    answer3_02: 'B) Pricing and Samples',
    answer3_03: 'C) QA Validation',
    answer3_04: 'D) Final Selection Panel Review',
    subtitle_4:
      'Exclusive Partners selected for further development or registered for sale with Central Food Retail Co., Ltd.'
  },
  homepage: {
    local: 'Local Goods From Great SME’s',
    local_sub: 'Are you trying to grow your business and sell your products in supermarkets?',
    local_sub_2: 'Tops Tongtin is for you!',
    local_sub_3: 'Here is how our program is different:',
    local_sub_4: `<ul class="padding-left-5">
    <li>Get in touch with us easily</li>
    <li>Manage your own application</li>
    <li>Stay updated on your status</li>
    <li>Get end-to-end support</li>
    <li>Get paid fast and easy access to financing</li>
  </ul>`,
    what_you_get: {
      title: 'What You Get!',
      get1: '15 Days Accelerated Payment',
      get2: 'No Registration or Listing Fees',
      get3: 'Access to all Central Food Retail Sales Channels',
      get4: 'Licensing and Financing support',
      get5: 'Marketing Support'
    },
    how_it_work: {
      title: 'How it Works?',
      how1: 'Check if you qualify',
      how2: 'Submit online form',
      how3: 'Application review',
      how4: 'Exclusive partner selected'
    },
    our_partner: 'Our Partners',
    ready: 'Ready to Become Our Partner?',
    more_question: 'Have more questions?',
    learn: 'Learn More',
    prepare: 'See things to prepare',
    register: 'Register'
  },
  what_you_get_page: {
    title: 'What You Get!',
    content1_1: '15 Days Accelerated Payment',
    content1_2:
      'Our faster payment terms are half of the standard 30 days, so you have the cash flow to keep your business growing!',
    content2_1: 'No Registration or Listing Fees',
    content2_2: 'Standard registration and listing fees are waived!',
    content3_1: 'Access to all Central Food Retail Sales Channels',
    content3_2:
      'If selected for Tops Tongtin, you will gain access to our numerous sales channels through Tops , Tops Food Hall, Tops Daily and Tops Online as well as opportunities for private label, B2B, and export. ',
    content4_1: 'Licensing and Financing support',
    content4_2: `
      <p>If you need, we can help you navigate the FDA and production certification procedures to take your products to a higher level; as well as facilitate access to financial assistance through our banking partners.</p>
      <p style="margin:20px 0 5px; font-family: PSL Kanda Extra Pro; font-weight: 400;">Our partners:</p>
      <div style="display:inline-flex;gap:20px;align-items:center;">
        <img src="/imgs/logo-scb.png" />
        <img src="/imgs/logo-ttb.png" />
        <img src="/imgs/logo-investree.png" />
      </div>
    `,
    content5_1: 'Marketing Support',
    content5_2:
      'We will market your brand  across our online and offline channels so that your products will shine and your story will be shared on.'
  },
  about: {
    title: 'Who we are?',
    text1: `
    Hello, micro and small SME Food and Beverage Producers! Are you trying to grow your
    business and sell your products in supermarkets? Have certification requirements,
    costly listing fees or complicated registration processes got in your way? <br />
    <br />
    Tops Tongtin is for you! We are a new division of Central Food Retail Co., Ltd.
    (e.g., Tops Market and Central Food Hall) dedicated to recruiting and partnering
    with the best micro and small SME Food and Beverage producers in Thailand.
    `,
    text2: `
    The idea for Tops Tongtin grew out of a Central Group company-wide online
    competition in August 2021. Finishing at the top of the contest, a team of food
    enthusiasts from Central Food Retail Co., Ltd. were inspired by the desire to see
    more local producers, small but fabulous, shine on the national stage. <br /> <br />
    Since then, Tops Tongtin has evolved into its own team with an agile start-up spirit and the ambition to propel the growth of Thailand's terrific Food and Beverage SME's. This includes producers of all shapes and sizes whether registered or non-registered individuals, companies, communities, farmers, OTOP producers or small manufacturers. <br /> <br />
    We want to support and foster exclusive partnerships with the best SME producers in
    Thailand because we believe that the success of small local businesses is key to a
    healthy and diverse economy. <br /> <br />
    This is why we have designed our program to be different than what's currently available in the market. <br /> <br />
    To apply, fill in the application form, upload your information and we will get in
    touch. Don’t fret if you don’t have all the answers now, all you need are
    outstanding products and the ambition to grow your business.
    `,
    title_unique: 'What makes Tops Tongtin unique?',
    text_unique: `
    <ul class="padding-left-4">
      <li><span class="font-weight-bold">Get in touch with us easily:</span> Our website simplifies how suppliers can reach us, acting as a single point of contact for SME's to present their products to us.</li>
      <li><span class="font-weight-bold">Manage your own application:</span> Producers have the flexibility to log in and edit their information (submitting when they are ready) so that we can get the most complete picture of their business and respond in a timely fashion.</li>
      <li><span class="font-weight-bold">Stay updated on your status:</span> Suppliers can also monitor the status of their application along the way, so they can stay informed about where they stand.</li>
      <li><span class="font-weight-bold">Get end-to-end support:</span> Our program will guide the process from end to end, from FDA registration support to marketing the products for sale.</li>
      <li><span class="font-weight-bold">Get paid fast and easy access to financing:</span> Tops Tongtin partners will gain monetary benefits including faster payment turnaround and access to financial assistance through our banking partners.</li>
    </ul>`
  },
  faq_page: {
    title: 'Frequently asked questions',
    content: {
      question1: 'What is Tops Tongtin?',
      answer1: `
      Tops Tongtin (<a target="_blank" href="https://www.topstongtin.com">www.topstongtin.com</a>) is a new program by Central Food Retail Co., Ltd. to recruit and partner with the best micro and small SME Food and Beverage producers in Thailand.
      <br />
      For more details click <a target="_blank" href="/about">here</a>`,
      question2: 'What qualifies as a micro or small SME Food and Beverage producer?',
      answer2: `
      • A micro and small Food and Beverage SME has <span class="font-size-small">&le;</span> 50 employees and <span class="font-size-small">&le;</span> 100 million baht annual revenue.
      <br />
      • The SME must manufacture their product wholly or partially in Thailand. Importers do not qualify for our program.
      <br />
      • Tops Tongtin SME's are NOT required to be registered with OSMEP (Office of SMEs Promotion).
      <br />
      • SME's can be
      <br /> 
      
        <p class="padding-left-5">1) a Company (Listed or Limited Liability)</p>
        <p class="padding-left-5">2) a Partnership (Unregistered, Registered or Limited)</p>
        <p class="padding-left-5">3) a SMCE (small and micro community enterprise)</p>
        <p class="padding-left-5">4) an Individual</p>
      `,
      question3: 'Why should you apply?',
      answer3: `Tops Tongtin exclusive partners earn multiple benefits including:
      <br />
      <ul class="padding-left-5">
        <li>Access to all Central Food Retail Co., Ltd sales channels</li>
        <li>No Registration or Listing Fees</li>
        <li>15 Days Accelerated Payment</li>
        <li>Licensing and Financing support</li>
        <li>Marketing Support</li>
      </ul>
      For more details click <a target="_blank" href="/what-you-get">here</a>`,
      question4:
        "How is Tops Tongtin different from the standard entrance procedures for potential Tops' suppliers?",
      answer4: `
      • Normally, interested suppliers can contact our buyers through conventional communication channels (e.g., telephone, email, etc...).
      <br />
      • The Tops Tongtin website is an open and singular point of entry for small and micro SME Food and Beverage producers interested in applying to be our partners.
      <br />
      • On the website, SME's can present all of their relevant information for our consideration as well as keep track of their application status in one place.
      <br />
      • The consolidation of supplier data on one platform allows us to get a more complete picture of individual applicants and therefore improve processing times.
      <br />
      • For more details click <a target="_blank" href="/how-it-works">here</a>`,
      question5: 'What / Who are we looking for?',
      answer5: `
      • We are looking for outstanding products at all stages of development made by the best micro and small SME Food and Beverage producers in Thailand.
      <br />
      • Micro and small SME Food and Beverage producers who have the ambition to grow their business in partnership with us.
      <br />
      • For more details click <a target="_blank" href="/how-it-works">here</a>`,
      question6: 'Do you need to fill in and upload everything that is listed in the form?',
      answer6: `
      • We understand that the amount of information requested may seem daunting, but please don't be intimidated if you do not have an answer for everything.
      <br />
      • You are only required to fill out the fields with an asterisk (*).
      <br />
      • However, please provide us with as much information and proof of documentation as possible so we can have the fullest picture of you and can process your application promptly.
      <br />
      • Remember we are looking for outstanding products at all stages of development made by the best micro and small SME Food and Beverage producers in Thailand. If you have fantastic products and the ambition to grow your business, we want to partner with you.
      <br />
      • For documents that will be useful for your application, please click <a target="_blank" href="/how-it-works">here</a>`,
      question7: 'How long does it take to process an application?',
      answer7: `
      We aim to process each application in 30 days on average, though the timeline will depend on the number of applications we receive and responsiveness of individual suppliers, among other factors.
      <br />
      For more details click <a target="_blank" href="/how-it-works">here</a>`,
      question8: 'How many products can you submit?',
      answer8:
        'You can submit as many products as you like, though we recommend you give us a broad view of your products in the product overview section and focus on your product highlights in the individual product details section.',
      question9: 'To which sales channels will Tops Tongtin partners gain access?',
      answer9:
        'If selected to be a Tops Tongtin exclusive partner, you will be eligible to sell through any channels that both you and our buyers deem appropriate. The possibilities include Tops, Tops Food Hall, Tops Fine Food, Tops Club and FamilyMart (online and offline); as well as private label, B2B, and export opportunities.',
      question10: 'What category is my product?',
      answer10: `
        <table class="terms-table">
          <tr>
            <th>Category</th>
            <th>Example of products in category</th>
          </tr>
          <tr>
            <td>Snack</td>
            <td>Chips / Dried Seafood Snacks / Nuts / Dried Fruit Snacks / Dried Meat Snacks / Chocolate / Candy / Breath Fresheners / Chewing Gum / Gummies / Candy / Biscuits / Crackers
            </td>
          </tr>
          <tr>
            <td>Packaged</td>
            <td>
            Dry Beverage / Healthy packaged products / Spreads & Dressings / Ready to Eat or Heat packaged food / Instant Noodle & Soup
            </td>
          </tr>
          <tr>
            <td>Cooking</td>
            <td>
            Rice & Noodles / Oils / Cooking Sauces / Seasonings / Baking & Dessert
            </td>
          </tr>
          <tr>
            <td>Milk & Baby Food</td>
            <td>
            Baby milk powder/ Baby Foods / Maternal Food
            </td>
          </tr>
          <tr>
            <td>Beverage</td>
            <td>
            Carbonated Soft Drinks / Functional Drink / RTD Tea & Coffee / Drinking water / Fruit Juice / Concentrated Drinks
            </td>
          </tr>
          <tr>
            <td>Fresh Packaged</td>
            <td>
            Milk / Yogurt / Ice Cream / Chilled Beverages / Frozen products
            </td>
          </tr>
          <tr>
            <td>Deli</td>
            <td>
            Cheese / Deli Meats / Eggs
            </td>
          </tr>
          <tr>
            <td>Bakery</td>
            <td>
            Breads / Cakes
            </td>
          </tr>
          <tr>
            <td>Fresh Meat</td>
            <td>
            Beef / Pork / Poultry / Specialty Meat
            </td>
          </tr>
          <tr>
            <td>Fresh Seafood</td>
            <td>
            Fresh Fish / Fresh Seafood / Dried Seafood
            </td>
          </tr>
          <tr>
            <td>Produce</td>
            <td>
            Vegetables / Fruits / Plants & Flowers
            </td>
          </tr>
        </table>
        `,
      question11: `Is there any application fee?`,
      answer11: `No. A key feature of our program is the dedication to support SME’s in saving on upfront costs and expenses as much as possible. Therefore, you can apply to Tops Tongtin for free. There is no extra cost for this. You’ll only have to have internet, a device and your ambition.`,
      question12: `Do I need to have an FDA license?`,
      answer12: `No, you don’t need to have an FDA license. We are open for SME’s with different levels of readiness, even those without company registration. What we ask for is great products. We love speaking with suppliers in early stages of development because together we can sharpen ideas for product and packaging development which will help speed up the registration process in the long run and give your products the best chance to shine on the shelves.`,
      question13: `Can I apply if I am already selling with Tops or any other Central Food Retail Group business units?`,
      answer13: `If you are already selling with Central Food Retail Group but would like to apply for Tops Tongtin, please do not submit an application yet. First, please email us at <a href="mailto:topstongtin@tops.co.th">topstongtin@tops.co.th</a> with your company name, products you are currently selling with Tops and your contact information; and we will get back to you as soon as possible.`,
      question14: `Who should I contact in case of technical issues on the website?`,
      answer14: `If you are experiencing any technical issues, please email us at <a href="mailto:topstongtin@tops.co.th">topstongtin@tops.co.th</a> or message us on Facebook <a target="_blank" href="https://www.facebook.com/topstongtin">https://www.facebook.com/topstongtin</a>`,
      question15: `Does Tops Tongtin use a consignment model?`,
      answer15: `If you apply and your products get selected, we will buy your products outright with 15 day credit terms. There is no consignment in our trading agreements. We want to simplify our terms with producers so they can focus on making the best products and we can focus on selling them.`
    },
    side: {
      more_question: 'Still have more questions or experiencing any technical issues?',
      contact_us: 'Contact us at',
      address: `
      Central Plaza Chaengwattana Office Tower (12, 15-18th Fl.), <br />
      99/9 Moo 2, Chaengwattana Road, Bangtalad, Pak Kret, Nonthaburi 11120
      `
    }
  },
  navigator: {
    who_we_are: 'Who we are',
    what_you_get: 'What you get',
    how_it_works: 'How it works',
    faq: 'FAQ',
    login: 'Log in',
    register: 'Register'
  },
  footer: {
    information: {
      title: 'Information',
      how_it_works: 'How it works',
      prepare: 'Things to prepare',
      what_you_get: 'What you get'
    },
    support: {
      title: 'Support',
      faq: 'FAQ',
      contact: 'Contact us'
    },
    company: {
      title: 'Company',
      about: 'About us',
      legal: 'Legal'
    },
    line: `Tops Tongtin. Local Goods From Great SME’s`
  },
  form_agreement: {
    gratitude: 'Thank you for your application to Tops Tongtin.',
    check_again: 'Please check the boxes below in order to complete your submission!',
    agreement: `I accept the <a target="_blank" href="/terms">Terms and Conditions</a> and acknowledged the <a target="_blank" href="/terms">Privacy Policy</a>.`,
    receive:
      'I would like to receive my privileges, offers and updates from Tops Tongtin, Central Group and partners as specified in Privacy Policy.'
  },
  forgot_password: {
    title: 'FORGOT YOUR PASSWORD?',
    subtitle:
      'Enter your email address below and we will send you an email with a link to reset your password.',
    complete: ' Check your email to reset your password',
    error: 'Something went wrong'
  },
  reset_password: {
    title: 'RESET PASSWORD',
    subtitle: 'Your password has been reset'
  },
  cookie_banner: {
    text: 'This Website uses cookies. Please accept our cookie policy for your best experience. You can learn more on how to adjust your cookie setting in <a href="/terms" target="_blank">our cookie policy here.</a>',
    accept: 'Accept & Continue',
    decline: 'Close'
  }
};
