import { aspirationSchema, initialAspirationValues } from './aspiration';
import { companySchema, initialValuesCompany } from './company';
import { initialValuesLicense, licenseSchema } from './license';
import { initialProductValues, productSchema } from './product';

export enum InitialValuesConstant {
  COMPANY = 0,
  LICENSE = 1,
  PRODUCT = 2,
  ASPIRATION = 3
}

export const initialValues = [
  initialValuesCompany,
  initialValuesLicense,
  initialProductValues,
  initialAspirationValues
];

export const validationSchema = [
  companySchema,
  licenseSchema,
  productSchema,
  aspirationSchema,
  null
];
