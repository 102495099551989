import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  homepage__container: {
    background: theme.palette.gray_bg.main
  },
  home__context__container: {
    padding: `${theme.spacing(0)} !important`
  },
  paper__container: {
    background: '#FFFFFF!important',
    boxShadow: `${theme.boxShadow.static}!important`,
    [theme.breakpoints.up('xs')]: {
      borderRadius: `${theme.spacing(2.5)} !important`,
      padding: `0 ${theme.spacing(4)}`
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: `${theme.spacing(8)} !important`,
      padding: `0 ${theme.spacing(10)}`
    }
  },
  title_text: {
    [theme.breakpoints.up('xs')]: {
      fontSize: `${theme.spacing(6)} !important`,
      lineHeight: `48px !important`
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${theme.spacing(10)} !important`,
      lineHeight: `64px !important`
    }
  },
  content_text: {
    [theme.breakpoints.up('xs')]: {
      fontSize: `${theme.spacing(3)} !important`,
      lineHeight: `${theme.spacing(3)} !important`
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${theme.spacing(4)} !important`,
      lineHeight: `${theme.spacing(4)} !important`
    }
  },
  what_you_get__container: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  what_you_get__image: {
    [theme.breakpoints.up('xs')]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      alignSelf: 'center'
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(15),
      height: theme.spacing(14)
    }
  },
  how_it_works__image: {
    [theme.breakpoints.up('xs')]: {
      alignSelf: 'center',
      width: theme.spacing(10),
      height: theme.spacing(10)
    },
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      width: theme.spacing(15),
      height: theme.spacing(15)
    }
  },
  ready__container: {
    textAlign: 'center',
    padding: `0 0 ${theme.spacing(10)}`,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(15)
    }
  }
}));

export default useStyles;
