import { Manufacturing } from './licenses';

export const farmingCerts = [
  {
    text: 'gap',
    value: 'GAP (Vegetable & Fruits)'
  },
  {
    text: 'gapThai',
    value: 'Thai GAP'
  },
  {
    text: 'gapGlobal',
    value: 'Global GAP'
  },
  {
    text: 'orgaThai',
    value: 'Organic Thailand'
  },
  {
    text: 'orgaIfoam',
    value: 'IFOAM Programme'
  },
  {
    text: 'orgaEuro',
    value: 'European Union Programme'
  },
  {
    text: 'orgaCanada',
    value: 'Canada Programme'
  },
  {
    text: 'orgaUs',
    value: 'US Programme'
  },
  {
    text: 'orgaLiveStock',
    value: 'Organic Livestock'
  },
  {
    text: 'pgsStandard',
    value: 'PGS Standard'
  },
  {
    text: 'pgsSdg',
    value: 'SDGsPGS'
  },
  {
    text: 'rspo',
    value: 'Roundtable on Sustainable Palm Oil (RSPO)'
  },
  {
    text: 'gapForFarm',
    value: 'Good Agricultural Practices for Farm  (Livestock and LAYER FARM)'
  },
  {
    text: 'fisheries',
    value: 'Good Agricultural Practices for Farm (Fisheries)'
  }
];

export const manufacturingCerts = [
  {
    text: 'fdaManufacturing',
    value: Manufacturing.FDA
  },
  {
    text: 'slaughterhouse',
    value: 'Slaughterhouse License (Pork / Chicken)'
  },
  {
    text: 'export_manufacture',
    value: 'Establishment Approved for Export Manufacture'
  },
  {
    text: 'gmp_fda',
    value: 'GMP FDA'
  },
  {
    text: 'halal',
    value: 'HALAL'
  },
  {
    text: 'kosher',
    value: 'KOSHER'
  },
  {
    text: 'ghps',
    value: 'Good Hygiene Practices (GHPs)'
  },
  {
    text: 'haccp',
    value: 'Hazard Analysis Critical Control Point (HACCP)'
  },
  {
    text: 'iso',
    value: 'ISO 22000:2005'
  },
  {
    text: 'fssc',
    value: 'FSSC 22000'
  },
  {
    text: 'brcgs',
    value: 'Global Standard for Food Safety (BRCGS)'
  },
  {
    text: 'ifs',
    value: 'International Featured Standard (IFS)'
  },
  {
    text: 'rspoManufacturing',
    value: 'Roundtable on Sustainable Palm Oil (RSPO) (Manufacturing)'
  }
];

export const licensesField = [...farmingCerts, ...manufacturingCerts];

export const productField = [
  { text: 'fda', value: 'FDA' },
  { text: 'organic', value: 'Organic' },
  { text: 'gi', value: 'GI' },
  { text: 'otop', value: 'OTOP' },
  { text: 'copyright', value: 'Copyright' },
  { text: 'halal', value: 'Halal' },
  { text: 'kosher', value: 'Kosher' },
  { text: 'q_certification', value: '“Q” Certificate' },
  { text: 'pgs', value: 'PGS' },
  { text: 'sdg', value: 'SDGs PGS' },
  { text: 'healthier', value: 'Healthier Choice' },
  { text: 'thai_community', value: 'Thai Community Product Standards' },
  { text: 'green_label', value: 'Green Label' },
  { text: 'rspo', value: 'Roundtable on Sustainable Palm Oil (RSPO)' }
];

export enum NoOptions {
  NO_CERTIFICATE = 'No License or Certification',
  NO_SALE_CHANNEL = 'Not Currently Selling'
}
