import licenseService from 'api/services/LicenseService';
import { useMutation } from 'react-query';
import ILicense from 'types/License';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
  companyId: number;
}

export const useCreateLicense = ({ onSuccess, onError, companyId }: Props) => {
  return useMutation((body: ILicense) => licenseService.createLicense(body, companyId), {
    onError,
    onSuccess
  });
};
