import { FormControl, FormHelperText, TextareaAutosize, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FastField, FieldProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ITextAreaProps {
  name: string;
  placeHolder: string;
  minRows?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  textArea__placeholder: {
    padding: theme.spacing(2),
    fontFamily: 'PSL Kanda Pro',
    fontWeight: '700',
    fontSize: '1.5rem'
  },
  error_text: {
    fontSize: '20px!important',
    fontWeight: '700!important'
  }
}));

const TextArea = ({ name, placeHolder, minRows = 5, ...props }: ITextAreaProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FastField name={name}>
      {({ field, meta }: FieldProps) => {
        const errorText = meta.error && meta.touched ? meta.error : '';
        return (
          <FormControl error={!!errorText} fullWidth={true}>
            <TextareaAutosize
              id={name}
              placeholder={t(placeHolder)}
              minRows={minRows}
              className={classes.textArea__placeholder}
              {...field}
              {...props}
            />
            {!!errorText && (
              <FormHelperText className={classes.error_text}>
                {t(`form.error_msg.${errorText}`)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default TextArea;
