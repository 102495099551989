import { Manufacturing } from 'constants/licenses';
import * as Yup from 'yup';

export const getInitialSubmissionValues = (company: any) => {
  const { vat, sme, type_of_business, facilities, license, product } = company;
  const { company_affidavit, unregistered, registered, limited, smce, individual } =
    type_of_business;

  const { productUploadCatalog, productUploadProfile, products } = product;

  const submissionValues = {
    companyId: company.id,
    companyDocument: {
      vat,
      sme,
      type_of_business: {
        value: type_of_business.value,
        company_affidavit: company_affidavit ?? { photo: [] },
        unregistered: unregistered ?? { id_card_upload: [] },
        registered: registered ?? { upload: [] },
        limited: limited ?? { upload: [] },
        smce: smce ?? {
          registration_certificate: [],
          commerial_certificate: []
        },
        individual: individual ?? { upload: [] }
      }
    },
    facilities,
    licenseDocument: {
      _id: license.id, // Resource (license): id (use _id to avoid being overriden with license.certificate.id)
      farming_certification: JSON.parse(license.farming_certification),
      manufacturing_certification: JSON.parse(license.manufacturing_certification),
      manufacturing_other: license.manufacturing_other,
      farming_other: license.farming_other,
      ...license.certificates,
      fdaManufacturing: license.certificates.fda
    },
    productDocument: {
      id: product.id,
      productUploadCatalog,
      productUploadProfile,
      products
    }
  };

  return submissionValues;
};

export const submissionSchema = Yup.object({
  companyDocument: Yup.object({
    vat: Yup.object({
      is_registered: Yup.boolean(),
      vat_registration_upload: Yup.array().when('is_registered', {
        is: true,
        then: Yup.array().min(1, 'required_photo'),
        otherwise: Yup.array().notRequired()
      })
    }),
    sme: Yup.object({
      is_registered: Yup.boolean(),
      sme_registration_upload: Yup.array().when('is_registered', {
        is: true,
        then: Yup.array().min(1, 'required_photo'),
        otherwise: Yup.array().notRequired()
      })
    }),
    type_of_business: Yup.object({
      value: Yup.string(),
      company_affidavit: Yup.object().when('value', (value) => {
        return value === 'Company (Listed or Limited Liability)'
          ? Yup.object({
              photo: Yup.array().min(1, 'required_photo')
            })
          : Yup.object().nullable();
      }),
      unregistered: Yup.object().when('value', (value) => {
        return value === 'Unregistered Partnership'
          ? Yup.object({
              id_card_upload: Yup.array().min(1, 'required_photo').nullable()
            })
          : Yup.object().nullable();
      }),
      registered: Yup.object().when('value', (value) => {
        return value === 'Registered Partnership'
          ? Yup.object({
              upload: Yup.array().min(1, 'required_photo')
            })
          : Yup.object().nullable();
      }),
      limited: Yup.object().when('value', (value) => {
        return value === 'Limited Partnership'
          ? Yup.object({
              upload: Yup.array().min(1, 'required_photo')
            })
          : Yup.object().nullable();
      }),
      smce: Yup.object().when('value', (value) => {
        return value === 'SMCE (small and micro community enterprise)'
          ? Yup.object({
              registration_certificate: Yup.array().min(1, 'required_photo')
            })
          : Yup.object().nullable();
      }),
      individual: Yup.object().when('value', (value) => {
        return value === 'Individual'
          ? Yup.object({
              upload: Yup.array().min(1, 'required_photo')
            })
          : Yup.object().nullable();
      })
    })
  }),
  facilities: Yup.array().of(
    Yup.object({
      has_facility: Yup.string().nullable().notRequired(),
      farm: Yup.object().when('has_facility', (value) => {
        return value === 'farm'
          ? Yup.object({
              entrance: Yup.array().nullable().notRequired(),
              plot: Yup.array().nullable().notRequired(),
              animal_stall: Yup.array().nullable().notRequired()
            })
          : Yup.object().nullable();
      }),
      production_facility: Yup.object().when('has_facility', (value) => {
        return value === 'production'
          ? Yup.object({
              front: Yup.array().nullable().notRequired(),
              sorting_area: Yup.array().nullable().notRequired(),
              packing_area: Yup.array().nullable().notRequired(),
              cold_storage_area: Yup.array().nullable().notRequired()
            })
          : Yup.object().nullable();
      }),
      fishery: Yup.object().when('has_facility', (value) => {
        return value === 'fishery'
          ? Yup.object({
              fishery_photo: Yup.array().nullable().notRequired()
            })
          : Yup.object().nullable();
      })
    })
  ),
  licenseDocument: Yup.object({
    // farming_certification: Yup.array(),
    // manufacturing_certification: Yup.array(),
    gap: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('GAP (Vegetable & Fruits)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_thai: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Thai GAP')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_global: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Global GAP')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    pgs_standard: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('PGS Standard')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    pgs_sdg: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('SDGsPGS')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_thai: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Organic Thailand')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_ifoam: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('IFOAM Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_european: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('European Union Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_canada: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Canada Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_us: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('US Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_livestock: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Organic Livestock')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_for_livestock: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Good Agricultural Practices for Farm  (Livestock and LAYER FARM)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_for_fisheries: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Good Agricultural Practices for Farm (Fisheries)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    rspo: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Roundtable on Sustainable Palm Oil (RSPO)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    iso: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('ISO 22000:2005')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    haccp: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Hazard Analysis Critical Control Point (HACCP)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    fdaManufacturing: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes(Manufacturing.FDA)
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    slaughterhouse: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Slaughterhouse License (Pork / Chicken)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    export_manufacture: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Establishment Approved for Export Manufacture')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gmp_fda: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('GMP FDA')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    halal: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('HALAL')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    kosher: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('KOSHER')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    ghps: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Good Hygiene Practices (GHPs)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    fssc: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('FSSC 22000')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    brcgs: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Global Standard for Food Safety (BRCGS)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    ifs: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('International Featured Standard (IFS)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    rspoManufacturing: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Roundtable on Sustainable Palm Oil (RSPO) (Manufacturing)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    farming_other: Yup.object({
      is_checked: Yup.boolean(),
      upload: Yup.array().when('is_checked', (value) => {
        if (value) {
          return value === true
            ? Yup.array().min(1, 'required_photo')
            : Yup.array().nullable().notRequired();
        }
        return Yup.array().nullable().notRequired();
      })
    }),
    manufacturing_other: Yup.object({
      is_checked: Yup.boolean().required(),
      upload: Yup.array().when('is_checked', (value) => {
        if (value) {
          return value === true
            ? Yup.array().min(1, 'required_photo')
            : Yup.array().nullable().notRequired();
        }
        return Yup.array().nullable().notRequired();
      })
    })
  }),
  productDocument: Yup.object({
    products: Yup.array().of(
      Yup.object({
        cerifications: Yup.array().notRequired(),
        other_certification: Yup.object({
          value: Yup.string().nullable(),
          upload: Yup.array().when('value', (value) => {
            return value ? Yup.array().min(1, 'required_photo') : Yup.array().notRequired();
          })
        }),
        photos: Yup.array().min(1, 'required_photo'),
        certification_photo: Yup.object().when('cerifications', (value) => {
          if (value) {
            return Yup.object({
              fda: value.includes('FDA')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              fda_label: value.includes('FDA')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              copyright: value.includes('Copyright')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              gi: value.includes('GI')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              green_label: value.includes('Green Label')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              halal: value.includes('Halal')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              healthier: value.includes('Healthier Choice')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              kosher: value.includes('Kosher')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              organic: value.includes('Organic')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              otop: value.includes('OTOP')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              pgs: value.includes('PGS')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              q_certification: value.includes('“Q” Certificate')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              rspo: value.includes('Roundtable on Sustainable Palm Oil (RSPO)')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              sdg: value.includes('SDGs PGS')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              thai_community: value.includes('Thai Community Product Standards')
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired(),
              rspoManufacturing: value.includes(
                'Roundtable on Sustainable Palm Oil (RSPO) (Manufacturing)'
              )
                ? Yup.array().min(1, 'required_photo')
                : Yup.array().notRequired()
            });
          }
          return Yup.object().nullable();
        })
      })
    )
  })
});
