import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    margin: theme.spacing(2),
    border: 0,
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#fafafa'
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid ${'#f0f0f0'}`
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${'#f0f0f0'}`
    },
    '& .MuiDataGrid-cell': {
      color: '#1F2937'
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0
    }
  }
}));

export default useStyles;
