import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import { styled } from '@mui/system';

const StyledButton = styled('button')(
  () => `
    font-family: 'PSL Kanda Pro';
    font-size: 24px;
    box-sizing: border-box;
    text-align: left;
    line-height: 1.5;
    color: white;
    width: 80px;
    max-width: 100px;
    min-height: 24px;
    background: none;
    border: none;

    & img {
      margin-right: 5px;
    }
  
    &:hover {
      cursor: pointer;
      
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: none;
    }
  
    `
);

const StyledListbox = styled('ul')(
  () => `
  font-family: 'PSL Kanda Pro';
    font-size: 24px;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    overflow: auto;
    outline: 0px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 0.75em;
    max-width: 100px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  () => `
    font-family: 'PSL Kanda Pro';
    font-size: 24px;
    list-style: none;
    padding: 10px;
    border-radius: 0.45em;
    cursor: default;
    color: #000;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    & img {
      margin-right: 5px;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: #eee;
      color: #000;
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: #eee;
      color: #000;
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: #eee;
      color: #000;
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: #eee;
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: #eee;
      color: #000;
    }
    `
);

export { StyledButton, StyledListbox, StyledOption };
