import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    // width: theme.spacing(15),
    fontFamily: 'Roboto!important',
    fontWeight: '500!important',
    fontSize: '15px!important',
    textTransform: 'uppercase',
    letterSpacing: '0.46px!important',
    minWidth: '116px!important'
  },
  buttonSmall: {
    // width: theme.spacing(15),
    fontFamily: 'Roboto!important',
    fontWeight: '500!important',
    fontSize: '15px!important',
    textTransform: 'uppercase',
    letterSpacing: '0.46px!important',
    minWidth: '80px!important',
    marginRight: '16px!important'
  }
}));

// #Readme
// button2 is styles for add register btn on mobile size nav

export default useStyles;
