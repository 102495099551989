import CustomStepper from 'components/Stepper';
import React from 'react';
import CustomModal from '..';

interface IReviewModalProps {
  open: boolean;
  onClose: () => void;
}

const ReviewModal: React.FC<IReviewModalProps> = ({ open, onClose }) => {
  return (
    <CustomModal
      title="Partnership Registration Review"
      open={open}
      onClose={onClose}
      sx={{
        padding: 0
      }}>
      <CustomStepper />
    </CustomModal>
  );
};

export default ReviewModal;
