import { useAuth } from 'auth';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const Auth: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { user, isAdmin } = useAuth();

  if (!user) {
    return <Navigate to="/" state={{ path: pathname }} />;
  }

  if (user.registration) {
    return <Navigate to="/application-status" />;
  }

  if (isAdmin) {
    return <Navigate to="/review" />;
  }

  return <>{children}</>;
};

export default Auth;
