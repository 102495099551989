import { FormControl, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import StyledUpload from 'components/Upload';
import { FastField, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

interface IUploadProps {
  name: string;
  destDir?: string;
  uploadText?: string;
}

const useStyles = makeStyles(() => ({
  input__label: {
    fontSize: '24px!important',
    fontWeight: '700!important',
    marginTop: '-6px!important'
  },
  input__text: {
    fontSize: '24px!important',
    fontWeight: '700!important'
  },
  error_text: {
    fontSize: '20px!important',
    fontWeight: '700!important'
  }
}));

const Upload = ({ name, uploadText, destDir }: IUploadProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FastField name={name}>
      {({ form, field, meta }: FieldProps) => {
        const errorText = meta.error ? meta.error : '';
        return (
          <FormControl error={!!errorText} fullWidth>
            <StyledUpload
              setFieldValue={form.setFieldValue}
              name={name}
              field={field}
              destDir={destDir}
              uploadText={uploadText ? uploadText : 'upload.attach'}
            />
            {!!errorText && (
              <FormHelperText className={classes.error_text}>
                {t(`form.error_msg.${errorText}`)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default Upload;
