import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  modal__register__form: {
    color: 'red'
  },
  modal__register__footer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2)
    }
  }
}));

export default useStyles;
