import { Box, FormHelperText, Link, Typography } from '@mui/material';
import {
  employeesOptions,
  revenueOptions
} from 'components/RegistrationWrapper/initialValues/company';
import { productTypeOptions } from 'components/RegistrationWrapper/initialValues/product';
import CustomButton from 'components/shared/Button';
import Controls from 'components/shared/Form/FormControl';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ModalContent } from '../ModalWrapper';
import modalStyles from '../modalStyles';
interface IQuestionnaireModalProps {
  setModal: React.Dispatch<React.SetStateAction<ModalContent>>;
}

interface FormFields {
  productType: string;
  numberEmployees: string;
  revenue: string;
}

const QuestionnaireModal = ({ setModal }: IQuestionnaireModalProps) => {
  const classes = modalStyles();
  const { t } = useTranslation();

  const initialValues: FormFields = {
    productType: '',
    numberEmployees: '',
    revenue: ''
  };

  const validationSchema: Yup.SchemaOf<FormFields> = Yup.object({
    productType: Yup.string().required('select_product'),
    numberEmployees: Yup.string()
      .required('number_employees')
      .notOneOf(['>50'], 'question_validation_2'),
    revenue: Yup.string()
      .required('required')
      .notOneOf(['more than 100,000,000 bht'], `${'question_validation_3'}`)
  });

  const onSubmit = () => {
    return;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => (
        <Form>
          <Box>
            <Controls.SelectFormik
              name="productType"
              label="questionnaire.q1.question"
              options={productTypeOptions}
              InputLabelProps={{ required: true }}
            />
          </Box>
          <Box pt={3}>
            <Controls.SelectFormik
              name="numberEmployees"
              label="questionnaire.q2.question"
              options={employeesOptions}
              InputLabelProps={{ required: true }}
            />
          </Box>
          <Box pt={3}>
            <Controls.SelectFormik
              name="revenue"
              label="questionnaire.q3.question"
              options={revenueOptions}
              InputLabelProps={{ required: true }}
            />
          </Box>
          {formik.isValid && formik.dirty && (
            <FormHelperText sx={{ color: 'green', fontSize: '1rem' }}>
              <Typography component="p" sx={{ color: 'green', fontSize: '1rem' }}>
                {t('questionnaire.congrat')}
              </Typography>
            </FormHelperText>
          )}
          <Box className={classes.modal__footer}>
            <Link href="/contact" target="_blank">
              <CustomButton
                variant="outlined"
                color="primary"
                text={t('questionnaire.contact')}
                sx={{
                  float: 'right',
                  marginRight: '8px'
                }}
              />
            </Link>
            <CustomButton
              onClick={() => {
                setModal(ModalContent.REGISTER);
              }}
              variant="contained"
              color="secondary"
              text={t('questionnaire.next')}
              sx={{
                color: '#fff'
              }}
              type="submit"
              disabled={!(formik.dirty && formik.isValid)}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default QuestionnaireModal;
