import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import {
  currentSaleChannelOptions,
  distributionReachOptions
} from 'components/RegistrationWrapper/initialValues/license';
import { useStyles } from 'components/shared/Form/Checkbox';
import Controls from 'components/shared/Form/FormControl';
import { Manufacturing } from 'constants/licenses';
import { NoOptions } from 'constants/submissionField';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ILicense from 'types/License';

const currentSaleChannelEmptyValue = currentSaleChannelOptions.map((option) => ({
  selected: false,
  type: option.value,
  detail: ''
}));

const License = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<ILicense>();

  const { sales_channels } = formik.values;

  // Show options if user already pick no/yes && not pick "no"
  const showSaleChannelOptions = Array.isArray(sales_channels);
  const showDistributionField =
    showSaleChannelOptions &&
    !_.some(sales_channels, (chan) => chan?.type === Manufacturing.EXPORT && chan?.selected);

  return (
    <>
      <Grid container spacing={2} mt={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h3" component="h3" sx={{ fontSize: '52px' }}>
            {t('license.currentSaleChannel.label')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <RadioGroup row name="toggle-currentSaleChannel">
            <FormControlLabel
              value={NoOptions.NO_SALE_CHANNEL}
              sx={{ minWidth: '230px' }}
              control={
                <Radio
                  checked={formik.values.sales_channels === NoOptions.NO_SALE_CHANNEL}
                  onChange={(event) => {
                    formik.setFieldTouched(`sales_channels`, true);
                    if (event.target.checked)
                      formik.setFieldValue(`sales_channels`, NoOptions.NO_SALE_CHANNEL);
                  }}
                />
              }
              label={t('license.currentSaleChannel.notSelling')}
            />
            <FormControlLabel
              value={'yes'}
              sx={{ minWidth: '230px' }}
              control={
                <Radio
                  checked={showSaleChannelOptions}
                  onChange={(event) => {
                    formik.setFieldTouched(`sales_channels`, true);
                    if (event.target.checked)
                      formik.setFieldValue(`sales_channels`, currentSaleChannelEmptyValue);
                  }}
                />
              }
              label={t('license.currentSaleChannel.selling')}
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        {formik.errors.sales_channels === 'choose_option' && (
          <Grid item xs={12}>
            <FormHelperText className={classes.error_text} error>
              {t(`form.error_msg.choose_option`)}
            </FormHelperText>
          </Grid>
        )}
        {showSaleChannelOptions && (
          <Grid item xs={12}>
            <Controls.CheckboxWithDetail
              name="sales_channels"
              label=""
              options={currentSaleChannelOptions}
              isGrid={true}
            />
          </Grid>
        )}
        {/* Show distribution if export is not selected */}
        {showDistributionField && (
          <>
            <Grid item xs={12}>
              <Typography variant="h3" component="h3" sx={{ fontSize: '52px' }}>
                {t('license.distribution.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controls.RadioButton
                name="distribution_reach"
                label=""
                options={distributionReachOptions}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default License;
