import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  contact__container: {
    backgroundPosition: 'center',
    background: theme.palette.gray_bg.main
  },
  contact__sidemenu: {
    boxShadow: theme.boxShadow.static,
    borderRadius: theme.spacing(5),
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`
  },
  contact__box__container: {
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(4)}`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(10)} ${theme.spacing(0)} ${theme.spacing(15)}`
    }
  },
  title__text: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '48px !important',
      lineHeight: '40px !important',
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '64px !important',
      lineHeight: '60px !important',
      textAlign: 'center'
    }
  },
  content__wrapper: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem !important',
      lineHeight: '1.5rem !important',
      marginTop: `${theme.spacing(6)} !important`
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem !important',
      lineHeight: '2rem !important',
      marginTop: `${theme.spacing(0)} !important`
    }
  },
  images__container: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column !important',
      paddingBottom: `${theme.spacing(6)}`
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      paddingBottom: `${theme.spacing(0)}`
    }
  },
  image: {
    [theme.breakpoints.up('xs')]: {
      borderRadius: '0 !important',
      width: '112px !important',
      height: '138px !important'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '0 !important',
      width: '100% !important',
      height: '100% !important'
    }
  },
  text__container: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      textAlign: 'left'
    }
  },
  text__container__grid: {
    [theme.breakpoints.up('xs')]: {
      display: 'none !important'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex !important'
    }
  },
  text__container__mobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex !important',
      flexDirection: 'column',
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    }
  }
}));

export default useStyles;
