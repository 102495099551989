import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

interface ITextError {
  error: string | null;
  sx?: SxProps<Theme>;
}

const TextError: React.FC<ITextError> = ({ error, sx }) => {
  return (
    <Box sx={sx} component="div" textAlign="center" width="100%">
      <Typography component="p" color="red" fontSize={24}>
        {error}
      </Typography>
    </Box>
  );
};

export default TextError;
