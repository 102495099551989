import { Box, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import FacebookFeed from 'components/FacebookFeed';
import HomeCarousel from 'components/HomeCarousel';
import ModalWrapper, { ModalContent } from 'components/Modal/ModalWrapper';
import PartnerLogoSection from 'components/PartnerlogoSection/PartnerLogoSection';
import VideoCarousel from 'components/VideoCarousel/VideoCarousel';
import CustomButton from 'components/shared/Button';
import { useHome } from 'hooks/home/useHome';
import { LANGUAGES, getLanguage } from 'localization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useStyles from './homeStyles';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [sliderItems] = useHome();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const selectedLang = getLanguage();

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box className={classes.homepage__container}>
      <Box textAlign={'center'}>
        {/* Video below - to allow once content confirmed */}
        <Container maxWidth="lg" className={classes.home__context__container}>
          <Box sx={{ px: { xs: 2, md: 0 } }}>
            <video
              controls
              autoPlay={!isMobile}
              playsInline
              muted={!isMobile}
              poster={
                selectedLang === LANGUAGES.ENGLISH
                  ? 'imgs/thumbnail/home-video-en-v3.jpg'
                  : 'imgs/thumbnail/home-video-th-v3.jpg'
              }
              style={{
                width: '100%',
                maxHeight: '100%'
              }}>
              <source
                src={
                  selectedLang === LANGUAGES.ENGLISH
                    ? `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBEN_V3.mp4`
                    : `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBTH_V3.mp4`
                }
                type="video/mp4"
              />
            </video>
          </Box>
        </Container>
      </Box>
      <Box pt={{ md: 15, xs: 8 }}>
        <Container maxWidth="lg" className={classes.home__context__container}>
          {/* What you get*/}
          <Box pb={{ md: 10, xs: 6 }}>
            <Paper className={classes.paper__container}>
              <Typography
                variant="h2"
                color="primary"
                textAlign="center"
                fontSize={{ xs: '48px', md: '64px' }}
                lineHeight={{ xs: '42px', md: '60px' }}
                pb={{ md: 10, xs: 6 }}
                pt={{ md: 8, xs: 6 }}>
                {t('homepage.what_you_get.title')}
              </Typography>
              <Box>
                <Grid container rowSpacing={{ xs: 6, md: 8 }} columnSpacing={{ xs: 0, md: 8 }}>
                  <Grid item xs={12} md={4}>
                    <Box
                      className={classes.what_you_get__container}
                      textAlign={{ xs: 'left', md: 'center' }}>
                      <img
                        className={classes.what_you_get__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/what-you-get-1-v2.svg`}
                        alt="what-you-get-1"
                      />
                      <Typography
                        pl={{ xs: 4, md: 0 }}
                        pt={{ xs: 0, md: 3 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.what_you_get.get1')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box
                      className={classes.what_you_get__container}
                      textAlign={{ xs: 'left', md: 'center' }}>
                      <img
                        className={classes.what_you_get__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/what-you-get-2-v2.svg`}
                        alt="what-you-get-2"
                      />
                      <Typography
                        pl={{ xs: 4, md: 0 }}
                        pt={{ xs: 0, md: 3 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.what_you_get.get2')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box
                      className={classes.what_you_get__container}
                      textAlign={{ xs: 'left', md: 'center' }}>
                      <img
                        className={classes.what_you_get__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/what-you-get-3-v2.svg`}
                        alt="what-you-get-3"
                      />
                      <Typography
                        pl={{ xs: 4, md: 0 }}
                        pt={{ xs: 0, md: 3 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.what_you_get.get3')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={0} md={2} />
                  <Grid item xs={12} md={4}>
                    <Box
                      className={classes.what_you_get__container}
                      textAlign={{ xs: 'left', md: 'center' }}>
                      <img
                        className={classes.what_you_get__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/what-you-get-4-v2.svg`}
                        alt="what-you-get-4"
                      />
                      <Typography
                        pl={{ xs: 4, md: 0 }}
                        pt={{ xs: 0, md: 3 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.what_you_get.get4')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box
                      className={classes.what_you_get__container}
                      textAlign={{ xs: 'left', md: 'center' }}>
                      <img
                        className={classes.what_you_get__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/what-you-get-5-v2.svg`}
                        alt="what-you-get-5"
                      />
                      <Typography
                        pl={{ xs: 4, md: 0 }}
                        pt={{ xs: 0, md: 3 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.what_you_get.get5')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={0} md={2} />
                </Grid>
              </Box>
              <Box textAlign={'center'} py={{ md: 10, xs: 6 }}>
                <CustomButton
                  onClick={() => navigate(`/what-you-get`)}
                  variant="contained"
                  color="secondary"
                  text={t('homepage.learn')}
                  sx={{
                    color: '#fff',
                    backgroundColor: '#ED1A3B'
                  }}
                />
              </Box>
            </Paper>
          </Box>
          {/* How to  get*/}
          <Box pb={{ xs: 11.5, md: 18 }}>
            <Paper className={classes.paper__container}>
              <Typography
                variant="h2"
                color="primary"
                fontSize={{ xs: '48px', md: '64px' }}
                lineHeight={{ xs: '42px', md: '60px' }}
                textAlign="center"
                pb={{ md: 10, xs: 6 }}
                pt={{ md: 8, xs: 6 }}>
                {t('homepage.how_it_work.title')}
              </Typography>
              <Box>
                <Grid
                  container
                  justifyContent={'center'}
                  rowSpacing={{ md: 0, xs: 3 }}
                  columnSpacing={{ md: 2, xs: 0 }}>
                  <Grid item xs={12} md={2}>
                    <Box textAlign={'center'} display={'flex'} flexDirection={'column'}>
                      <img
                        className={classes.how_it_works__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/how-it-work-1-v2.svg`}
                        alt="how-it-work-1"
                      />
                      <Typography
                        pt={{ md: 3, xs: 2 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.how_it_work.how1')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    md={1}
                    display={{ xs: 'none', md: 'flex' }}
                    justifyContent={'center'}
                    alignItems="center">
                    <img src="/icons/arrow-right-long.svg" alt="arrow-right" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={0}
                    display={{ xs: 'flex', md: 'none' }}
                    justifyContent={'center'}
                    alignItems="center">
                    <img src="/icons/arrow-down-long.svg" alt="arrow-down" />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box textAlign={'center'} display={'flex'} flexDirection={'column'}>
                      <img
                        className={classes.how_it_works__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/how-it-work-2-v2.svg`}
                        alt="how-it-work-2"
                      />
                      <Typography
                        pt={{ md: 3, xs: 2 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.how_it_work.how2')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    md={1}
                    display={{ xs: 'none', md: 'flex' }}
                    justifyContent={'center'}
                    alignItems="center">
                    <img src="/icons/arrow-right-long.svg" alt="arrow-right" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={0}
                    display={{ xs: 'flex', md: 'none' }}
                    justifyContent={'center'}
                    alignItems="center">
                    <img src="/icons/arrow-down-long.svg" alt="arrow-down" />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box textAlign={'center'} display={'flex'} flexDirection={'column'}>
                      <img
                        className={classes.how_it_works__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/how-it-work-3-v2.svg`}
                        alt="how-it-work-3"
                      />
                      <Typography
                        pt={{ md: 3, xs: 2 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.how_it_work.how3')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    md={1}
                    display={{ xs: 'none', md: 'flex' }}
                    justifyContent={'center'}
                    alignItems="center">
                    <img src="/icons/arrow-right-long.svg" alt="arrow-right" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={0}
                    display={{ xs: 'flex', md: 'none' }}
                    justifyContent={'center'}
                    alignItems="center">
                    <img src="/icons/arrow-down-long.svg" alt="arrow-down" />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box textAlign={'center'} display={'flex'} flexDirection={'column'}>
                      <img
                        className={classes.how_it_works__image}
                        src={`${process.env.REACT_APP_STORAGE}/static/homepage/how-it-work-4-v2.svg`}
                        alt="how-it-work-4"
                      />
                      <Typography
                        pt={{ md: 3, xs: 2 }}
                        alignSelf={'center'}
                        variant="body2_bold"
                        fontSize={{ md: '2rem', xs: '1.5rem' }}
                        lineHeight={{ md: '1.75rem', xs: '1.5rem' }}
                        color={'#4B5563'}>
                        {t('homepage.how_it_work.how4')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box textAlign={'center'} pt={{ md: 8, xs: 6 }} pb={{ md: 9, xs: 6 }}>
                <CustomButton
                  onClick={() => navigate(`/how-it-works`)}
                  variant="contained"
                  color="secondary"
                  text={t('homepage.prepare')}
                  sx={{
                    color: '#fff',
                    backgroundColor: '#ED1A3B'
                  }}
                />
              </Box>
            </Paper>
          </Box>

          {/* About Section Start*/}
          <Box pb={{ md: 10, xs: 7 }} px={{ xs: 2, md: 0 }}>
            <Grid container columnSpacing={8}>
              <Grid item xs={12} md={6} order={{ md: 1, xs: 2 }}>
                <Box pb={5}>
                  <Typography
                    className={classes.title_text}
                    component={'h1'}
                    variant="heading1"
                    pb={{ md: 3, xs: 2 }}>
                    {t('homepage.local')}
                  </Typography>
                  <Typography
                    className={classes.content_text}
                    component={'p'}
                    variant="body1_temp"
                    pb={{ md: 3, xs: 2 }}>
                    {t('homepage.local_sub')}
                  </Typography>
                  <Typography
                    className={classes.content_text}
                    component={'p'}
                    variant="body1_temp"
                    pb={{ md: 3, xs: 2 }}>
                    {t('homepage.local_sub_2')}
                  </Typography>
                  <Typography className={classes.content_text} component={'p'} variant="body1_temp">
                    {t('homepage.local_sub_3')}
                  </Typography>
                  <Typography
                    className={classes.content_text}
                    component={'div'}
                    variant="body1_temp">
                    {t('homepage.local_sub_4')}
                  </Typography>
                </Box>
                <Box pb={4}>
                  <CustomButton
                    onClick={() => navigate(`/about`)}
                    variant="contained"
                    color="secondary"
                    text={t('homepage.learn')}
                    sx={{
                      color: '#fff',
                      backgroundColor: '#ED1A3B'
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} order={{ md: 2, xs: 1 }} pb={{ md: 0, xs: 8 }}>
                <HomeCarousel slideItems={sliderItems} />
              </Grid>
            </Grid>
          </Box>

          {/* MORE VID CONTENT */}

          <Box sx={{ px: { xs: 2, md: 0 } }} pb={{ md: 10, xs: 7 }}>
            <VideoCarousel />
          </Box>

          {/* <Box textAlign={'center'}>
            <Container maxWidth="lg" className={classes.home__context__container}>
              <Box sx={{ px: { xs: 2, md: 0 } }}>
                <video
                  controls
                  autoPlay={!isMobile}
                  playsInline
                  muted={!isMobile}
                  poster={
                    selectedLang === LANGUAGES.ENGLISH
                      ? 'imgs/thumbnail/home-video-en-v3.jpg'
                      : 'imgs/thumbnail/home-video-th-v3.jpg'
                  }
                  style={{
                    width: '100%',
                    maxHeight: '100%'
                  }}>
                  <source
                    src={
                      selectedLang === LANGUAGES.ENGLISH
                        ? `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBEN_V3.mp4`
                        : `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBTH_V3.mp4`
                    }
                    type="video/mp4"
                  />
                </video>
              </Box>
            </Container>
          </Box> */}
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '1rem', gap: '1rem' }}>
            <Box textAlign={'center'}>
           
              <Container maxWidth="md" className={classes.home__context__container}>
                <Box sx={{ px: { xs: 2, md: 0 } }}>
                  <video
                    controls
                    autoPlay={!isMobile}
                    playsInline
                    muted={!isMobile}
                    poster={
                      selectedLang === LANGUAGES.ENGLISH
                        ? 'imgs/thumbnail/home-video-en-v3.jpg'
                        : 'imgs/thumbnail/home-video-th-v3.jpg'
                    }
                    style={{
                      width: '100%',
                      maxHeight: '100%'
                    }}>
                    <source
                      src={
                        selectedLang === LANGUAGES.ENGLISH
                          ? `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBEN_V3.mp4`
                          : `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBTH_V3.mp4`
                      }
                      type="video/mp4"
                    />
                  </video>
                </Box>
              </Container>
            </Box> */}

          {/* <Box textAlign={'center'}>
              <Container maxWidth="md" className={classes.home__context__container}>
                <Box sx={{ px: { xs: 2, md: 0 } }}>
                  <video
                    controls
                    autoPlay={!isMobile}
                    playsInline
                    muted={!isMobile}
                    poster={
                      selectedLang === LANGUAGES.ENGLISH
                        ? 'imgs/thumbnail/home-video-en-v3.jpg'
                        : 'imgs/thumbnail/home-video-th-v3.jpg'
                    }
                    style={{
                      width: '100%',
                      maxHeight: '100%'
                    }}>
                    <source
                      src={
                        selectedLang === LANGUAGES.ENGLISH
                          ? `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBEN_V3.mp4`
                          : `${process.env.REACT_APP_STORAGE}/static/movies/MASTER_TOPS_TONGTIN_SUBTH_V3.mp4`
                      }
                      type="video/mp4"
                    />
                  </video>
                </Box>
              </Container>
            </Box>
          </Box> */}

          {/* About Section End*/}
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box sx={{ flexGrow: 1, fleShrink: 0, flexBasis: 500, mb: 8, textAlign: 'center' }}>
              <FacebookFeed />
            </Box>
            <Box sx={{ flexGrow: 1, order: { xs: -1, sm: -1, md: 1 } }}>
              <Box className={classes.ready__container}>
                <Typography variant="h3" color="primary" pb={6}>
                  {t('homepage.ready')}
                </Typography>
                <Box pb={3}>
                  <CustomButton
                    onClick={() => setOpenModal(true)}
                    variant="contained"
                    color="secondary"
                    text={t('homepage.register')}
                    sx={{
                      color: '#fff',
                      backgroundColor: '#ED1A3B'
                    }}
                  />
                  <ModalWrapper
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    modalContent={ModalContent.QUESTIONNAIRE}
                  />
                </Box>
                <Box>
                  <Link to="/contact">
                    <Typography fontSize={24}>{t('homepage.more_question')}</Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* partner logo with link section */}
          <Box>
            {/* <PartnerMarquee /> */}
            <PartnerLogoSection />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
