import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import userService from 'api/services/UserService';
import { useAuth } from 'auth';
import useStyles from 'components/Layout/layoutStyles';
import ModalWrapper, { ModalContent } from 'components/Modal/ModalWrapper';
import CustomButton from 'components/shared/Button';
import Controls from 'components/shared/Form/FormControl';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const Schema = Yup.object().shape({
  password: Yup.string().required('password_required_6').min(6, 'password_required_6'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'confirm_password')
    .required('password_required_6')
});

const ResetPasswordPage = () => {
  const classes = useStyles();
  const { token } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    if (user) {
      return navigate('/');
    }
  }, [user]);

  const { isLoading, mutate } = useMutation((body: any) => userService.resetPassword(body), {
    onError: (error: any) => {
      const realError = error.message[0].messages[0].message;
      toast.error(realError);
    },
    onSuccess: (response: any) => {
      if (response.user) {
        setShowSuccess(true);
      }
    }
  });

  const initialValues = {
    password: '',
    passwordConfirmation: ''
  };

  const onSubmit = (values: any) => {
    const params = {
      code: token,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation
    };
    mutate(params);
  };

  return (
    <Container
      maxWidth="sm"
      disableGutters
      sx={{
        margin: `${theme.spacing(6)} auto ${theme.spacing(5)}`,
        padding: `0 ${theme.spacing(2)}`,
        backgroundColor: theme.palette.white.main,
        borderRadius: '10px'
      }}>
      <Box className={classes.layout__header}>
        <Typography textAlign="center" component="h1" variant="h1">
          {t('reset_password.title')}
        </Typography>
        <Box pt={5} pb={8} px={4}>
          {showSuccess ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
              <Typography variant="body1" sx={{ textAlign: { xs: 'center', md: 'start' } }}>
                {t('reset_password.subtitle')}
              </Typography>
              <Box sx={{ mr: 3 }}>
                <CustomButton
                  onClick={() => setOpenLoginModal(true)}
                  variant="outlined"
                  color="primary"
                  text={t('navigator.login')}
                  sx={{ border: `1px solid ${theme.palette.primary.main}` }}
                />
                <ModalWrapper
                  open={openLoginModal}
                  onClose={() => setOpenLoginModal(false)}
                  modalContent={ModalContent.LOGIN}
                />
              </Box>
            </Box>
          ) : (
            <Formik initialValues={initialValues} validationSchema={Schema} onSubmit={onSubmit}>
              {(formik) => {
                return (
                  <Form>
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Controls.Input
                            name="password"
                            type="password"
                            label="account_registration.account_representative.password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controls.Input
                            name="passwordConfirmation"
                            type="password"
                            label="account_registration.account_representative.confirmPassword"
                            required
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pt={2}>
                      <CustomButton
                        variant="contained"
                        color="secondary"
                        text={t('form.submit')}
                        sx={{
                          width: '178px',
                          color: '#ffffff'
                        }}
                        type="submit"
                        disabled={!(formik.dirty && formik.isValid) || isLoading}
                      />
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
