// Read More: https://www.i18next.com/misc/creating-own-plugins#post-processor
// How to use PostProcessor : https://www.i18next.com/overview/api#use
import HTMLReactParser from 'html-react-parser';
import DOMPurify from 'dompurify';
import { PostProcessorModule, TOptions } from 'i18next';
/* 
=== Pacakge dependencies ====
- i18next (https://www.i18next.com/)
- html-react-parser (https://github.com/remarkablemark/html-react-parser)
- dompurify (https://github.com/cure53/DOMPurify)
- @types/dompurify (https://www.npmjs.com/package/@types/dompurify)
*/
interface IHtmlParseProcess {
  process: (
    value: string,
    key: string,
    options: TOptions,
    translator: any
  ) => string | JSX.Element | JSX.Element[];
}
type HtmlParserModule = Omit<PostProcessorModule, 'process'> & IHtmlParseProcess;
const HtmlParsePostProcessor: HtmlParserModule = {
  name: 'htmlParserProcessor',
  type: 'postProcessor',
  process: function (value: string) {
    const htmlSanitizered = DOMPurify.sanitize(value);
    return HTMLReactParser(htmlSanitizered);
  }
};
export default HtmlParsePostProcessor;
