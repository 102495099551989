import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

interface INavLink {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}));

const NavLink = ({ to, children, style }: INavLink) => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link} style={style}>
      {children}
    </Link>
  );
};

export default NavLink;
