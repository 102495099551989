import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useAuth } from 'auth';
import Language from 'components/Language';
import ModalWrapper, { ModalContent } from 'components/Modal/ModalWrapper';
import CustomButton from 'components/shared/Button';
import Logo from 'components/shared/Logo';
import { SECONDARY_COLOR } from 'constants/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'routes/Nav';
import { stringAvatar } from 'utils/stringAvatar';
import NavLink from './components/NavLink';
import useStyles from './headerStyles';

const Header = () => {
  const classes = useStyles();
  const { user, logout, isAdmin } = useAuth();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

  const [openModal, setOpenModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const [openModalNav, setOpenModalNav] = useState(false);
  const [openLoginModalNav, setOpenLoginModalNav] = useState(false);

  const handleSignOut = () => {
    setAnchorElMenu(null);
    logout();
  };

  const getUserMenu = () => {
    if (user) {
      return (
        <>
          <Tooltip title="User menu" className={classes.header__user__menu__box}>
            <IconButton
              color="secondary"
              onClick={(event) => {
                setAnchorElMenu(event.currentTarget);
              }}>
              <Avatar
                alt="avatar"
                sizes="large"
                sx={{ fontWeight: 700, bgcolor: SECONDARY_COLOR }}
                {...stringAvatar(`${user.first_name} ${user.last_name}`)}
              />
              <Typography ml={1} className={classes.header__menu__text}>
                {user.first_name} {user.last_name}
              </Typography>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorElMenu}
            keepMounted
            open={Boolean(anchorElMenu)}
            onClose={() => {
              setAnchorElMenu(null);
            }}>
            {!isAdmin && (
              <MenuItem>
                <CustomButton
                  onClick={() => {
                    navigate('/registration');
                    setAnchorElMenu(null);
                  }}
                  sx={{ textDecoration: 'none', width: '100%' }}
                  text={t('form.registration')}
                  variant="outlined"></CustomButton>
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem>
                <Link to="/review">
                  <CustomButton
                    onClick={() => setAnchorElMenu(null)}
                    sx={{ textDecoration: 'none' }}
                    text="Admin Review"
                    variant="outlined"></CustomButton>
                </Link>
              </MenuItem>
            )}

            <MenuItem>
              <CustomButton
                onClick={handleSignOut}
                text={t('form.sign_out')}
                variant="contained"
                endIcon={<LogoutIcon />}
              />
            </MenuItem>
          </Menu>
        </>
      );
    }

    return (
      <>
        {mdUp && (
          <>
            <Box sx={{ mr: 3 }} className={classes.header__menu__box}>
              <CustomButton
                onClick={() => setOpenLoginModal(true)}
                variant="outlined"
                color="primary"
                text={t('navigator.login')}
                sx={{
                  border: `1px solid ${theme.palette.secondary.main}`,
                  color: theme.palette.secondary.main
                }}
              />
              <ModalWrapper
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
                modalContent={ModalContent.LOGIN}
              />
            </Box>
            {/* //REGISTER BTN */}
            <Box>
              <CustomButton
                onClick={() => setOpenModal(true)}
                variant="contained"
                color="secondary"
                text={t('navigator.register')}
                sx={{
                  color: '#fff'
                }}
              />
              <ModalWrapper
                open={openModal}
                onClose={() => setOpenModal(false)}
                modalContent={ModalContent.QUESTIONNAIRE}
              />
            </Box>{' '}
          </>
        )}
      </>
    );
  };

  /* User menu on nav */
  const getUserMenuNav = () => {
    if (user) {
      return (
        <>
          <Divider />
          <ListItem className={classes.header__menu__drawer__item}>
            <ListItemAvatar>
              <Avatar
                alt="avatar"
                sizes="large"
                sx={{ fontWeight: 700, bgcolor: SECONDARY_COLOR }}
                {...stringAvatar(`${user.first_name} ${user.last_name}`)}
              />
            </ListItemAvatar>
            <ListItemText primary={`${user.first_name} ${user.last_name}`} />
          </ListItem>
          {!isAdmin && (
            <ListItem
              className={classes.header__menu__drawer__item}
              onClick={() => setAnchorElNav(null)}>
              <NavLink to="/registration">
                <Typography variant="body2_bold" component="p">
                  {t('form.registration')}
                </Typography>
              </NavLink>
            </ListItem>
          )}
          {isAdmin && (
            <ListItem
              className={classes.header__menu__drawer__item}
              onClick={() => setAnchorElNav(null)}>
              <NavLink to="/review">
                <Typography variant="body2_bold" component="p">
                  Admin Review
                </Typography>
              </NavLink>
            </ListItem>
          )}
          <Divider />
          <ListItem className={classes.header__menu__drawer__item} onClick={handleSignOut}>
            <NavLink to="/review">
              <Typography variant="body2_bold" component="p">
                {t('form.sign_out')}
              </Typography>
            </NavLink>
          </ListItem>
        </>
      );
    }
    return (
      <>
        <Divider />
        <ListItem
          className={classes.header__menu__drawer__item}
          onClick={() => {
            setAnchorElNav(null);
            setOpenLoginModalNav(true);
          }}>
          <Typography variant="body2_bold" component="p" color={'primary.main'}>
            {t('navigator.login')}
          </Typography>
        </ListItem>
        {/* REGISTER BTN ON NAV WITH HAMBURGER */}
        <ListItem
          className={classes.header__menu__drawer__item}
          onClick={() => {
            setAnchorElNav(null);
            setOpenModalNav(true);
          }}>
          <Typography variant="body2_bold" component="p" color={'primary.main'}>
            {t('navigator.register')}
          </Typography>
        </ListItem>
      </>
    );
  };

  return (
    <AppBar position="relative" className={classes.header}>
      <Container maxWidth="xl" className={classes.header__container}>
        <Toolbar disableGutters>
          <Box className={classes.header__start}>
            <Box className={classes.header__logo_container}>
              <Link to="/">
                <Logo theme="color" className={classes.header__logo} style={{ maxWidth: 142 }} />
              </Link>
            </Box>
            {!isAdmin && (
              <div className={classes.header__menu}>
                {routes.map((route, index) => (
                  <Box key={index}>
                    <NavLink to={route.path}>
                      <Typography variant="body2_bold" component="p">
                        {t(route.text)}
                      </Typography>
                    </NavLink>
                  </Box>
                ))}
              </div>
            )}
          </Box>

          {/* Responsive menu start*/}
          <Box className={classes.header__logo__responsive}>
            <Box className={classes.header__logo__content} display={{ xs: 'none', md: 'block' }}>
              <Link to="/">
                <Logo theme="color" className={classes.header__logo} style={{ maxWidth: 142 }} />
              </Link>
            </Box>
            <Box display={{ xs: 'block', md: 'none' }} pt={0.5} pl={1}>
              <Link to="/">
                <Logo theme="color" style={{ width: 72 }} />
              </Link>
            </Box>
          </Box>

          {/* NEW MOBILE REGISTER BTN HERE */}
          {/* NEW MOBILE REGISTER BTN HERE */}
          {!user && (
            <Box className={classes.header__menu__responsive}>
              <CustomButton
                onClick={() => {
                  setAnchorElNav(null);
                  setOpenModal(true);
                }}
                variant="contained"
                color="secondary"
                text={t('navigator.register')}
                isSmallBtn={true}
                sx={{
                  color: '#fff'
                }}
              />

              <ModalWrapper
                open={openModal}
                onClose={() => setOpenModal(false)}
                modalContent={ModalContent.QUESTIONNAIRE}
              />
            </Box>
          )}
          <Box className={classes.header__menu__responsive}>
            {getUserMenu()}
            <IconButton
              size="large"
              color="inherit"
              onClick={(event) => {
                setAnchorElNav(event.currentTarget);
              }}>
              <MenuIcon />
            </IconButton>
            <Drawer
              PaperProps={{
                className: classes.header__menu__drawer
              }}
              anchor={'left'}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}>
              <Toolbar disableGutters className={classes.header__menu__drawer__logo}>
                <Box flexGrow={1} pt={0.5}>
                  <Link to="/">
                    <Logo theme="color" className={classes.header__logo} style={{ width: 72 }} />
                  </Link>
                </Box>
                <div className={classes.header_language_mobile}>
                  <Language />
                </div>
                <Box flexGrow={0}>
                  <IconButton
                    size="large"
                    color="inherit"
                    onClick={() => {
                      setAnchorElNav(null);
                    }}>
                    <MenuIcon />
                  </IconButton>
                </Box>
              </Toolbar>

              <Divider />
              <List>
                {routes.map((route, index) => (
                  <ListItem
                    key={index}
                    className={classes.header__menu__drawer__item}
                    onClick={() => setAnchorElNav(null)}>
                    <NavLink to={route.path}>
                      <Typography variant="body2_bold" component="p">
                        {t(route.text)}
                      </Typography>
                    </NavLink>
                  </ListItem>
                ))}

                {getUserMenuNav()}
              </List>
              <Box className={classes.header__menu__drawer__bottom}>
                <Divider />
                <Box pt={3.5} pb={3}>
                  <Link to="/">
                    <Logo theme="color" className={classes.header__logo} style={{ width: 72 }} />
                  </Link>
                </Box>
              </Box>
            </Drawer>
          </Box>
          {/* Responsive menu end */}

          {/* Mobile modal */}
          <ModalWrapper
            open={openLoginModalNav}
            onClose={() => setOpenLoginModalNav(false)}
            modalContent={ModalContent.LOGIN}
          />

          <ModalWrapper
            open={openModalNav}
            onClose={() => setOpenModalNav(false)}
            modalContent={ModalContent.QUESTIONNAIRE}
          />

          <Box sx={{ flexGrow: 0 }}>
            <div className={classes.header__end}>
              <Language />
              {getUserMenu()}
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
