import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps, Typography } from '@mui/material';
import useStyles from './buttonStyles';

interface IButtonProps extends ButtonProps {
  text: string;
  loading?: boolean;
  isSmallBtn?: boolean;
}

const CustomButton = ({ isSmallBtn, text, loading = false, ...props }: IButtonProps) => {
  const classes = useStyles();

  return (
    <LoadingButton
      className={isSmallBtn ? `${classes.buttonSmall}` : `${classes.button}`}
      loading={loading}
      {...props}>
      <Typography variant="body1_bold" fontSize={{ xs: '1.25rem', md: '1.5rem' }}>
        {text}
      </Typography>
    </LoadingButton>
  );
};

export default CustomButton;
