import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%'
  },
  footer__container: {
    padding: `0 ${theme.spacing(6)}`
  },
  footer__content: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(6),
    borderBottom: '1px solid white',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5.75),
      paddingBottom: theme.spacing(2.5)
    }
  },
  footer__contact: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column-reverse!important',
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(5),
      textAlign: 'center'
    }
  },
  footer__title: {
    fontFamily: 'PSL Kanda Extra Pro !important',
    color: `${theme.palette.white.main}!important`,
    fontSize: '1.25rem!important',
    fontWeight: '400 !important'
  },
  footer__link__container: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  footer__link: {
    paddingLeft: theme.spacing(2.25),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: theme.spacing(2)
    }
  },
  footer__text_content: {
    color: '#fff !important',
    fontFamily: 'PSL Kanda Pro',
    fontSize: '1.5rem !important'
  },
  footer__social_icons: {
    width: `${theme.spacing(4)} !important`,
    height: `${theme.spacing(4)} !important`
  },
  footer__logo: {
    [theme.breakpoints.up('xs')]: {
      width: `${theme.spacing(20.25)} !important`,
    },
    [theme.breakpoints.up('md')]: {
      width: `100% !important`,
    }
  }
}));

export default useStyles;
