import { Avatar, Box, Container, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { LANGUAGES, getLanguage } from 'localization';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useHowItWorkStyle from './HowItWorkStyle';

import Alert from 'components/Alert';

const HowItWorkPage = () => {
  const { t } = useTranslation();
  const classes = useHowItWorkStyle();
  const selectedLang = getLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const howItWorkContent = [
    {
      step: 1,
      name: t('how_it_work.subtitle_1'),
      icon: `${process.env.REACT_APP_STORAGE}/static/how_it_works/square-check-v2.svg`,
      content: t('how_it_work.answer1'),
      isNext: true
    },
    {
      step: 2,
      name: t('how_it_work.subtitle_2'),
      icon: `${process.env.REACT_APP_STORAGE}/static/how_it_works/file-alt-v2.svg`,
      content: t('how_it_work.answer2'),
      isNext: true
    },
    {
      step: 3,
      name: t('how_it_work.subtitle_3'),
      icon: `${process.env.REACT_APP_STORAGE}/static/how_it_works/search-v2.svg`,
      list: [
        t('how_it_work.answer3_01'),
        t('how_it_work.answer3_02'),
        t('how_it_work.answer3_03'),
        t('how_it_work.answer3_04')
      ],
      isNext: true
    },
    {
      step: 4,
      name: t('how_it_work.subtitle_4'),
      icon: `${process.env.REACT_APP_STORAGE}/static/how_it_works/handshake-v2.svg`,
      isNext: false
    }
  ];
  return (
    <Box className={classes.container}>
      <Helmet>
        <title>Tongtin - How It Works</title>
      </Helmet>
      <Box pt={{ xs: 6, md: 10 }} pb={{ xs: 6, md: 10 }}>
        <Container className={classes.layout__content} disableGutters>
          <Box className={classes.layout__header}>
            <Typography
              className={classes.things_to_prepare_title}
              component="h2"
              variant="h1"
              pb={6}>
              {t('how_it_work.title')}
            </Typography>
            <Box>
              <Grid
                container
                rowSpacing={{ lg: 6, md: 4, sm: 2, xs: 6 }}
                columns={{ lg: 20, md: 16, sm: 2, xs: 2 }}
                alignItems="flex-start">
                {howItWorkContent.map((content, index) => {
                  return (
                    <HowItWorkContent
                      classes={classes}
                      content={content}
                      key={`${content.name}-${index}`}
                    />
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box pb={{ xs: 6, md: 15 }}>
        <Container className={classes.layout__content} disableGutters>
          <Box className={classes.layout__header}>
            <Typography
              className={classes.things_to_prepare_title}
              component="h2"
              variant="h1"
              pb={6}>
              {t('how_it_work.things_to_prepare.title')}
            </Typography>
            <Box>
              <Typography className={classes.things_to_prepare_describe} component="p">
                {t('how_it_work.things_to_prepare.describe')}
              </Typography>
              <p>&nbsp;</p>
              <Alert severity="info" color="error">
                {t('how_it_work.things_to_prepare.alert')}
              </Alert>
              <Grid
                container
                columnSpacing={{ lg: 6, md: 4, sm: 2, xs: 0 }}
                columns={{ lg: 24, md: 16, sm: 8, xs: 2 }}
                pt={6}>
                <Grid item lg={12} md={8} sm={4} xs={2} pb={{ xs: 4, md: 0 }}>
                  <Typography component="h5" variant="h5">
                    {t('how_it_work.things_to_prepare.company_doc')}
                  </Typography>
                  <Box pl={{ xs: 2, md: 0 }} pt={{ xs: 2, md: 1 }}>
                    <ul className={classes.list_text}>
                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_01_1')}
                        </Typography>
                      </li>

                      <li>
                        {' '}
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_01_2')}
                        </Typography>
                      </li>

                      <li>
                        {' '}
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_01_3')}
                        </Typography>
                      </li>

                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_01_4')}
                        </Typography>
                      </li>

                      {selectedLang === LANGUAGES.ENGLISH && (
                        <Box pl={{ xs: 6, md: 5 }}>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_01')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_02')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_03')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_04')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_05')}
                          </Typography>
                        </Box>
                      )}
                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_01_5')}
                        </Typography>
                      </li>

                      {selectedLang === LANGUAGES.THAI && (
                        <Box pl={{ xs: 6, md: 5 }}>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_01')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_02')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_03')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_04')}
                          </Typography>
                          <Typography component="p" variant="body2_temp">
                            {t('how_it_work.things_to_prepare.content.example_05')}
                          </Typography>
                        </Box>
                      )}
                      {selectedLang === LANGUAGES.THAI &&
                        [...[6, 7, 8, 9, 10]].map((x) => (
                          <Typography key={x} component="li" variant="body2_temp">
                            {t(`how_it_work.things_to_prepare.content.content_01_${x}`)}
                          </Typography>
                        ))}
                    </ul>
                  </Box>
                </Grid>
                <Grid item lg={12} md={8} sm={4} xs={2}>
                  <Typography component="h5" variant="h5">
                    {t('how_it_work.things_to_prepare.license_cert')}
                  </Typography>
                  <Box pl={{ xs: 2, md: 0 }} pt={{ xs: 2, md: 1 }}>
                    <ul className={classes.list_text}>
                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_02_01')}
                        </Typography>
                      </li>
                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_02_02')}
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Typography component="h5" variant="h5" pt={4}>
                    {t('how_it_work.things_to_prepare.product_doc')}
                  </Typography>
                  <Box pl={{ xs: 2, md: 0 }} pt={{ xs: 2, md: 1 }}>
                    <ul className={classes.list_text}>
                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_03_01')}
                        </Typography>
                      </li>

                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_03_02')}
                        </Typography>
                      </li>

                      <li>
                        <Typography component="span" variant="body2_temp">
                          {t('how_it_work.things_to_prepare.content.content_03_03')}
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const timeline = [
  { label: 'A', d: 5 },
  { label: 'B', d: 10 },
  { label: 'C', d: 10 },
  { label: 'D', d: 5 }
];
const HowItWorkContent = ({ classes, content }: any) => {
  return (
    <>
      <Grid
        item
        className={classes.image__content}
        lg={5}
        md={4}
        sm={2}
        xs={2}
        key={`${content.name} icon`}>
        <Box className={classes.imageBox}>
          <Avatar className={classes.image} alt={content.name} src={content.icon} />
        </Box>
        <Box className={classes.content__mobile}>
          <Typography
            variant="h2"
            component="p"
            fontSize={{ xs: '2rem', md: '64px' }}
            lineHeight={{ xs: '1.75rem', md: '60px' }}>
            {content.name}
          </Typography>
          {content.answer && (
            <Typography variant="body2_temp" component="p" pt={2}>
              {content.answer}
            </Typography>
          )}
          {content.content && (
            <Typography variant="body2_temp" component="p" pt={2}>
              {content.content}
            </Typography>
          )}
          {content.step === 3 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 492,
                mx: 'auto'
              }}>
              <Typography
                variant="body2_bold"
                component="div"
                sx={{ textAlign: 'center', color: 'red', my: 2 }}>
                {t('how_it_work.answer3_timeline')}
              </Typography>
              <Typography variant="body1_bold" component="div" sx={{ textAlign: 'center' }}>
                {t('how_it_work.answer3_timeline_remark')}
              </Typography>
              <Box sx={{ display: 'flex', width: '100%', gap: '2px' }}>
                {timeline.map((step) => (
                  <Box key={step.label} sx={{ flexGrow: step.d }}>
                    <Typography className={classes.line__text}>{step.label}</Typography>
                    <Box className={classes.line__line} />
                    <Typography className={classes.line__days} variant="body2_temp">
                      {step.d} {t('how_it_work.days')}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {content.list &&
            content.list.map((value: string, index: number) => {
              return (
                <Typography key={index} variant="body2" component="p" pt={2} textAlign={'left'}>
                  {value}
                </Typography>
              );
            })}
        </Box>
      </Grid>
      <Grid
        item
        className={classes.text__content}
        lg={15}
        md={12}
        sm={2}
        xs={2}
        key={`${content.name}`}>
        <Box>
          <Typography variant="h5" component="p" maxWidth={'785px'}>
            {content.name}
          </Typography>
          {content.content && (
            <Typography
              variant="body2"
              component="p"
              fontSize={'28px'}
              lineHeight={'28px'}
              pt={2}
              maxWidth={'785px'}>
              {content.content}
            </Typography>
          )}
          {content.step === 3 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 800
              }}>
              <Typography
                variant="body2_bold"
                component="div"
                sx={{ textAlign: 'center', color: 'red', my: 2 }}>
                {t('how_it_work.answer3_timeline')}
              </Typography>
              <Typography variant="body1_bold" component="div" sx={{ textAlign: 'center' }}>
                {t('how_it_work.answer3_timeline_remark')}
              </Typography>
              <Box sx={{ display: 'flex', width: '100%', gap: '2px' }}>
                {timeline.map((step) => (
                  <Box key={step.label} sx={{ flexGrow: step.d, textAlign: 'center' }}>
                    <Typography className={classes.line__text}>{step.label}</Typography>
                    <Box className={classes.line__line} />
                    <Typography className={classes.line__days} variant="body2_temp">
                      {step.d} {t('how_it_work.days')}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {content.list &&
            content.list.map((value: string, index: number) => {
              return (
                <Typography key={index} variant="body2" component="p" pt={2}>
                  {value}
                </Typography>
              );
            })}
        </Box>
      </Grid>
      {content.isNext && (
        <Grid item className={classes.arrow_icon} lg={20} md={16} sm={2} xs={2}>
          <Avatar
            className={classes.image}
            src={`${process.env.REACT_APP_STORAGE}/static/how_it_works/Union-v2.svg`}
          />
        </Grid>
      )}
    </>
  );
};

export default HowItWorkPage;
