import axiosClient from 'api/axiosClient';
import { IProduct, IProductInitValue, IProductResponse } from 'types/Product';

class ProductService {
  createProduct = (body: IProductInitValue, company: number) => {
    const { products } = body;
    const formatProducts = productService.formatProductForClient(products);

    return axiosClient.post('/products', { ...body, products: formatProducts, company });
  };

  updateProduct = (id: number, body: IProductInitValue) => {
    const { products } = body;
    const formatProducts = productService.formatProductForClient(products);
    return axiosClient.put(`/products/${id}`, { ...body, products: formatProducts });
  };

  getProductById = (id: number): Promise<IProductResponse> => {
    return axiosClient.get(`/products/${id}`);
  };

  formatProductForClient = (products: IProduct[]) => {
    const formatProducts = products.map((product) => ({
      name: product.productName,
      type: product.productType,
      brand_name: product.brandName,
      capacity: product.productCapacity,
      category: product.productCategory,
      storage_temperature: product.storageTemp,
      barcodes: product.barcode,
      barcodes_value: product.barcodeValue,
      expiry_dates: product.expiryDate,
      distribution_type: product.distributionType,
      cerifications: JSON.stringify(product.productCertifications),
      has_fda_certification: product.productCertifications.includes('FDA') ? 1 : 0,
      shelf_life: product.shelfLife,
      retail_selling_price: product.retailSellingPrice,
      ...product
    }));

    return formatProducts;
  };
}

const productService = new ProductService();
export default productService;
