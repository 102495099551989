import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, SxProps, Theme, Typography } from '@mui/material';
import useStyles from './modalStyles';

interface ICustomModalProps {
  title?: string;
  subTitle?: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const CustomModal = ({ title, subTitle, children, open, sx, onClose }: ICustomModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} aria-labelledby={title} aria-describedby={subTitle}>
      <Box className={classes.modal__container}>
        <Box className={classes.modal__header}>
          <Box position="absolute" top={50} right={23}>
            <CloseIcon cursor="pointer" onClick={onClose} fontSize="medium" />
          </Box>

          <Typography id={title} component="h1" variant="h1" pt={4}>
            {title}
          </Typography>
          <Typography
            id={subTitle}
            variant="subtitle2"
            pl={{ md: 5, xs: 1 }}
            pr={{ md: 5, xs: 1 }}
            pb={{ md: 5, xs: 0 }}>
            <p>{subTitle}</p>
          </Typography>
        </Box>
        <Box className={classes.modal__content} sx={sx}>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
