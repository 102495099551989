import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useHowItWorkStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.gray_bg.main
  },
  layout__header: {
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(6)} ${theme.spacing(2)}`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)}`
    }
  },
  layout__content: {
    backgroundColor: theme.palette.white.main,
    boxShadow: theme.boxShadow.static,
    [theme.breakpoints.up('xs')]: {
      maxWidth: '100%important',
      borderRadius: '20px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '900px!important',
      borderRadius: '64px'
    },
    [theme.breakpoints.up('laptop')]: {
      maxWidth: '1024px!important',
      borderRadius: '64px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1200px!important',
      borderRadius: '64px'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1536px!important',
      borderRadius: '64px'
    }
  },
  image__content: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex !important',
      justifyContent: 'center !important',
      flexDirection: 'column !important',
      alignItems: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex !important',
      justifyContent: 'center !important'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block !important'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex !important',
      justifyContent: 'center !important'
    },
    [theme.breakpoints.up('xl')]: {
      display: 'flex !important',
      justifyContent: 'center !important'
    }
  },
  imageBox: {
    display: 'flex',
    width: '124px !important',
    height: '124px !important',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#F8F8F8'
  },
  image: {
    width: 'inherit !important',
    height: 'inherit !important',
    borderRadius: '0 !important',
    alignSelf: 'center',
    '& .MuiAvatar-img': {
      objectFit: 'none !important'
    }
  },
  text__content: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
      textAlign: 'center !important'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      textAlign: 'center !important'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left !important'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      textAlign: 'left !important'
    },
    [theme.breakpoints.up('xl')]: {
      display: 'block',
      textAlign: 'left !important'
    }
  },
  line__text: {
    textAlign: 'center',
    color: `${theme.palette.light_purple.main} !important`,
    fontWeight: '700 !important',
    fontSize: '1.25rem !important'
  },
  line__days: {
    textAlign: 'center',
    color: `${theme.palette.light_purple.main} !important`,
    fontSize: '1.25rem !important',
    lineHeight: '1.25rem !important'
  },
  line__line: {
    background:
      'linear-gradient(71.62deg, rgba(91, 44, 136, 0.8) 4.51%, rgba(122, 49, 221, 0.8) 92.8%)',
    borderRadius: '4px',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      height: '4px'
    },
    [theme.breakpoints.up('md')]: {
      height: '6px'
    }
  },
  arrow_icon: {
    display: 'flex',
    justifyContent: 'center'
  },
  title__mobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  title__pc: {
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  content__mobile: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      marginTop: `${theme.spacing(3)}`
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  content__pc: {
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  things_to_prepare_title: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'left',
      fontSize: '3rem !important'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      fontSize: '4rem !important'
    }
  },
  things_to_prepare_describe: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'left',
      fontFamily: 'PSL Kanda Pro !important',
      fontSize: '28px !important',
      lineHeight: '28px !important',
      fontWeight: '700 !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '36px !important',
      lineHeight: '36px !important'
    }
  },
  list_text: {
    paddingLeft: theme.spacing(3),
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '28px',
    color: '#4B5563'
  }
}));

export default useHowItWorkStyle;
