import { Box, Grid, Link } from '@mui/material';
import userService, { IUserResponse } from 'api/services/UserService';
import { useAuth } from 'auth';
import CustomButton from 'components/shared/Button';
import Controls from 'components/shared/Form/FormControl';
import TextError from 'components/shared/Form/TextError';
import { Form, Formik } from 'formik';
import { getLanguage, LANGUAGES } from 'localization';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useStyles from '../modalStyles';
import { ModalContent } from '../ModalWrapper';

export interface ILoginFields {
  identifier: string;
  password: string;
}

interface ILoginModalProps {
  onClose: () => void;
  setModal: React.Dispatch<React.SetStateAction<ModalContent>>;
}

const LoginModal: React.FC<ILoginModalProps> = ({ onClose, setModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const auth = useAuth();
  const navigate = useNavigate();

  const {
    isLoading,
    mutate: login,
    isError
  } = useMutation((body: ILoginFields) => userService.login(body), {
    onError: (error: any) => {
      const message = error.data[0].messages[0].message;
      if (message) {
        if (message === 'Identifier or password invalid.' && getLanguage() === LANGUAGES.THAI) {
          return setError('ระบุอีเมลหรือรหัสผ่านไม่ถูกต้อง');
        }
        setError(message);
      } else {
        setError('Unknow error');
      }
    },
    onSuccess: (response: IUserResponse) => {
      onClose();
      auth.login(response);
      navigate('/registration', { replace: true });
    }
  });

  const initialValues: ILoginFields = {
    identifier: '',
    password: ''
  };

  const validationSchema: Yup.SchemaOf<ILoginFields> = Yup.object({
    identifier: Yup.string().email('invalid_email').required('required'),
    password: Yup.string().required('required')
  });

  const onSubmit = (values: ILoginFields) => {
    login(values);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => (
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controls.Input
                  name="identifier"
                  label="account_registration.account_representative.email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  name="password"
                  type="password"
                  label="account_registration.account_representative.password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Link href="/forgot-password" variant="subtitle1">
                  {t('account_registration.account_representative.forgotPassword')}
                </Link>
              </Grid>
              {isError && <TextError error={error} />}
            </Grid>
          </Box>
          <Box className={classes.modal__footer}>
            <CustomButton
              variant="outlined"
              color="primary"
              text={t('form.register')}
              sx={{
                width: '178px',
                marginRight: '8px'
              }}
              onClick={() => {
                setModal(ModalContent.QUESTIONNAIRE);
              }}
            />
            <CustomButton
              variant="contained"
              color="secondary"
              text={t('form.submit')}
              sx={{
                width: '178px',
                color: '#ffffff'
              }}
              type="submit"
              disabled={!(formik.dirty && formik.isValid) || isLoading}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default LoginModal;
