import { IProductInitValue } from 'types/Product';
import * as Yup from 'yup';

export const BLANK_PRODUCT = {
  productName: '',
  productType: '',
  brandName: '',
  productCapacity: '',
  productCategory: '',
  storageTemp: '',
  barcode: false,
  barcodeValue: '',
  expiryDate: false,
  testing: false,
  distribution: false,
  distributionType: '',
  weight: '',
  shelfLife: '',
  retailSellingPrice: '',
  productCertifications: [],
  hasCertification: null,
  photos: null,
  certification_photo: {
    fda: null,
    fda_label: null,
    organic: null,
    gi: null,
    otop: null,
    copyright: null,
    halal: null,
    kosher: null,
    q_certification: null,
    pgs: null,
    sdg: null,
    healthier: null,
    thai_community: null,
    green_label: null,
    rspo: null
  },
  other_certification: {
    value: null,
    upload: null,
    is_checked: false
  }
};
export const initialProductValues: IProductInitValue = {
  productUploadCatalog: null,
  productUploadProfile: null,
  products: [BLANK_PRODUCT]
};

export const productSchema = Yup.object({
  products: Yup.array().of(
    Yup.object({
      productName: Yup.string().required('required').max(225, 'required'),
      productType: Yup.string().required('required'),
      brandName: Yup.string().notRequired().max(225, 'max_characters_1'),
      productCapacity: Yup.string().required('required').max(225, 'max_characters_1'),
      productCategory: Yup.string().required('required'),
      storageTemp: Yup.string().required('required'),
      barcode: Yup.boolean().required('required'),
      expiryDate: Yup.boolean().required('required'),
      testing: Yup.boolean().required('required'),
      distribution: Yup.boolean().required('required'),
      distributionType: Yup.string().when('distribution', {
        is: true,
        then: Yup.string().required('required'),
        otherwise: Yup.string().notRequired()
      }),
      weight: Yup.string().nullable().required('required'),
      shelfLife: Yup.string().nullable().required('required'),
      retailSellingPrice: Yup.string().nullable().required('required'),
      other_certification: Yup.object({
        is_checked: Yup.boolean().required(),
        value: Yup.string().when('is_checked', (value) => {
          if (value) {
            return value === true
              ? Yup.string().nullable().required('required')
              : Yup.string().nullable().notRequired();
          }
          return Yup.string().nullable().notRequired();
        })
      }),
      productCertifications: Yup.array().notRequired()
    })
  )
});

export const productTypeOptions = [
  {
    id: 1,
    value: 'Fresh or Perishable (< 6 months shelf life)',
    label: 'questionnaire.q1.option1'
  },
  {
    id: 2,
    value: 'Shelf Stable (> 6 months shelf life) ',
    label: 'questionnaire.q1.option2'
  },
  {
    id: 3,
    value: 'Frozen (>12 months shelf life)',
    label: 'questionnaire.q1.option3'
  }
];

export const productCategoryOptions = [
  { id: 1, label: 'product.detail.productCategory.snack', value: 'Snack' },
  { id: 2, label: 'product.detail.productCategory.packaged', value: 'Packaged' },
  { id: 3, label: 'product.detail.productCategory.cooking', value: 'Cooking' },
  { id: 4, label: 'product.detail.productCategory.milk', value: 'Milk & Baby Food' },
  { id: 5, label: 'product.detail.productCategory.beverage', value: 'Beverage' },
  { id: 6, label: 'product.detail.productCategory.freshPackaged', value: 'Fresh Packaged' },
  { id: 7, label: 'product.detail.productCategory.deli', value: 'Deli' },
  { id: 8, label: 'product.detail.productCategory.bakery', value: 'Bakery' },
  { id: 9, label: 'product.detail.productCategory.freshMeat', value: 'Fresh Meat ' },
  { id: 10, label: 'product.detail.productCategory.freshSeafood', value: 'Fresh Seafood' },
  { id: 11, label: 'product.detail.productCategory.produce', value: 'Produce' }
];

export const productTempOptions = [
  {
    id: 1,
    label: 'product.detail.productStorageTemp.normal',
    value: 'Normal / Ambient temperature'
  },
  { id: 2, label: 'product.detail.productStorageTemp.chilled', value: 'Chilled' },
  { id: 3, label: 'product.detail.productStorageTemp.iced', value: 'Iced' },
  { id: 4, label: 'product.detail.productStorageTemp.frozen', value: 'Frozen' },
  {
    id: 5,
    label: 'product.detail.productStorageTemp.other',
    value: 'Other (Please Specify temperature)'
  }
];

export const productBarcodeOptions = [
  {
    id: 1,
    label: 'product.detail.productBarcode.yes',
    value: true
  },
  {
    id: 2,
    label: 'product.detail.productBarcode.no',
    value: false
  }
];

export const productExpirationOptions = [
  {
    id: 1,
    label: 'product.detail.productExipration.yes',
    value: true
  },
  {
    id: 2,
    label: 'product.detail.productExipration.no',
    value: false
  }
];

export const productTestingOptions = [
  {
    id: 1,
    label: 'product.detail.productTesting.yes',
    value: true
  },
  {
    id: 2,
    label: 'product.detail.productTesting.no',
    value: false
  }
];

export const productDistributionOptions = [
  {
    id: 1,
    label: 'product.detail.productDistribution.yes',
    value: true
  },
  {
    id: 2,
    label: 'product.detail.productDistribution.no',
    value: false
  }
];

export const productDistributionTypeOptions = [
  {
    id: 1,
    label: 'product.detail.productDistributionType.owned',
    value: 'Owned / Internal'
  },
  { id: 2, label: 'product.detail.productDistributionType.external', value: 'External' }
];

export const productCertificationOptions = [
  { id: 1, label: 'product.detail.productCertification.fda', value: 'FDA' },
  { id: 2, label: 'product.detail.productCertification.organic', value: 'Organic' },
  { id: 3, label: 'product.detail.productCertification.GI', value: 'GI' },
  { id: 4, label: 'product.detail.productCertification.OTOP', value: 'OTOP' },
  { id: 5, label: 'product.detail.productCertification.Copyright', value: 'Copyright' },
  { id: 6, label: 'product.detail.productCertification.Halal', value: 'Halal' },
  { id: 7, label: 'product.detail.productCertification.Kosher', value: 'Kosher' },
  { id: 8, label: 'product.detail.productCertification.QCertificate', value: '“Q” Certificate' },
  { id: 9, label: 'product.detail.productCertification.PGS', value: 'PGS' },
  { id: 10, label: 'product.detail.productCertification.SDG', value: 'SDGs PGS' },
  { id: 11, label: 'product.detail.productCertification.healthier', value: 'Healthier Choice' },
  {
    id: 12,
    label: 'product.detail.productCertification.standard',
    value: 'Thai Community Product Standards'
  },
  { id: 13, label: 'product.detail.productCertification.greenLabel', value: 'Green Label' },
  {
    id: 14,
    label: 'product.detail.productCertification.rspo',
    value: 'Roundtable on Sustainable Palm Oil (RSPO)'
  }
];
