import { Box, Step, StepLabel, Stepper } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import companyService from 'api/services/CompanyService';
import { useAuth } from 'auth';
import RegistrationWrapper from 'components/RegistrationWrapper';
import {
  InitialValuesConstant,
  initialValues,
  validationSchema
} from 'components/RegistrationWrapper/initialValues';
import {
  getInitialSubmissionValues,
  submissionSchema
} from 'components/RegistrationWrapper/initialValues/submission';

import Alert from 'components/Alert';

import { useReview } from 'contexts/review';
import LocalStorage, { LOCALSTORAGE_KEY } from 'helper/LocalStorage';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { stepperRoutes } from 'routes/Nav';
import { IAspiration } from 'types/Aspiration';
import ICompany from 'types/Company';
import ILicense from 'types/License';
import { IProductInitValue, IProductResponse } from 'types/Product';
import { formatAspirationResponse } from 'utils/formatAspirationResponse';
import { formatLicenseResponse } from 'utils/formatLicenseResponse';
import { formatProductResponse } from 'utils/formatProductResponse';
import { isEmptyObject } from 'utils/isEmptyObject';
import useStyles from './stepperStyles';

const StepperStyled = styled(Stepper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '80%',
    '& .MuiStepLabel-label': {
      lineHeight: 1,
      marginTop: 0,
      fontSize: '20px !important',
      display: 'none'
    },
    '& .MuiStepLabel-root': {
      width: 30
    }
  }
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 30
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#9CA3AF',
    borderRadius: 1,
    [theme.breakpoints.down('sm')]: {
      height: 1
    }
  }
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.white.main,
  fontWeight: 700,
  border: `3px solid ${theme.palette.primary.main}`,
  zIndex: 1,
  color: theme.palette.primary.main,
  width: 64,
  height: 64,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '40px',
  textDecoration: 'none',
  ...(ownerState.completed && {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    border: 'none'
  }),
  ...(ownerState.active && {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.secondary.main,
    border: 'none'
  }),
  [theme.breakpoints.down('md')]: {
    transform: 'scale(0.4)'
  }
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: number } = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export type FormValues = ICompany | ILicense | IProductInitValue | IAspiration | Record<any, any>;

const localStorage = new LocalStorage();

const CustomStepper = () => {
  const { user } = useAuth();
  const { isReviewing, userReviewing } = useReview();
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState<number>(() => {
    if (isReviewing) {
      return 0;
    }

    if (localStorage.getItemByKey(LOCALSTORAGE_KEY.STEP)) {
      return parseInt(localStorage.getItemByKey(LOCALSTORAGE_KEY.STEP));
    }

    return 0;
  });
  const [initialFormValues, setInitialFormValues] = useState<FormValues>(initialValues[activeStep]);
  const [validation, setValidation] = useState(validationSchema[0]);

  if (!user) {
    return <></>;
  }

  const { data: companyData, refetch } = useQuery(
    'get-company',
    () => companyService.getCompanyByUserId(isReviewing ? userReviewing : user.id),
    {
      select: (companyData) => {
        let formatCompany = companyData.company;

        if (companyData.company) {
          formatCompany = {
            ...companyData.company,
            companyName: companyData.company.name
          };
        }

        return formatCompany;
      }
    }
  );

  const fetchFormValues = async (step: number): Promise<FormValues> => {
    let company = null;
    if (companyData) {
      company = companyData;
    }

    switch (step) {
      case 0:
        if (company) {
          return company;
        }

        return initialValues[InitialValuesConstant.COMPANY];
      case 1:
        if (company && !isEmptyObject(company.license)) {
          const licenseValues = formatLicenseResponse(company.license);
          return {
            ...licenseValues,
            certificates: {
              ...licenseValues.certificates,
              fdaManufacturing: licenseValues.certificates.fda
            }
          } as ILicense;
        }
        return initialValues[InitialValuesConstant.LICENSE];
      case 2:
        if (company) {
          const product: IProductResponse = company.product;
          const isEmptyProduct = isEmptyObject(product);
          if (isEmptyProduct) {
            return initialValues[InitialValuesConstant.PRODUCT];
          }

          const productValues = formatProductResponse(product);
          return productValues as IProductInitValue;
        }

        return initialValues[InitialValuesConstant.PRODUCT];
      case 3:
        if (company && !isEmptyObject(company.aspiration)) {
          const aspirationValues = formatAspirationResponse(company.aspiration);
          return aspirationValues as IAspiration;
        }

        return initialValues[InitialValuesConstant.ASPIRATION];
      default: {
        if (company) {
          return getInitialSubmissionValues(company);
        }

        return {};
      }
    }
  };

  const getValidationSchema = (step: number) => {
    const MAX_STEPS = 5;
    if (step === MAX_STEPS - 1) {
      return submissionSchema as any;
    }

    return validationSchema[step];
  };

  useEffect(() => {
    (async () => {
      const values = await fetchFormValues(activeStep);

      setInitialFormValues(values);
    })();
  }, [activeStep, companyData]);

  useEffect(() => {
    refetch();

    setValidation(getValidationSchema(activeStep));

    if (activeStep > 0 && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeStep, initialFormValues]);

  return (
    <>
      <StepperStyled
        ref={ref}
        className={classes.root}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}>
        {stepperRoutes.map((label) => (
          <Step key={label.text}>
            <StepLabel className={classes.root} StepIconComponent={ColorlibStepIcon}>
              {t(label.text)}
            </StepLabel>
          </Step>
        ))}
      </StepperStyled>
      {activeStep < 4 && (
        <Box className={classes.root}>
          <Alert severity="info" color="error" sx={{ mt: 2 }}>
            {t('account_registration.alert')}
          </Alert>
          <Alert severity="info" color="warning" sx={{ mt: 2 }}>
            {t('account_registration.file_size')}
          </Alert>
        </Box>
      )}
      <RegistrationWrapper
        step={activeStep}
        handleBack={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        handleNext={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        initValues={initialFormValues}
        validationSchema={isReviewing ? false : validation}
        company={companyData ?? {}}
      />
    </>
  );
};

export default CustomStepper;
