import Aspiration from 'components/RegistrationForm/Aspiration';
import Company from 'components/RegistrationForm/Company';
import License from 'components/RegistrationForm/License';
import Product from 'components/RegistrationForm/Product';
import Submission from 'components/RegistrationForm/Submission';
import { TFunction } from 'i18next';

const makeFormContent = (t: TFunction, step: number) => {
  let header;
  let subtitle;
  let content;

  switch (step) {
    case 0:
      header = t('account_registration.company_info');
      subtitle = t('subTitle.company');
      content = <Company />;
      break;
    case 1:
      header = t('account_registration.sale_channels');
      subtitle = t('subTitle.sale_channels');
      content = <License />;
      break;

    case 2:
      header = t('account_registration.products2');
      subtitle = '';
      content = <Product />;
      break;
    case 3:
      header = t('account_registration.aspiration');
      subtitle = t('subTitle.aspiration');
      content = <Aspiration />;
      break;
    case 4:
      header = t('account_registration.document');
      subtitle = t('subTitle.document');
      content = <Submission />;
      break;
    default:
      header = 'Header';
      subtitle = 'Sub Title';
      content = <></>;
  }

  return { header, subtitle, content };
};

export type makeFormContentType = ReturnType<typeof makeFormContent>;

export default makeFormContent;
