import aspirationService from 'api/services/AspirationService';
import { useMutation } from 'react-query';
import { IAspiration } from 'types/Aspiration';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
}

export const useUpdateAspiration = ({ onSuccess, onError }: Props) => {
  return useMutation((body: IAspiration) => aspirationService.updateAspiration(body, body.id!), {
    onError,
    onSuccess
  });
};
