import Admin from 'components/Admin';
import Auth from 'components/Auth';
import About from 'pages/About';
import NotFoundPage from 'pages/About/404';
import ApplicationStatusPage from 'pages/ApplicationStatus';
import ContactPage from 'pages/Contact';
import ForgotPasswordPage from 'pages/ForgotPassword';
import Home from 'pages/Home';
import HowItWorkPage from 'pages/HowItWork';
import RegistrationPage from 'pages/Registration';
import ResetPasswordPage from 'pages/ResetPassword';
import ReviewSubmissionPage from 'pages/ReviewSubmission';
import UpdateSubmissionPage from 'pages/ReviewSubmission/UpdateSubmission';
import TermsPage from 'pages/Terms';
import WhatYouGetPage from 'pages/WhatYouGet';
import { Route, Routes as Router } from 'react-router-dom';

const Routes = () => {
  return (
    <Router>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/what-you-get" element={<WhatYouGetPage />} />
      <Route path="/how-it-works" element={<HowItWorkPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/reset-password/:token" element={<ResetPasswordPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/registration"
        element={
          <Auth>
            <RegistrationPage />
          </Auth>
        }
      />
      <Route path="/review">
        <Route
          index
          element={
            <Admin>
              <ReviewSubmissionPage />
            </Admin>
          }
        />
        <Route
          path=":id"
          element={
            <Admin>
              <UpdateSubmissionPage />
            </Admin>
          }
        />
      </Route>
      <Route path="/application-status" element={<ApplicationStatusPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Router>
  );
};

export default Routes;
