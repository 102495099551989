import { ArrowBackIos } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';
import useStyles from 'components/Layout/layoutStyles';
import CustomButton from 'components/shared/Button';
import UpdateReviewTable from 'components/UpdateReviewTable';
import { useNavigate } from 'react-router-dom';

const UpdateSubmissionPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container className={classes.layout__content} disableGutters>
      <Box p={3}>
        <CustomButton
          startIcon={<ArrowBackIos />}
          variant="outlined"
          color="primary"
          text="Back"
          onClick={() => {
            navigate(`/review`);
          }}
        />
      </Box>
      <Box className={classes.layout__header} p={0}>
        <Typography textAlign="center" component="h1" variant="h1">
          Review Partnership Application Status
        </Typography>
        <UpdateReviewTable />
      </Box>
    </Container>
  );
};

export default UpdateSubmissionPage;
