import IUser from 'types/User';
import axiosClient from 'api/axiosClient';
import { IRegisterFields } from 'components/Modal/RegisterModal';
import { ILoginFields } from 'components/Modal/LoginModal';

export type IUserResponse = {
  user: IUser;
  jwt: string;
};

class UserService {
  me = (): Promise<IUser> => {
    return axiosClient.get('/users/me');
  };

  register = (formData: IRegisterFields): Promise<IUserResponse> => {
    return axiosClient.post('/auth/local/register', {
      username: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone_number: formData.phoneNumber,
      email: formData.email,
      password: formData.password,
      line_id: formData.lineId
    });
  };

  login = ({ identifier, password }: ILoginFields): Promise<IUserResponse> => {
    return axiosClient.post('/auth/local', {
      identifier,
      password
    });
  };

  forgotPassword = (body: { email: string }) => {
    return axiosClient.post('/auth/forgot-password', body);
  };

  resetPassword = (body: any) => {
    return axiosClient.post('/auth/reset-password', body);
  };
}

const userService = new UserService();
export default userService;
