import axiosClient from 'api/axiosClient';
import { useMutation } from 'react-query';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
}

const submitUpload = (body: any) => {
  return axiosClient.post('/submission', {
    ...body,
    licenseDocument: {
      ...body.licenseDocument,
      fda: body.licenseDocument.fdaManufacturing
    }
  });
};

export const useUploadSubmission = ({ onSuccess, onError }: Props) => {
  return useMutation((body: any) => submitUpload(body), {
    onError,
    onSuccess
  });
};
