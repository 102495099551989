import { EditOutlined, FileDownloadOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Chip, FormControl, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridFilterModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import reviewService from 'api/services/ReviewService';
import ReviewModal from 'components/Modal/ReviewModal';
import { useReview } from 'contexts/review';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IReviewBody, IReviewTable } from 'types/Review';
import { customErrorResponse } from 'utils/customErrorResponse';
import { getDateFilterOperator } from 'utils/getDateFilterOperator';
import { getStringFilterOperators } from 'utils/getStringFilterOperators';
import { handleFilterChange } from 'utils/handleFilterChange';
import { handleSortChange } from 'utils/handleSortChange';
import { getLabelColor, menuStatuses } from 'utils/renderCell';
import useStyles from './submissionTableStyles';

const CURRENT_PAGE = 0;
const PAGE_SIZE_DEFAULT = 10;
const PAGE_SIZE_OPTIONS = [10, 20, 30];
const DEFAULT_BODY: IReviewBody = {
  filter: {},
  page: CURRENT_PAGE,
  pageSize: PAGE_SIZE_DEFAULT,
  sort: {
    table: 'registration',
    field: 'created_at',
    direction: 'DESC'
  }
};

const Toolbar = (props: GridToolbarContainerProps) => (
  <GridToolbarContainer {...props}>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const SubmissionTable = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { changeReviewing, openModal, handleOpenModal } = useReview();

  const [page, setPage] = useState<number>(CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_DEFAULT);
  const [body, setBody] = useState<typeof DEFAULT_BODY>(DEFAULT_BODY);

  const { data, isLoading, error, refetch } = useQuery(
    ['get-review', body],
    () => reviewService.getReviews(body),
    {
      select: (reviewData) => {
        const formatData: IReviewTable[] = reviewData.data.map((review) => {
          const { registration, user, company, product } = review;

          let fdaCheck = false;
          if (product.products && product.products.length > 0) {
            fdaCheck = product.products.some((product: any) => product.has_fda_certification);
          }

          return {
            id: registration.id,
            created_at: dayjs(registration.created_at).format('MM/DD/YYYY'),
            updated_at: dayjs(registration.updated_at).format('MM/DD/YYYY'),
            company_name: company.name,
            user_name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phone_number: user.phone_number,
            revenue: company.annual_revenue,
            province: company.province,
            product_type: company.product_type,
            has_fda_certification: fdaCheck,
            status: registration.status,
            companyId: company.id,
            userId: user.id
          };
        });

        return {
          data: formatData,
          total: reviewData.total
        };
      }
    }
  );

  const { mutate, isLoading: submitting } = useMutation(
    (body: any) => reviewService.updateStatusById(body.id, body),
    {
      onError: (error: any) => {
        toast.error(customErrorResponse(error));
      },
      onSuccess: () => {
        refetch();
      }
    }
  );

  const { mutate: download, isLoading: downloading } = useMutation(
    ({ companyId, userId }: any) => reviewService.downloadDocument(companyId, userId),
    {
      onError: (error: any) => {
        toast.error(customErrorResponse(error));
      },
      onSuccess: (response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    }
  );

  const columns = React.useMemo<GridColumns>(
    () => [
      {
        field: 'action',
        headerName: 'Actions',
        minWidth: 150,
        type: 'actions',
        getActions: ({ row }) => [
          <>
            <Tooltip title="View document">
              <GridActionsCellItem
                icon={<VisibilityOutlined />}
                label="View document"
                onClick={() => {
                  changeReviewing(true, row.userId);
                  handleOpenModal(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Download Document">
              <GridActionsCellItem
                icon={<FileDownloadOutlined />}
                label="Download Document"
                onClick={() => {
                  const { companyId, userId } = row;
                  download({ companyId, userId });
                }}
              />
            </Tooltip>
            <Tooltip title="Update Document">
              <GridActionsCellItem
                icon={<EditOutlined />}
                label="Update Document"
                onClick={() => {
                  navigate(`/review/${row.id}`);
                }}
              />
            </Tooltip>
          </>
        ]
      },
      {
        field: 'status',
        headerName: 'Application Status',
        type: 'singleSelect',
        valueOptions: menuStatuses.map((menu) => {
          return {
            label: t(menu.label),
            value: menu.value
          };
        }),
        minWidth: 200,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value, row }) => {
          return (
            <FormControl fullWidth>
              <Select
                value={value}
                label="Age"
                onChange={(event) => {
                  mutate({
                    id: row.id,
                    status: event.target.value
                  });
                }}>
                {menuStatuses.map((menu, index) => (
                  <MenuItem key={index} value={menu.value}>
                    <Chip
                      label={
                        <Typography color="#fff" fontSize={16}>
                          {t(menu.label).toUpperCase()}
                        </Typography>
                      }
                      color={getLabelColor(menu.value)}
                      size="medium"
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      },
      {
        field: 'created_at',
        headerName: 'Date Submitted',
        type: 'date',
        minWidth: 150,
        sortingOrder: ['desc', 'asc'],
        filterOperators: getDateFilterOperator,
        align: 'right'
      },
      {
        field: 'updated_at',
        headerName: 'Modified Date',
        type: 'date',
        minWidth: 150,
        sortingOrder: ['desc', 'asc'],
        filterOperators: getDateFilterOperator,
        align: 'right'
      },
      {
        field: 'product_type',
        headerName: 'Product Type',
        type: 'singleSelect',
        valueOptions: [
          {
            value: 'Fresh or Perishable (< 6 months shelf life)',
            label: t('questionnaire.q1.option1')
          },
          {
            value: 'Shelf Stable (> 6 months shelf life) ',
            label: t('questionnaire.q1.option2')
          },
          {
            value: 'Frozen (>12 months shelf life)',
            label: t('questionnaire.q1.option3')
          }
        ],
        minWidth: 150,
        sortingOrder: ['desc', 'asc']
      },
      {
        field: 'has_fda_certification',
        headerName: 'FDA Certification',
        type: 'boolean',
        minWidth: 150,
        sortable: false
      },
      {
        field: 'company_name',
        headerName: 'Company Name',
        type: 'string',
        filterOperators: getStringFilterOperators,
        minWidth: 150,
        sortingOrder: ['desc', 'asc']
      },
      {
        field: 'user_name',
        headerName: 'User Name',
        type: 'string',
        minWidth: 150,
        sortingOrder: ['desc', 'asc'],
        filterOperators: getStringFilterOperators
      },

      {
        field: 'email',
        headerName: 'Email address',
        type: 'string',
        minWidth: 150,
        sortingOrder: ['desc', 'asc'],
        filterOperators: getStringFilterOperators
      },

      {
        field: 'phone_number',
        headerName: 'Telephone No.',
        type: 'string',
        minWidth: 150,
        sortingOrder: ['desc', 'asc'],
        filterOperators: getStringFilterOperators,
        align: 'right'
      },
      {
        field: 'revenue',
        headerName: 'Revenue',
        type: 'singleSelect',
        valueOptions: [
          {
            value: 'less than 100,000 bht',
            label: t('questionnaire.q3.option1')
          },
          {
            value: '100,001 - 500,000 bht',
            label: t('questionnaire.q3.option2')
          },
          {
            value: '500,001 - 1,000,000 bht',
            label: t('questionnaire.q3.option3')
          },
          {
            value: '1,000,001 - 10,000,000 bht',
            label: t('questionnaire.q3.option4')
          },
          {
            value: '10,000,001 - 50,000,000 bht',
            label: t('questionnaire.q3.option5')
          },
          {
            value: '50,000,001 - 100,000,000 bht',
            label: t('questionnaire.q3.option6')
          }
        ],
        minWidth: 200,
        sortingOrder: ['desc', 'asc']
      },
      {
        field: 'province',
        headerName: 'Province',
        type: 'singleSelect',
        valueOptions: ['United Kingdom', 'Spain', 'Brazil'],
        minWidth: 150,
        sortingOrder: ['desc', 'asc']
      }
    ],
    []
  );

  const changeBody = (bodyPart: any) => {
    const initialBody = body;

    const newBody = {
      ...initialBody,
      ...bodyPart
    };

    setBody(newBody);
  };

  const pageOptions = {
    paginationMode: 'server' as const,
    pagination: true,
    pageSize,
    page,
    rowsPerPageOptions: PAGE_SIZE_OPTIONS,
    rowCount: data ? data.total : 0,
    onPageSizeChange: (pageSize: number) => {
      setPageSize(pageSize);
      changeBody({
        pageSize
      });
    },
    onPageChange: (page: number) => {
      setPage(page);
      changeBody({
        page
      });
    }
  };

  const filterOptions = {
    filterMode: 'server' as const,
    onFilterModelChange: (filterModel: GridFilterModel) => {
      const { items, linkOperator } = filterModel;
      const filters = handleFilterChange(items);
      changeBody({
        filter: { ...filters, linkOperator }
      });
    }
  };

  const sortOptions = {
    sortingMode: 'server' as const,
    onSortModelChange: (model: GridSortModel) => {
      const sortModel = handleSortChange(model);
      changeBody({ sort: { ...sortModel } });
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <DataGridPro
        autoHeight
        loading={isLoading || submitting || downloading}
        columns={columns}
        rows={data ? data.data : []}
        disableSelectionOnClick
        {...pageOptions}
        {...filterOptions}
        {...sortOptions}
        disableMultipleColumnsSorting
        components={{
          Toolbar
        }}
        className={classes.table}
      />
      <ReviewModal open={openModal} onClose={() => handleOpenModal(false)} />
    </>
  );
};

export default SubmissionTable;
