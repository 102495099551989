import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  upload__preview: {
    width: '100px',
    height: '100px',
    display: 'inline-flex',
    background: theme.palette.gray_bg.main,
    boxSizing: 'border-box',
    marginRight: theme.spacing(1),
    position: 'relative'
  },
  upload__add: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '6px',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee'
    }
  },
  upload__preview__img: {
    borderRadius: '6px',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    '&:hover': {
      opacity: '0.5'
    }
  },
  upload__blank: {
    padding: '32px 16px',
    justifyContent: 'center'
  },
  upload__active: {
    padding: '8px 32px'
  }
}));

export default useStyles;
