export interface IAsirationResponse {
  selling_places: string;
  aspirations: string;
  assistance: string;
  questions: string;
  heard_from: string;
}

export const formatAspirationResponse = (response: IAsirationResponse) => {
  const { selling_places, assistance } = response;

  return {
    ...response,
    selling_places: JSON.parse(selling_places),
    assistance: JSON.parse(assistance)
  };
};
