import { Box, Grid, Link } from '@mui/material';
import userService, { IUserResponse } from 'api/services/UserService';
import { useAuth } from 'auth';
import CustomButton from 'components/shared/Button';
import Controls from 'components/shared/Form/FormControl';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ModalContent } from '../ModalWrapper';
import useStyles from './registerModalStyles';
interface IRegisterModalProps {
  setModal?: React.Dispatch<React.SetStateAction<ModalContent>>;
  onClose: () => void;
}

export interface IRegisterFields {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  lineId?: string | null;
  password: string;
  confirmPassword: string;
}

const RegisterModal = ({ onClose }: IRegisterModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const auth = useAuth();
  const navigate = useNavigate();

  const { isLoading: addLoading, mutate: addData } = useMutation(
    (body: IRegisterFields) => userService.register(body),
    {
      onError: (error: any) => {
        const realError = error.message[0].messages[0].message;
        toast.error(realError);
      },
      onSuccess: (response: IUserResponse) => {
        onClose();
        auth.login(response);
        navigate('/registration', { replace: true });
      }
    }
  );

  const initialValues: IRegisterFields = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    lineId: '',
    password: '',
    confirmPassword: ''
  };

  const validationSchema: Yup.SchemaOf<IRegisterFields> = Yup.object({
    firstName: Yup.string()
      .required('required')
      .matches(/^[\u0E00-\u0E7Fa-zA-Z']*$/, 'first_name')
      .max(100, 'max_characters_2'),
    lastName: Yup.string()
      .required('required')
      .matches(/^[\u0E00-\u0E7Fa-zA-Z']*$/, 'last_name')
      .max(100, 'max_characters_2'),
    phoneNumber: Yup.string()
      .required('required')
      .max(20, 'phone_number')
      // eslint-disable-next-line no-useless-escape
      .matches(/([\-\\()\d]{1,20})/, 'max_characters_3'),
    email: Yup.string().email('invalid_email').required('required'),
    password: Yup.string().required('password_required_6').min(6, 'password_required_6'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'confirm_password')
      .required('password_required_6'),
    lineId: Yup.string().notRequired()
  });

  const onSubmit = (values: IRegisterFields) => {
    addData(values);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => (
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controls.Input
                  name="firstName"
                  label="account_registration.account_representative.firstName"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controls.Input
                  name="lastName"
                  label="account_registration.account_representative.lastName"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controls.Input
                  name="phoneNumber"
                  label="account_registration.account_representative.phoneNumber"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controls.Input
                  name="email"
                  label="account_registration.account_representative.email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  name="lineId"
                  label="account_registration.account_representative.lineId"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controls.Input
                  name="password"
                  type="password"
                  label="account_registration.account_representative.createPassword"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controls.Input
                  name="confirmPassword"
                  type="password"
                  label="account_registration.account_representative.confirmPassword"
                  required
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.modal__register__footer} textAlign={'center'}>
            <Link href="/contact" target="_blank" mr={1}>
              <CustomButton
                variant="outlined"
                color="primary"
                text={t('questionnaire.contact')}
                sx={{
                  float: 'right'
                }}
              />
            </Link>
            <CustomButton
              variant="contained"
              color="secondary"
              text={t('account_registration.account_representative.createAccount')}
              sx={{
                color: '#ffffff'
              }}
              type="submit"
              disabled={!(formik.dirty && formik.isValid) || addLoading}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterModal;
