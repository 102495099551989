/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, LinearProgress, Link, Typography } from '@mui/material';
import Controls from 'components/shared/Form/FormControl';
import { DocumentTypes } from 'constants/documents';
import { licensesField, productField } from 'constants/submissionField';
import { useModal } from 'contexts/modal';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { useProduct } from 'hooks/product/useProduct';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IFacility from 'types/Facility';
import { IProductItemResponse, ProductCertificateObjType } from 'types/Product';
import { checkObjectPropertyLength } from 'utils/checkObjectPropertyLength';
import { isEmptyObject } from 'utils/isEmptyObject';
import { renderFacilitySubmission } from 'utils/renderFacilitySubmission';
import { renderLicenseSubmission } from 'utils/renderLicenseSubmission';
import { renderTypeOfBusiness } from 'utils/renderTypeOfBusinessForm';
import UploadWithHint from '../UploadWithHint';

const Submission = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<any>();
  const { productCertificate } = useProduct();
  const { handleOpenModal, setLink } = useModal();

  const currentStep = 4;

  if (isEmptyObject(values)) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  const { companyDocument, facilities, licenseDocument, productDocument } = values;
  const { farming_certification, manufacturing_certification, farming_other, manufacturing_other } =
    licenseDocument;
  const { vat, sme, type_of_business } = companyDocument;
  const { products } = productDocument;

  const licenseRequirement = false;
  // !farming_certification.includes('No License or Certification') ||
  // !manufacturing_certification.includes('No License or Certification') ||
  // farming_other.value ||
  // manufacturing_other.value;

  const facilityNameLabel = (type: string, name: string) => {
    switch (type) {
      case 'farm':
        return t('submission.facility.farm_label', { name: name });

      case 'fishery':
        return t('submission.facility.fishery_label', { name: name });
      case 'production':
      default:
        return t('submission.facility.facility_label', { name: name });
    }
  };

  return (
    <Grid container spacing={2}>
      {/* Company Document start */}

      {vat.is_registered === true && (
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            {t('submission.company.vat')}
          </Typography>
          <Controls.Upload
            name="companyDocument.vat.vat_registration_upload"
            destDir={DocumentTypes.COMPANY}
          />
          <Box sx={{ pt: 2, pl: 2 }}>
            <ul>
              <li>
                <Typography variant="body2" component="p">
                  {t('company_information.section2.registrationType.vatUpload.li1')}
                </Typography>
              </li>
              <li>
                <Link>
                  <Typography
                    variant="body2"
                    component="p"
                    onClick={() => {
                      setLink([
                        {
                          url: `${process.env.REACT_APP_STORAGE}/examples/company/VAT+Registration+(PP20).jpg`
                        }
                      ]);
                      handleOpenModal(true);
                    }}>
                    {t('company_information.section2.registrationType.vatUpload.li3')}
                  </Typography>
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
      )}
      {sme.is_registered === true && (
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            {t('submission.company.sme')}
          </Typography>
          <Controls.Upload
            name="companyDocument.sme.sme_registration_upload"
            destDir={DocumentTypes.COMPANY}
          />
          <Box sx={{ pt: 2, pl: 2 }}>
            <ul>
              <li>
                <Typography variant="body2" component="p">
                  {t('company_information.section2.registrationType.smeUpload.li1')}
                </Typography>
              </li>
              <li>
                <Link>
                  <Typography
                    variant="body2"
                    component="p"
                    onClick={() => {
                      setLink([
                        {
                          url: `${process.env.REACT_APP_STORAGE}/examples/company/SME+Registration+(OSMEP).jpg`
                        }
                      ]);
                      handleOpenModal(true);
                    }}>
                    {t('company_information.section2.registrationType.smeUpload.li3')}
                  </Typography>
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
      )}

      {renderTypeOfBusiness(type_of_business, currentStep)}

      {facilities && (
        <Grid item xs={12}>
          <FieldArray name="facilities">
            {(fieldArray: FieldArrayRenderProps) => {
              return (
                <>
                  {facilities.map((facility: IFacility, index: number) => {
                    const { farm, fishery, production_facility, other, has_facility } = facility;
                    const farmRequirement = checkObjectPropertyLength(farm);
                    const fisheryRequirement = checkObjectPropertyLength(fishery);
                    const productionRequirement = checkObjectPropertyLength(production_facility);
                    const otherRequirement = checkObjectPropertyLength(other);
                    const facilityCondition =
                      farmRequirement ||
                      fisheryRequirement ||
                      productionRequirement ||
                      otherRequirement;

                    return (
                      <>
                        {facilityCondition && (
                          <React.Fragment key={index}>
                            {/* Production facility photos start */}
                            <Typography variant="h3" component="h3" pt={5}>
                              {facilityNameLabel(has_facility, facility.name)}
                            </Typography>
                            <Grid pt={1} container spacing={2}>
                              {renderFacilitySubmission(
                                facilities,
                                index,
                                setLink,
                                handleOpenModal,
                                t,
                                currentStep
                              )}
                              {otherRequirement && (
                                <Grid item xs={12} pt={3}>
                                  <Typography fontWeight={700} component="p">
                                    {t('facility.otherPhoto.title')}
                                  </Typography>
                                  <Typography variant="body2" component="p" pb={3}>
                                    {t('facility.otherPhoto.subtitle')}
                                  </Typography>

                                  <Controls.Upload
                                    name={`facilities[${index}].other.other_photo`}
                                    destDir={DocumentTypes.FACILITY}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            {/* Production facility photos end */}
                          </React.Fragment>
                        )}
                      </>
                    );
                  })}
                </>
              );
            }}
          </FieldArray>
        </Grid>
      )}

      {/* Company Document end */}

      {/* License Document start */}
      {licenseRequirement && (
        <Grid item xs={12} mt={3}>
          <Typography variant="h3" component="h3">
            {t('document.licenseDocument.label')}
          </Typography>
        </Grid>
      )}
      {Array.isArray(farming_certification) &&
      farming_certification.length === 0 &&
      Array.isArray(manufacturing_certification) &&
      manufacturing_certification.length === 0 &&
      !farming_other.is_checked &&
      !manufacturing_other.is_checked ? (
        <Typography variant="body1" component="p">
          No certification found!
        </Typography>
      ) : (
        licensesField.map((field, index) => {
          return renderLicenseSubmission(
            farming_certification,
            manufacturing_certification,
            field,
            index,
            'licenseDocument',
            true,
            currentStep
          );
        })
      )}

      {farming_other && farming_other.value && (
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            {t('product.uploadDocument.nameLabel', {
              name: farming_other.value ? `(${farming_other.value})` : ' '
            })}
          </Typography>
          <Controls.Upload
            name={`licenseDocument.farming_other.upload`}
            destDir={DocumentTypes.CERTIFICATE}
          />
          <Box sx={{ pt: 2, pl: 2 }}>
            <ul>
              <li>
                <Typography variant="body2" component="p">
                  {t('submission.guidance.other')}
                </Typography>
              </li>
            </ul>
          </Box>
        </Grid>
      )}

      {manufacturing_other && manufacturing_other.value && (
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            {t('product.uploadDocument.nameLabel', {
              name: manufacturing_other.value ? `(${manufacturing_other.value})` : ' '
            })}
          </Typography>
          <Controls.Upload
            name={`licenseDocument.manufacturing_other.upload`}
            destDir={DocumentTypes.CERTIFICATE}
          />
          <Box sx={{ pt: 2, pl: 2 }}>
            <ul>
              <li>
                <Typography variant="body2" component="p">
                  {t('submission.guidance.other')}
                </Typography>
              </li>
            </ul>
          </Box>
        </Grid>
      )}
      {/* License Document end */}

      {/* Product Document start */}
      <FieldArray name="productDocument.products">
        {(fieldArray: FieldArrayRenderProps) => {
          return (
            <>
              {products.map((product: IProductItemResponse, index: number) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} mt={3}>
                    <Typography
                      variant="h3"
                      component="p"
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}>
                      {t('submission.product.label')} {product.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" component="p">
                      {t('product.upload.label')}
                    </Typography>
                    <UploadWithHint
                      label=""
                      name={`productDocument.products[${index}].photos`}
                      destDir={DocumentTypes.PRODUCT}
                      hints={[t('product.detail.subtitle')]}
                      onClickExample={() => {
                        setLink([
                          {
                            url: `${process.env.REACT_APP_STORAGE}/examples/products/product_picture1.webp`
                          },
                          {
                            url: `${process.env.REACT_APP_STORAGE}/examples/products/product_picture2.webp`
                          }
                        ]);
                        handleOpenModal(true);
                      }}
                      exampleText={t('product.upload.list2')}
                    />
                  </Grid>
                  {productField.map((field, indexP) => {
                    if (JSON.parse(product.cerifications).includes(field.value)) {
                      return (
                        <Grid key={indexP} item xs={12}>
                          <UploadWithHint
                            label={t(`submission.product.${field.text}`)}
                            name={`productDocument.products[${index}].certification_photo.${field.text}`}
                            destDir={DocumentTypes.PRODUCT}
                            hints={[t(`submission.guidance.${field.text}`)]}
                            onClickExample={() => {
                              setLink(
                                productCertificate[field.text as keyof ProductCertificateObjType] ||
                                  []
                              );
                              handleOpenModal(true);
                            }}
                            exampleText={`(${t(`submission.example_link_label.${field.text}`)})`}
                          />
                          {field.value === 'FDA' && (
                            <Grid item xs={12} mt={3}>
                              <UploadWithHint
                                label={t(`submission.product.fda_label`)}
                                name={`productDocument.products[${index}].certification_photo.fda_label`}
                                destDir={DocumentTypes.PRODUCT}
                                hints={[
                                  t(`submission.guidance.fda_label`),
                                  t('form.info_msg.upload')
                                ]}
                                onClickExample={() => {
                                  setLink(productCertificate['fda_label']);
                                  handleOpenModal(true);
                                }}
                                exampleText={`(${t(`submission.example_link_label.fda_label`)})`}
                              />
                            </Grid>
                          )}
                        </Grid>
                      );
                    }
                    return <></>;
                  })}
                  {product.other_certification && product.other_certification.value && (
                    <Grid item xs={12}>
                      <Typography variant="body1" component="p">
                        {t('product.uploadDocument.nameLabel', {
                          name: product.other_certification.value
                            ? `(${product.other_certification.value})`
                            : ' '
                        })}
                      </Typography>
                      <Controls.Upload
                        name={`productDocument.products[${index}].other_certification.upload`}
                        destDir={DocumentTypes.PRODUCT}
                      />
                      <Box sx={{ pt: 2, pl: 2 }}>
                        <ul>
                          <li>
                            <Typography variant="body2" component="p">
                              {t('submission.guidance.other')}
                            </Typography>
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </>
          );
        }}
      </FieldArray>
      {/* Product Document end */}
    </Grid>
  );
};

export default Submission;
