import LocalStorage, { LOCALSTORAGE_KEY } from 'helper/LocalStorage';
import { clearToken } from './../api/axiosClient';

const localStorage = new LocalStorage();

export const getToken = () => {
  return localStorage.getItemByKey(LOCALSTORAGE_KEY.JWT);
};

export const setToken = (jwt: string) => {
  localStorage.setItemByKey(LOCALSTORAGE_KEY.JWT, jwt);
  return;
};

export const emptyToken = () => {
  localStorage.removeItemByKey(LOCALSTORAGE_KEY.JWT);
  localStorage.removeItemByKey(LOCALSTORAGE_KEY.STEP);
  clearToken();
  return;
};
