import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '@mui/icons-material/Facebook';
import RoomIcon from '@mui/icons-material/Room';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Link,
  Typography
} from '@mui/material';
import { useContact } from 'hooks/contact/useContact';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useStyles from './contactStyles';

const ContactPage = () => {
  const classes = useStyles();
  const [accordions] = useContact();
  const { t, i18n } = useTranslation();

  const contactEmail = 'topstongtin@tops.co.th';
  const contactFacebook = 'https://www.facebook.com/topstongtin';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.contact__container}>
      <Helmet>
        <title>Tongtin - Contact Us</title>
      </Helmet>
      <Box>
        <Container maxWidth="lg" className={classes.contact__box__container}>
          <Grid container columnSpacing={{ md: 6, xs: 0 }} rowSpacing={{ md: 0, xs: 5 }}>
            <Grid item xs={12} md={7} marginBottom={{ xs: 2, md: 0 }}>
              <Box>
                <Typography
                  component={'h2'}
                  variant="h2"
                  color="primary"
                  fontSize={{ xs: '48px', md: '64px' }}
                  lineHeight={{ xs: '42px', md: '60px' }}
                  mb={{ xs: 5, md: 0 }}
                  mx={{ xs: 2, md: 0 }}>
                  {t('faq_page.title')}
                </Typography>
                <Box>
                  {accordions.map(({ title, detail, id }, index) => (
                    <Accordion
                      key={id}
                      sx={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        m: '0px !important',
                        borderTop: index === 0 ? 'none' : `1px solid rgba(0, 0, 0, 0.12)`,
                        '&:not(:last-child)': {
                          borderBottom: 0
                        },
                        '&:before': {
                          display: 'none'
                        }
                      }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={id}>
                        <Typography variant="body1_bold">{title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="body2_temp"
                          component={'p'}
                          fontSize={'24px'}
                          lineHeight={'24px'}>
                          {detail}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className={classes.contact__sidemenu}>
                <Typography
                  component={'h3'}
                  variant="h3"
                  color="primary"
                  pb={{ md: 3, xs: 2 }}
                  fontSize={{ xs: '36px', md: '54px' }}
                  lineHeight={{ xs: '36px', md: i18n.language === 'en' ? '42px' : '56px' }}>
                  {t('faq_page.side.more_question')}
                </Typography>
                <Box>
                  <Typography pb={1}>{t('faq_page.side.contact_us')}</Typography>
                  <Box display={'flex'} alignItems={'flex-start'} pb={1}>
                    <Box mr={1}>
                      <EmailIcon />
                    </Box>{' '}
                    <Link fontSize={24} href={`mailto:${contactEmail}`}>
                      <Typography
                        variant="body2_temp"
                        component={'p'}
                        fontSize={'24px'}
                        lineHeight={'24px'}>
                        {contactEmail}
                      </Typography>
                    </Link>
                  </Box>
                  <Box display={'flex'} alignItems={'flex-start'} pb={1}>
                    <Box mr={1}>
                      <FacebookIcon />
                    </Box>{' '}
                    <Link fontSize={24} href={contactFacebook}>
                      <Typography
                        variant="body2_temp"
                        component={'p'}
                        fontSize={'24px'}
                        lineHeight={'24px'}>
                        {contactFacebook.replace('https://www.', '')}
                      </Typography>
                    </Link>
                  </Box>
                  <Box display={'flex'} alignItems={'flex-start'} pb={1}>
                    <Box mr={1}>
                      <RoomIcon />
                    </Box>{' '}
                    <Typography variant="body2_temp" fontSize={'1.5rem'} lineHeight={'1.5rem'}>
                      {t('faq_page.side.address')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default ContactPage;
