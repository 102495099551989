import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useStyles from './aboutStyles';

const About = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.about__page__container}>
      <Helmet>
        <title>Tongtin - Who we are</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.about__box__container}>
        <Box className={classes.about__container}>
          <Typography
            className={classes.title_text}
            component={'h1'}
            variant="heading1"
            pb={{ xs: 4, md: 6 }}>
            {t('about.title')}
          </Typography>
          <Grid container columnSpacing={{ md: 8, xs: 0 }} rowSpacing={{ md: 0, xs: 2 }}>
            <Grid item xs={12} md={6} order={{ md: 1, xs: 2 }}>
              <Typography component={'p'} variant="body2_temp" pb={2}>
                {t('about.text1')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} order={{ md: 2, xs: 1 }}>
              <img
                className={classes.banner_img}
                src={`${process.env.REACT_APP_STORAGE}/static/aboutpage/banner1.jpeg`}
                alt="who-we-are-1"
              />
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={{ md: 8, xs: 0 }}
            rowSpacing={{ md: 0, xs: 2 }}
            pt={{ xs: 4, md: 9 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                gap: '1rem',
                pb: { xs: 2 }
              }}>
              <Box>
                <img
                  className={classes.banner_img}
                  src={`${process.env.REACT_APP_STORAGE}/static/aboutpage/banner2.jpeg`}
                  alt="who-we-are-2"
                />
              </Box>
              <Box>
                <img
                  className={classes.banner_img}
                  src={`${process.env.REACT_APP_STORAGE}/static/aboutpage/banner3.jpeg`}
                  alt="who-we-are-3"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} pt={{ xs: 2, md: 0 }}>
              <Typography component={'p'} variant="body2_temp" pb={2}>
                {t('about.text2')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="lg" className={classes.unique__box__container}>
        <Box className={classes.about__container_2}>
          <Typography
            className={classes.title_text}
            component={'h1'}
            variant="heading1"
            pb={{ xs: 4, md: 6 }}>
            {t('about.title_unique')}
          </Typography>
          <Grid item xs={12} md={12} pt={{ xs: 2, md: 0 }}>
            <Typography component={'p'} variant="body2_temp">
              {t('about.text_unique')}
            </Typography>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default About;
