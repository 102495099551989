import { Box, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import userService from 'api/services/UserService';
import { useAuth } from 'auth';
import useStyles from 'components/Layout/layoutStyles';
import CustomButton from 'components/shared/Button';
import Controls from 'components/shared/Form/FormControl';
import TextError from 'components/shared/Form/TextError';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('invalid_email').required('required')
});

interface IForgotPasswordField {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [complete, setComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const initialValues = {
    email: ''
  };

  useEffect(() => {
    if (user) {
      return navigate('/');
    }
  }, [user]);

  const { isLoading, mutate } = useMutation(
    (body: IForgotPasswordField) => userService.forgotPassword(body),
    {
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        setError(true);
      },
      onSuccess: () => {
        console.log('email sent success');
        setComplete(true);
        setLoading(false);
      }
    }
  );

  const onSubmit = (values: any) => {
    setLoading(true);
    setComplete(false);
    setError(false);
    mutate(values);
  };

  return (
    <Container
      maxWidth="sm"
      disableGutters
      sx={{
        margin: `${theme.spacing(6)} auto ${theme.spacing(5)}`,
        padding: `0 ${theme.spacing(2)}`,
        backgroundColor: theme.palette.white.main,
        borderRadius: '10px'
      }}>
      {loading ? (
        <>
          <Box textAlign="center" style={{ textAlign: 'center', margin: 'auto' }}>
            <CircularProgress color="primary" />
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.layout__header}>
            {complete ? (
              <>
                <Typography variant="subtitle2" component="h2" textAlign={'center'}>
                  {t('forgot_password.complete')}
                </Typography>
              </>
            ) : (
              <>
                <Typography textAlign="center" component="h1" variant="h1">
                  {t('forgot_password.title')}
                </Typography>
                <Typography variant="subtitle2" component="p" textAlign={'center'}>
                  {t('forgot_password.subtitle')}
                </Typography>
                <Box pt={5} pb={8} px={4}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    {(formik) => (
                      <Form>
                        <Box pb={2}>
                          <Controls.Input
                            name="email"
                            label="account_registration.account_representative.email"
                            required
                          />
                        </Box>
                        <Box>
                          <CustomButton
                            variant="contained"
                            color="secondary"
                            text={t('form.submit')}
                            sx={{
                              width: '178px',
                              color: '#ffffff'
                            }}
                            type="submit"
                            disabled={!(formik.dirty && formik.isValid) || isLoading}
                          />
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      {error && (
        <TextError sx={{ textAlign: 'center' }} error={t('forgot_password.error')}></TextError>
      )}
    </Container>
  );
};

export default ForgotPasswordPage;
