import { ProductCertificateObjType } from 'types/Product';

const productCertificate: ProductCertificateObjType = {
  fda: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/FDA+Product+Registration+Sorbor.7+Example.pdf`,
      pdf: true
    }
  ],
  fda_label: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/FDA+Photo+Example_Product+Certificate.pdf`,
      pdf: true
    }
  ],
  organic: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Organic+Thailand.pdf`,
      pdf: true
    }
  ],
  gi: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/GI+Photo+example_1.jpg`
    },
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/GI+Photo+example_2.pdf`,
      pdf: true
    }
  ],
  otop: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/OTOP.pdf`,
      pdf: true
    }
  ],
  copyright: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Copyright+Photo+Example.pdf`,
      pdf: true
    }
  ],
  halal: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/HALAL+(Manufacturing+and+Product+Certification).jpg`
    }
  ],
  kosher: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/KOSHER.pdf`,
      pdf: true
    }
  ],
  q_certification: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Q+GAP-GMP+Photo+Example.pdf`,
      pdf: true
    }
  ],
  pgs: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/PGS+Standard.pdf`,
      pdf: true
    }
  ],
  sdg: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/SDGsPGS.pdf`,
      pdf: true
    }
  ],
  healthier: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Healther+Choice_1.jpg`
    },
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Healthier+Choice_2.pdf`,
      pdf: true
    }
  ],
  thai_community: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Thai+Community+Product+Standards.jpg`
    }
  ],
  green_label: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Green+Label_TEI.pdf`,
      pdf: true
    }
  ],
  rspo: [
    {
      url: `${process.env.REACT_APP_STORAGE}/examples/products/Roundtable+on+Sustainable+Palm+Oil+(RSPO).jpg`
    }
  ]
};

export const useProduct = () => {
  return { productCertificate };
};
