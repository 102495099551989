import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  registration__wrapper: {
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(3)}`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(8)}`
    }
  },
  registration__wrapper__divider: {
    marginTop: `${theme.spacing(3)}!important`,
    marginBottom: `${theme.spacing(3)}!important`
  },
  registration__wrapper__footer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(6)
  },
  dialog__title: {
    padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(2)} !important`
  },
  dialog__action: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(3)} !important`
  }
}));

export default useStyles;
