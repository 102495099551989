import { NoOptions } from 'constants/submissionField';
import { IProduct, IProductResponse } from 'types/Product';

export const formatProductResponse = (response: IProductResponse) => {
  const { products } = response;

  const formatProducts: IProduct[] = products.map((product) => {
    const productCertifications = JSON.parse(product.cerifications);

    return {
      productName: product.name,
      productType: product.type,
      brandName: product.brand_name,
      productCapacity: product.capacity,
      productCategory: product.category,
      storageTemp: product.storage_temperature,
      barcode: product.barcodes,
      barcodeValue: product.barcodes_value,
      expiryDate: product.expiry_dates,
      testing: product.testing,
      distribution: product.distribution,
      distributionType: product.distribution_type,
      weight: product.weight,
      shelfLife: product.shelf_life,
      retailSellingPrice: product.retail_selling_price,
      hasCertification: !productCertifications.includes(NoOptions.NO_CERTIFICATE),
      productCertifications,
      photos: product.photos,
      certification_photo: product.certification_photo,
      other_certification: product.other_certification
    };
  });

  return {
    ...response,
    products: formatProducts
  };
};
