/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material';
import CustomModal from 'components/Modal';
import React, { createContext, useContext, useState } from 'react';

interface Link {
  pdf?: boolean;
  url: string;
}

interface ModalContext {
  handleOpenModal: (state: boolean) => void;
  setLink: (link: Link[]) => void;
}

const initModalContext: ModalContext = {
  handleOpenModal: (state: boolean) => null,
  setLink: (link: Link[]) => null
};

const ModalContext = createContext<ModalContext>(initModalContext);

export const ModalProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState<Link[]>(Array);

  const handleOpenModal = (state: boolean) => {
    setOpen(state);
  };

  return (
    <ModalContext.Provider value={{ handleOpenModal, setLink }}>
      <CustomModal title={''} subTitle={''} open={open} onClose={() => setOpen(false)}>
        <Box
          mt={-5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight={500}>
          {link.map((item, index) => {
            const ext: string = item.url.substring(item.url.length - 3);
            return (
              <React.Fragment key={index}>
                {item.pdf || ext === 'pdf' ? (
                  <object
                    data={item.url}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                    style={{ marginTop: '16px' }}
                  />
                ) : (
                  <img
                    style={{
                      width: '100%',
                      minHeight: '500px',
                      objectFit: 'cover',
                      marginTop: '16px'
                    }}
                    src={item.url}
                    alt="example-image"
                  />
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </CustomModal>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
