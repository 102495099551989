import { SelectOptions } from 'constants/controls';
import ICompany from 'types/Company';
import IFacility from 'types/Facility';
import * as Yup from 'yup';

export const BLANK_FACILITY: IFacility = {
  name: '',
  address_number: '',
  road: '',
  soi: '',
  moo: '',
  building: '',
  floor: '',
  zip_code: '',
  province: '',
  district: '',
  subdistrict: '',
  has_facility: '',
  farm: {
    entrance: null,
    plot: null,
    animal_stall: null
  },
  production_facility: {
    front: null,
    sorting_area: null,
    packing_area: null,
    cold_storage_area: null
  },
  fishery: {
    fishery_photo: null
  },
  other: {
    other_photo: null
  }
};

export const initialValuesCompany: ICompany = {
  companyName: '',
  annual_revenue: '',
  number_of_employees: '',
  registration_type: '',
  address_number: '',
  road: '',
  soi: '',
  moo: '',
  building: '',
  floor: '',
  zip_code: '',
  province: '',
  district: '',
  subdistrict: '',
  facilities: [BLANK_FACILITY],
  type_of_business: {
    value: '',
    company_affidavit: {
      registered_number: '',
      photo: null
    },
    unregistered: {
      id_card_number: '',
      id_card_upload: null
    },
    registered: {
      registered_number: '',
      upload: null
    },
    limited: {
      registration_number: '',
      upload: null
    },
    smce: {
      code: '',
      registration_certificate: null,
      commerial_certificate: null
    },
    individual: {
      id_card_number: '',
      upload: null
    }
  },
  vat: {
    is_registered: false,
    vat_registration_upload: null
  },
  sme: {
    is_registered: false,
    sme_registration_upload: null
  },
  license: null,
  product: null,
  product_type: ''
};

export const companySchema = Yup.object({
  companyName: Yup.string().required('required'),
  annual_revenue: Yup.string().required('required'),
  number_of_employees: Yup.string().required('required'),
  address_number: Yup.string().required('required'),
  road: Yup.string().required('required'),
  zip_code: Yup.string()
    .required('required')
    .matches(/^[0-9]+$/, 'only_digits')
    .min(5, 'exactly_digits')
    .max(5, 'exactly_digits'),
  province: Yup.string().required('required'),
  district: Yup.string().required('required'),
  subdistrict: Yup.string().required('required'),
  type_of_business: Yup.object({
    value: Yup.string().required('required'),
    unregistered: Yup.object().when('value', (value) => {
      return value === 'Unregistered Partnership'
        ? Yup.object({
            id_card_number: Yup.string().required('required')
          })
        : Yup.object().nullable();
    }),
    registered: Yup.object().when('value', (value) => {
      return value === 'Registered Partnership'
        ? Yup.object({
            registered_number: Yup.string().required('required')
          })
        : Yup.object().nullable();
    }),
    limited: Yup.object().when('value', (value) => {
      return value === 'Limited Partnership'
        ? Yup.object({
            registration_number: Yup.string().required('required')
          })
        : Yup.object().nullable();
    }),
    smce: Yup.object().when('value', (value) => {
      return value === 'SMCE (small and micro community enterprise)'
        ? Yup.object({
            code: Yup.string().required('required')
          })
        : Yup.object().nullable();
    }),
    individual: Yup.object().when('value', (value) => {
      return value === 'Individual'
        ? Yup.object({
            id_card_number: Yup.string().required('required')
          })
        : Yup.object().nullable();
    }),
    company_affidavit: Yup.object().when('value', (value) => {
      return value === 'Company (Listed or Limited Liability)'
        ? Yup.object({
            registered_number: Yup.string().nullable().required('required')
          })
        : Yup.object({
            registered_number: Yup.string().nullable().notRequired()
          }).nullable();
    })
  }),
  product_type: Yup.string().required('required')
});

export const typeOfBussinessOptions: SelectOptions[] = [
  {
    id: 1,
    value: 'Company (Listed or Limited Liability)',
    label: 'company_information.section2.selectBussiness.option1'
  },
  {
    id: 2,
    value: 'Unregistered Partnership',
    label: 'company_information.section2.selectBussiness.option2'
  },
  {
    id: 3,
    value: 'Registered Partnership',
    label: 'company_information.section2.selectBussiness.option3'
  },
  {
    id: 4,
    value: 'Limited Partnership',
    label: 'company_information.section2.selectBussiness.option4'
  },
  {
    id: 5,
    value: 'SMCE (small and micro community enterprise)',
    label: 'company_information.section2.selectBussiness.option5'
  },
  {
    id: 6,
    value: 'Individual',
    label: 'company_information.section2.selectBussiness.option6'
  }
];

export const employeesOptionsForCompany = [
  {
    id: 1,
    value: '0-5',
    label: 'questionnaire.q2.option1'
  },
  {
    id: 2,
    value: '6-10',
    label: 'questionnaire.q2.option2'
  },
  {
    id: 3,
    value: '10-20',
    label: 'questionnaire.q2.option3'
  },
  {
    id: 4,
    value: '21-50',
    label: 'questionnaire.q2.option4'
  }
];

export const employeesOptions: SelectOptions[] = [
  ...employeesOptionsForCompany,
  {
    id: 5,
    value: '>50',
    label: 'questionnaire.q2.option5'
  }
];

export const revenueOptionsForCompany = [
  {
    id: 1,
    value: 'less than 100,000 bht',
    label: 'questionnaire.q3.option1'
  },
  {
    id: 2,
    value: '100,001 - 500,000 bht',
    label: 'questionnaire.q3.option2'
  },
  {
    id: 3,
    value: '500,001 - 1,000,000 bht',
    label: 'questionnaire.q3.option3'
  },
  {
    id: 4,
    value: '1,000,001 - 10,000,000 bht',
    label: 'questionnaire.q3.option4'
  },
  {
    id: 5,
    value: '10,000,001 - 50,000,000 bht',
    label: 'questionnaire.q3.option5'
  },
  {
    id: 6,
    value: '50,000,001 - 100,000,000 bht',
    label: 'questionnaire.q3.option6'
  }
];

export const revenueOptions: SelectOptions[] = [
  ...revenueOptionsForCompany,
  {
    id: 7,
    value: 'more than 100,000,000 bht',
    label: 'questionnaire.q3.option7'
  }
];

export const vatOptions = [
  {
    label: 'company_information.section2.vat.yes',
    value: true
  },
  {
    label: 'company_information.section2.vat.no',
    value: false
  }
];

export const smeOptions = [
  {
    label: 'company_information.section2.sme.yes',
    value: true
  },
  {
    label: 'company_information.section2.sme.no',
    value: false
  }
];

export const registrationTypeOptions = [
  {
    label: 'company_information.section2.registrationType.commercial',
    value: 'Commercial Registration'
  },
  {
    label: 'company_information.section2.registrationType.taxId',
    value: 'Tax ID'
  }
];

export const facilityOptions = [
  {
    label: 'facility.farm.label',
    value: 'farm'
  },
  {
    label: 'facility.productionFacility.label',
    value: 'production'
  },
  {
    label: 'facility.fishery.label',
    value: 'fishery'
  }
];
