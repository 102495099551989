export default {
  questionnaire: {
    title: 'เช็คคุณสมบัติเบื้องต้นในการเป็นพาร์ตเนอร์กับเรา',
    subTitle:
      'ขอบคุณที่สนใจมาร่วมเป็นพาร์ตเนอร์ในโครงการท็อปส์ ท้องถิ่นกับเรา ก่อนที่คุณจะเริ่มกรอกข้อมูลในใบสมัคร ช่วยตอบคำถามสำคัญ 3 ข้อนี้ เพื่อเช็คว่าคุณมีคุณสมบัติตรงตามที่ท็อปส์ ท้องถิ่นมองหาหรือไม่ <a target="_blank" href="/how-it-works">ดูข้อมูลเพิ่ม</a>',
    q1: {
      question: 'คุณต้องการที่จะขายสินค้าประเภทใด',
      label: 'เลือกประเภทสินค้า',
      option1: 'สินค้าที่มีอายุน้อยกว่า 6 เดือน',
      option2: 'สินค้าที่มีอายุมากกว่า 6 เดือน',
      option3: 'สินค้าแช่แข็งที่มีอายุมากกว่า 12 เดือน'
    },
    q2: {
      question: 'คุณมีพนักงานกี่คน',
      label: 'เลือกจำนวนพนักงาน',
      option1: '0-5',
      option2: '6-10',
      option3: '10-20',
      option4: '21-50',
      option5: 'มากกว่า 50',
      validation:
        'ขออภัยค่ะ คุณมีจำนวนพนักงานมากกว่าข้อกำหนดของบริษัทที่สามารถเข้าร่วมโครงการท็อปส์ ท้องถิ่นได้ แต่เรายังสนใจสินค้าของคุณ ดังนั้น คุณสามารถเข้าไปที่ลิ้งค์นี้ https://cgbpm.central.co.th/cfgworkflow/portal เพื่อนำเสนอสินค้าของคุณตามช่องทางติดต่อของแผนกจัดซื้อปกติของเซ็นทรัลได้'
    },
    q3: {
      question: 'บริษัทของคุณมีรายได้ในปี 2023 ทั้งหมดเท่าไร',
      option1: 'น้อยกว่า 100,000 บาท',
      option2: '100,001 - 500,000 บาท',
      option3: '500,001 - 1,000,000 บาท',
      option4: '1,000,001 - 10,000,000 บาท',
      option5: '10,000,001 - 50,000,000 บาท',
      option6: '50,000,001 - 100,000,000 บาท',
      option7: 'มากกว่า 100,000,000 บาท',
      validation:
        'ขออภัยค่ะ รายได้ของบริษัทของคุณสูงเกินกว่ารายได้ของบริษัทที่สามารถเข้าร่วมโครงการท็อปส์ ท้องถิ่นได้ แต่เรายังสนใจสินค้าของคุณ ดังนั้น คุณสามารถเข้าไปที่ลิ้งค์นี้ https://cgbpm.central.co.th/cfgworkflow/portal เพื่อนำเสนอสินค้าของคุณตามช่องทางติดต่อของแผนกจัดซื้อปกติของเซ็นทรัลได้ '
    },
    congrat: `ยินดีด้วยค่ะ คุณมีคุณสมบัติเบื้องต้นที่ตรงกับที่ท็อปส์ ท้องถิ่นมองหาในพาร์ตเนอร์ ต่อจากนี้ ขอให้คุณกรอกข้อมูลให้มากที่สุดเท่าที่ทำได้ เพื่อที่ทีมงานของเราจะได้เข้าใจภาพธุรกิจและสินค้าของคุณ ถ้าตอบคำถามของเราไม่ได้ทุกข้อ ไม่เป็นไรนะคะ เราเพียงแค่อยากให้คุณรู้ว่าความตั้งใจหลักของเราคือ หาสินค้าอาหารที่ดีเลิศ แตกต่าง ที่ผลิตโดย SME และเรายังตั้งใจที่จะช่วยพัฒนา SME ใดก็ตามที่ต้องการความช่วยเหลือ ทางท็อปส์ ท้องถิ่นขอขอบคุณบริษัทของคุณที่แนะนำและแชร์สินค้าให้เรารู้จัก แล้วเจอกันนะคะ
      <br />                                                                                                                                                                                                                                                                                                                              
      เราขอแนะนำให้คุณดู <a target="_blank" href="/how-it-works">ที่นี่</a> เพื่อดูว่ามีเอกสารอะไรบ้างที่จะเป็นประโยชน์ในการกรอกใบสมัคร`,
    contact: 'ติดต่อเรา',
    next: 'ต่อไป'
  },
  account_registration: {
    partnership: 'ใบสมัครเป็นพาร์ตเนอร์',
    account_infor: 'Account Information',
    company_info: 'ข้อมูลบริษัท',
    licenses: 'ใบอนุญาตและใบรับรองต่างๆ',
    sale_channels: 'ช่องทางการขายสินค้า',
    products: 'ข้อมูลสินค้า',
    products2: 'ภาพรวมสินค้า',
    aspiration: 'เหตุผลในการสมัครและความช่วยเหลือที่ต้องการ',
    document: 'ตรวจทานใบสมัครก่อนส่ง',
    alert: `
      คุณไม่จำเป็นต้องมีเอกสารทุกอย่างครบนะคะ<br />
      <strong>เช่น ถ้ายังไม่ได้ขอ อ.ย. หรือใบอนุญาตสถานที่ผลิต ก็ยังสมัครได้ค่ะ</strong><br />      
      สิ่งที่โครงการมองหา คือ สินค้าที่อร่อยและโดดเด่น และเราพร้อมที่จะช่วยให้คำแนะนำคุณเพื่อพัฒนาสินค้าต่อไป
    `,
    file_size: '<strong>แนะนำให้อัพโหลดไฟล์รูปภาพ (JPG, PNG) ขนาดไฟล์ละไม่เกิน 2mb</strong>',
    account_representative: {
      login: 'เข้าสู่ระบบ',
      welcome: 'ยินดีต้อนรับสู่ ท้องถิ่น',
      password: 'รหัสผ่าน',
      forgotPassword: 'ลืมรหัสผ่าน?',
      title: 'ข้อมูลผู้ติดต่อหลัก',
      subTitle: 'กรุณากรอกข้อมูลที่จำเป็น (*) ในช่องด้านล่าง',
      firstName: 'ชื่อ',
      lastName: 'นามสกุล',
      phoneNumber: 'เบอร์โทรศัพท์มือถือ',
      email: 'อีเมล',
      lineId: 'LINE ID',
      createPassword: 'สร้างรหัสผ่าน',
      confirmPassword: 'ยืนยันรหัสผ่าน',
      createAccount: 'สร้างบัญชีผู้ใช้'
    }
  },
  company_information: {
    title: 'ข้อมูลบริษัท <span class="asterisk">*</span>',
    name: 'ชื่อบริษัท / ธุรกิจ',
    subtitle: 'กรุณากรอกข้อมูลที่จำเป็น (*) ในช่องด้านล่าง',
    section1: {
      title: 'ที่อยู่บริษัท / สำนักงานใหญ่ *'
    },
    section2: {
      title: 'ข้อมูลบริษัท *',
      numberOfEmployees: 'จำนวนพนักงาน',
      totalRevenue: 'รายได้ในปี 2023 ทั้งหมด',
      typeOfBussiness: 'ประเภทธุรกิจ',
      selectBussiness: {
        label: 'เลือกประเภทธุรกิจ',
        option1: 'บริษัท: บริษัทมหาชน หรือ บริษัทจำกัด',
        option2: 'ห้างหุ้นส่วนสามัญ แบบไม่จดทะเบียน',
        option3: 'ห้างหุ้นส่วนสามัญ แบบจดทะเบียน',
        option4: 'ห้างหุ้นส่วนจำกัด',
        option5: 'วิสาหกิจชุมชน (ขนาดเล็กและขนาดย่อม)',
        option6: 'บุคคลธรรมดา'
      },
      vat: {
        label: 'ได้จดทะเบียน VAT หรือไม่',
        yes: 'ใช่',
        no: 'ไม่ใช่'
      },
      sme: {
        label: 'ได้จดทะเบียน SME กับ สสว. หรือไม่',
        yes: 'ใช่',
        no: 'ไม่ใช่'
      },
      registrationType: {
        label: 'ประเภทของการจดทะเบียนบริษัท <span class="asterisk">*</span>',
        commercial: 'หนังสือรับรองการจดทะเบียนบริษัท',
        taxId: 'เลขประจำตัวผู้เสียภาษีอากร',
        uploadRegistration: {
          label: 'อัพโหลดหนังสือรับรองบริษัท <span class="asterisk">*</span>',
          li1: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือรับรองการจดทะเบียนบริษัท เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
          li2: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
          li3: '(คลิกเพื่อดูตัวอย่างเอกสารจดทะเบียนบริษัท)'
        },
        uploadCardId: {
          label: 'อัพโหลดสำเนาบัตรประจำตัวประชาชน <span class="asterisk">*</span>',
          li1: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาบัตรประจำตัวประชาชนเพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
          li2: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
          li3: '(คลิกเพื่อดูตัวอย่างบัตรประจำตัวประชาชน)'
        },
        vatUpload: {
          label: 'อัพโหลดเอกสารจดทะเบียน VAT <span class="asterisk">*</span>',
          li1: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือจดทะเบียน VAT (ภพ 20) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
          li2: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
          li3: '(คลิกเพื่อดูตัวอย่างหนังสือจดทะเบียน VAT (ภพ 20))'
        },
        smeUpload: {
          label: 'อัพโหลดเอกสารลงทะเบียน SME <span class="asterisk">*</span>',
          li1: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือรับรองการเป็นสมาชิก สสว. เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
          li2: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
          li3: '(คลิกเพื่อดูตัวอย่างหนังสือรับรองการเป็นสมาชิก สสว.)',
          li4: '(คลิกเพื่อดูตัวอย่างหนังสือสำคัญแสดงการจดทะเบียน (ท.ว.ช.2))',
          li5: '(คลิกเพื่อดูตัวอย่างเอกสารสำคัญแสดงการดำเนินกิจการ (ท.ว.ช.3))'
        }
      }
    }
  },
  facility: {
    label: 'ข้อมูลอาคารสถานที่ผลิต / แปลงปลูก / ฟาร์มปศุสัตว์ / ฟาร์มประมง *',
    name: 'ชื่ออาคารสถานที่ผลิต / แปลงปลูก / ฟาร์มปศุสัตว์ / ฟาร์มประมง',
    address: 'ที่อยู่อาคารสถานที่ผลิต / แปลงปลูก / ฟาร์มปศุสัตว์ / ฟาร์มประมง',
    sameAsCompany: 'ที่อยู่เดียวกับที่อยู่บริษัท / สำนักงานใหญ่',
    title: 'รูปภาพอาคารสถานที่ผลิต / แปลงปลูก / ฟาร์มปศุสัตว์ / ฟาร์มประมง',
    notices:
      '(ถ้าคุณมีรูปถ่ายไว้อยู่แล้ว เราขอแนะนำให้คุณอัพโหลดรูปภาพประกอบให้เราพิจารณา อย่างไรก็ตาม รูปภาพไม่ใช่เอกสารจำเป็นในการส่งใบสมัครออนไลน์)',
    selection: {
      text: 'ถ้าคุณมีรูปถ่ายไว้อยู่แล้ว เราขอแนะนำให้คุณอัพโหลดรูปภาพประกอบให้เราพิจารณา อย่างไรก็ตาม รูปภาพไม่ใช่เอกสารจำเป็นในการส่งใบสมัครออนไลน์'
    },
    farm: {
      label: 'ฟาร์ม',
      li1: 'คุณคลิกเลือกแปลงปลูก / ฟาร์มปศุสัตว์ กรุณาอัพโหลดรูปต่างๆ (ถ้ามี) ดังนี้',
      li2: 'หากต้องการเข้าใจการอัพโหลดเพิ่ม คลิกที่นี่เพื่อดูเพิ่มเติม ',
      li3: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้',
      farmEntrance: {
        title: 'รูปภาพทางเข้าแปลงปลูก / ฟาร์มปศุสัตว์ / ฟาร์มประมง',
        subtitle: 'รูปภาพทางเข้าแปลงปลูก / ฟาร์มปศุสัตว์ ที่เห็นป้ายบริษัท '
      },
      farmPlot: {
        title: 'รูปภาพแปลงปลูก / เรือนกระจก / ฟาร์มปศุสัตว์ / ฟาร์มประมง',
        subtitle: 'รูปแปลงปลูกพืชที่เห็นรูปผลผลิตต่างๆ '
      },
      livestock: {
        title: 'รูปภาพคอกสัตว์',
        subtitle: 'รูปภาพคอกปศุสัตว์ที่มีสัตว์ในฟาร์มต่างๆ '
      }
    },
    productionFacility: {
      label: 'อาคารสถานที่ผลิต',
      li1: 'คุณคลิกเลือก "อาคารสถานที่ผลิต" กรุณาอัพโหลดรูปต่างๆ (ถ้ามี) ดังนี้',
      li2: 'หากต้องการเข้าใจการอัพโหลดเพิ่ม คลิกที่นี่เพื่อดูข้อมูลเพิ่มเติม ',
      li3: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้',
      front: {
        title: 'รูปด้านหน้าของสถานที่ผลิต',
        subTitle: 'รูปด้านหน้าของสถานที่ผลิตที่เห็นป้ายของบริษัท '
      },
      sortingOrGrading: {
        title: 'รูปบริเวณคัดแยกและแบ่งเกรดสินค้า',
        subTitle: 'รูปบริเวณคัดแยกและแบ่งเกรดสินค้าที่เห็นภาพพนักงานและอุปกรณ์ด้วย '
      },
      packingArea: {
        title: 'รูปบริเวณแพ็คของ',
        subTitle: 'รูปบริเวณแพ็คของที่เห็นภาพพนักงานและอุปกรณ์ด้วย '
      },
      coldStorageArea: {
        title: 'รูปบริเวณห้องแช่เย็น / แช่แข็ง',
        subTitle: 'รูปบริเวณห้องแช่เย็น / แช่แข็ง '
      }
    },
    fishery: {
      label: 'ฟาร์มประมง',
      li1: 'คุณคลิกเลือก "ฟาร์มประมง" กรุณาอัพโหลดรูปต่างๆ (ถ้ามี) ดังนี้',
      li2: 'หากต้องการเข้าใจการอัพโหลดเพิ่ม คลิกที่นี่เพื่อดูข้อมูลเพิ่มเติม ',
      li3: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้',
      fisheryPhoto: {
        title: 'รูปฟาร์มประมง',
        subtitle: 'รูปสถานที่ที่เลี้ยงสัตว์น้ำ '
      }
    },
    morePhoto: {
      more1: 'คุณต้องการที่จะอัพโหลดรูปภาพใดๆ เพิ่มเติมที่จะเป็นประโยชน์ต่อการสมัครหรือไม่',
      more2:
        'คุณสามารถอัพโหลดภาพเพิ่มเติมหรือดูข้อมูลเพิ่มจากหัวข้อคำแนะนำเรื่องการใส่รูปภาพในใบสมัคร'
    },
    addMore: 'เพิ่มรูปอาคารสถานที่ผลิต / แปลงปลูก / ฟาร์มปศุสัตว์ / ฟาร์มประมง เพิ่มเติม',
    otherPhoto: {
      title: 'รูปภาพอื่นๆ',
      subtitle: `คุณต้องการที่จะอัพโหลดรูปภาพใดๆ เพิ่มเติมที่จะเป็นประโยชน์ต่อการสมัครหรือไม่ คุณสามารถอัพโหลดภาพได้ที่นี่.`
    },
    example: '(คลิกเพื่อดูรูปตัวอย่าง)'
  },
  license: {
    title: 'ใบอนุญาตและใบรับรองต่างๆ',
    subtitle:
      'กรุณาขีดในช่องเพื่อระบุว่าคุณมีใบอนุญาตและใบรับรองต่างๆ ใดบ้างแล้ว และอัพโหลดรูปของเอกสารนั้นเพื่อประกอบใบสมัคร',
    upload: {
      subtitle: 'อัพโหลดเอกสารใบรับรองอย่างน้อย 1 ใบ',
      info: {
        farming: 'อัพโหลดเอกสารใบรับรองแหล่งเพาะปลูก/เพาะเลี้ยงอย่างน้อย 1 ใบ',
        manufacture: 'อัพโหลดเอกสารใบรับรองด้านการผลิตอย่างน้อย 1 ใบ'
      }
    },
    farmingCertification: {
      title: 'ใบรับรองแหล่งเพาะปลูก/เพาะเลี้ยง *',
      organic: {
        label: 'เกษตรอินทรีย์',
        option1: 'มาตรฐานเกษตรอินทรีย์ Organic Thailand',
        option2: 'มาตรฐานเกษตรอินทรีย์ IFOAM',
        option3: 'มาตรฐานเกษตรอินทรีย์สหภาพยุโรป',
        option4: 'มาตรฐานเกษตรอินทรีย์แคนาดา',
        option5: 'มาตรฐานเกษตรอินทรีย์สหรัฐอเมริกา',
        upload: {
          organicThailand: {
            label:
              'อัพโหลดใบรับรองมาตรฐานเกษตรอินทรีย์ Organic Thailand <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานเกษตรอินทรีย์ Organic Thailand เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานเกษตรอินทรีย์ Organic Thailand)'
          },
          organicUSprogramme: {
            label:
              'อัพโหลดใบรับรองมาตรฐานเกษตรอินทรีย์สหรัฐอเมริกา <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานเกษตรอินทรีย์สหรัฐอเมริกา เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานเกษตรอินทรีย์สหรัฐอเมริกา)'
          },
          organicIFOAM: {
            label: 'อัพโหลดใบรับรองมาตรฐานเกษตรอินทรีย์ IFOAM <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานเกษตรอินทรีย์ IFOAM เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: ' (คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานเกษตรอินทรีย์ IFOAM)'
          },
          organicEUprogramme: {
            label: 'อัพโหลดใบรับรองมาตรฐานเกษตรอินทรีย์สหภาพยุโรป <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานเกษตรอินทรีย์สหภาพยุโรป เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานเกษตรอินทรีย์สหภาพยุโรป)'
          },
          organicCAprogramme: {
            label: 'อัพโหลดใบรับรองมาตรฐานเกษตรอินทรีย์แคนาดา <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานเกษตรอินทรีย์แคนาดา เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานเกษตรอินทรีย์แคนาดา)'
          },
          organicLiveStock: {
            label: 'อัพโหลดใบรับรองมาตรฐานปศุสัตว์อินทรีย์ <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานปศุสัตว์อินทรีย์ เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานปศุสัตว์อินทรีย์)'
          }
        }
      },
      GAP: {
        label: 'Good Agricultural Practices (มาตรฐาน GAP ต่างๆ)',
        option1: 'มาตรฐาน GAP (ผักและผลไม้)',
        option2: 'มาตรฐาน Thai GAP',
        option3: 'มาตรฐาน Global GAP',
        upload: {
          gap: {
            label: 'อัพโหลดใบรับรองมาตรฐาน GAP (ผักและผลไม้) <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐาน GAP (ผักและผลไม้) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐาน GAP (ผักและผลไม้))'
          },
          gap_thai: {
            label: 'อัพโหลดใบรับรองมาตรฐาน Thai GAP <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐาน Thai GAP เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐาน Thai GAP)'
          },
          gap_global: {
            label: 'อัพโหลดใบรับรองมาตรฐาน Global GAP <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐาน Global GAP เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐาน Global GAP)'
          },
          gap_for_livestock: {
            label:
              'อัพโหลดใบรับรองมาตรฐาน Good Agricultural Practices for Farm (ปศุสัตว์) <span class="asterisk">*</span>',
            subtitle:
              ' คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐาน GAP for Farm (ปศุสัตว์) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐาน GAP for Farm (ปศุสัตว์))'
          },
          gap_for_fisheries: {
            label:
              'อัพโหลดใบรับรองมาตรฐาน Good Agricultural Practices for Farm (ประมง) <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐาน GAP for Farm (ประมง) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐาน GAP for Farm (ประมง))'
          }
        }
      },
      PGS: {
        label: 'มาตรฐานเกษตรอินทรีย์แบบชุมชนรับรอง (PGS)',
        option1: 'มาตรฐานเกษตรอินทรีย์แบบชุมชนรับรอง (PGS)',
        option2: 'มาตรฐานเกษตรอินทรีย์แบบมีส่วนร่วม (SDGsPGS)',
        upload: {
          pgs_sdg: {
            label:
              'อัพโหลดใบรับรองมาตรฐานเกษตรอินทรีย์แบบมีส่วนร่วม (SDGsPGS) <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานเกษตรอินทรีย์แบบมีส่วนร่วม เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานเกษตรอินทรีย์แบบมีส่วนร่วม)'
          },
          pgs_standard: {
            label:
              'อัพโหลดใบรับรองมาตรฐานเกษตรอินทรีย์แบบชุมชนรับรอง (PGS) <span class="asterisk">*</span>',
            subtitle:
              'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานเกษตรอินทรีย์แบบชุมชนรับรอง เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
            example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานเกษตรอินทรีย์แบบชุมชนรับรอง)'
          }
        }
      },
      RSPO: {
        label: 'มาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO)',
        upload: {
          label:
            'อัพโหลดใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO) <span class="asterisk">*</span>',
          subtitle:
            'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
          example: '(คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน)'
        }
      },
      livestockAndLayerFarm: {
        label: 'มาตรฐาน Good Agricultural Practices for Farm (ปศุสัตว์)'
      },
      fisheries: {
        label: 'มาตรฐาน Good Agricultural Practices for Farm (ประมง)',
        upload: {
          label: 'อัพโหลดใบรับรอง GAP Fisheries <span class="asterisk">*</span>',
          subtitle:
            'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรอง GAP Fisheries เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง คลิกเพื่อดูตัวอย่างใบรับรอง GAP Fisheries'
        }
      },
      organicLivestock: {
        label: 'ใบรับรองมาตรฐานปศุสัตว์อินทรีย์'
      },
      other: {
        label: 'อื่นๆ (โปรดระบุ)'
      },
      noLicense: {
        label: 'ไม่มีใบอนุญาตหรือใบรับรองใดๆ เลย'
      },
      hasLicense: {
        label: 'มีใบอนุญาตหรือใบรับรอง'
      }
    },
    manufacturingCertification: {
      label: 'ใบรับรองด้านการผลิต *',
      FDA: 'ใบอนุญาตผลิตอาหาร (อย.) (อ.2, สบ.1)',
      slaughter: 'ใบอนุญาตตั้งโรงฆ่าสัตว์ (หมู/วัว,เป็ด/ไก่)',
      establishment: 'ใบรับรองโรงงานผลิตผลิตภัณฑ์เนื้อสัตว์เพื่อการส่งออก',
      GMP_FDA: 'GMP กฎหมาย (อย.)',
      HALAL: 'มาตรฐานฮาลาล (HALAL)',
      KOSHER: 'มาตรฐานโคเชอร์ (KOSHER)',
      GHPs: 'มาตรฐานสุขลักษณะที่ดีในการผลิตอาหาร (GHPs)',
      HACCP: 'การวิเคราะห์อันตราย และจุดวิกฤตที่ต้องควบคุม (HACCP)',
      ISO: 'ระบบการจัดการความปลอดภัยของอาหารตามมาตรฐาน ISO 22000 : 2005',
      FSSC: 'มาตรฐานการรับรองความปลอดภัยสำหรับการผลิตอาหาร (FSSC 22000)',
      BRCGS: 'มาตรฐานความปลอดภัยทางอาหาร (BRCGS)',
      IFS: 'มาตรฐานอาหารระหว่างประเทศเพื่อให้การผลิตอาหารปลอดภัย (IFS)',
      RSPO: 'มาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO)',
      TIS: 'มอก. 2581-2556 ระบบบริหารงานคุณภาพสำหรับโรงสีข้าว',
      other: 'อื่นๆ (โปรดระบุ)',
      no: 'ไม่มีใบอนุญาต หรือ เอกสารรับรองระบบในปัจจุบัน',
      upload: {
        HACCP: {
          label: 'อัพโหลดใบรับรอง HACCP <span class="asterisk">*</span>',
          subtitle:
            'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรอง HACCP เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง คลิกเพื่อดูตัวอย่างใบรับรอง HACCP'
        },
        ISO: {
          label: 'อัพโหลดใบรับรอง ISO 22000:2018 <span class="asterisk">*</span>',
          subtitle:
            'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรอง ISO 22000:2018 เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง คลิกเพื่อดูตัวอย่างใบรับรอง ISO 22000:2018'
        }
      }
    },
    currentSaleChannel: {
      label: 'ช่องทางการขายสินค้าในปัจจุบัน *',
      small: 'ร้านขายของชำ',
      convenience: 'ร้านสะดวกซื้อ / มินิมาร์ท',
      supermarket: 'ซุปเปอร์มาร์เก็ต / ไฮเปอร์มาร์เก็ต ',
      wetMarket: 'ตลาดสด / ตลาดนัด',
      OTOP: 'ร้านขายของ OTOP หรือของฝากประจำจังหวัด',
      cashCarry: 'ร้านค้าส่งแบบซื้อสดและรับไปเอง (ได้แก่ แม็กโคร)',
      regional: 'ศูนย์กระจายสินค้าค้าส่งในท้องถิ่นต่างๆ',
      horeca:
        'ร้านค้าส่ง HoReCa หรือกลุ่มธุรกิจค้าส่งที่เกี่ยวข้องกับ ธุรกิจโรงแรม (Hotel), ร้านอาหาร (Restaurant), กาแฟและธุรกิจจัดเลี้ยง (Cafe and Catering)',
      online: 'ขายออนไลน์',
      jingJai: 'ตลาดจริงใจ ฟาร์มเมอร์ มาร์เก็ต',
      export: 'ส่งออก',
      other: 'อื่นๆ (โปรดระบุ)',
      notSelling: 'ไม่ได้ขายสินค้าในช่องทางใดๆ เลยในปัจจุบัน',
      selling: 'มีช่องทางการขายสินค้า'
    },
    distribution: {
      title: 'คุณขายสินค้าของคุณในปัจจุบันไปยังร้านค้าในพื้นที่ระดับใด *',
      local: 'ระดับชุมชน แต่ไม่ใหญ่ถึงจังหวัด',
      provincial: 'ระดับจังหวัด',
      regional: 'ระดับภาค',
      national: 'ระดับประเทศ'
    }
  },
  product: {
    detail: {
      label: 'รายละเอียดสินค้า *',
      subtitle:
        'กรุณากรอกข้อมูลและใส่รูป<span class="emphasis">สินค้าทุกรายการ</span>ที่คุณต้องการที่จะเสนอเข้าโครงการของเรา (อย่างน้อยจำเป็นต้องใส่ข้อมูลสินค้าไฮไลท์ 1 รายการ)',
      productType: {
        label: 'ประเภทสินค้า',
        option1: 'สินค้าที่มีอายุน้อยกว่า 6 เดือน',
        option2: 'สินค้าที่มีอายุมากกว่า 6 เดือน',
        option3: 'สินค้าแช่แข็งที่มีอายุมากกว่า 12 เดือน'
      },
      productName: {
        label: 'ชื่อสินค้า'
      },
      brandName: {
        label: 'ชื่อแบรนด์'
      },
      capacity: {
        label: 'กำลังการผลิตสินค้าโดยเฉลี่ยต่อวัน (ชิ้น)'
      },
      productCategory: {
        label: 'กลุ่มสินค้า',
        snack: 'ขนมขบเคี้ยว',
        packaged: 'อาหารในบรรจุภัณฑ์',
        cooking: 'ประกอบอาหาร',
        milk: 'ผลิตภัณฑ์นมและอาหารเด็ก',
        beverage: 'เครื่องดื่ม',
        freshPackaged: 'อาหารสดในบรรจุภัณฑ์',
        deli: 'ผลิตภัณฑ์สด',
        bakery: 'เบเกอรี่',
        freshMeat: 'เนื้อสด',
        freshSeafood: 'อาหารทะเลสด',
        produce: 'ผัก / ผลไม้สด'
      },
      productStorageTemp: {
        label: 'อุณหภูมิในการเก็บสินค้าก่อนส่ง',
        normal: 'อุณหภูมิปกติ / อุณหภูมิห้อง',
        chilled: 'แช่เย็น',
        iced: 'แช่น้ำแข็ง',
        frozen: 'แช่แข็ง',
        other: 'อื่นๆ (โปรดระบุอุณหภูมิ)'
      },
      productBarcode: {
        label: 'สินค้าของคุณมีบาร์โค้ด',
        yes: 'มี',
        no: 'ไม่มี'
      },
      productBarcodeValue: {
        label: 'กรุณากรอกเลขบาร์โค้ดของคุณ (13 หลัก)'
      },
      productExipration: {
        label: 'สินค้าของคุณมีวันหมดอายุ',
        yes: 'มี',
        no: 'ไม่มี'
      },
      productTesting: {
        label: 'สินค้ามีการตรวจสอบและวิเคราะห์ในห้องแล็บ',
        yes: 'มี',
        no: 'ไม่มี'
      },
      productDistribution: {
        label: 'สินค้ามีระบบขนส่งรองรับอยู่',
        yes: 'มี',
        no: 'ไม่มี'
      },
      productDistributionType: {
        label: 'ประเภทการขนส่งสินค้า',
        owned: 'ขนส่งของตัวเอง / ขนส่งของบริษัทตัวเอง',
        external: 'ขนส่งของบริษัทรับจ้างภายนอก'
      },
      productWeight: {
        label: 'น้ำหนักสินค้า/ ปริมาตรสินค้า (เช่น ม.ล. กรัม, ฯลฯ)'
      },
      shelfLife: {
        label: 'อายุสินค้า (วัน)'
      },
      topsBuyingPrice: {
        label: 'ราคาขายให้ท็อปส์แบบไม่รวม VAT'
      },
      retailSellingPrice: {
        label: 'ราคาขายปลีก ณ ปัจจุบัน'
      },
      productCertification: {
        label: 'ใบรับรองสินค้า *',
        subtitle: 'ใบรับรองสินค้า <span class="asterisk">*</span>',
        fda: 'FDA (อย.)',
        organic: 'เกษตรอินทรีย์',
        GI: 'GI (สินค้าที่ได้รับรองสิ่งบ่งชี้ทางภูมิศาสตร์)',
        OTOP: 'โอทอป',
        Copyright: 'ลิขสิทธิ์',
        Halal: 'ฮาลาล',
        Kosher: 'โคเชอร์',
        QCertificate: 'ใบรับรอง Q',
        PGS: 'PGS',
        SDG: 'SDGs PGS',
        healthier: 'สัญลักษณ์ทางเลือกสุขภาพ',
        standard: 'มผช.',
        greenLabel: 'ฉลากเขียว',
        rspo: 'มาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน',
        other: 'อื่นๆ (โปรดระบุ)'
      }
    },
    upload: {
      label: 'อัพโหลดรูปสินค้า <span class="asterisk">*</span>',
      subtitle: 'อัพโหลดรูปสินค้าอย่างน้อย 1 รูป <span class="asterisk">*</span>',
      list1:
        'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
      list2: '(คลิกเพื่อดูตัวอย่างรูปสินค้า)',
      list3:
        'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ แต่ต้องมีรูปถ่ายอย่างน้อย 1 รูปในการส่งใบสมัครของคุณ'
    },
    uploadDocument: {
      label: 'อัพโหลดเอกสารต่างๆ',
      nameLabel: 'อัพโหลดเอกสารใบรับรองอื่นๆ {{name}} <span class="asterisk">*</span>',
      info: 'อัพโหลดรูปใบรับรองสินค้าอย่างน้อย 1 รูป',
      subtitle:
        'กรุณาอัพโหลดเอกสารประวัติของบริษัท และ/หรือ แค็ตตาล็อกสินค้า (ถ้ามี) เพื่อที่จะให้เราเห็นภาพรวมของธุรกิจและสินค้าของคุณ',
      note: 'ข้อมูลทุกอย่างได้รับการคุ้มครองภายใต้นโยบายคุ้มครองข้อมูลส่วนบุคคลของเรา'
    },
    saveProduct: 'บันทึกสินค้า',
    addProduct: 'เพิ่มสินค้าอีกรายการ',
    link_faq: `หากคุณไม่แน่ใจว่าสินค้าอยู่ในหมวดหมู่ไหน คลิก <a target="blank" href="/contact"> ที่นี่ </a>เพื่ออ่านรายละเอียดเพิ่มเติม`
  },
  aspiration: {
    title: 'เหตุผลในการสมัคร *',
    place: {
      label:
        'คุณสนใจที่จะขายสินค้ากับเราผ่านทางช่องทางการขายใดบ้าง <span class="asterisk">*</span>',
      topsMarket: 'ท็อปส์ มาร์เก็ต (Tops Market)',
      centrall: 'เซ็นทรัล ฟู้ด ฮอลล์ (Central Food Hall)',
      topsDaily: 'ท็อปส์ เดลี่ (Tops Daily)',
      familyMart: 'แฟมิลี มาร์ท (Family Mart)',
      topsOnline: 'ท็อปส์ ออนไลน์ (Tops Online)',
      wholeSale: 'ขายส่ง/ HoReCa',
      privateLabel: 'สินค้าที่ห้างเป็นผู้จ้างผลิตและติดแบรนด์ของห้าง (Private label)',
      export: 'ส่งออก',
      other: 'อื่นๆ (โปรดระบุ)',
      retails: {
        label: 'ร้านค้าค้าปลีก',
        local: 'ในชุมชน/ท้องถิ่นของคุณ',
        province: 'ในจังหวัดของคุณ',
        region: 'ในภาคของคุณ',
        nationwide: 'ทั่วประเทศ'
      }
    },
    question: {
      label:
        'ขอให้คุณช่วยอธิบายให้เราเข้าใจเรื่องต่างๆ เกี่ยวกับธุรกิจและสินค้าของคุณ ดังนี้ <span class="asterisk">*</span>',
      question1:
        '1. คุณคิดว่าอะไรคือ 3 สิ่งสำคัญที่เราควรรู้เกี่ยวกับสินค้าของคุณ? <span class="asterisk">*</span>',
      question2:
        '2. คุณมีความฝัน ความคาดหวัง และเป้าหมายในการร่วมค้าขายกับบริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด อะไรบ้าง และคิดว่าอะไรเป็นเหตุผลสำคัญที่เราควรเลือกคุณมาเป็นคู่ค้ากับเรา? <span class="asterisk">*</span>'
    },
    reason: {
      label:
        'อยากให้คุณเล่าให้เราฟังว่าคุณมีความฝัน ความคาดหวัง ตลอดจนเป้าหมายอะไรบ้างในการร่วมค้าขายกับบริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด และคิดว่าอะไรเป็นเหตุผลสำคัญที่เราควรเลือกคุณมาเป็นคู่ค้ากับเรา? <span class="asterisk">*</span>'
    },
    assistance: {
      title: 'ความช่วยเหลือ',
      place: {
        label: 'เหตุผลในการสมัครและความช่วยเหลือที่ต้องการ',
        finane: 'การเงิน / การกู้เงิน',
        license: 'การขอใบอนุญาตสถานที่ผลิต',
        fda: 'การสมัครขอเลขสารบบอาหารจาก อย. / การตรวจคุณภาพสินค้า',
        other: 'อื่นๆ (โปรดระบุ)',
        retails: {
          label: 'ร้านค้าค้าปลีก',
          local: 'ในชุมชน/ท้องถิ่นของคุณ',
          province: 'ในจังหวัดของคุณ',
          region: 'ในภาคของคุณ',
          nationwide: 'ทั่วประเทศ'
        }
      },
      feedback: {
        label: 'คุณมีข้อมูลอะไรจะเพิ่มเติมหรือไม่ สามารถพิมพ์บอกเราได้ที่นี่'
      }
    },
    heardFrom: {
      label: 'คุณรู้จักเราได้อย่างไร *',
      text: 'คุณรู้จัก Tops Tongtin ครั้งแรกจากช่องทางใด <span class="asterisk">*</span>'
    },
    heardFromOptions: {
      tongtin_facebook: 'โฆษณาทางเฟซบุ้คของท็อปส์ ท้องถิ่น',
      tongtin_instagram: 'โฆษณาทางอินสตาแกรมของท็อปส์ ท้องถิ่น',
      tongtin_media: 'สื่อของท็อปส์ ท้องถิ่นในร้านท็อปส์มาร์เก็ต / เซ็นทรัล ฟู้ด ฮอลล์',
      online_website: 'เว็บไซต์ Tops Online',
      facebook: 'เฟซบุ้ค Tops Thailand',
      instagram: 'อินสตาแกรม Tops Thailand',
      google: 'โฆษณาทาง Google',
      friends: 'เพื่อน / การบอกต่อ',
      event: 'อีเว้นท์ในบริเวณห้างสรรพสินค้าเซ็นทรัล',
      roadShows: 'โร้ดโชว์หรืออีเว้นท์ในย่านที่คุณอยู่อาศัยหรือทำงาน',
      radio: 'วิทยุ',
      news: 'หนังสือพิมพ์ / นิตยสาร',
      netWorking:
        'กิจกรรมเพื่อสร้างเครื่อข่ายต่างๆ เช่น กิจกรรมที่จัดโดยรัฐบาล กิจกรรมของหอการค้าไทย',
      tops_local_sourcing: 'ทีมจัดซื้อส่วนภูมิภาคของท็อปส์'
    }
  },
  document: {
    title: 'Document Submission Review',
    subtitle:
      'กรุณาตรวจสอบเอกสารที่คุณอัพโหลดทุกรายการให้เรียบร้อย โดยคุณจำเป็นต้องอัพโหลดเอกสารที่จำเป็นและเอกสารที่คุณเลือกที่จะแนบเพิ่มให้ครบก่อน จึงจะสามารถกดส่งใบสมัครได้ หลังจากที่คุณกดส่งใบสมัครแล้ว คุณจะไม่สามารถที่จะแก้ไขข้อมูลใดๆ ได้อีก',
    note: 'หมายเหตุ: หากกดส่งใบสมัครช้า ก็จะมีผลทำให้ขั้นตอนการพิจารณาใบสมัครล่าช้าตามไปด้วย',
    companyDocument: {
      label: 'เอกสารเกี่ยวกับบริษัท',
      idCard: 'อัพโหลดรูปหรือสำเนาบัตรประจำตัวประชาชน (คลิกเพื่อดูตัวอย่างเอกสาร)',
      registration:
        'อัพโหลดรูปหรือสำเนาหนังสือรับรองการจดทะเบียนบริษัท (คลิกเพื่อดูตัวอย่างเอกสาร)',
      pp20: 'อัพโหลดรูปหรือสำเนาเอกสาร ภพ.20 (คลิ๊กเพื่อดูเอกสารตัวอย่าง)'
    },
    licenseDocument: {
      label: 'ใบอนุญาตและใบรับรองต่างๆ',
      haccap: 'อัพโหลดรูปหรือสำเนาเอกสาร HACCP (คลิ๊กเพื่อดูเอกสารตัวอย่าง)',
      iso: 'อัพโหลดรูปหรือสำเนาเอกสาร ISO (คลิ๊กเพื่อดูเอกสารตัวอย่าง)'
    },
    productDocument: {
      label: 'เอกสารเกี่ยวกับสินค้า',
      fda: 'อัพโหลดรูปหรือสำเนาใบอนุญาตจาก อย. (คลิ๊กเพื่อดูเอกสารตัวอย่าง)',
      haccap: 'อัพโหลดรูปหรือสำเนาเอกสาร HACCP (คลิ๊กเพื่อดูเอกสารตัวอย่าง)',
      iso: 'อัพโหลดรูปหรือสำเนาเอกสาร ISO (คลิ๊กเพื่อดูเอกสารตัวอย่าง)'
    }
  },
  upload: {
    attach: 'ลากไฟล์มาที่นี่ หรือคลิกเพื่อเลือกไฟล์จากเครื่อง',
    product: `รูปสินค้าต่างๆ 
    <br/> 
    ลากไฟล์มาที่นี่ หรือคลิกเพื่อเลือกไฟล์จากเครื่อง`
  },
  form: {
    addressNumber: 'บ้านเลขที่',
    building: 'อาคาร',
    floor: 'ชั้น',
    moo: 'หมู่',
    soi: 'ซอย',
    road: 'ถนน',
    zipCode: 'รหัสไปรษณีย์',
    province: 'จังหวัด',
    district: 'เขต / อำเภอ',
    subDistrict: 'แขวง / ตำบล',
    saveDraft: 'บันทึกร่างเอกสาร',
    next: 'ต่อไป',
    previous: 'ย้อนกลับ',
    submit: 'ต่อไป',
    register: 'ลงทะเบียน',
    other: 'อื่นๆ (โปรดระบุ)',
    confirm: 'ยืนยัน',
    pleaseSpecify: 'กรุณาระบุ',
    registration: 'ใบสมัคร',
    sign_out: 'ออกจากระบบ',
    cancel: 'ยกเลิก',
    example: 'ตัวอย่าง',
    info_msg: {
      upload:
        'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย'
    },
    error_msg: {
      required: 'โปรดระบุ',
      invalid_email: 'อีเมลไม่ถูกต้อง',
      max_characters_1: 'สูงสุด 225 ตัวอักษร',
      max_characters_2: 'สูงสุด 100 ตัวอักษร',
      max_characters_3: 'สูงสุด 20 ตัวอักษร',
      select_product: 'กรุณาเลือกหมวดหมู่สินค้าของคุณ!',
      number_employees: 'กรุณาเลือกจำนวนพนักงานของคุณ!',
      first_name: 'ชื่อต้องเป็นข้อความ',
      last_name: 'นามสกุลต้องเป็นข้อความ',
      phone_number: 'หมายเลขโทรศัพท์ต้องเป็นตัวเลข',
      password: 'รหัสผ่านต้องมีอักขระอย่างน้อย 6 ตัว',
      confirm_password: 'รหัสผ่านไม่ถูกต้อง',
      only_digits: 'ต้องเป็นตัวเลขเท่านั้น',
      exactly_digits: 'ต้องเป็นตัวเลข 5 หลักพอดี',
      choose_option: 'โปรดเลือกหนึ่งในตัวเลือกเหล่านี้',
      choose_option_or_other: 'โปรดเลือกหนึ่งในตัวเลือกเหล่านี้หรือระบุอื่นๆ',
      required_photo: 'ต้องมีอย่างน้อย 1 รูป',
      question_validation_2:
        'ขออภัยค่ะ คุณมีจำนวนพนักงานมากกว่าข้อกำหนดของบริษัทที่สามารถเข้าร่วมโครงการท็อปส์ ท้องถิ่นได้ แต่เรายังสนใจสินค้าของคุณ ดังนั้น คุณสามารถเข้าไปที่ลิ้งค์นี้ https://cgbpm.central.co.th/cfgworkflow/portal เพื่อนำเสนอสินค้าของคุณตามช่องทางติดต่อของแผนกจัดซื้อปกติของเซ็นทรัลได้',
      question_validation_3:
        'ขออภัยค่ะ รายได้ของบริษัทของคุณสูงเกินกว่ารายได้ของบริษัทที่สามารถเข้าร่วมโครงการท็อปส์ ท้องถิ่นได้ แต่เรายังสนใจสินค้าของคุณ ดังนั้น คุณสามารถเข้าไปที่ลิ้งค์นี้ https://cgbpm.central.co.th/cfgworkflow/portal เพื่อนำเสนอสินค้าของคุณตามช่องทางติดต่อของแผนกจัดซื้อปกติของเซ็นทรัลได้ ',
      password_required_6: 'จำเป็น (ตัวอักษรหรือตัวเลขอย่างน้อย 6 ตัว)'
    },
    save: 'บันทึก'
  },
  subTitle: {
    company: 'กรุณากรอกข้อมูลที่จำเป็น (*) ในช่องด้านล่าง',
    license:
      'กรุณาขีดในช่องเพื่อระบุว่าคุณมีใบอนุญาตและใบรับรองต่างๆ ใดบ้างแล้ว และอัพโหลดรูปของเอกสารนั้นเพื่อประกอบใบสมัคร',
    sale_channels: 'กรุณากรอกข้อมูลที่จำเป็น (*) ในช่องด้านล่าง',
    product: 'กรุณากรอกข้อมูลสินค้าและใส่รูปสินค้าไฮไลท์ (ต้องใส่อย่างน้อย 1 รูป)',
    aspiration: 'กรุณากรอกข้อมูลที่จำเป็น (*) ในช่องด้านล่างนี้',
    document:
      'กรุณาตรวจสอบเอกสารที่คุณอัพโหลดทุกรายการให้เรียบร้อย โดยคุณจำเป็นต้องอัพโหลดเอกสารที่จำเป็นและเอกสารที่คุณเลือกที่จะแนบเพิ่มให้ครบก่อน จึงจะสามารถกดส่งใบสมัครได้ หลังจากที่คุณกดส่งใบสมัครแล้ว คุณจะไม่สามารถที่จะแก้ไขข้อมูลใดๆ ได้อีก'
  },
  type_of_business: {
    company: {
      label: 'อัพโหลดเอกสารจดทะเบียนบริษัท <span class="asterisk">*</span>',
      text: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือรับรองการจดทะเบียนบริษัท เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      companyRegistrationNumber: 'เลขทะเบียนพาณิชย์ <span class="asterisk">*</span>',
      example: '(คลิกเพื่อดูตัวอย่างเอกสารจดทะเบียนบริษัท)'
    },
    unregistered: {
      idCardNumber:
        'เลขประจำตัวผู้เสียภาษีอากร (ห้างหุ้นส่วนสามัญ แบบไม่จดทะเบียน) <span class="asterisk">*</span>',
      idCardUpload: {
        label:
          'อัพโหลดรูปหรือสำเนาบัตรประชาชน (ห้างหุ้นส่วนสามัญ แบบไม่จดทะเบียน) <span class="asterisk">*</span>',
        text: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาบัตรประชาชนเพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
        example: '(คลิกเพื่อดูตัวอย่างบัตรประชาชน)'
      }
    },
    registered: {
      registrationNumber:
        'เลขทะเบียนห้างหุ้นส่วน (ห้างหุ้นส่วนสามัญ แบบจดทะเบียน) <span class="asterisk">*</span>',
      registrationUpload: {
        label:
          'อัพโหลดหนังสือรับรองจัดตั้งห้างหุ้นส่วน (ห้างหุ้นส่วนสามัญ แบบจดทะเบียน) <span class="asterisk">*</span>',
        text: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือรับรองจัดตั้งห้างหุ้นส่วน เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
        example: '(คลิกเพื่อดูตัวอย่างหนังสือรับรองจัดตั้งห้างหุ้นส่วน)'
      }
    },
    limited: {
      registrationNumber:
        'เลขทะเบียนห้างหุ้นส่วน (ห้างหุ้นส่วนจำกัด) <span class="asterisk">*</span>',
      upload: {
        label:
          'อัพโหลดหนังสือรับรองจัดตั้งห้างหุ้นส่วน (ห้างหุ้นส่วนจำกัด) <span class="asterisk">*</span>',
        text: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือรับรองจัดตั้งห้างหุ้นส่วน เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
        example: '(คลิกเพื่อดูตัวอย่างหนังสือรับรองจัดตั้งห้างหุ้นส่วน)'
      }
    },
    smce: {
      code: 'รหัสทะเบียนวิสาหกิจชุมชน <span class="asterisk">*</span>',
      registrationCertificate: {
        label: 'อัพโหลดหนังสือสำคัญแสดงการจดทะเบียน (ท.ว.ช.2) <span class="asterisk">*</span>',
        text: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือสำคัญแสดงการจดทะเบียน (ท.ว.ช.2) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
        li1: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของหนังสือสำคัญแสดงการจดทะเบียน (ท.ว.ช.2) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
        li2: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
        example: '(คลิกเพื่อดูตัวอย่างหนังสือสำคัญแสดงการจดทะเบียน (ท.ว.ช.2))'
      },
      commercialCertificate: {
        label: 'อัพโหลดเอกสารสำคัญแสดงการดำเนินกิจการ (ท.ว.ช.3) (ไม่ใช่เอกสารบังคับ)',
        text: 'คุณสามารถแนบไฟล์รูปหรือสำเนาของเอกสารสำคัญแสดงการดำเนินกิจการ (ท.ว.ช.3) เพื่อเป็นหลักฐานเพิ่มให้เราได้ แต่ไม่ใช่เอกสารบังคับ',
        li1: 'คุณสามารถแนบไฟล์รูปหรือสำเนาของเอกสารสำคัญแสดงการดำเนินกิจการ (ท.ว.ช.3) เพื่อเป็นหลักฐานเพิ่มให้เราได้ แต่ไม่ใช่เอกสารบังคับ',
        li2: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
        example: '(คลิกเพื่อดูตัวอย่างเอกสารสำคัญแสดงการดำเนินกิจการ (ท.ว.ช.3))'
      }
    },
    individual: {
      idCardNumber: 'เลขประจำตัวผู้เสียภาษีอากร (ของบุคคล) <span class="asterisk">*</span>',
      idCardUpload: {
        label: 'อัพโหลดรูปหรือสำเนาบัตรประชาชน <span class="asterisk">*</span>',
        text: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาบัตรประชาชนเพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
        li1: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาบัตรประชาชนเพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
        li2: 'คุณสามารถอัพโหลดเอกสารที่จำเป็นต่างๆ ได้ที่ขั้นตอนสุดท้ายของใบสมัครออนไลน์นี้ อย่างไรก็ตาม กรุณาเตรียมเอกสารต่างๆ ที่เป็นตัวจริงไว้แสดงกับเจ้าหน้าที่อีกครั้งด้วย',
        example: '(คลิกเพื่อดูตัวอย่างบัตรประชาชน)'
      }
    }
  },
  submission: {
    company: {
      vat: 'อัพโหลดเอกสารจดทะเบียน VAT <span class="asterisk">*</span>',
      sme: 'อัพโหลดเอกสารลงทะเบียน SME <span class="asterisk">*</span>',
      type_of_business: {
        company_affidavit: 'อัพโหลดเอกสารจดทะเบียนบริษัท',
        unregistered:
          'อัพโหลดสำเนาบัตรประจำตัวประชาชน (ห้างหุ้นส่วนสามัญ แบบไม่จดทะเบียน) <span class="asterisk">*</span>',
        registered:
          'อัพโหลดหนังสือรับรองจัดตั้งห้างหุ้นส่วน (ห้างหุ้นส่วนสามัญ แบบจดทะเบียน) <span class="asterisk">*</span>',
        limited:
          'อัพโหลดหนังสือรับรองจัดตั้งห้างหุ้นส่วน (ห้างหุ้นส่วนจำกัด) <span class="asterisk">*</span>',
        smce: 'อัพโหลดหนังสือสำคัญแสดงการจดทะเบียน (ท.ว.ช.2) <span class="asterisk">*</span>',
        smce2: 'อัพโหลดเอกสารสำคัญแสดงการดำเนินกิจการ (ท.ว.ช.3) (ไม่ใช่เอกสารบังคับ)',
        individual: 'อัพโหลดสำเนาบัตรประจำตัวประชาชน <span class="asterisk">*</span>'
      }
    },
    facility: {
      facility_label: 'รูปภาพ Facility {{name}}',
      farm_label: 'รูปภาพ Farm {{name}}',
      fishery_label: 'รูปภาพ Fishery {{name}}',
      farm: {
        farmEntrance: {
          title: 'รูปภาพทางเข้าแปลงปลูก/ฟาร์มปศุสัตว์/ฟาร์มประมง'
        },
        farmPlot: {
          title: 'รูปภาพทางเข้าแปลงปลูก/ฟาร์มปศุสัตว์/ฟาร์มประมง'
        },
        livestock: {
          title: 'รูปภาพคอกสัตว์'
        }
      },
      productionFacility: {
        front: {
          title: 'รูปด้านหน้าของสถานที่ผลิต'
        },
        sortingOrGrading: {
          title: 'รูปบริเวณคัดแยกและแบ่งเกรดสินค้า'
        },
        packingArea: {
          title: 'รูปบริเวณแพ็คของ'
        },
        coldStorageArea: {
          title: 'รูปบริเวณห้องแช่เย็น/แช่แข็ง'
        }
      },
      fishery: {
        fisheryPhoto: {
          title: 'รูปฟาร์มประมง'
        }
      }
    },
    license: {
      gap: 'อัพโหลดใบรับรอง GAP ผลไม้และผัก <span class="asterisk">*</span>',
      pgs: 'อัพโหลดใบรับรอง PGS <span class="asterisk">*</span>',
      organic: 'อัพโหลดใบรับรองเกษตรอินทรีย์ <span class="asterisk">*</span>',
      rspo: 'อัพโหลดใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO) <span class="asterisk">*</span>',
      gapForFarm:
        'อัพโหลดใบรับรองมาตรฐานการปฏิบัติทางการเกษตรที่ดีสำหรับฟาร์ม (ปศุสัตว์) <span class="asterisk">*</span>',
      fisheries:
        'อัพโหลดใบรับรองมาตรฐานการปฏิบัติทางการเกษตรที่ดีสำหรับฟาร์ม (ประมง) <span class="asterisk">*</span>',
      fda: 'อัพโหลดใบอนุญาตผลิตอาหาร (อย.) (อ.2, สบ.1/1) <span class="asterisk">*</span>',
      slaughterhouse:
        'อัพโหลดใบอนุญาตตั้งโรงฆ่าสัตว์ (หมู/วัว,เป็ด/ไก่) <span class="asterisk">*</span>',
      export_manufacture:
        'อัพโหลดใบรับรองโรงงานผลิตผลิตภัณฑ์เนื้อสัตว์เพื่อการส่งออก <span class="asterisk">*</span>',
      gmp_fda: 'อัพโหลดใบรับรอง GMP กฎหมาย (อย.) <span class="asterisk">*</span>',
      halal: 'อัพโหลดใบรับรองมาตรฐานฮาลาล (HALAL) <span class="asterisk">*</span>',
      kosher: 'อัพโหลดใบรับรองมาตรฐานโคเชอร์ (KOSHER) <span class="asterisk">*</span>',
      ghps: 'อัพโหลดใบรับรองมาตรฐานสุขลักษณะที่ดีในการผลิตอาหาร (GHPs) <span class="asterisk">*</span>',
      haccp:
        'อัพโหลดใบรับรองการวิเคราะห์อันตราย และจุดวิกฤตที่ต้องควบคุม (HACCP) <span class="asterisk">*</span>',
      iso: 'อัพโหลดใบรับรองระบบการจัดการความปลอดภัยของอาหารตามมาตรฐาน ISO 22000 : 2005 <span class="asterisk">*</span>',
      fssc: 'อัพโหลดใบรับรองมาตรฐานการรับรองความปลอดภัยสำหรับการผลิตอาหาร (FSSC 22000) <span class="asterisk">*</span>',
      brcgs: 'อัพโหลดใบรับรองมาตรฐานความปลอดภัยทางอาหาร (BRCGS) <span class="asterisk">*</span>',
      ifs: 'อัพโหลดใบรับรองมาตรฐานอาหารระหว่างประเทศเพื่อให้การผลิตอาหารปลอดภัย (IFS) <span class="asterisk">*</span>',
      rspoManufacturing:
        'อัพโหลดใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO) <span class="asterisk">*</span>',
      organicLiveStock: 'อัพโหลดใบรับรองมาตรฐานปศุสัตว์อินทรีย์ <span class="asterisk">*</span>',
      other: 'อื่นๆ (โปรดระบุ) <span class="asterisk">*</span>',
      fdaManufacturing:
        'อัพโหลดใบอนุญาตผลิตอาหาร (อย.) (อ.2, สบ.1/1) <span class="asterisk">*</span>'
    },
    product: {
      label: 'เอกสารเกี่ยวกับสินค้า',
      productUploadCatalog: 'แค็ตตาล็อกสินค้า / รูปสินค้าต่างๆ',
      productUploadProfile: 'อัพโหลดเอกสารประวัติของบริษัท',
      fda: 'อัพโหลดใบรับรองจาก อย. <span class="asterisk">*</span>',
      fda_label: 'อัพโหลดรูปฉลากสินค้าที่ได้รับอนุญาตจาก อ.ย. <span class="asterisk">*</span>',
      fdaManufacturing:
        'อัพโหลดใบอนุญาตผลิตอาหาร (อย.) (อ.2, สบ.1/1) <span class="asterisk">*</span>',
      organic: 'อัพโหลดใบรับรองเกษตรอินทรีย์ <span class="asterisk">*</span>',
      gi: 'อัพโหลดใบรับรอง GI (สินค้าที่ได้รับรองสิ่งบ่งชี้ทางภูมิศาสตร์) <span class="asterisk">*</span>',
      otop: 'อัพโหลดใบรับรองโอทอป <span class="asterisk">*</span>',
      copyright: 'อัพโหลดใบรับรองลิขสิทธิ์ <span class="asterisk">*</span>',
      halal: 'อัพโหลดใบรับรองฮาลาล <span class="asterisk">*</span>',
      kosher: 'อัพโหลดใบรับรองโคเชอร์ <span class="asterisk">*</span>',
      q_certification: 'อัพโหลดใบรับรองใบรับรอง Q <span class="asterisk">*</span>',
      pgs: 'อัพโหลดใบรับรอง PGS <span class="asterisk">*</span>',
      sdg: 'อัพโหลดใบรับรอง SDGs PGS <span class="asterisk">*</span>',
      healthier: 'อัพโหลดใบรับรองสัญลักษณ์ทางเลือกสุขภาพ <span class="asterisk">*</span>',
      thai_community: 'อัพโหลดใบรับรอง มผช. <span class="asterisk">*</span>',
      green_label: 'อัพโหลดใบรับรองฉลากเขียว <span class="asterisk">*</span>',
      rspo: 'อัพโหลดใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน <span class="asterisk">*</span>',
      other: 'อัพโหลดเอกสารใบรับรองอื่นๆ <span class="asterisk">*</span>'
    },
    guidance: {
      fda: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองจาก อย.  เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      fda_label:
        'คุณจำเป็นต้องแนบไฟล์รูปฉลากสินค้าที่ได้รับอนุญาตจาก อ.ย. เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      slaughterhouse:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบอนุญาตตั้งโรงฆ่าสัตว์ (หมู/วัว,เป็ด/ไก่) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      export_manufacture:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองโรงงานผลิตผลิตภัณฑ์เนื้อสัตว์เพื่อการส่งออก เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      gmp_fda:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรอง GMP กฎหมาย (อย.) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      ghps: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานสุขลักษณะที่ดีในการผลิตอาหาร (GHPs)เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      haccp:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองการวิเคราะห์อันตราย และจุดวิกฤตที่ต้องควบคุม (HACCP) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      iso: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองระบบการจัดการความปลอดภัยของอาหารตามมาตรฐาน ISO 22000 : 2005 เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      fssc: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานการรับรองความปลอดภัยสำหรับการผลิตอาหาร (FSSC 22000) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      brcgs:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานความปลอดภัยทางอาหาร (BRCGS) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      ifs: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานอาหารระหว่างประเทศเพื่อให้การผลิตอาหารปลอดภัย (IFS) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      rspoManufacturing:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      organic:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองเกษตรอินทรีย์ เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      gi: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรอง GI (สินค้าที่ได้รับรองสิ่งบ่งชี้ทางภูมิศาสตร์) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      otop: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรองโอทอป เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      copyright:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรองลิขสิทธิ์ เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      halal:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานฮาลาล (HALAL) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง ',
      kosher:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานโคเชอร์ (KOSHER) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      q_certification:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรอง Q เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      pgs: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรอง PGS เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      sdg: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรอง SDGs PGS เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      healthier:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรองสัญลักษณ์ทางเลือกสุขภาพ เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      thai_community:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรอง มผช. เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      green_label:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของ ใบรับรองฉลากเขียว เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      rspo: 'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      other:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบรับรองอื่นๆ  เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง',
      fdaManufacturing:
        'คุณจำเป็นต้องแนบไฟล์รูปหรือสำเนาของใบอนุญาตผลิตอาหาร (อย.) (อ.2, สบ.1/1) เพื่อเป็นหลักฐานให้เราเช็คความถูกต้อง'
    },
    example_link_label: {
      fda: 'คลิกเพื่อดูตัวอย่างใบสำคัญการแจ้งรายละเอียดอาหาร สบ.7 ของ อ.ย.',
      fda_label: 'คลิกเพื่อดูตัวอย่างรูปฉลากสินค้าที่ได้รับอนุญาตจาก อย.',
      fdaManufacturing: 'คลิกเพื่อดูตัวอย่างใบอนุญาตผลิตอาหาร (อย.) (อ.2, สบ.1/1)',
      organic: 'คลิกเพื่อดูตัวอย่างใบรับรองเกษตรอินทรีย์',
      gi: 'คลิกเพื่อดูตัวอย่างใบรับรอง GI (สินค้าที่ได้รับรองสิ่งบ่งชี้ทางภูมิศาสตร์)',
      otop: 'คลิกเพื่อดูตัวอย่างใบรับรองโอทอป',
      copyright: 'คลิกเพื่อดูตัวอย่างใบรับรองลิขสิทธิ์',
      halal: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานฮาลาล (HALAL)',
      kosher: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานโคเชอร์ (KOSHER)',
      q_certification: 'คลิกเพื่อดูตัวอย่างใบรับรอง Q',
      pgs: 'คลิกเพื่อดูตัวอย่างใบรับรอง PGS',
      sdg: 'คลิกเพื่อดูตัวอย่างใบรับรอง SDGs PGS',
      healthier: 'คลิกเพื่อดูตัวอย่างใบรับรองสัญลักษณ์ทางเลือกสุขภาพ',
      thai_community: 'คลิกเพื่อดูตัวอย่างใบรับรอง มผช.',
      green_label: 'คลิกเพื่อดูตัวอย่างใบรับรองฉลากเขียว',
      rspo: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO)',
      gap: 'คลิกเพื่อดูตัวอย่างใบรับรอง GAP ผลไม้และผัก',
      gapForFarm:
        'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานการปฏิบัติทางการเกษตรที่ดีสำหรับฟาร์ม (ปศุสัตว์)',
      fisheries: 'คลิกเพื่อดูตัวอย่างใบรับรอง GAP Fisheries',
      slaughterhouse: 'คลิกเพื่อดูตัวอย่างใบอนุญาตตั้งโรงฆ่าสัตว์ (หมู/วัว,เป็ด/ไก่)',
      export_manufacture: 'คลิกเพื่อดูตัวอย่างใบรับรองโรงงานผลิตผลิตภัณฑ์เนื้อสัตว์เพื่อการส่งออก',
      gmp_fda: 'คลิกเพื่อดูตัวอย่างใบรับรอง GMP กฎหมาย (อย.)',
      ghps: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานสุขลักษณะที่ดีในการผลิตอาหาร (GHPs)',
      haccp: 'คลิกเพื่อดูตัวอย่างใบรับรองการวิเคราะห์อันตราย และจุดวิกฤตที่ต้องควบคุม (HACCP)',
      iso: 'คลิกเพื่อดูตัวอย่างใบรับรองระบบการจัดการความปลอดภัยของอาหารตามมาตรฐาน ISO 22000 : 2005',
      fssc: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานการรับรองความปลอดภัยสำหรับการผลิตอาหาร (FSSC 22000)',
      brcgs: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานความปลอดภัยทางอาหาร (BRCGS)',
      ifs: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานอาหารระหว่างประเทศเพื่อให้การผลิตอาหารปลอดภัย (IFS)',
      rspoManufacturing: 'คลิกเพื่อดูตัวอย่างใบรับรองมาตรฐานการผลิตน้ำมันปาล์มอย่างยั่งยืน (RSPO)'
    }
  },
  application: {
    title: 'สถานะการสมัครเป็นพาร์ตเนอร์',
    subtitle: 'ใบสมัคร SME ของคุณกำลังอยู่ในขั้นตอนการพิจารณา',
    subtitle2: 'กรุณาเข้ามาเช็คสถานะการสมัครล่าสุดที่หน้าเพจนี้เรื่อยๆ',
    application_status: 'สถานะการสมัคร',
    application_status_value: {
      received: 'ได้รับใบสมัครแล้ว',
      review: 'กำลังตรวจสอบใบสมัครและเอกสาร',
      samples: 'กำลังพิจารณาตัวอย่างสินค้าและราคาที่เสนอมา',
      validation: 'กำลังอยู่ในขั้นตอนการตรวจสอบ ณ สถานที่จริง',
      final: 'กำลังอยู่ในขั้นตอนพิจารณาเลือกขั้นสุดท้ายโดยคณะกรรมการ'
    },
    status: {
      selected: 'ผ่านการพิจารณา',
      rejected: 'ไม่ผ่านการพิจารณา',
      need_review: 'ยังไม่ได้พิจารณา',
      in_review: 'กำลังพิจารณา',
      price_samples: 'ตัวอย่างสินค้า',
      qa: 'QA และ LS ',
      final_panel: 'คณะกรรมการ',
      hold_develop: 'รอ/กำลังพัฒนา',
      redirected: 'ส่งต่อให้แผนกอื่นพิจารณา'
    },
    PROCESS: 'ขั้นตอน',
    DETAILS: 'รายละเอียด',
    STATUS: 'สถานะ',
    congrat: 'ยินดีด้วยค่ะ คุณได้รับคัดเลือก',
    congrat2:
      'ขอต้อนรับคุณเข้าสู่ครอบครัว Tops Tongtin ทีมงานของเราจะติดต่อคุณไปอีกครั้งเร็วๆ นี้ เพื่อลงทะเบียนคู่ค้าในระบบและวางแผนการสั่งของร่วมกัน',
    reject: 'ขอบคุณที่พยายามไปกับเรานะคะ แต่ใบสมัครของคุณไม่ได้รับการคัดเลือกค่ะ',
    reject2: 'คุณสามารถดูเหตุผลที่ไม่ได้รับการคัดเลือกในรายละเอียดด้านล่างนี้'
  },
  terms: {
    title: 'ข้อกำหนดการใช้งาน',
    content: `
    <p>
    ยินดีต้อนรับสู่หน้าข้อกำหนดและเงื่อนไขของท็อปส์ ท้องถิ่น ท็อปส์ ท้องถิ่น (<a target="_blank" href="https://topstongtin.com">www.topstongtin.com</a>)
    เป็นโครงการใหม่โดยบริษัทเซ็นทรัล ฟู้ด รีเทล จำกัด เพื่อตามหาและร่วมเป็นพันธมิตรกับ SME
    ผู้ผลิตอาหารและเครื่องดื่มขนาดย่อมและขนาดเล็กชั้นเลิศในประเทศไทย โดย SME
    ที่สมัครเข้าโครงการจะต้องผ่านกระบวนการคัดเลือกตามที่อธิบายไว้ในหน้า “ต้องทำอย่างไร” (link)
    และจะได้รับการติดต่อกลับเพื่อแจ้งผลการคัดเลือกของคณะกรรมการโดยทีมงานท็อปส์ ท้องถิ่นต่อไป
    เพื่อให้เป็นไปตามกฎหมาย ท่านยืนยันว่าท่านมีอายุ 10 ปีขึ้นไป หากท่านมีอายุต่ำกว่า 10 ปี
    ท่านรับทราบว่าท่านจะต้องได้รับอนุญาตจากพ่อแม่หรือผู้ปกครองตามกฎหมายเพื่อเปิดบัญชี
    และพ่อแม่หรือผู้ปกครองตามกฎหมายดังกล่าวต้องเห็นด้วยกับข้อกำหนดของข้อตกลงนี้ หากคุณไม่เข้าใจความในข้อนี้
    โปรดอย่าสร้างบัญชีจนกว่าจะได้ขอความช่วยเหลือจากพ่อแม่หรือผู้ปกครองตามกฎหมายแล้ว
    หากคุณคือพ่อแม่หรือผู้ปกครองตามกฎหมายของผู้เยาว์ที่กำลังสร้างบัญชี
    คุณจะต้องยอมรับข้อกำหนดของข้อตกลงนี้แทนผู้เยาว์และคุณจะต้องรับผิดชอบต่อการใช้งานบัญชีหรือบริการของบริษัททั้งหมดจากการใช้บัญชีดังกล่าว
    ไม่ว่าบัญชีดังกล่าวจะเปิดอยู่แล้วในขณะนี้หรือสร้างในภายหลังก็ตาม
</p>
<h2>ข้อกำหนดและเงื่อนไข</h2>
<p>กรุณาอ่านข้อกำหนดในการใช้เว็บไซต์นี้อย่างละเอียด การใช้เว็บไซต์และระบบต่าง ๆ
    ของเว็บไซต์นี้ถือว่าท่านได้ยอมรับข้อกำหนดต่าง ๆ รวมทั้งนโยบายความเป็นส่วนตัวแล้ว
    และหากท่านไม่ยินยอมให้ข้อกำหนดนี้ผูกพันตัวท่าน กรุณาอย่าใช้เว็บไซต์นี้ โดยทางท็อปส์
    ท้องถิ่นขอสงวนสิทธิ์ในการเปลี่ยนแปลง จำกัด ปรับปรุง
    หรือยกเลิกเงื่อนไขและข้อกำหนดนี้ทั้งหมดหรือแต่บางส่วนเมื่อใดก็ได้ และการเปลี่ยนแปลงดังกล่าวนั้นจะผูกพันตัวท่าน
    ท่านจะต้องตรวจสอบรายละเอียดดังกล่าวเมื่อใช้เว็บไซต์ และเมื่อท่านใช้เว็บไซต์นี้ต่อไป
    ถือว่าท่านได้ให้ความยินยอมในการเปลี่ยนแปลงใด ๆ ที่อาจจะมีขึ้นแล้ว
    แม้ว่าทางเราจะได้พยายามอย่างสุดความสามารถในการยืนยันว่าข้อมูลต่าง ๆ เกี่ยวกับบริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด
    (“ท็อปส์ ท้องถิ่น”) ที่อยู่ในเว็บไซต์นี้มีความถูกต้องเหมาะสม แต่ท่านจะต้องไม่ด่วนสรุปว่าข้อมูลต่าง ๆ
    ที่ปรากฎอยู่บนเว็บไซต์เป็นข้อมูลล่าสุดที่มีการปรับปรุงแล้ว หรือด่วนสรุปว่าเว็บไซต์นี้มีข้อมูลทุกอย่างเกี่ยวกับท็อปส์
    ท้องถิ่น</p>
<h2>ลิขสิทธิ์และเครื่องหมายการค้า</h2>
<p>ข้อมูลทุกอย่างบนเว็บไซต์นี้ได้รับการคุ้มครองในรูปแบบของงานอันมีลิขสิทธิ์ตามกฎหมายลิขสิทธิ์และเครื่องหมายการค้า
    และ/หรือทรัพย์สินทางปัญญาอื่น ๆ ที่เป็นของท็อปส์ ท้องถิ่น และ/หรือบริษัทลูกและแบรนด์ที่เกี่ยวข้อง หรือคู่สัญญาอื่น ๆ
    ที่อนุญาตให้ท็อปส์ ท้องถิ่นใช้ทรัพย์สินทางปัญญาได้เสมือนเป็นเจ้าของ โดยท็อปส์ ท้องถิ่นขอสงวนสิทธิ์ในทรัพย์สินใด ๆ
    และ/หรือในทรัพย์สินทางปัญญาอื่น ๆ ที่จะไม่ได้รับอนุญาตอย่างชัดแจ้งให้ใช้ได้ในเว็บไซต์นี้</p>
<h2>การใช้เพื่อส่วนตัว</h2>
<p>ท่านยอมรับว่าท่านจะไม่เปิดเผยชื่อผู้ใช้ (username) และรหัสผ่าน (password) ของบัญชีผู้ใช้ (account)
    ของท่านให้กับบุคคลภายนอก
    ท่านจะต้องรับผิดชอบแต่เพียงผู้เดียวในการรักษาความลับของบัญชีผู้ใช้ของท่านรวมถึงกิจกรรมทุกอย่างที่เกิดขึ้นบนหรือผ่านทางบัญชีผู้ใช้ของท่าน
    ท็อปส์ ท้องถิ่นจะไม่รับผิดชอบต่อการสูญเสียใด ๆ
    ที่เกิดขึ้นจากการใช้บัญชีผู้ใช้ของท่านโดยไม่ได้รับอนุญาตท่านตกลงและให้คำมั่นที่จะไม่ปลอมเป็นบุคคลอื่น
    หรือระบุถ้อยคำอันเป็นเท็จหรือแสดงตนโดยหลอกลวงด้วยประการอื่นใดในความเกี่ยวข้องสัมพันธ์กับบุคคลอื่น</p>
<h2>การใช้ส่วนบุคคล</h2>
<p>ข้อมูลในเว็บไซต์นี้จะใช้เพื่อจุดประสงค์ส่วนบุคคลเท่านั้น ท่านยอมรับและตกลงว่าจะไม่นำไปเผยแพร่ ส่งต่อ ดัดแปลง
    หรือทำซ้ำ หรือใช้เนื้อหาที่ปรากฏอยู่บนเว็บไซต์และที่อยู่ในระบบการทำงานของเว็บไซต์เพื่อการใด ๆ และท่านจะต้องไม่คัดลอก
    เผยแพร่ หรือนำไปแสดงซึ่งข้อมูลใด ๆ ที่แสดงอยู่บนเว็บไซต์นี้ ไม่ว่าทั้งหมดหรือแต่บางส่วน ให้กับบุคคลภายนอก
    ท่านจะต้องไม่ติดต่อหรือเชื่อมต่อหรือทำซ้ำ/ลอกเลียน (mirror) ส่วนหนึ่งส่วนใดของเว็บไซต์นี้ทั้งสิ้น
    พึงระลึกว่าทางท็อปส์ ท้องถิ่นไม่อนุญาตให้ท่านใช้ ทำซ้ำ หรือเผยแพร่ข้อมูลใด ๆ หากไม่ได้รับอนุญาตเป็นหนังสือจากท็อปส์
    ท้องถิ่น หากมีการละเมิดสิทธิต่าง ๆ เหล่านี้จะต้องมีการดำเนินการทางกฎหมายตามความเหมาะสม และท่านยอมรับที่จะชดใช้
    ต่อสู้ในคดีความ และป้องกันท็อปส์ ท้องถิ่นจากความเสียหายที่อาจจะเกิดขึ้นจากการใช้ข้อมูลใด ๆ
    บนเว็บไซต์นี้โดยไม่ได้รับอนุญาต
    ท่านยอมรับว่าการใช้เนื้อหาและข้อความหรือรูปลักษณะที่ปรากฏอยู่บนเว็บไซต์นี้โดยไม่ได้รับอนุญาต
    อาจทำให้เกิดผลร้ายอย่างที่สุด และในกรณีที่มีการใช้ข้อมูลโดยไม่ได้รับอนุญาตจากท็อปส์ ท้องถิ่น ท็อปส์
    ท้องถิ่นมีสิทธิ์ที่จะได้รับค่าชดเชยและค่าสินไหมทดแทนในทางกฎหมายที่เป็นธรรมในฐานะเจ้าของ
    รวมถึงสิทธิในการจำกัดห้ามมิให้มีการกระทำอันเป็นการฝ่าฝืนนั้นต่อไป</p>
<h2>ข้อเสนอแนะและผลตอบรับ</h2>
<p>ท่านยอมรับว่าท่านเป็นผู้รับผิดชอบแต่เพียงผู้เดียว และจะรับผิดชอบในเนื้อหาใด ๆ ก็ตามที่ท่านส่งมายังเว็บไซต์
    ท่านจะต้องไม่ส่งมาซึ่งข้อมูลใด ๆ ที่ขัดต่อกฎหมายซึ่งอาจก่อให้เกิดความเสียหายต่อชื่อเสียง
    หรือข้อมูลอันมีลักษณะลามกอนาจาร ท่านยอมรับอีกด้วยว่าท่านจะไม่ส่งสิ่งใด ๆ ในเว็บไซต์มายังท็อปส์ ท้องถิ่น
    อันเป็นการละเมิดสิทธิของบุคคลภายนอก ซึ่งรวมถึงลิขสิทธิ์ เครื่องหมายการค้า ข้อมูลส่วนบุคคล ข้อมูลส่วนตัว ข้อมูลลับ
    หรือข้อมูลที่มีเจ้าของ เรายินดีที่ท่านให้ความสนใจกับท็อปส์ ท้องถิ่น แต่เราไม่ประสงค์
    และไม่สามารถยอมรับความคิดหรือข้อมูลใด ๆ อันเกี่ยวกับการออกแบบ เทคโนโลยีในการผลิต
    หรือคำชี้แนะอื่นใดที่ท่านอาจมีอยู่หรือได้ทำการพัฒนาขึ้นมาซึ่งท่านเป็นเจ้าของหรือเป็นทรัพย์สินของท่าน ดังนั้น
    หากท่านส่งข้อมูลหรือเนื้อหาใด ๆ มายังเว็บไซต์นี้หรือมายังส่วนหนึ่งส่วนใดของระบบของเว็บไซต์นี้
    ถือว่าท่านได้อนุญาตให้เรามีสิทธิในการใช้ข้อมูล และ/หรือเนื้อหาเหล่านี้โดยไม่มีค่าใช้จ่ายในการใช้ ทำซ้ำ ดัดแปลง
    ส่งต่อ นำออกแสดง แปล สร้างงานสร้างสรรค์ต่อเนื่อง และเผยแพร่โดยไม่มีข้อจำกัด ไม่ว่าโดยช่องทางหรือจะใช้วิธีการใด ๆ
    ในการเผยแพร่หรือนำออกแสดงซึ่งงานที่เป็นปัจจุบันหรือที่จะพัฒนาขึ้นในอนาคตก็ตาม นอกจากนี้
    ท่านรับรองว่าท่านได้สละแล้วซึ่งสิทธิสงวนใด ๆ (ธรรมสิทธิ) ในตัวของข้อมูล/เนื้อหาที่ท่านส่งมา</p>
<h2>การสละสิทธิ์และการยกเว้นความเสียหายต่อเนื้อหา</h2>
<p>ท็อปส์ ท้องถิ่นจะไม่รับผิดต่อท่านและต่อบุคคลภายนอกหากเกิดความผิดพลาดหรือความล่าช้าขึ้นกับเนื้อหาของเว็บไซต์นี้
    หรือต่อการกระทำใด ๆ ที่เกิดจากการใช้เนื้อหาเหล่านั้น และภายใต้กฎหมายที่เกี่ยวข้อง ท็อปส์
    ท้องถิ่นจะไม่รับผิดในค่าเสียหายใด ๆ อันเกิดจากอุบัติเหตุไม่ว่าโดยตรงหรือโดยอ้อม รวมทั้งค่าเสียหายข้างเคียงใด ๆ
    ค่าเสียหายพิเศษ หรือค่าเสียหายเชิงลงโทษ อันเป็นผลจากการใช้เนื้อหาและระบบของเว็บไซต์ แม้ว่าท็อปส์
    ท้องถิ่นจะได้รับคำแนะนำเกี่ยวกับความเป็นไปได้ของคามเสียหายก็ตาม ทั้งนี้ ท็อปส์
    ท้องถิ่นอาจจะต้องรับผิดในค่าเสียหายอันเกิดจากการแสดงหรือนำเสนอข้อมูลโดยทุจริตโดยตัวท็อปส์ ท้องถิ่นเอง
    ในกรณีที่เกิดกรณีเสียชีวิตหรือมีผู้ใดได้รับความเสียหายอันเนื่องมาจากความประมาทของท็อปส์ ท้องถิ่น ท็อปส์
    ท้องถิ่นจะใช้ความชำนาญและความระมัดระวังอย่างวิญญูชนในการตระเตรียมเว็บไซต์
    แต่จะไม่รับประกันความถูกต้องแม่นยำของเว็บไซต์ ไม่รับประกันการเข้าถึงเว็บไซต์นี้เมื่อใดก็ได้และตลอดเวลา
    ไม่รับประกันความน่าเชื่อถือของเว็บไซต์ไม่ว่าในกรณีใด ๆ
    ไม่รับรองว่าระบบการทำงานของเว็บไซต์จะไม่ถูกรบกวนหรือปราศจากข้อผิดพลาด ท็อปส์
    ท้องถิ่นไม่รับรองว่าข้อบกพร่องเช่นว่านั้นจะได้รับการการแก้ไข แม้ว่าจะได้รับรู้ถึงข้อบกพร่องเช่นว่านั้นก็ตาม</p>
<h2>เหตุการณ์ที่อยู่นอกเหนือการควบคุม</h2>
<p>ท็อปส์ ท้องถิ่นจะไม่รับผิดหรือรับผิดชอบต่อการผิดนัดชำระหนี้
    หรือชำระหนี้ล่าช้าอันมีสาเหตุมาจากเหตุการณ์ที่อยู่นอกเหนือการควบคุมตามปกติธรรมดา (เหตุสุดวิสัย)
    หรือปรากฎการณ์ธรรมชาติ ซึ่งรวมถึงการกระทำใด ๆ เหตุการณ์ การไม่เกิดเหตุการณ์
    หรืออุบัติเหตุที่อยู่นอกเหนือการควบคุมโดยปกติธรรมดา และรวมถึงเหตุการณ์เฉพาะ (แต่ไม่จำกัดเพียงเท่านี้) ดังต่อไปนี้
    <br />
    - ภาวะปั่นป่วนวุ่นวาย จลาจล การบุกรุก การโจมตีของผู้ก่อการร้าย หรือการขู่ว่าจะมีการก่อการร้าย สงคราม
    (ไม่ว่าจะมีการประกาศหรือไม่) หรือการขู่หรือการตระเตรียมทำสงคราม <br />
</p>
<p clas="padding-left-2">
    - ภาวะที่ไม่สามารถใช้เครือข่ายสื่อสารโทรคมนาคมสาธารณะหรือส่วนตัว <br />
    - การนัดหยุดงาน การปิดงาน หรือการปฏิบัติเชิงบังคับอื่นใดในทางอุตสาหกรรม <br />
    - วาตภัย ระเบิด อัคคีภัย อุทกภัย แผ่นดินไหว แผ่นดินถล่ม โรคระบาด หรือภัยธรรมชาติอื่นใด <br />
    - ภาวะที่ไม่สามารถใช้ทางรถไฟ เรือขนส่ง อากาศยาน การขนส่งทางรถยนต์ หรือการขนส่งสาธารณะหรือส่วนตัวอื่น ๆ
    - ภาวะเมื่ออยู่ในระหว่างเกิดปรากฏการณ์ธรรมชาติ
    หากถือว่าการชำระหนี้ของเราได้เลื่อนออกไปในช่วงที่เหตุสุดวิสัยยังคงมีอยู่
    เราจะขยายระยะเวลาการชำระหนี้ให้เท่ากับช่วงเวลานั้น หากเราไม่สามารถหาวิธีในการแก้ไขปัญหาที่เกิดขึ้นได้
    เราจะไม่รับผิดชอบใด ๆ ในสิ่งที่เกิดขึ้น
</p>
<h2>กรณีปฏิบัติผิดข้อกำหนดและเงื่อนไขนี้</h2>
<p>โดยที่ไม่เป็นการเสื่อมสิทธิอื่นใดของท็อปส์ ท้องถิ่นภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
    หากท่านปฏิบัติผิดข้อกำหนดและเงื่อนไขฉบับนี้ไม่ว่าทางหนึ่งทางใด ท็อปส์ ท้องถิ่นอาจดำเนินการอย่างหนึ่งอย่างใดที่ท็อปส์
    ท้องถิ่นเห็นว่าเหมาะสมเพื่อจัดการกับการปฏิบัติอันไม่ถูกต้องนั้น ซึ่งรวมถึงการระงับเข้าถึงเว็บไซต์ของท่าน
    การห้ามไม่ให้ท่านเข้าถึงเว็บไซต์ และ/หรือการฟ้องร้องท่านเป็นคดีต่อศาล</p>
<h2>การโอนสิทธิ</h2>
<p>ท็อปส์ ท้องถิ่นอาจโอน ทำสัญญาว่าจ้าง หรือตกลงกับพันธมิตรต่าง ๆ ของท็อปส์
    ท้องถิ่นภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้โดยไม่ต้องแจ้งให้ท่านทราบหรือขอความยินยอมจากท่าน
    กรณีข้อกำหนดและเงื่อนไขตกเป็นโมฆะบางส่วน
</p>
<p>หากข้อหนึ่งข้อใดของข้อกำหนดและเงื่อนไขฉบับนี้ถูกศาลหรือหน่วยงานที่มีอำนาจอื่นใดวินิจฉัยว่าไม่ชอบด้วยกฎหมาย
    และ/หรือใช้บังคับไม่ได้ ให้ถือว่าข้ออื่น ๆ ยังคงมีผลใช้บังต่อไป
    และถ้าข้อที่ไม่ชอบด้วยกฎหมายหรือใช้บังคับไม่ได้นั้นจะกลายเป็นชอบด้วยกฎหมายหรือใช้บังคับได้เมื่อส่วนหนึ่งส่วนใดของข้อนั้นได้ถูกลบออกไป
    ให้ถือว่าส่วนหนึ่งส่วนใดนั้นได้ถูกลบออกไปแล้ว และให้ส่วนที่เหลือของข้อดังกล่าวนั้นมีผลใช้บังคับต่อไป</p>
<h2>กฎหมายที่ใช้บังคับ</h2>
<p>ให้ใช้และตีความข้อกำหนดและเงื่อนไขฉบับนี้ภายใต้กฎหมายไทย ข้อพิพาทใด ๆ
    ที่เกี่ยวข้องกับข้อกำหนดและเงื่อนไขฉบับนี้จะต้องตกอยู่ภายใต้เขตอำนาจของศาลไทยเท่านั้น</p>

<h2>นโยบายความเป็นส่วนตัว </h2>
<p>
    <strong>บริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด (ซึ่งต่อไปนี้จะเรียกรวมกันว่า “บริษัท” “เรา” หรือ “ของเรา”) </strong>
    เล็งเห็นถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคลของท่าน ผู้ใช้ผลิตภัณฑ์และบริการของเรา
    เราทราบดีว่าท่านเป็นห่วงและให้ความสำคัญกับวิธีการที่ข้อมูลของท่านจะถูกเก็บรวบรวม ใช้ เปิดเผย และ
    ส่งหรือโอนไปยังต่างประเทศ ซึ่งเราและบริษัทที่อยู่ในระบบนิเวศของข้อมูล (Data ecosystem) ของกลุ่มเซ็นทรัล
    จะนำข้อมูลที่ท่านให้กับเรานั้นไปใช้เพื่อมอบผลิตภัณฑ์และให้บริการที่ตรงกับความต้องการและความจำเป็นที่ออกแบบมาอย่างเหมาะสมสำหรับท่าน
    บริษัทฯ
    ซาบซึ้งในความไว้วางใจของท่านและเราจะดูแลข้อมูลส่วนบุคคลของท่านด้วยความระมัดระวังและอย่างสมเหตุสมผลในการมอบประสบการณ์และการให้บริการลูกค้าอย่างดีที่สุดให้แก่ท่านโดยเฉพาะ
</p>
<p>
    นโยบายความเป็นส่วนตัวฉบับนี้ (“นโยบายความเป็นส่วนตัว”) ครอบคลุมถึงร้านค้าปลีก เว็บไซต์ แอปพลิเคชันบนโทรศัพท์มือถือ
    ศูนย์บริการข้อมูลลูกค้า (call center) ช่องทางสื่อสังคมออนไลน์ ช่องทางการสื่อสารทางออนไลน์ ตลอดจนสถานที่อื่น ๆ
    ที่ได้มีการเก็บข้อมูลส่วนบุคคลของท่าน อย่างไรก็ตาม
    กรุณาอ่านนโยบายความเป็นส่วนตัวฉบับนี้ควบคู่กับข้อตกลงและเงื่อนไขสำหรับบริการเฉพาะดังกล่าวที่ท่านใช้
    (ซึ่งอาจมีกำหนดไว้แยกต่างหากตามประเภทของข้อมูลส่วนบุคคลที่เราเก็บจากท่าน)
</p>
<p>
    เพื่อให้เป็นไปตามวัตถุประสงค์ตามนโยบายความเป็นส่วนตัวฉบับนี้ “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลใด ๆ
    ที่เกี่ยวข้องกับตัวบุคคลหรือที่ทำให้สามารถระบุตัวบุคคลนั้นได้
</p>
<p>
    บริษัทฯ ขอสงวนสิทธิ์ในการแก้ไขนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราว ดังนั้น
    โปรดตรวจสอบเป็นประจำเพื่อดูว่านโยบายความเป็นส่วนตัวนี้ได้รับการแก้ไขครั้งล่าสุดเมื่อใด การเปลี่ยนแปลงใด ๆ
    จะมีผลทันทีเมื่อเราเผยแพร่นโยบายความเป็นส่วนตัวที่แก้ไขดังกล่าวลงในเว็บไซต์หรือแอปพลิเคชันของเรา
    เราจะแจ้งให้ท่านทราบหากมีการแก้ไขหรือปรับปรุงที่สำคัญ
    ส่วนกรณีเป็นการแก้ไขเปลี่ยนแปลงที่เป็นการลิดรอนสิทธิของท่านในส่วนของข้อมูลที่ละเอียดอ่อนตามนโยบายความเป็นส่วนตัวฉบับนี้
    บริษัทจะดำเนินการเพื่อขอความยินยอมจากท่านก่อน เว้นแต่เป็นกรณีที่กฎหมายกำหนดเป็นอย่างอื่น
</p>
<h3>1. ข้อมูลส่วนบุคคลประเภทใดบ้างที่เราเก็บรวบรวม</h3>
<p>เราอาจมีการเก็บรวบรวมหรือรับข้อมูลประเภทดังต่อไปนี้ซึ่งอาจรวมไปถึงข้อมูลส่วนบุคคลของท่านโดยตรงหรือโดยอ้อมจากท่านหรือแหล่งข้อมูลอื่น
    ๆ หรือจากบริษัทในเครือของเรา บริษัทย่อย บริษัทอื่น ๆ หรือ พันธมิตรทางธุรกิจ
    โดยลักษณะของข้อมูลที่เราเก็บรวบรวมนั้นจะขึ้นอยู่กับบริบทของการติดต่อสื่อสารของท่านกับเรา
    รวมถึงบริการหรือผลิตภัณฑ์ที่ท่านต้องการจากเราและจากบริษัทที่อยู่ในระบบนิเวศของข้อมูล (Data ecosystem)
    ของกลุ่มเซ็นทรัล</p>
<h4 class="padding-left-3">
    <strong>1.1 ข้อมูลส่วนตัว</strong> เช่น คำนำหน้าชื่อ ชื่อเต็ม เพศ อายุ อาชีพ คุณสมบัติ ตำแหน่งงาน ตำแหน่งหรือฐานะ
    ประเภทธุรกิจ ชื่อบริษัท สัญชาติ ประเทศที่พำนัก วันเกิด สถานภาพทางการสมรส จำนวนสมาชิกในครอบครัวและบุตร อายุของบุตร
    ข้อมูลบนบัตรที่ออกโดยรัฐบาล (เช่น เลขที่บัตรประจำตัวประชาชน รูปถ่ายบัตรประจำตัวประชาชน ข้อมูลบนบัตรประจำตัวประชาชน
    เลขควบคุมหลังบัตรประจำตัวประชาชน (Laser ID) เลขประกันสังคม เลขหนังสือเดินทาง เลขประจำตัวผู้เสียภาษีอากร
    ข้อมูลใบอนุญาตขับขี่รถยนต์ หรือเอกสารระบุตัวตนที่มีลักษณะเดียวกัน เป็นต้น) รายละเอียดการเข้าเมือง เช่น
    วันที่เดินทางมาถึงและเดินทางออกประเทศ ลายมือชื่อ เสียง เสียงที่บันทึก รูปถ่าย รูปแบบลักษณะใบหน้าเพื่อการจดจำ
    ภาพจากกล้องวงจรปิด สถานที่ทำงาน วุฒิการศึกษา ข้อมูลประกันภัย ป้ายทะเบียนรถ ทะเบียนบ้าน รายรับภายในครัวเรือน
    เงินเดือน และรายรับส่วนตัว เป็นต้น
</h4>
<h4 class="padding-left-3">
    <strong>1.2 ข้อมูลเพื่อการติดต่อ</strong> เช่น ที่อยู่ทางไปรษณีย์ ข้อมูลการจัดส่ง ที่อยู่สำหรับส่งใบแจ้งหนี้
    ที่อยู่ที่เป็นที่พักอาศัย ที่อยู่ของสถานที่ทำงาน ที่อยู่ตามที่ปรากฏบนหน้าบัตรประจำตัวประชาชน หมายเลขโทรศัพท์
    หมายเลขโทรสาร ที่อยู่อีเมล ไลน์ไอดี (LINE ID) บัญชีผู้ใช้ Facebook ไอดี Facebook (Facebook ID) ไอดี Google (Google
    ID) ไอดี Twitter (Twitter ID) และบัญชีผู้ใช้ในเว็บไซต์โซเชียลมีเดียอื่น ๆ รวมไปถึงข้อมูลผู้ติดต่อ เช่น
    หมายเลขโทรศัพท์ ข้อมูลการติดต่อบนช่องทางอื่น ๆ (เช่น การติดต่อสื่อสารเป็นลายลักษณ์อักษรกับท่าน)
</h4>
<h4 class="padding-left-3">
    <strong>1.3 ข้อมูลการเป็นสมาชิก</strong> เช่น รายละเอียดข้อมูลบัญชีสมาชิก หมายเลขบัตรสมาชิก คะแนนสะสม
    วันที่ออกบัตร/วันหมดอายุ รหัสสมาชิก (เช่น หมายเลขบัตรสมาชิก The 1 รหัส The 1 (The 1 ID) รหัสซีเบล (Siebel ID)
    รหัสสมาชิก (Member ID) รหัสลูกค้า (Customer ID)) ประเภทสมาชิก ประเภทลูกค้า วันและเดือนที่เข้าร่วม/วันที่สมัคร
    ระยะเวลาการเป็นสมาชิก บัญชีธนาคาร และข้อมูลการชำระเงิน และการสมัครใช้บริการและผลิตภัณฑ์ (เช่น ใบสมัครสมาชิก
    ใบสมัครประกันภัย เป็นต้น)
</h4>
<h4 class="padding-left-3">
    <strong>1.4 ข้อมูลทางการเงิน</strong> เช่น ข้อมูลบัตรเครดิต/เดบิตหรือข้อมูลทางธนาคาร หมายเลขบัตรเครดิต/เดบิต
    ประเภทของบัตรเครดิต วันเดือนปีที่ออก/ วันเดือนปีที่หมดอายุ รอบบิล รายละเอียดบัญชี รายละเอียดเกี่ยวกับบัญชีธนาคาร
    หมายเลขประจำตัวพร้อมเพย์ รายละเอียดและประวัติการชำระเงิน
    รวมไปถึงข้อมูลของท่านที่เกี่ยวกับรายละเอียดความเสี่ยงสำหรับพันธมิตรทางธุรกิจ
    รวมไปถึงการจัดอันดับความน่าเชื่อถือทางเครดิตและความสามารถในการชำระหนี้ (credit rating and solvency)
    และข้อมูลตามแบบประเมินความเสี่ยง (information in accordance with the declaration of suitability)
    ความเหมาะสมในการทำธุรกรรม และรายละเอียดอื่นใดทางการเงิน
</h4>
<h4 class="padding-left-3">
    <strong>1.5 ข้อมูลการทำธุรกรรม</strong> เช่น ข้อมูลเกี่ยวกับการชำระเงินไปยังและจากท่าน วันที่และ/หรือเวลาที่ชำระเงิน
    ยอดชำระเงิน ข้อมูลเกี่ยวกับการขอเงินคืน ยอดเงินคืน คะแนนสะสม วันที่และสถานที่ที่ซื้อ หมายเลขการซื้อหรือคำสั่งซื้อ
    วันนัดหมายเข้ารับบริการ ที่อยู่/วันที่และเวลาในการรับหรือจัดส่ง ข้อความตอบรับของผู้รับ ข้อความลงท้ายในอีเมลของผู้รับ
    ข้อมูลการรับประกันสินค้า คำร้องเรียนและข้อเรียกร้อง ข้อมูลการจอง ข้อมูลการเช่า ธุรกรรม ประวัติการทำธุรกรรม สถานที่
    สถานะของธุรกรรม ธุรกรรมทางการขายในอดีต สถานะ สถานะการทำธุรกรรม พฤติกรรมการซื้อ และข้อมูลอื่น ๆ
    ของผลิตภัณฑ์และบริการที่ท่านซื้อ ซึ่งรวมถึงแต่ไม่จำกัดเพียง ข้อมูลใด ๆ
    ที่เกิดจากการใช้ผลิตภัณฑ์/หรือการบริการผ่านแพลตฟอร์มของเรา เช่น กระเป๋าเงินอิเล็กทรอนิกส์ สินทรัพย์ดิจิทัล
    ผลิตภัณฑ์การให้เงินกู้ยืม ผลิตภัณฑ์เกี่ยวกับการประกันภัย ผลิตภัณฑ์เกี่ยวกับการบริหารจัดการความมั่งคั่ง เป็นต้น
</h4>
<h4 class="padding-left-3">
    <strong>1.6 ข้อมูลทางเทคนิค</strong> เช่น เลขที่อยู่ไอพีหรืออินเทอร์เน็ตโพรโทคอล (IP address) คุกกี้ ที่อยู่ media
    access control (MAC) เว็บบีคอน (web beacon) ล็อก (Log) รหัสอุปกรณ์ (Device ID) (เช่น International Mobile Equipment
    Identity (IMEI) Electronic Serial Number (ESN) Mobile Equipment Identifier (MEID) และ Serial Number (SN))
    รุ่นอุปกรณ์และประเภทของอุปกรณ์ รูปแบบของฮาร์ดแวร์และซอฟต์แวร์ของอุปกรณ์ที่ใช้ เวลาการเปิดใช้งานอุปกรณ์ เครือข่าย
    ข้อมูลการเชื่อมต่อ ข้อมูลการเข้าถึง ข้อมูลการเข้าใช้งานแบบ single sign-on (SSO) การเข้าสู่ระบบ (Login log)
    ระยะเวลาและสถานที่ที่เข้าถึง ระยะเวลาที่ใช้บนหน้าเพจของเรา ข้อมูลการเข้าสู่ระบบ จีพีเอส ละติจูด ลองจิจูด
    และเวลาที่ใช้ในหน้าเว็บแต่ละหน้า ข้อมูลการล็อกอิน แอปพลิเคชันที่ดาวน์โหลดบนอุปกรณ์สื่อสาร ประวัติการค้นหา
    ข้อมูลการเรียกดู ประเภทและเวอร์ชันของเบราว์เซอร์ การตั้งค่าเขตเวลา (Time zone setting) และสถานที่ตั้ง
    ประเภทและเวอร์ชันของปลั๊กอินเบราว์เซอร์ ระบบปฏิบัติการและแพลตฟอร์ม และเทคโนโลยีอื่นๆ
    บนอุปกรณ์ที่ท่านใช้ในการเข้าถึงแพลตฟอร์มของเรา รวมทั้งข้อมูลทางเทคนิคอื่นใดอันเกิดจากการใช้แพลตฟอร์มและระบบของเรา
</h4>
<h4 class="padding-left-3">
    <strong>1.7 ข้อมูลเกี่ยวกับพฤติกรรม</strong> เช่น ข้อมูลเกี่ยวกับพฤติกรรมการซื้อของท่าน
    และข้อมูลที่ได้รับจากการใช้ผลิตภัณฑ์และบริการของเรา
</h4>
<h4 class="padding-left-3">
    <strong>1.8 รายละเอียดข้อมูลส่วนตั</strong> ว เช่น ชื่อผู้ใช้และรหัสผ่านของท่าน รายละเอียดและรูปภาพข้อมูลส่วนตัว
    การซื้อ ประวัติคำสั่งซื้อ คำสั่งซื้อในอดีต ประวัติการซื้อ สินค้าที่ซื้อ จำนวนสินค้า
    คำสั่งซื้อหรือคำสั่งเรียกคืนสินค้าโดยท่าน คำสั่งซื้อผ่านทางเว็บไซต์ รายละเอียดการเก็บเงินปลายทาง (Cash on Delivery)
    ไอดีคำสั่งซื้อ (order ID) บันทึกข้อมูลทางการเงิน เลขรหัสลับส่วนตัว (PIN) ความสนใจของท่าน ความชอบ การตอบรับและผลสำรวจ
    การสำรวจความพึงพอใจ การใช้โซเชียลมีเดีย ข้อมูลการเข้าร่วม ข้อมูลโปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty
    programs) รหัสส่วนลดและโปรโมชั่นที่ท่านใช้ รายละเอียดคำสั่งซื้อของลูกค้า การบริการลูกค้า
    การเข้าร่วมนิทรรศการการค้าและกิจกรรม นิทรรศการการค้า การดำเนินคดี การทดสอบและทดลองใช้งาน
</h4>
<h4 class="padding-left-3">
    <strong>1.8 รายละเอียดข้อมูลส่วนตั</strong> ว เช่น ชื่อผู้ใช้และรหัสผ่านของท่าน รายละเอียดและรูปภาพข้อมูลส่วนตัว
    การซื้อ ประวัติคำสั่งซื้อ คำสั่งซื้อในอดีต ประวัติการซื้อ สินค้าที่ซื้อ จำนวนสินค้า
    คำสั่งซื้อหรือคำสั่งเรียกคืนสินค้าโดยท่าน คำสั่งซื้อผ่านทางเว็บไซต์ รายละเอียดการเก็บเงินปลายทาง (Cash on Delivery)
    ไอดีคำสั่งซื้อ (order ID) บันทึกข้อมูลทางการเงิน เลขรหัสลับส่วนตัว (PIN) ความสนใจของท่าน ความชอบ การตอบรับและผลสำรวจ
    การสำรวจความพึงพอใจ การใช้โซเชียลมีเดีย ข้อมูลการเข้าร่วม ข้อมูลโปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty
    programs) รหัสส่วนลดและโปรโมชั่นที่ท่านใช้ รายละเอียดคำสั่งซื้อของลูกค้า การบริการลูกค้า
    การเข้าร่วมนิทรรศการการค้าและกิจกรรม นิทรรศการการค้า การดำเนินคดี การทดสอบและทดลองใช้งาน
</h4>
<h4 class="padding-left-3">
    <strong>1.8 รายละเอียดข้อมูลส่วนตั</strong> ว เช่น ชื่อผู้ใช้และรหัสผ่านของท่าน รายละเอียดและรูปภาพข้อมูลส่วนตัว
    การซื้อ ประวัติคำสั่งซื้อ คำสั่งซื้อในอดีต ประวัติการซื้อ สินค้าที่ซื้อ จำนวนสินค้า
    คำสั่งซื้อหรือคำสั่งเรียกคืนสินค้าโดยท่าน คำสั่งซื้อผ่านทางเว็บไซต์ รายละเอียดการเก็บเงินปลายทาง (Cash on Delivery)
    ไอดีคำสั่งซื้อ (order ID) บันทึกข้อมูลทางการเงิน เลขรหัสลับส่วนตัว (PIN) ความสนใจของท่าน ความชอบ การตอบรับและผลสำรวจ
    การสำรวจความพึงพอใจ การใช้โซเชียลมีเดีย ข้อมูลการเข้าร่วม ข้อมูลโปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty
    programs) รหัสส่วนลดและโปรโมชั่นที่ท่านใช้ รายละเอียดคำสั่งซื้อของลูกค้า การบริการลูกค้า
    การเข้าร่วมนิทรรศการการค้าและกิจกรรม นิทรรศการการค้า การดำเนินคดี การทดสอบและทดลองใช้งาน
</h4>
<h4 class="padding-left-3">
    <strong>1.9 ข้อมูลการใช้งาน เช่น </strong> ข้อมูลเกี่ยวกับการค้นหาหรือใช้งานของท่านบนเว็บไซต์ แพลตฟอร์ม แอปพลิเคชัน
    การใช้ผลิตภัณฑ์และบริการของเรา สินค้าในรถเข็น รายการสินค้าที่สนใจ บันทึกการแจ้งเตือนเกี่ยวกับสินค้าลดราคา
    การติดตามร้านค้า (Follow-shop record) เวลาที่คลิกครั้งสุดท้าย (Timestamp of last click) และบันทึกคำถาม-คำตอบ
</h4>
<h4 class="padding-left-3">
    <strong>1.10 ข้อมูลทางการตลาดและการติดต่อสื่อสาร</strong> เช่น ความต้องการของท่านในการรับข้อมูลทางการตลาดจากเรา
    บริษัทในเครือของเรา บริษัทย่อย บุคคลภายนอก พันธมิตรทางธุรกิจ และรูปแบบการติดต่อสื่อสารที่ต้องการ และ/หรือ
</h4>
<h4 class="padding-left-3">
    <strong>1.11 ข้อมูลที่ละเอียดอ่อน</strong> เช่น เชื้อชาติ ศาสนา ความคิดเห็นทางการเมือง ลายนิ้วมือ ระบบจดจำใบหน้า
    ข้อมูลอัตลักษณ์บุคคล (Biometrics) ใบหน้า ข้อมูลจากการจดจำม่านตา ข้อมูลสุขภาพหรือสภาพทางร่างกายหรือจิตใจ
    ข้อมูลทางพันธุกรรม ประวัติทางการแพทย์ ภาวะทุพพลภาพ และประวัติอาชญากรรม
</h4>
<p>
    หากท่านได้ให้ข้อมูลส่วนบุคคลของบุคคลที่สามแก่เรา เช่น ชื่อ นามสกุล ที่อยู่
    และหมายเลขโทรศัพท์เพื่อติดต่อในกรณีฉุกเฉิน รายได้ของบุคคลในครอบครัว หรือ ในกรณีที่ท่านเข้าใช้บริการในแพลตฟอร์มใด ๆ
    ของเรา หากท่านให้ความยินยอม เราสามารถเข้าถึงและเก็บรวบรวมข้อมูลส่วนบุคคลของบุคคลที่สามที่เกี่ยวข้องกับท่านได้ เช่น
    ข้อมูลชื่อ รูปภาพ และ/หรือ หมายเลขโทรศัพท์ ตลอดจนข้อมูลส่วนบุคคลและข้อมูลสำหรับติดต่อของ ครอบครัว เพื่อน
    บุคคลที่ติดต่อได้ในกรณีฉุกเฉิน บุคคลตามคำแนะนำ หรือบุคคลอ้างอิงที่เข้าถึงได้จากโทรศัพท์มือถือของท่าน เป็นต้น
    กรุณาแจ้งบุคคลเหล่านั้นให้ทราบถึงนโยบายความเป็นส่วนตัวฉบับนี้ และ/หรือขอความยินยอมจากบุคคลเหล่านั้น หากจำเป็น <br />
    เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลที่ละเอียดอ่อนก็ต่อเมื่อได้รับความยินยอมโดยชัดแจ้งจากท่าน
    หรือในกรณีที่กฎหมายอนุญาตให้ดำเนินการได้ <br />
    เราจะเก็บรวบรวมข้อมูลของผู้เยาว์ คนเสมือนไร้ความสามารถ
    คนไร้ความสามารถต่อเมื่อได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์ ผู้พิทักษ์ หรือผู้อนุบาล
    เท่านั้น เราจะไม่เก็บรวบรวมข้อมูลจากลูกค้าที่เรารู้โดยแจ้งชัดว่าอายุต่ำกว่ายี่สิบปี
    หรือจากคนเสมือนไร้ความสามารถและคนไร้ความสามารถหากไม่ได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์
    ผู้พิทักษ์ หรือผู้อนุบาล ในกรณีที่จำเป็นต้องได้รับความยินยอม ในกรณีที่เราทราบว่า
    เราได้เก็บรวบรวมข้อมูลส่วนบุคคลโดยไม่มีเจตนาจากบุคคลใด ๆ ที่อายุต่ำกว่ายี่สิบปี คนเสมือนไร้ความสามารถ
    หรือคนไร้ความสามารถโดยไม่ได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์ ผู้พิทักษ์ หรือผู้อนุบาล
    เราจะดำเนินการลบข้อมูลดังกล่าวทันที หรือประมวลผลเฉพาะในส่วนของข้อมูลที่เราสามารถกระทำได้บนฐานทางกฎหมายอื่น ๆ
    นอกเหนือจากการขอความยินยอม
</p>

<h3>2. เหตุใดเราจึงเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน<h3>
        <h4 class="padding-left-3">
            <strong>2.1. วัตถุประสงค์ที่ต้องได้รับความยินยอมจากท่าน</strong>
        </h4>
        <p class="padding-left-4">
            <strong>การตลาดและการติดต่อสื่อสาร:</strong> เราเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อมอบสิทธิพิเศษและโปรโมชั่น การลดราคา ข้อเสนอพิเศษ การโฆษณา การแจ้งเตือน
            ข่าวสาร ข้อมูล การตลาดและการติดต่อสื่อสารต่างๆ เกี่ยวกับผลิตภัณฑ์และบริการจากเรา กลุ่มเซ็นทรัล บริษัทในเครือ
            บริษัทย่อย และ/หรือพันธมิตรทางธุรกิจของเรา ซึ่งเราไม่สามารถอาศัยฐานทางกฎหมายอื่น <br />
            กรุณาติดต่อเรา เพื่อจัดการความยินยอมของท่านสำหรับการตลาดและการติดต่อสื่อสาร
        </p>
        <h4 class="padding-left-3">
            <strong>2.2. วัตถุประสงค์และฐานทางกฎหมายอื่นๆ ที่เราใช้เพื่อการประมวลผลข้อมูลส่วนบุคคลของท่าน</strong>
        </h4>
        <p class="padding-left-4">
            เราอาจอาศัย (1) ฐานทางสัญญาในการเข้าทำสัญญาหรือการปฏิบัติตามสัญญากับท่าน (2) หน้าที่ตามกฎหมาย
            เพื่อการปฏิบัติหน้าที่ตามกฎหมายของเรา (3) ประโยชน์โดยชอบด้วยกฎหมายของเราและของบุคคลภายนอก (4)
            ประโยชน์ซึ่งจำเป็นเพื่อการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล และ/หรือ (5)
            ประโยชน์สาธารณะ สำหรับการดำเนินภารกิจเพื่อประโยชน์สาธารณะ หรือการใช้สิทธิของเจ้าหน้าที่ของรัฐ
            <br />
            เราอาจเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้
        </p>
        <p class="padding-left-5">
            <strong>1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน </strong>
            เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาระหว่างเรากับท่าน เพื่อสนับสนุนและดำเนินกิจกรรมอื่น ๆ
            ที่เกี่ยวข้องกับบริการ หรือผลิตภัณฑ์ต่าง ๆ ดังกล่าว เพื่อจัดการการจองให้สำเร็จ
            และเพื่อดำเนินธุรกรรมทางการเงินและบริการที่เกี่ยวข้องกับการชำระเงิน
            รวมถึงการตรวจสอบและการยืนยันและยกเลิกธุรกรรม เพื่อประมวลผลคำสั่งซื้อของท่าน การจัดส่ง การรับสินค้า
            และการส่งคืนสินค้า การคืนเงินและการเปลี่ยนสินค้าหรือบริการ
            เพื่อรายงานสถานะการจัดส่งสินค้าและเพื่อดำเนินกิจกรรมภายในคลังสินค้า รวมถึง การรับ การจัด
            และการติดฉลากผลิตภัณฑ์ เพื่อตรวจสอบการรับประกัน เพื่อให้บริการหลังการขาย รวมถึง
            การนัดหมายบริการการบำรุงรักษาและการอำนวยความสะดวก <br />
            <strong>2) การตลาดและการติดต่อสื่อสาร: </strong> เพื่อมอบสิทธิพิเศษและโปรโมชั่น ข่าวสาร การลดราคา
            ข้อเสนอพิเศษ การโฆษณา การแจ้งเตือน ข่าวสาร ข้อมูล การตลาดและการติดต่อสื่อสารต่างๆ
            เกี่ยวกับผลิตภัณฑ์และบริการจากเรา กลุ่มเซ็นทรัล บริษัทในเครือ บริษัทย่อย และพันธมิตรทางธุรกิจ <br />
            <strong>3) โปรโมชั่น ข้อเสนอพิเศษ โปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty programs) โปรแกรมการสะสมคะแนน
                (Reward programs) การจับรางวัล การแข่งขัน และข้อเสนอ/โปรโมชั่นอื่น ๆ</strong>
            เพื่อให้ท่านได้เข้าร่วมหรือรับโปรโมชั่น ข้อเสนอพิเศษ โปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty programs)
            โปรแกรมการสะสมคะแนน การชิงโชค สิทธิพิเศษ การจับรางวัล การแข่งขัน และข้อเสนอ/โปรโมชั่นอื่น ๆ (เช่น
            การส่งอีเมลแจ้งเตือน การโอนข้อมูลส่วนบุคคลของท่านไปยังคู่ค้า/พันธมิตร) เพื่อการเข้าร่วมกิจกรรม และการจัดอบรม
            และบริการทั้งหมดที่เกี่ยวข้องกับการโฆษณา โดยรวมถึง การประมวลผลและจัดการการลงทะเบียนบัญชีผู้ใช้งานของท่าน
            การลงทะเบียนรับของขวัญ การลงทะเบียนเข้าร่วมกิจกรรม เพื่อการประมวลคะแนน การสะสม เพิ่ม แลกเปลี่ยน
            หรือได้รับคะแนน การแลกหรือจ่ายด้วยคะแนน และการโอนคะแนน
            เพื่อการตรวจสอบประวัติการใช้งานทั้งทางออนไลน์และออฟไลน์ เพื่อการมอบบัตรกำนัล บัตรของขวัญ และออกใบแจ้งหนี้
            <br />
            <strong>4) การลงทะเบียนและการพิสูจน์ยืนยันตัวตน</strong> เพื่อลงทะเบียน ตรวจสอบ พิสูจน์ ยืนยัน ระบุและ/หรือ
            รับรองท่านหรือตัวตนของท่าน <br />
            <strong>5) เพื่อดูแลความสัมพันธ์ระหว่างเรากับท่าน </strong>
            เพื่อติดต่อสื่อสารกับท่านเกี่ยวกับผลิตภัณฑ์และการบริการที่ท่านได้รับจากเรา ทั้งจากภายในระบบนิเวศของข้อมูล
            (Data ecosystem) ของกลุ่มเซ็นทรัล บริษัทในเครือของเรา บริษัทย่อย และจากพันธมิตรทางธุรกิจของเรา
            เพื่อจัดการในด้านต่าง ๆ ที่เกี่ยวข้องกับการบริการลูกค้า ไม่ว่าจะเป็น คำถาม คำขอ ผลตอบรับ คำร้องเรียน
            ข้อเรียกร้อง ข้อพิพาท หรือการเยียวยาชดใช้ค่าเสียหาย เพื่อให้ความช่วยเหลือทางเทคนิคและแก้ไขปัญหาทางเทคนิค
            เพื่อประมวลผลและปรับปรุงข้อมูลของท่าน เพื่ออำนวยความสะดวกแก่ท่านในการใช้ผลิตภัณฑ์และรับบริการของเรา <br />
            <strong>6) การมอบประสบการณ์เฉพาะบุคคล (Personalization) การวิเคราะห์ข้อมูลตามความสนใจหรือพฤติกรรมของลูกค้า
                (Profiling) และการวิเคราะห์ข้อมูล (Data Analytics) </strong> เพื่อแนะนำผลิตภัณฑ์และบริการที่ท่านอาจสนใจ
            ระบุความชอบของท่าน และมอบประสบการณ์ที่เหมาะสมกับท่าน เพื่อเรียนรู้เพิ่มเติมเกี่ยวกับท่าน
            ผลิตภัณฑ์และบริการที่ท่านได้รับ และผลิตภัณฑ์และการบริการอื่นๆ ที่ท่านอาจสนใจ
            เพื่อประเมินความความสนใจหรือพฤติกรรมของท่านที่มีต่อผลิตภัณฑ์และการบริการ เพื่อดำเนินการวิเคราะห์ข้อมูล (Data
            analytics) การทำความสะอาดข้อมูล (Data cleansing) และการวิเคราะห์ข้อมูลตามความสนใจหรือพฤติกรรมของลูกค้า (Data
            profiling) เพื่อทำการค้นคว้าวิจัยทางการตลาด ทำแบบสำรวจ ประเมินผล พฤติกรรม สถิติและการแบ่งประเภท
            รูปแบบและกระแสความนิยมด้านการบริโภค การวิเคราะห์ข้อมูลตามความสนใจหรือพฤติกรรม (Profiling)
            จากการประมวลผลข้อมูลส่วนบุคคลของท่าน เช่น
            การประมวลผลจากชนิดของผลิตภัณฑ์และประเภทของการบริการที่ท่านใช้หรือได้รับ ช่องทางที่ท่านต้องการได้รับการติดต่อ
            เพื่อรู้จักท่านมากขึ้น และเพื่อยกระดับการดำเนินธุรกิจให้ดียิ่งขึ้น
            ตลอดจนเพื่อปรับเปลี่ยนเนื้อหาให้ตรงกับความพึงพอใจ เพื่อพิจารณาความมีประสิทธิภาพของแคมเปญส่งเสริมการขาย
            เพื่อระบุและแก้ไขปัญหาที่เกิดขึ้นกับผลิตภัณฑ์และการบริการที่มีอยู่ เพื่อการพัฒนาคุณภาพของข้อมูล
            เพื่อจุดประสงค์ดังกล่าว เราจะเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อความสนใจและผลประโยชน์ของท่าน
            และเพื่อประโยชน์โดยชอบด้วยกฎหมายและการดำเนินธุรกิจของกลุ่มเซ็นทรัล บริษัทในเครือ
            บริษัทย่อยและพันธมิตรทางธุรกิจ ซึ่งไม่เกินกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
            ทั้งนี้ เราจะขอความยินยอมจากท่านเป็นคราว ๆ ไป หากจำเป็น <br />
            <strong>7) เพื่อยกระดับการดำเนินธุรกิจ ผลิตภัณฑ์และการบริการให้ดียิ่งขึ้น</strong> เพื่อประเมิน พัฒนา
            จัดการและยกระดับ ค้นคว้าวิจัยและพัฒนาการให้บริการ ผลิตภัณฑ์
            ระบบและการดำเนินธุรกิจให้แก่ท่านและลูกค้าของเราในระบบนิเวศของข้อมูล (Data ecosystem) ของกลุ่มเซ็นทรัลทั้งหมด
            และรวมถึงแต่ไม่จำกัดเพียงพันธมิตรทางธุรกิจของเรา เพื่อระบุและแก้ไขปัญหาที่เกิดขึ้น
            เพื่อจัดทำรายงานภาพรวมของข้อมูลแบบไม่ระบุชื่อ และประเมินประสิทธิภาพในเรื่องผลิตภัณฑ์ สื่อดิจิทัล
            การตรวจวัดประสิทธิภาพในการทำงานของผลิตภัณฑ์ในเชิงกายภาพ คุณสมบัติด้านดิจิทัล และแคมเปญทางการตลาดของเรา
            ตลอดจนการพัฒนาโมเดลทางธุรกิจ แบบจำลองสำหรับการพิจารณาสินเชื่อ แบบจำลองด้านการประกันภัยและการจัดเก็บหนี้สิน
            <br />
            <strong>8) เพื่อเรียนรู้เพิ่มเติมเกี่ยวกับท่าน </strong>
            เพื่อเรียนรู้เพิ่มเติมเกี่ยวกับผลิตภัณฑ์และบริการที่ท่านได้รับ และผลิตภัณฑ์และการบริการอื่น ๆ ที่ท่านอาจสนใจ
            รวมถึงการวิเคราะห์ข้อมูลตามความสนใจหรือพฤติกรรม (Profiling) จากการประมวลผลข้อมูลส่วนบุคคลของท่าน เช่น
            การประมวลผลจากชนิดของผลิตภัณฑ์และประเภทของการบริการที่ท่านใช้หรือได้รับจากเรา
            ช่องทางที่ท่านต้องการได้รับการติดต่อ และอื่น ๆ <br />
            <strong>9) การทำงานของเว็บไซต์ แอปพลิเคชันมือถือ </strong>และแพลตฟอร์มของเรา เพื่อดูแล ดำเนินงาน ติดตาม
            สังเกตการณ์
            และจัดการเว็บไซต์และแพลตฟอร์มของเราเพื่ออำนวยความสะดวกและรับรองให้ท่านมั่นใจว่าเว็บไซต์และแพลตฟอร์มทำงานอย่างราบรื่น
            มีประสิทธิภาพและปลอดภัย เพื่ออำนวยความสะดวกท่านในการใช้งานเว็บไซต์และแพลตฟอร์มของเรา
            เพื่อปรับปรุงแผนงานและเนื้อหาของเว็บไซต์และแพลตฟอร์มของเราให้ดียิ่งขึ้น <br />
            <strong>10) การบริหารจัดการทางด้านเทคโนโลยีสารสนเทศ </strong> เพื่อวัตถุประสงค์ในการบริหารจัดการธุรกิจของเรา
            รวมถึงการจัดการระบบปฏิบัติการด้านเทคโนโลยีสารสนเทศ การจัดการระบบติดต่อสื่อสาร
            ระบบความปลอดภัยทางด้านเทคโนโลยีสารสนเทศ และการตรวจสอบความปลอดภัยทางด้านเทคโนโลยีสารสนเทศ
            การจัดการภายในด้านธุรกิจตามข้อปฏิบัติภายใน นโยบายและกระบวนการต่าง ๆ <br />
            <strong>11) การปฏิบัติตามหน้าที่ตามกฎข้อบังคับและข้อผูกพัน </strong>
            เพื่อดำเนินการให้เป็นไปตามบทบัญญัติแห่งกฎหมาย กระบวนการทางกฎหมาย
            หรือคำสั่งของหน่วยงานรัฐซึ่งรวมถึงหน่วยงานรัฐภายนอกประเทศไทย และ/หรือให้ความร่วมมือกับศาล ผู้กำกับดูแล
            หน่วยงานรัฐ และหน่วยงานที่บังคับใช้กฎหมาย
            ในกรณีที่มีเหตุผลอันควรเชื่อได้ว่าเราต้องดำเนินการดังกล่าวเพื่อปฏิบัติตามกฎหมายและในกรณีที่เราจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่านอย่างเคร่งครัดเพื่อปฏิบัติตามบทบัญญัติแห่งกฎหมาย
            กระบวนการทางกฎหมาย หรือคำสั่งของรัฐดังกล่าว โดยรวมถึง
            การให้บริการและการจัดการเรื่องบริการการขอคืนภาษีมูลค่าเพิ่ม (VAT refund) การออกใบกำกับภาษี
            หรือออกแบบฟอร์มภาษีแบบเต็ม การบันทึกและติดตามการติดต่อสื่อสาร
            การเปิดเผยข้อมูลให้แก่หน่วยงานที่มีอำนาจจัดเก็บภาษี ผู้กำกับดูแลด้านบริการทางการเงิน
            และองค์กรและหน่วยงานรัฐอื่น ๆ ที่กำกับดูแล และเพื่อการสืบสวนสอบสวนหรือป้องกันอาชญากรรม <br />
            <strong>12) การปกป้องผลประโยชน์ของเรา </strong> เพื่อปกป้องความมั่นคงและความเชื่อมั่นทางธุรกิจของบริษัทฯ
            เพื่อใช้สิทธิหรือปกป้องผลประโยชน์ของเราที่จำเป็นและสามารถทำได้ตามกฎหมาย เช่น การตรวจจับ ป้องกัน
            และตอบโต้ข้อเรียกร้องการทุจริต การละเมิดทรัพย์สินทางปัญญา หรือการไม่ปฏิบัติตามกฎหมาย
            เพื่อจัดการและป้องกันความเสียหายแก่ทรัพย์สินและสมบัติของเรา เพื่อการปฏิบัติตามข้อกำหนดและเงื่อนไขของเรา
            เพื่อตรวจจับและป้องกันการประพฤติที่มิชอบภายในองค์กรซึ่งรวมไปถึงการใช้กล้องวงจรปิด
            เพื่อติดตามเฝ้าระวังเหตุการณ์ เพื่อป้องกันและรายงานความผิดทางอาญา
            และเพื่อปกป้องความมั่นคงและและความเชื่อมั่นทางธุรกิจของบริษัทฯ <br />
            <strong>13) การตรวจจับการทุจริต </strong> เพื่อตรวจสอบตัวตนของท่าน
            และเพื่อดำเนินการตรวจสอบทางกฎหมายหรือกฎข้อบังคับอื่น ๆ ที่บังคับใช้ (เช่น
            เพื่อดำเนินการตามกฎหมายป้องกันและปราบปรามการฟอกเงิน และป้องกันการทุจริต) ทั้งนี้
            รวมไปถึงการจัดทำรายชื่อผู้มีความเสี่ยง (Sanction list checking) การตรวจสอบและทำบันทึกภายใน
            การจัดการทรัพย์สิน ระบบ และการควบคุมทางธุรกิจอื่น ๆ <br />
            <strong>14) การทำธุรกรรมขององค์กร </strong> ในกรณีที่มีการขาย การโอน การควบรวม การปรับโครงสร้างองค์กร
            หรือเหตุการณ์อื่น ๆ ที่ใกล้เคียงกัน เราอาจโอนข้อมูลของท่านไปยังบุคคลภายนอกอื่น ๆ ไม่ว่าจะรายเดียวหรือหลายราย
            อันเป็นส่วนหนึ่งของการทำธุรกรรมนั้น ๆ <br />
            <strong>15) ความเสี่ยง </strong> เพื่อดำเนินการบริหารความเสี่ยง การตรวจสอบการดําเนินงาน
            และการประเมินความเสี่ยง และ/หรือ <br />
            <strong>16) ชีวิต </strong> เพื่อป้องกันหรือยับยั้งอันตรายที่เกิดขึ้นต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
            หากท่านไม่สามารถให้ข้อมูลส่วนบุคคลแก่เราเมื่อมีการร้องขอ
            เราอาจไม่สามารถนำเสนอหรือจัดหาผลิตภัณฑ์หรือบริการของเราแก่ท่านได้ <br />
        </p>
        <h3>3. บุคคลใดที่เราอาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านให้</h3>
        <p>เราอาจเปิดเผย
            หรือโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอกทั้งภายในประเทศไทยหรือนอกประเทศไทยดังต่อไปนี้ซึ่งเก็บรวบรวม
            ใช้หรือเปิดเผยข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์ตามนโยบายฉบับนี้
            โดยท่านสามารถอ่านนโยบายส่วนบุคคลของบุคคลภายนอกเหล่านั้นเพื่อศึกษาข้อมูลเพิ่มเติมเกี่ยวกับวิธีการที่บุคคลภายนอกดังกล่าวเก็บรวบรวม
            ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่านได้ ซึ่งท่านเองก็ตกอยู่ภายใต้นโยบายความเป็นส่วนตัวเหล่านั้นด้วยเช่นกัน
        </p>
        <h4 class="padding-left-3">
            <strong>3.1. บริษัทในระบบนิเวศของข้อมูล (Data ecosystem) ของกลุ่มเซ็นทรัล</strong>
        </h4>
        <p class="padding-left-3">
            1) ในฐานะที่ บริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด เป็นส่วนหนึ่งของระบบนิเวศของข้อมูล (Data ecosystem)
            ภายใต้กลุ่มเซ็นทรัล ซึ่งทั้งหมดมีการร่วมมือและแบ่งปันการให้บริการและระบบต่าง ๆ แก่ลูกค้า รวมไปถึง
            การให้บริการที่เกี่ยวข้องกับเว็บไซต์และระบบอื่น ๆ ดังนั้น บริษัทฯ
            อาจมีความจำเป็นที่จะต้องเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบริษัทอื่น ๆ ที่อยู่ในระบบนิเวศของข้อมูล
            (Data ecosystem) ของกลุ่มเซ็นทรัล หรืออนุญาตให้บริษัทอื่น ๆ ที่อยู่ในระบบนิเวศของข้อมูล (Data ecosystem)
            ของกลุ่มเซ็นทรัล เข้าถึงข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ตามที่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
            โปรดดูรายชื่อบริษัทของกลุ่มเซ็นทรัล [ที่นี่]
        </p>
        <h4 class="padding-left-3">
            <strong>3.2. การให้บริการของเรา</strong>
        </h4>
        <p class="padding-left-3">
            เราอาจว่าจ้างบริษัทอื่น ตัวแทน หรือผู้รับจ้างในการให้บริการในนามของเรา
            หรืออำนวยความสะดวกในการมอบผลิตภัณฑ์และบริการให้กับท่าน
            โดยเราอาจแบ่งปันข้อมูลส่วนบุคคลของท่านไปยังผู้ให้บริการหรือผู้จัดจำหน่ายซึ่งเป็นบุคคลภายนอก ต่อไปนี้
            ซึ่งรวมถึงแต่ไม่จำกัดเพียง (1) ผู้พัฒนา/ให้บริการโครงสร้างพื้นฐาน อินเทอร์เน็ต โครงสร้างพื้นฐานทางเทคนิค
            ซอฟต์แวร์และเว็บไซต์ และผู้ให้บริการด้านเทคโนโลยีสารสนเทศ (2) ผู้ให้บริการโลจิสติกส์และคลังสินค้า (3)
            ผู้ให้บริการด้านการชำระเงิน (4) บริษัทตัวแทนหรือหน่วยงานวิจัย (5) ผู้ให้บริการด้านการวิเคราะห์ข้อมูล (6)
            บริษัทตัวแทนหรือหน่วยงานสำรวจข้อมูล (7) ผู้ตรวจสอบบัญชี (8) บริษัทตัวแทนหรือหน่วยงานด้านการติดต่อสื่อสาร
            สื่อโฆษณาและการตลาด (9) ศูนย์บริการข้อมูลลูกค้า (call center) (10) ผู้จัดงานและกิจกรรม (11)
            บริษัทตัวแทนการขาย (12) ผู้ให้บริการด้านโทรคมนาคมและการติดต่อสื่อสาร (13)
            ผู้ให้บริการและตัวแทนด้านการชำระเงิน ระบบการชำระเงิน การยืนยันตัวตน
            และการตรวจสอบและอ่านข้อมูลด้วยอุปกรณ์อิเล็กทรอนิกส์ (Dip Chip Service) (14)
            ผู้ให้บริการด้านการบริหารจัดการที่เป็นบุคคลภายนอกที่ได้รับการว่าจ้าง (15)
            ผู้ให้บริการด้านการจัดเก็บข้อมูลและบริการคลาวด์ (Cloud) (16) ผู้ให้บริการด้านการตรวจสอบและยืนยันข้อมูล
            (เน็ตเบย์ และกรมการปกครอง) (17) พนักงานเดินเอกสาร (18) ผู้ให้บริการด้านการพิมพ์
        </p>
        <p class="padding-left-3">
            ในระหว่างการให้บริการต่าง ๆ ดังกล่าว ผู้ให้บริการอาจจะต้องเข้าถึงข้อมูลส่วนบุคคลของท่าน อย่างไรก็ตาม
            เราจะให้ข้อมูลแก่ผู้ให้บริการของเราเพียงเท่าที่จำเป็นสำหรับการให้บริการดังกล่าวเท่านั้น
            และจะขอให้ผู้ให้บริการไม่ใช้ข้อมูลของท่านเพื่อวัตถุประสงค์อื่นแต่อย่างใด
        </p>
        <h4 class="padding-left-3">
            <strong>3.3. พันธมิตรทางธุรกิจของเรา</strong>
        </h4>
        <p class="padding-left-3">
            เราอาจโอนข้อมูลส่วนบุคคลของท่านไปยังพันธมิตรทางธุรกิจด้านการธนาคาร การเงิน การให้สินเชื่อ การให้กู้ยืม
            การบริหารสินทรัพย์ การลงทุน การประกันภัย บัตรเครดิต โทรคมนาคม การตลาด การค้าปลีก อี-คอมเมิร์ซ (E-commerce)
            คลังสินค้าและการขนส่งโลจิสติกส์ ศูนย์สุขภาพ ผลิตภัณฑ์และบริการเพื่อการดำเนินชีวิต สปาและศูนย์ออกกำลังกาย
            โปรแกรมการสะสมคะแนน (Reward program) และโปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty program)
            และการวิเคราะห์ข้อมูล (Data analytics) รวมถึง
            แพลตฟอร์มของผู้ขายหรือผู้ให้บริการซึ่งเราอาจได้ร่วมกันเสนอผลิตภัณฑ์หรือบริการ
            หรือที่ทางผู้ขายหรือผู้ให้บริการได้เสนอผลิตภัณฑ์หรือบริการให้กับท่าน ทั้งนี้
            ข้อมูลที่ส่งต่อหรือแบ่งปันกันในลักษณะนี้นั้นจะอยู่ภายใต้นโยบายความเป็นส่วนตัวของบุคคลภายนอก
            มิใช่ภายใต้นโยบายความเป็นส่วนตัวฉบับนี้
        </p>
        <h4 class="padding-left-3">
            <strong>3.4. เว็บไซต์ต่าง ๆ ในสื่อสังคมออนไลน์</strong>
        </h4>
        <p class="padding-left-3">
            ท่านสามารถล็อกอินเข้าสู่เว็บไซต์และแพลตฟอร์มของเราได้โดยไม่ต้องกรอกข้อมูลในแบบฟอร์ม
            ในกรณีที่ท่านเข้าสู่ระบบผ่านระบบล็อคอินของสื่อสังคมออนไลน์
            ท่านให้ความยินยอมโดยชัดแจ้งให้เราเข้าถึงและจัดเก็บข้อมูลสาธารณะที่อยู่ในบัญชีต่าง ๆ
            ในสื่อสังคมออนไลน์ของท่าน (เช่น Facebook Google หรือ Instagram) รวมไปถึงข้อมูลอื่น ๆ
            ที่ปรากฏขึ้นระหว่างการใช้ระบบล็อกอินผ่านสื่อสังคมออนไลน์ดังกล่าว นอกจากนี้
            เราอาจส่งอีเมลของท่านไปยังสื่อสังคมออนไลน์
            เพื่อเป็นการระบุตัวตนว่าท่านเป็นสมาชิกของบรรดาสื่อสังคมออนไลน์ที่เกี่ยวข้องหรือไม่
            และเพื่อเป็นการลงโฆษณาที่เหมาะสมกับความชอบและที่เกี่ยวข้องบนบัญชีสื่อสังคมออนไลน์ของท่าน ตามความเหมาะสม
        </p>
        <p class="padding-left-3">
            เรายังร่วมมือกับบุคคลภายนอกอื่น ๆ
            ซึ่งจะทำให้ท่านสามารถเข้ารับบริการหรือเข้าร่วมรับข้อเสนอโปรโมชั่นของบุคคลภายนอกอื่น ๆ เหล่านั้นได้
            ยกตัวอย่างเช่น สำหรับบางบริษัท ท่านสามารถใช้เลขโปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty program number)
            ของท่าน หรือล็อกอินเข้าสู่ระบบออนไลน์ของท่าน เพื่อรับหรือสมัครบริการของบริษัทนั้น ๆ ได้ นอกจากนี้
            ท่านสามารถเชื่อมบัญชีสื่อสังคมออนไลน์ของท่านกับบัญชีบริการออนไลน์ของท่านได้
            หรือเข้าสู่ระบบของบัญชีบริการออนไลน์ของท่านจากบัญชีสื่อสังคมออนไลน์ของท่าน ทั้งนี้ เมื่อท่านสมัครบริการต่าง
            ๆ ดังกล่าวนั้น เราจะแบ่งปันข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอกที่ท่านได้ทำการสมัครบริการไว้
            หากท่านไม่ประสงค์ที่จะแบ่งปันข้อมูลส่วนบุคคลของท่านด้วยวิธีการนี้
            โปรดอย่าให้เลขโปรแกรมการเป็นลูกค้าอย่างต่อเนื่อง (Loyalty program number) หรือหมายเลขโปรแกรมสะสมคะแนน
            (Reward program number) ของท่านแก่บุคคลภายนอก และอย่าสมัครรับข้อเสนอโปรโมชั่นโดยใช้บัญชีบริการออนไลน์ของท่าน
            รวมทั้งอย่าเชื่อมต่อบัญชีบริการออนไลน์ของท่านเข้ากับบัญชีที่ให้บริการโดยบุคคลภายนอก
            ข้อมูลที่ส่งต่อหรือแบ่งปันกันในลักษณะนี้นั้นจะอยู่ภายใต้นโยบายความเป็นส่วนตัวของบุคคลภายนอก
            มิใช่ภายใต้นโยบายความเป็นส่วนตัวฉบับนี้
        </p>
        <h4 class="padding-left-3">
            <strong>3.5. บุคคลภายนอกตามที่กฎหมายกำหนด</strong>
        </h4>
        <p class="padding-left-3">
            ในบางกรณี เราอาจจะจำเป็นที่จะต้องเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านเพื่อปฏิบัติตามกฎหมาย
            หรือกฎข้อบังคับ ซึ่งรวมถึง การปฏิบัติตามหน่วยงานที่บังคับใช้กฎหมาย ศาล เจ้าพนักงาน หน่วยงานรัฐ
            หรือบุคคลภายนอกอื่น ๆ ในกรณีที่เราเชื่อว่าจำเป็นจะต้องปฏิบัติตามหน้าที่ตามกฎหมายหรือกฎข้อบังคับทางกฎหมาย
            หรือเพื่อการปกป้องสิทธิของเรา สิทธิของบุคคลอื่น หรือเพื่อความปลอดภัยของบุคคล หรือเพื่อตรวจสอบ ป้องกัน
            หรือจัดการเกี่ยวกับปัญหาการทุจริต หรือด้านความมั่นคงหรือความปลอดภัย
        </p>
        <h4 class="padding-left-3">
            <strong>3.6 ที่ปรึกษาด้านต่าง ๆ</strong>
        </h4>
        <p class="padding-left-3">
            ที่ปรึกษาด้านต่าง ๆ นั้นรวมถึงทนายความ ผู้เชี่ยวชาญทางเทคนิคและผู้ตรวจสอบบัญชีที่ช่วยในการประกอบธุรกิจ
            การดำเนินคดี หรือจัดการเกี่ยวกับข้อเรียกร้องทางกฎหมาย
        </p>
        <h4 class="padding-left-3">
            <strong>3.7 สมาคม และองค์กรต่าง ๆ ๆ</strong>
        </h4>
        <p class="padding-left-3">
            เราอาจโอนข้อมูลส่วนบุคคลของท่านไปยังสมาคมต่าง ๆ เช่น สมาคมการค้าผู้ให้บริการชำระเงินอิเล็กทรอนิกส์ไทย (TEPA)
            สำนักงานพัฒนาธุรกรรมทางอิเล็กทรอนิกส์ (สพธอ.) สมาคมสหพันธ์องค์กรผู้บริโภค (สสอบ.) มูลนิธิเพื่อผู้บริโภค
            หอการค้าไทยและสภาหอการค้าแห่งประเทศไทย สมาคมผู้ประกอบการพาณิชย์อิเล็กทรอนิกส์ไทย สมาคมค้าปลีก
            สมาคมศูนย์การค้า หรือกลุ่มแยกราชประสงค์
        </p>
        <h3>4. การโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ</h3>
        <p>
            เราอาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอก
            หรือผู้ให้บริการที่อยู่ในต่างประเทศซึ่งประเทศปลายทางอาจมี
            หรืออาจไม่มีระดับการคุ้มครองข้อมูลส่วนบุคคลที่เท่าเทียมกัน ทั้งนี้ เราจะดำเนินการตามขั้นตอนและมาตรการต่าง ๆ
            เพื่อทำให้ท่านมั่นใจได้ว่าการโอนข้อมูลส่วนบุคคลของท่านจะโอนอย่างปลอดภัยและบุคคลที่รับโอนข้อมูลนั้นมีระดับการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสมหรือกรณีอื่น
            ๆ ตามที่กฎหมายกำหนด
            และเราจะขอความยินยอมจากท่านเพื่อการโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศหากจำเป็นต้องปฏิบัติตามที่กฎหมายกำหนด
        </p>
        <h3>5. ระยะเวลาในการที่เราเก็บข้อมูลส่วนบุคคลของท่าน</h3>
        <p>
            เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผล
            เพื่อให้บรรลุตามวัตถุประสงค์ที่เราได้รับข้อมูลส่วนบุคคลนั้นมา
            และเพื่อปฏิบัติตามภาระหน้าที่ทางกฎหมายและกฎข้อบังคับต่าง ๆ อย่างไรก็ตาม
            เราอาจเก็บข้อมูลส่วนบุคคลของท่านนานขึ้นหากจำเป็นตามกฎหมายที่ใช้บังคับ
        </p>
        <h3>5. ระยะเวลาในการที่เราเก็บข้อมูลส่วนบุคคลของท่าน</h3>
        <p>
            เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผล
            เพื่อให้บรรลุตามวัตถุประสงค์ที่เราได้รับข้อมูลส่วนบุคคลนั้นมา
            และเพื่อปฏิบัติตามภาระหน้าที่ทางกฎหมายและกฎข้อบังคับต่าง ๆ อย่างไรก็ตาม
            เราอาจเก็บข้อมูลส่วนบุคคลของท่านนานขึ้นหากจำเป็นตามกฎหมายที่ใช้บังคับ
        </p>
        <h3>6. ความปลอดภัยของข้อมูล</h3>
        <p>
            บริษัทฯ ตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของท่าน บริษัทฯ
            จึงพยายามอย่างยิ่งที่จะปกป้องข้อมูลของท่านด้วยการกำหนดให้มีมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของท่านอย่างเหมาะสมและสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการสูญหาย
            เข้าถึง ทำลาย ใช้ แปลง แก้ไข หรือเปิดเผย โดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย โดยบริษัทฯ
            จะตรวจสอบวิธีการเก็บรวบรวมข้อมูลส่วนบุคคล พื้นที่ในการจัดเก็บข้อมูลและดำเนินการตามหลักปฏิบัติในการประมวลผล
            รวมถึงมาตรการความปลอดภัยทางกายภาพ
            ให้เป็นไปตามที่กำหนดในนโยบายและแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศของบริษัทฯ
        </p>
        <h3>7. คุกกี้และวิธีการใช้คุกกี้</h3>
        <p>
            เมื่อท่านเยี่ยมชมเว็บไซต์ของเรา เราจะเก็บรวบรวมข้อมูลบางประการโดยอัตโนมัติจากท่านผ่านการใช้คุกกี้ <br />
            คุกกี้ คือ ข้อมูลขนาดเล็กหรือข้อความที่ออกให้กับคอมพิวเตอร์ของท่านเมื่อท่านเยี่ยมชมเว็บไซต์
            และใช้เพื่อจัดเก็บหรือติดตามข้อมูลเกี่ยวกับการใช้เว็บไซต์ของท่านและนำมาใช้ในการวิเคราะห์กระแสความนิยม
            (trend) การบริหารจัดการเว็บไซต์ ติดตามการเคลื่อนไหวการใช้เว็บไซต์ของผู้ใช้บริการ
            หรือเพื่อจดจำการตั้งค่าของผู้ใช้บริการ ทั้งนี้ คุกกี้บางประเภทนั้นจำเป็นเป็นอย่างมาก
            มิฉะนั้นหน้าเว็บไซต์อาจจะไม่สามารถใช้การได้อย่างเหมาะสม และคุกกี้ประเภทอื่น ๆ
            นั้นอนุญาตให้เราปรับปรุงประสบการณ์การเบราวซ์ของท่าน ปรับแต่งเนื้อหาตามความต้องการของท่าน
            และทำให้ปฏิสัมพันธ์ของท่านกับเว็บไซต์สะดวกมากขึ้น เนื่องจากคุกกี้จะจดจำชื่อผู้ใช้ (ในวิธีการที่ปลอดภัย)
            รวมทั้งจดจำการตั้งค่าทางภาษาของท่าน <br />
            อินเตอร์เน็ตเบราว์เซอร์ส่วนใหญ่จะให้ท่านเป็นคนตัดสินใจว่าจะยอมรับคุกกี้หรือไม่ หากท่านปฏิเสธ ลบออก
            หรือบล็อคการติดตามโดยคุกกี้ อาจกระทบประสบการณ์ผู้ใช้ของท่าน และหากปราศจากคุกกี้
            ความสามารถของท่านในการใช้งานลักษณะหรือพื้นที่ทั้งหมดหรือบางส่วนของเว็บไซต์อาจถูกจำกัด <br />
            นอกจากนี้
            บุคคลภายนอกอาจออกคุกกี้ผ่านเว็บไซต์ของเราเพื่อมอบโฆษณาที่เกี่ยวข้องกับความสนใจของท่านตามกิจกรรมการเบราวซ์ของท่าน
            บุคคลภายนอกเหล่านี้อาจเก็บรวบรวมประวัติการเบราวซ์ของท่านหรือข้อมูลอื่นเพื่อให้ทราบว่าท่านเข้าถึงเว็บไซต์อย่างไร
            และเพจที่ท่านเยี่ยมชมหลังจากที่ออกจากเว็บไซต์ของเราข้อมูลที่รวบรวมผ่านตัวกลางอัตโนมัติเหล่านี้อาจมีความเกี่ยวข้องกับข้อมูลส่วนบุคคลที่ท่านได้ให้ไว้ก่อนหน้านี้บนเว็บไซต์ของเรา
            <br />
        </p>
        <h3>8. สิทธิของเจ้าของข้อมูลส่วนบุคคล</h3>
        <p>
            ภายใต้บทบัญญัติแห่งกฎหมายและข้อยกเว้นตามกฎหมายที่เกี่ยวข้อง ท่านอาจมีสิทธิตามที่ระบุไว้ดังต่อไปนี้
        </p>
        <p class="padding-left-2">
            <strong>1) การเข้าถึง: </strong> ท่านอาจมีสิทธิในการขอเข้าถึงหรือขอรับสำเนาข้อมูลส่วนบุคคลที่เราเก็บรวบรวม
            ใช้หรือเปิดเผยเกี่ยวกับท่าน ทั้งนี้ เพื่อความเป็นส่วนตัวและความปลอดภัยของท่าน
            เราอาจขอให้ท่านพิสูจน์ตัวตนของท่านก่อนจะให้ข้อมูลตามที่ท่านขอ <br />
            <strong>2) การแก้ไขให้ถูกต้อง:
            </strong>ท่านอาจมีสิทธิขอให้มีการดำเนินการแก้ไขข้อมูลส่วนบุคคลที่เราได้เก็บรวบรวม
            ใช้หรือเปิดเผยเกี่ยวกับท่าน ให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด <br />
            <strong>3) การโอนย้ายข้อมูล:
            </strong>ท่านอาจมีสิทธิขอรับข้อมูลส่วนบุคคลที่เรามีเกี่ยวกับท่านในรูปแบบที่มีการจัดระเบียบแล้วและสามารถอ่านได้ในรูปแบบอิเล็กทรอนิกส์
            และเพื่อส่งหรือโอนข้อมูลดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น โดยต้องเป็น (ก)
            ข้อมูลส่วนบุคคลที่ท่านได้ให้กับเรา และ (ข) กรณีที่เราได้รับความยินยอมจากท่านในการเก็บรวบรวม ใช้
            หรือเปิดเผยหรือเพื่อปฏิบัติตามสัญญาที่เรามีกับท่าน <br />
            <strong>4) การคัดค้าน: </strong> ท่านอาจมีสิทธิคัดค้านการเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
            เช่น การคัดค้านการตลาดแบบตรง <br />
            <strong>5) การขอให้ระงับการใช้: </strong> ท่านอาจมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลของท่านในบางกรณี
            <br />
            <strong>6) การถอนความยินยอม: </strong>สำหรับวัตถุประสงค์ที่ท่านได้ให้ความยินยอมให้เราเก็บรวบรวม
            ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ท่านมีสิทธิที่จะถอนความยินยอมของท่าน ณ เวลาใด ก็ได้ <br />
            <stong>7) การลบหรือทำลายข้อมูล: </stong>ท่านอาจมีสิทธิขอให้เราดำเนินการลบหรือทำลาย
            หรือทำให้ข้อมูลส่วนบุคคลของท่านที่เราเก็บรวบรวม ใช้หรือเปิดเผย
            เป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูล เว้นเสียแต่ว่า
            การเก็บรักษาข้อมูลดังกล่าวของเรานั้นเป็นไปเพื่อการปฏิบัติตามกฎหมาย หรือเพื่อก่อตั้งสิทธิเรียกร้องตามกฎหมาย
            เพื่อการใช้ หรือการปกป้องสิทธิเรียกร้องตามกฎหมาย <br />
            <strong>8) การยื่นเรื่องร้องเรียน:
            </strong>ท่านอาจมีสิทธิยื่นเรื่องร้องเรียนไปยังหน่วยงานที่มีอำนาจในกรณีที่ท่านเชื่อว่าการเก็บรวบรวม
            ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของเรานั้นไม่ชอบด้วยกฎหมายหรือไม่สอดคล้องกับกฎหมายคุ้มครองข้อมูลที่บังคับใช้
        </p>
        <h3>9. รายละเอียดการติดต่อเรา</h3>
        <p>
            หากท่านมีความประสงค์ที่จะใช้สิทธิของท่านที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน
            หรือหากท่านมีข้อสงสัยเกี่ยวกับข้อมูลส่วนบุคคลของท่านภายใต้นโยบายความเป็นส่วนตัวฉบับนี้
            โปรดติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราที่
        </p>
        <h4>บริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด </h4>
        <ul class="padding-left-3">
            <li>
                แผนกบริการลูกค้าสัมพันธ์
            </li>
            <li>
                99/9 เซ็นทรัลพลาซา แจ้งวัฒนะ ออฟฟิศ ทาวเวอร์ หมู่ 2 ถนนแจ้งวัฒนะ ตำบลบางตลาด อำเภอปากเกร็ด
                จังหวัดนนทบุรี 11120
            </li>
            <li>
                <a href="mailto:cusserv@tops.co.th">cusserv@tops.co.th</a>
                เบอร์โทรศัพท์ 02-660-1000
            </li>
            <li>
                Tel: <a href="tel:026601000">02-660-1000</a>
            </li>
        </ul>
        <h4>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h4>
        <ul class="padding-left-3">
            <li>
                เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
            </li>
            <li>
                ฝ่ายคุ้มครองข้อมูลส่วนบุคคล เซ็นทรัล กรุ๊ป เลขที่ 22 ซอยสมคิด ถนนเพลินจิต แขวงลุมพินี เขตปทุมวัน
                กรุงเทพมหานคร 10330
            </li>
            <li>
                <a href="mailto:dpo@central.co.th">dpo@central.co.th</a>
            </li>
        </ul>
    `
  },
  homepage: {
    local: 'รวมของดีถิ่นไทย SME ไปไกลระดับท็อป​',
    local_sub:
      'คุณกำลังมองหาช่องทางการเติบโตในธุรกิจเล็กๆ ของตัวเองและขายสินค้าในซูเปอร์มาร์เก็ตชั้นนำอยู่หรือเปล่า',
    local_sub_2: '“ท็อปส์ ท้องถิ่น” นี่แหละ “ใช่” สำหรับคุณ!',
    local_sub_3: 'ทำไมโครงการของเราถึงแตกต่างจากโครงการ SME อื่นๆ:',
    local_sub_4: `<ul class="padding-left-5">
    <li>ติดต่อกับเราได้ง่ายกว่าเดิม</li>
    <li>สามารถออกแบบการสมัครเข้าโครงการ ได้ด้วยตัวเอง</li>
    <li>ดูสถานะการสมัครได้ตลอด</li>
    <li>ได้รับความช่วยเหลือและคำแนะนำตั้งแต่ต้นจนจบ</li>
    <li>ได้รับเงินจากการขายกับเราเร็วขึ้นและเข้าถึงความช่วยเหลือทางการเงินได้ง่ายขึ้น</li>
  </ul>`,
    what_you_get: {
      title: 'คุณจะได้อะไร!',
      get1: 'ได้รับเงินเร็วขึ้นใน 15 วัน',
      get2: 'ไม่ต้องจ่ายค่าแรกเข้าและลิสต์สินค้าในระบบ',
      get3: 'เข้าถึงช่องทางการขายของเซ็นทรัล ฟู้ด รีเทล ทุกช่องทาง',
      get4: 'ความช่วยเหลือด้านใบอนุญาต ใบรับรองต่างๆ และการเงิน',
      get5: 'ความช่วยเหลือด้านการตลาด'
    },
    how_it_work: {
      title: 'ต้องทำอะไรบ้าง?',
      how1: 'ตรวจสอบคุณสมบัติ SME ที่โครงการต้องการ',
      how2: 'ส่งใบสมัครออนไลน์',
      how3: 'รอใบสมัครผ่านการพิจารณา',
      how4: 'SME ที่มีคุณสมบัติตรงได้รับคัดเลือกให้เป็นพาร์ตเนอร์'
    },
    our_partner: 'พันธมิตรของเรา',
    ready: 'พร้อมหรือยังที่จะมาเป็นพาร์ตเนอร์กับเรา?',
    more_question: 'มีคำถามเพิ่มเติม?',
    learn: 'เรียนรู้เพิ่มเติม',
    prepare: 'ข้อมูลที่ต้องเตรียม',
    register: 'สมัครเลย'
  },
  faq_page: {
    title: 'คำถามที่พบบ่อย',
    content: {
      question1: 'ท็อปส์ ท้องถิ่น คืออะไร?',
      answer1: `
      ท็อปส์ ท้องถิ่น (<a target="_blank" href="https://www.topstongtin.com">www.topstongtin.com</a>) คือ โครงการใหม่ของ บริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด เพื่อตามหาและพาร์ตเนอร์ร่วมกับผู้ประกอบการ SME ที่ผลิตอาหารและเครื่องดื่ม ขนาดย่อมและขนาดเล็กในประเทศไทย
      <br />
      คลิก <a target="_blank" href="/about">ที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม`,
      question2:
        'ผู้ประกอบการ SME ที่ผลิตอาหารและเครื่องดื่ม ขนาดย่อมและขนาดเล็ก ที่ท็อปส์ ท้องถิ่นต้องการ คุณสมบัติอย่างไร?',
      answer2: `
      • ผู้ประกอบการ SME ที่ผลิตอาหารและเครื่องดื่ม ขนาดย่อมและขนาดเล็ก มีพนักงานในธุรกิจได้ตั้งแต่ 1-50 คน และ SME มีรายได้ต่อปีไม่เกิน 100 ล้านบาท.
      <br />
      • ผู้ประกอบการ SME นี้ต้องเป็นผู้ผลิตสินค้าทั้งหมดหรือบางส่วนในประเทศไทย โดยโครงการของเราไม่ได้เปิดรับผู้ประกอบการที่นำเข้าสินค้า.
      <br />
      • ผู้ประกอบการ SME นี้ไม่จำเป็นต้องเป็นสมาชิกกับ สสว. (สำนักงานส่งเสริมวิสาหกิจขนาดกลางและขนาดย่อม)
      <br />
      • ผู้ประกอบการ SME นี้ สามารถเป็นได้ตั้งแต่...
      <br /> 
        <ol class="padding-left-5">1) บริษัท (บริษัทมหาชน หรือ บริษัทจำกัด)</p>
        <ol>2) ห้างหุ้นส่วน (ห้างหุ้นส่วนสามัญแบบไม่จดทะเบียน ห้างหุ้นส่วนสามัญแบบจดทะเบียน และห้างหุ้นส่วนจำกัด)</p>
        <ol>3) วิสาหกิจชุมชน (ขนาดเล็กและขนาดย่อม)</p>
        <ol>4) บุคคลธรรมดา</p>
      `,
      question3: 'ทำไมคุณจึงควรสมัครร่วมโครงการ ท็อปส์ ท้องถิ่น?',
      answer3: `พาร์ตเนอร์ของ ท็อปส์ ท้องถิ่น ที่มีเงื่อนไขเอ็กซ์คลูซีฟกับเรา จะได้รับผลประโยชน์มากมาย ได้แก่
      <br />
      <ul class="padding-left-5">
        <li>เข้าถึงช่องทางการขายของเซ็นทรัล ฟู้ด รีเทล ทุกช่องทาง</li>
        <li>ไม่ต้องจ่ายค่าแรกเข้าและลิสต์สินค้าในระบบ</li>
        <li>ได้รับเงินเร็วขึ้นใน 15 วัน</li>
        <li>ความช่วยเหลือด้านใบอนุญาต ใบรับรองต่างๆ และการเงิน</li>
        <li>ความช่วยเหลือด้านการตลาด</li>
      </ul>
      คลิก <a target="_blank" href="/what-you-get">ที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม.`,
      question4:
        'ท็อปส์ ท้องถิ่น มีกระบวนการรับซัพพลายเออร์แรกเข้าที่แตกต่างจากกระบวนการรับเข้าขายปกติอย่างไร?',
      answer4: `
      • โดยปกติแล้ว ซัพพลายเออร์สามารถติดต่อแผนกจัดซื้อทางช่องทางปกติ (เช่น โทรศัพท์ อีเมล ฯลฯ).
      <br />
      • เว็บไซต์ ท็อปส์ ท้องถิ่น เป็นศูนย์กลางที่เปิดกว้างสำหรับผู้ประกอบการ SME ที่ผลิตอาหารและเครื่องดื่ม ขนาดย่อมและขนาดเล็ก ที่สนใจสมัครเพื่อเข้าร่วมเป็นพาร์ตเนอร์กับเรา
      <br />
      • ในเว็บไซต์นี้ ผู้ประกอบการ SME สามารถนำเสนอข้อมูลที่เกี่ยวข้องและน่าสนใจเพื่อให้เราพิจารณาว่า คุณเหมาะสมหรือไม่สำหรับโครงการนี้ต่อไป ในขณะเดียวกันก็สามารถที่จะติดตามสถานะการสมัครได้ในที่ที่เดียว
      <br />
      • การที่เรามีแพลตฟอร์มหรือศูนย์กลางเดียว เพื่อรวบรวมข้อมูลต่างๆ ของซัพพลายเออร์ก็จะช่วยให้เราสามารถเห็นภาพของ SME ผู้สมัครแต่ละคนได้อย่างครบรอบด้าน และทำให้เราสามารถทำเวลาในขั้นตอนการพิจารณาได้ดีขึ้นกว่าเดิม
      <br />
      • คลิก <a target="_blank" href="/how-it-works">ที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม`,
      question5: 'เรากำลังมองหาอะไรและพาร์ตเนอร์แบบไหน?',
      answer5: `
      • เรากำลังตามหาสินค้าที่มีความโดดเด่น ไม่ว่าจะอยู่ในขั้นตอนการคิดค้นสินค้าช่วงใดก็ตาม ซึ่งเป็นสินค้าที่ผลิตโดยผู้ประกอบการ SME ที่ผลิตอาหารและเครื่องดื่ม ขนาดย่อมและขนาดเล็ก ในประเทศไทย
      <br />
      • ผู้ประกอบการ SME ผู้ผลิตอาหารและเครื่องดื่ม ขนาดย่อมและขนาดเล็กที่มีความตั้งใจและความฝันที่จะสร้างธุรกิจในฐานะพาร์ตเนอร์กับเรา
      <br />
      • คลิก <a target="_blank" href="/how-it-works">ที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม`,
      question6: 'คุณต้องกรอกและอัพโหลดเอกสารทุกอย่างที่อยู่ในใบสมัครออนไลน์หรือไม่?',
      answer6: `
      • เราเข้าใจว่าคุณอาจจะรู้สึกว่าต้องใช้เวลาและความพยายามในการกรอกใบสมัครพอสมควร แต่หากมีบางข้อที่คุณยังไม่มีข้อมูล คุณก็สามารถเว้นไว้ได้หรือติดต่อสอบถามเราได้
      <br />
      • คุณเพียงแค่กรอกข้อมูลลงในช่องต่างๆ ที่มีเครื่องหมายดอกจันทร์ (*)
      <br />
      • อย่างไรก็ตาม เราอยากให้คุณกรอกข้อมูลและแนบเอกสาร หลักฐาน และรูปต่างๆ ให้มากที่สุดเท่าที่ทำได้ เพื่อที่เราจะได้เห็นภาพของ SME ผู้สมัครแต่ละคนได้อย่างครบรอบด้าน และทำให้เราสามารถทำเวลาในขั้นตอนการพิจารณาได้ดีขึ้น
      <br />
      • เราอยากให้คุณไม่ลืมเสมอว่า เรากำลังตามหาสินค้าที่มีความโดดเด่น ไม่ว่าจะอยู่ในขั้นตอนการคิดค้นสินค้าช่วงใดก็ตาม และเป็นสินค้าที่ผลิตโดยผู้ประกอบการ SME ที่ผลิตอาหารและเครื่องดื่ม ขนาดย่อมและขนาดเล็กในประเทศไทย ถ้าคุณมีสินค้าที่ดีและโดดเด่นและความตั้งใจและความฝันที่จะสร้างธุรกิจ เราอยากเชิญให้คุณมาสมัครกับเราเพื่อโอกาสในการเป็นพาร์ตเนอร์กับเรา
      <br />
      • คลิก <a target="_blank" href="/how-it-works">ที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม`,
      question7: 'ใช้เวลานานเท่าไรในการพิจารณาใบสมัครของคุณ?',
      answer7: `
      เราตั้งใจที่จะพิจารณาใบสมัครของคุณให้เรียบร้อยในระยะเวลา 30 วันโดยเฉลี่ย ทั้งนี้ ขึ้นอยู่กับจำนวนของใบสมัครที่เข้ามาและความรวดเร็วในการติดต่อกลับของซัพพลายเออร์แต่ละราย นอกเหนือจากกรณีอื่นๆ
      <br />
      คลิก <a target="_blank" href="/how-it-works">ที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม`,
      question8: 'คุณสามารถยื่นใบสมัครด้วยสินค้ากี่รายการ?',
      answer8:
        'คุณสามารถยื่นใบสมัครด้วยสินค้ากี่รายการก็ได้ ทั้งนี้ เราขอแนะนำให้คุณอัพโหลดแค็ตตาล็อคสินค้าหรือรูปภาพสินค้าหลายๆ รายการลงใน “ใบสมัครส่วนภาพรวมสินค้า” เพื่อช่วยให้เราเห็นภาพรวมของสินค้าที่คุณผลิตอย่างครบถ้วนมากขึ้น และใน “ใบสมัครส่วนรายละเอียดสินค้า” เราขอแนะนำให้คุณนำเสนอสินค้าไฮไลท์ เพื่อให้เรารู้จักสินค้าหลักของคุณมากขึ้น',
      question9: 'พาร์ตเนอร์ของ ท็อปส์ ท้องถิ่น จะได้เข้าถึงช่องทางการขายใดได้บ้าง?',
      answer9:
        'ถ้าคุณได้รับเลือกเป็นพาร์ตเนอร์ของ ท็อปส์ ท้องถิ่น ที่มีเงื่อนไขเอ็กซ์คลูซีฟกับเรา คุณจะมีโอกาสเข้าถึงช่องทางการขายที่ทั้งคุณและทีมงานของเรามองว่าเหมาะสม โดยช่องทางที่เป็นไปได้มีตั้งแต่ ท็อปส์, ท็อปส์ ฟู้ด ฮอลล์, ท็อปส์ ไฟน์ ฟู้ด, ท็อปส์ คลับ และ แฟมิลี่มาร์ท ทั้งออฟไลน์และออนไลน์ และยังมีโอกาสต่อยอดถึงการผลิตสินค้าเพื่อแบรนด์ของ เซ็นทรัล ฟู้ด รีเทล, การขายแบบ B2B ระหว่างธุรกิจกันเอง และการส่งออก',
      question10: 'สินค้าของฉันอยู่ในประเภทสินค้าใด?',
      answer10: `
        <table class="terms-table">
          <tr>
            <th>ประเภทสินค้าอาหารและเครื่องดื่ม</th>
            <th>ตัวอย่างสินค้าในแต่ละประเภท</th>
          </tr>
          <tr>
            <td>ขนมขบเคี้ยว</td>
            <td>ขนมขบเคี้ยวทอดกรอบ / อาหารทะเลแห้งขบเคี้ยว / ถั่วขบเคี้ยว / ผลไม้อบแห้ง / เนื้อแห้งขบเคี้ยว / ช็อคโกแล็ต / ลูกอมท้องถิ่น / ขนมที่ช่วยทำให้ลมหายใจและปากหอม / หมากฝรั่ง / เยลลี่ / บิสกิตหรือขนมปังกรอบชนิดหวาน / แคร็กเกอร์หรือขนมปังกรอบ
            </td>
          </tr>
          <tr>
            <td>อาหารในบรรจุภัณฑ์</td>
            <td>
            เครื่องดื่มแบบชงดื่ม-ผงชงดื่ม / ผลิตภัณฑ์เพื่อสุขภาพ / สินค้าทาขนมปัง-น้ำสลัด / อาหารสำเร็จรูป อาหารพร้อมปรุง / บะหมี่กึ่งสำเร็จรูปและซุป
            </td>
          </tr>
          <tr>
            <td>ประกอบอาหาร</td>
            <td>
            ข้าว / เส้นที่ทำจากแป้ง / น้ำมัน / ซอสปรุงอาหาร / เครื่องปรุง / วัตถุดิบสำหรับทำขนมและของหวาน
            </td>
          </tr>
          <tr>
            <td>ผลิตภัณฑ์นมและอาหารเด็ก</td>
            <td>
            นมผงเด็ก / อาหารเด็ก / อาหารสำหรับมารดา
            </td>
          </tr>
          <tr>
            <td>เครื่องดื่ม</td>
            <td>
            เครื่องดื่มอัดลม / เครื่องดื่มเสริมประโยชน์ / ชากาแฟพร้อมดื่ม/ น้ำดื่ม / น้ำผลไม้ / เครื่องดื่มชนิดเข้มข้น
            </td>
          </tr>
          <tr>
            <td>อาหารสดในบรรจุภัณฑ์</td>
            <td>
            นม / โยเกิร์ต / ไอศกรีม / เครื่องดื่มเย็น / อาหารแช่แข็ง
            </td>
          </tr>
          <tr>
            <td>ผลิตภัณฑ์สด</td>
            <td>
            ชีส / เนื้อสดแปรรูปต่างๆ / ไข่
            </td>
          </tr>
          <tr>
            <td>เบเกอรี่</td>
            <td>
            ขนมปัง / เค้ก
            </td>
          </tr>
          <tr>
            <td>เนื้อสด</td>
            <td>
            เนื้อวัว / เนื้อหมู / เนื้อสัตว์ปีก / เนื้อพิเศษอื่นๆ
            </td>
          </tr>
          <tr>
            <td>อาหารทะเลสด</td>
            <td>
            ปลาสด / อาหารทะเลสดอื่นๆ / อาหารทะเลแห้ง
            </td>
          </tr>
          <tr>
            <td>ผักผลไม้สด</td>
            <td>
            ผัก / ผลไม้ / ต้นไม้และดอกไม้
            </td>
          </tr>
        </table>
        `,
      question11: `มีค่าสมัครเข้าร่วมโครงการท็อปส์ ท้องถิ่นหรือไม่?`,
      answer11: `ไม่มี หนึ่งในความตั้งใจหลักของโครงการท็อปส์ ท้องถิ่นนี้ คือ ความตั้งใจที่จะสนับสนุน SME ให้ได้รับความช่วยเหลือเรื่องค่าธรรมเนียมหรือค่าใช้จ่ายต่างๆ ที่อาจจะต้องจ่ายในช่วงเริ่มของธุรกิจ ดังนั้น จึงเป็นเหตุผลที่คุณสามารถสมัครเข้าร่วมโครงการของเราได้แบบฟรีๆ ไม่มีค่าใช้จ่ายเพิ่มเติมในการส่งใบสมัครเลย ขอเพียงคุณมีอินเตอร์เน็ต อุปกรณ์ (มือถือหรือคอมพิวเตอร์) สักเครื่อง และความฝัน เท่านั้นเอง ก็สมัครได้แล้ว`,
      question12: `ต้องมีใบอนุญาตจาก อ.ย. ก่อนหรือเปล่า ถึงจะสมัครได้?`,
      answer12: `คุณไม่จำเป็นต้องมีใบอนุญาตจาก อ.ย. เพื่อที่จะสมัครเข้าร่วมโครงการท็อปส์ ท้องถิ่น เราเปิดกว้างสำหรับ SME ที่มีความพร้อมไม่เท่ากัน ไม่ว่าจะมากหรือน้อย แม้จะเป็นแค่บุคคลธรรมดา ไม่มีการจดทะเบียนเป็นบริษัทก็สมัครได้ สิ่งที่เรามองหา คือ ขอเพียงคุณมีสินค้าที่ยอดเยี่ยม และเราก็ชอบที่จะได้เริ่มคุยกับ supplier ตั้งแต่ช่วงแรกๆ ของการพัฒนาสินค้า เพราะคุณและเราสามารถร่วมมือกันที่จะสร้างสรรค์ไอเดียสินค้าให้ออกมาเฉียบมากกว่าเดิม รวมถึงไอเดียของแพคเกจจิ้งด้วย ซึ่งถ้าทำได้เช่นนี้ตั้งแต่ต้นแล้ว จะทำให้กระบวนการนำสินค้าเข้าระบบขายเป็นไปได้อย่างเร็วมากขึ้นและทำให้สินค้าของคุณมีโอกาสที่จะเจิดจรัสบนเชลฟ์ได้อย่างดีที่สุด`,
      question13: `ถ้าฉันขายสินค้ากับท็อปส์หรือร้านค้าในเครือเซ็นทรัล ฟู้ด รีเทล กรุ้ปอยู่แล้วและสนใจโครงการท็อปส์ ท้องถิ่น ฉันยังสมัครได้หรือไม่?`,
      answer13: `ถ้าคุณสนใจ ยังไม่ต้องส่งใบสมัครเพิ่ม ขอเพียงอีเมลหาเราที่ <a href="mailto:topstongtin@tops.co.th">topstongtin@tops.co.th</a> ก่อน โดยแจ้งชื่อบริษัท รายละเอียดของสินค้าที่กำลังขายอยู่กับเรา และข้อมูลการติดต่อ (เช่น เบอร์โทรศัพท์) และเราจะติดต่อคุณกลับโดยเร็วที่สุด`,
      question14: `ฉันควรติดต่อใคร ถ้าเกิดมีปัญหาทางเทคนิคในการใช้เว็บไซต์?`,
      answer14: `หากคุณมีปัญหาทางเทคนิคใดๆ ก็ตามขณะกรอกใบสมัคร สามารถอีเมลหาเราได้ที่ <a href="mailto:topstongtin@tops.co.th">topstongtin@tops.co.th</a> หรือส่งข้อความหาเราทาง inbox ของ Facebook <a target="_blank" href="https://www.facebook.com/topstongtin">https://www.facebook.com/topstongtin</a>`,
      question15: `การค้าขายกับ supplier ของท็อปส์ ท้องถิ่น เป็นแบบซื้อขาดหรือฝากขาย?`,
      answer15: `ถ้าหากคุณสมัครเข้าร่วมโครงการท็อปส์ ท้องถิ่น แล้วสินค้าของคุณได้รับคัดเลือก ท็อปส์ ท้องถิ่นจะซื้อสินค้าของคุณแบบซื้อขาดเลย โดยจะมีการจ่ายเงินให้กับ supplier ภายใต้เครดิตเทอม 15 วัน ทั้งนี้ ในโครงการท็อปส์ ท้องถิ่น จะไม่มีข้อตกลงแบบฝากขาย เพราะเราต้องการให้ข้อตกลงทางการค้าต่างๆ ง่าย ไม่ยุ่งยากซับซ้อน เพื่อที่คุณจะได้ใช้พลังไปกับการสร้างสรรค์สินค้าที่ดีที่สุดและเราก็จะตั้งใจช่วยดูแลเรื่องการขายให้กับคุณเอง`
    },
    side: {
      more_question: 'หากคุณมีคำถามเพิ่มเติมเรื่องใดหรือพบปัญหาทางเทคนิค ขณะกรอกใบสมัคร',
      contact_us: 'ติดต่อเราได้ที่',
      address: `
      เซ็นทรัล แจ้งวัฒนะ ออฟฟิศ ทาวเวอร์ ชั้นที่ 12,15-18 <br />
      99/9 หมู่ที่ 2 ถนนแจ้งวัฒนะ ตำบลบางตลาด อำเภอปากเกร็ด จ.นนทบุรี
      `
    }
  },
  navigator: {
    who_we_are: 'ทำความรู้จักเรา',
    what_you_get: 'คุณจะได้อะไร',
    how_it_works: 'ต้องทำอะไรบ้าง',
    faq: 'คำถามที่พบบ่อย',
    login: 'เข้าสู่ระบบ',
    register: 'สมัคร'
  },
  footer: {
    information: {
      title: 'ข้อมูลโครงการ',
      how_it_works: 'ต้องทำอะไรบ้าง',
      prepare: 'ข้อมูลที่ต้องเตรียม',
      what_you_get: 'คุณจะได้อะไร'
    },
    support: {
      title: 'ความช่วยเหลือ',
      faq: 'คำถามที่พบบ่อย',
      contact: 'ติดต่อเรา'
    },
    company: {
      title: 'เกี่ยวกับเรา',
      about: 'ทำความรู้จักเรา',
      legal: 'ข้อกำหนดและเงื่อนไข'
    },
    line: 'Tops ท้องถิ่น รวมของดีถิ่นไทย SME ไปไกลระดับท็อป​​'
  },
  about: {
    title: 'ทำความรู้จักเรา',
    text1: `
    คุณกำลังมองหาช่องทางการเติบโตในธุรกิจเล็กๆ ของตัวเอง และขายสินค้าในซูเปอร์มาร์เก็ตชั้นนำอยู่หรือเปล่า คุณเจอปัญหาเรื่องการขอใบอนุญาต ค่าธรรมเนียมแรกเข้า และระบบที่ซับซ้อนในการขอเข้าขายสินค้าในห้างสรรพสินค้าอยู่รึเปล่า
    <br />
    <br />
    “ท็อปส์ ท้องถิ่น” นี่แหละ “ใช่” สำหรับคุณ! เรา คือ ทีมงานมืออาชีพของบริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด (Central Food Retail Co. Ltd.) ซึ่งมีร้านค้าชั้นนำ เช่น ท็อปส์ มาร์เก็ต และ เซ็นทรัล ฟู้ด ฮอลล์ ซึ่งจัดตั้งขึ้นเพื่อสรรหา SME ผู้ผลิตอาหารและเครื่องดื่มที่ดีที่สุดทั่วประเทศไทย มาร่วมเป็นพาร์ตเนอร์ทางธุรกิจกับเรา
    `,
    text2: `
    แนวคิดของ ท็อปส์ ท้องถิ่น เกิดจากการแข่งขันทางออนไลน์ของกลุ่มพนักงานในบริษัทเครือ เซ็นทรัล กรุ๊ป (Central Group) และทีมจากเซ็นทรัล ฟู้ด รีเทล ซึ่งเป็นกลุ่มคนผู้หลงรักในอาหารและชุมชน ก็ได้รางวัลชนะเลิศในครั้งนี้ โดยแรงบันดาลใจมาจากความปรารถนาที่จะเห็นผู้ผลิตอาหารและเครื่องดื่มในประเทศที่แม้จะเป็นขนาดเล็กแต่มีศักยภาพสูง ได้เติบโตยิ่งขึ้นไปในเวทีระดับประเทศ
    <br /> <br />
    จากนั้น ท็อปส์ ท้องถิ่น จึงพัฒนาเป็นทีมที่ทำงานด้วยจิตวิญญาณแบบสตาร์ตอัพที่มีความคล่องตัวสูง ด้วยการทุ่มเททำงานและความมุ่งมั่นตั้งใจผลักดันให้ SME ผู้ผลิตอาหารและเครื่องดื่มที่ยอดเยี่ยมในประเทศเติบโตอย่างมั่นคง โดย SME ผู้ผลิตที่เรามองหามีความหลากหลาย ซึ่งรวมถึง ผู้ที่ลงทะเบียนเป็น SME กับรัฐบาลและผู้ที่ไม่ได้ลงทะเบียน บริษัท ชุมชน เกษตรกร ผู้ผลิตสินค้า OTOP และผู้ผลิตขนาดเล็ก
    <br /> <br />
    เราต้องการสร้างพันธมิตรและความร่วมมือทางการค้ากับ SME ผู้ผลิตอาหารและเครื่องดื่มที่ยอดเยี่ยม เพราะเราเชื่อว่าความสำเร็จของธุรกิจเล็กๆ จะเป็นกุญแจสำคัญให้เศรษฐกิจของประเทศมีรากฐานที่ดี และมีความเข้มแข็งและความหลากหลาย
    <br /><br />
    นี่คือเหตุผลว่าทำไมโครงการของเราจึงได้รับการออกแบบมาให้แตกต่างจากโครงการที่อื่นๆ ที่เกี่ยวข้องกับ SME 
    <br /><br />
    ถ้าคุณสนใจ เพียงกรอกใบสมัครออนไลน์ อัพโหลดข้อมูลต่างๆ แล้วรอเราติดต่อกลับ หากคุณยังไม่มีคำตอบครบทุกข้อในใบสมัคร ก็ไม่ต้องกังวลไป เพราะสิ่งสำคัญที่สุดคือขอแค่คุณมีผลิตภัณฑ์อาหารและเครื่องดื่มที่ดีและโดดเด่น พร้อมความฝันและความตั้งใจในการขยายธุรกิจให้เติบโตไปด้วยกันกับเรา
    `,
    title_unique: 'อะไรทำให้ ท็อปส์ ท้องถิ่น แตกต่าง? ',
    text_unique: `
    <ul class="padding-left-4">
      <li><span class="font-weight-bold">ติดต่อกับเราได้ง่ายกว่าเดิม:</span> เราตั้งใจให้คู่ค้าของเราสามารถเข้าถึงเราได้ง่ายขึ้น ผ่านเว็บไซต์ของเรา ซึ่งเป็นศูนย์กลางเดียวให้ SME ที่ค้าขายกับเราใช้ในการติดต่อและนำเสนอสินค้ากับเรา</li>
      <li><span class="font-weight-bold">สามารถออกแบบการสมัครเข้าโครงการได้ด้วยตัวเอง:</span> SME สามารถที่จะเข้าระบบไปจัดการและปรับเปลี่ยนข้อมูลการสมัคร (และกดสมัครเมื่อพร้อม) ได้ตามต้องการและเมื่อไรก็ได้ตามที่ตัวเองสะดวก</li>
      <li><span class="font-weight-bold">ดูสถานะการสมัครได้ตลอด:</span> SME สามารถที่จะติดตามสถานะการสมัครเข้าโครงการได้ตลอดเวลา เพื่อที่จะได้เห็นภาพว่าการพิจารณาใบสมัครของตัวเองอยู่ในขั้นตอนใดแล้ว</li>
      <li><span class="font-weight-bold">ได้รับความช่วยเหลือและคำแนะนำตั้งแต่ต้นจนจบ:</span> โครงการของเราจะพา SME เดินไปด้วยกันตั้งแต่เริ่มจนจบกระบวนการ ไม่ว่าจะเป็นเรื่องคำแนะนำในการขอใบอนุญาตจาก อ.ย. ไปจนถึงการทำการตลาดให้กับสินค้าเพื่อขายต่อไป</li>
      <li><span class="font-weight-bold">ได้รับเงินจากการขายกับเราเร็วขึ้นและเข้าถึงความช่วยเหลือทางการเงินได้ง่ายขึ้น: </span> พาร์ตเนอร์ของ ท็อปส์ ท้องถิ่น จะได้รับสิทธิประโยชน์ทางการเงินต่างๆ ซึ่งรวมถึงรอบเครดิตเทอมที่สั้นลง ได้เงินเร็วขึ้น และการแนะนำ SME ให้รู้จักกับพาร์ตเนอร์ทางการเงินต่างๆ</li>
    </ul>`
  },
  how_it_work: {
    title: 'ต้องทำอะไรบ้าง?',
    days: 'วัน',
    things_to_prepare: {
      title: 'ข้อมูลที่ต้องเตรียม',
      describe:
        'ก่อนที่คุณจะสมัครออนไลน์ เราขอแนะนำให้คุณเตรียมข้อมูลและเอกสารต่างๆ ตามนี้ (เพื่อเป็นประโยชน์ต่อการกรอกข้อมูล) คุณไม่จำเป็นจะต้องให้ข้อมูลหรือเอกสารครบทุกอย่าง แต่กรุณาให้ข้อมูลและเอกสารมากที่สุด เพื่อให้เราเห็นภาพครบที่สุดและเพิ่มโอกาสในพิจารณาเสร็จสิ้นรวดเร็วยิ่งขึ้น',
      alert: `
        คุณไม่จำเป็นต้องมีเอกสารทุกอย่างครบนะคะ<br />
        <strong>เช่น ถ้ายังไม่ได้ขอ อ.ย. หรือใบอนุญาตสถานที่ผลิต ก็ยังสมัครได้ค่ะ</strong><br />      
        สิ่งที่โครงการมองหา คือ สินค้าที่อร่อยและโดดเด่น และเราพร้อมที่จะช่วยให้คำแนะนำคุณเพื่อพัฒนาสินค้าต่อไป
      `,
      company_doc: 'เอกสารเกี่ยวกับบริษัท',
      license_cert: 'เอกสารใบรับรองการผลิต',
      product_doc: 'เอกสารเกี่ยวกับสินค้า',
      content: {
        content_01_1: 'จำนวนพนักงาน ข้อมูลรายได้ และข้อมูลช่องทางการขายในปีที่ผ่านมา ',
        content_01_2: 'ข้อมูลที่อยู่ของบริษัทและสถานที่ผลิต',
        content_01_3: 'รูปสถานที่ผลิต เช่น สวน, ฟาร์มปศุสัตว์ และฟาร์มประมง',
        content_01_4: 'บัตรประจำตัวประชาชน',
        content_01_5: 'เอกสารสำคัญที่เกี่ยวข้องกับบริษัท เช่น',
        example_01: '• หนังสือรับรองบริษัท',
        example_02: '• หนังสือรับรองจัดตั้งห้างหุ้นส่วนประเภทต่างๆ ',
        example_03: '• หนังสือสำคัญแสดงการจดทะเบียน ท.ว.ช. 2 และ ท.ว.ช.3. ',
        example_04: '• ใบทะเบียนภาษีมูลค่าเพิ่ม (ภ.พ.20)',
        example_05: '• หนังสือรับรองการเป็น SME จาก สสว.',
        content_01_6: 'เอกสารแนะนำบริษัทและแคตตาล็อกสินค้า',
        content_01_7: 'รูปสินค้าและคุณลักษณะของสินค้าต่างๆ',
        content_01_8: 'ใบรับรองด้านเกษตรกรรมต่างๆ เช่น ออร์แกนิค, GAP ฯลฯ',
        content_01_9: 'ใบรับรองด้านอุตสาหกรรมต่างๆ เช่น GMP, HACCP ฯลฯ',
        content_01_10: 'ใบอนุญาตและใบรับรองต่างๆ เช่น อ.ย., OTOP ฯลฯ',
        content_02_01: 'ใบรับรองด้านเกษตรกรรมต่างๆ เช่น ออร์แกนิค, GAP ฯลฯ',
        content_02_02: 'ใบรับรองด้านอุตสาหกรรมต่างๆ เช่น GMP, HACCP ฯลฯ',
        content_03_01: 'เอกสารแนะนำบริษัทและแคตตาล็อกสินค้า',
        content_03_02: 'รูปสินค้าและคุณลักษณะของสินค้าต่างๆ',
        content_03_03: 'ใบอนุญาตและใบรับรองต่างๆ เช่น อ.ย., OTOP ฯลฯ'
      }
    },
    subtitle_1: 'ตรวจสอบคุณสมบัติ SME ที่โครงการต้องการ',
    answer1: `
    <div class="text-align-left">
      • ท็อปส์ ท้องถิ่น เป็นโครงการที่ตั้งขึ้นมาเพื่อ SME อาหารและเครื่องดื่มขนาดย่อมและขนาดเล็กเป็นหลัก ที่มีพนักงานไม่เกิน 50 คน และมีรายได้ต่อปีไม่เกิน 100 ล้านบาท<br />
      • ท็อปส์ ท้องถิ่น มีไว้เพื่อ SME ผู้ผลิตสินค้าทั้งหมดหรือบางส่วนในประเทศไทย โดยโครงการของเราไม่ได้เปิดรับผู้ประกอบการที่นำเข้าสินค้า <br />
      • SME ที่สนใจสมัครเข้าร่วมโครงการ ท็อปส์ ท้องถิ่น ไม่จำเป็นต้องเป็นสมาชิกกับ สสว. (สำนักงานส่งเสริมวิสาหกิจขนาดกลางและขนาดย่อม)
    <div>`,
    subtitle_2: 'กรอกใบสมัครออนไลน์และกด “ยืนยันการยื่นเอกสาร” ',
    answer2:
      'หลังจากที่คุณลงทะเบียนบัญชีผู้ใช้ใหม่แล้ว คุณสามารถเข้าระบบเพื่อแก้ไขใบสมัครออนไลน์ของคุณได้จนกว่าคุณจะกด “ยืนยันการยื่นเอกสาร”',
    subtitle_3: 'ใบสมัครส่งให้ทีม ท็อปส์ ท้องถิ่น พิจารณาและรอเราติดต่อกลับ',
    answer3_timeline: 'ระยะเวลาโดยประมาณ',
    answer3_timeline_remark:
      'ทั้งนี้ขึ้นอยู่กับจำนวนของใบสมัคร รวมถึงการตอบรับที่รวดเร็วของผู้สมัคร นอกเหนือจากเหตุผลอื่นๆ',
    answer3_01: 'A) พิจารณาใบสมัครออนไลน์เบื้องต้น',
    answer3_02: 'B) พิจารณาราคาขายและลองชิมสินค้าตัวอย่าง',
    answer3_03: 'C) ตรวจสอบโดยเจ้าหน้าที่ตรวจสอบคุณภาพ',
    answer3_04: 'D) พิจารณาการสมัครรอบสุดท้ายโดยคณะกรรมการ',
    subtitle_4:
      'SME ที่มีคุณสมบัติตรงได้รับคัดเลือกให้เป็นพาร์ตเนอร์เข้าร่วมงานกับเราด้วยเงื่อนไขเอ็กซ์คลูซีฟ โดยเราจะเตรียมนำสินค้าเข้าระบบการขายต่อไป '
  },
  what_you_get_page: {
    title: 'คุณจะได้อะไร?',
    content1_1: 'ได้รับเงินเร็วขึ้นใน 15 วัน',
    content1_2:
      'เงื่อนไขการชำระเงินของเรารวดเร็วกว่า เพียง 15 วัน เท่านั้น (ปกติ 30 วัน) เพราะเราเข้าใจว่าคุณต้องการเงินเพื่อหมุนเวียนธุรกิจให้เติบโตอย่างต่อเนื่อง',
    content2_1: 'ไม่ต้องจ่ายค่าแรกเข้าและลิสต์สินค้าในระบบ',
    content2_2:
      'คุณจะได้รับงดเว้นค่าธรรมเนียมแรกเข้าและค่าลิสต์สินค้าในระบบที่ปกติซัพพลายเออร์ทั่วไปต้องถูกเก็บ',
    content3_1: 'เข้าถึงช่องทางการขายของเซ็นทรัล ฟู้ด รีเทล ทุกช่องทาง',
    content3_2:
      'เมื่อคุณได้รับคัดเลือกเข้าโครงการ ท็อปส์ ท้องถิ่น คุณจะได้เข้าถึงช่องทางการขายผ่าน ท็อปส์, ท็อปส์ ฟู้ด ฮอลล์, ท็อปส์ เดลี่ และท็อปส์ ออนไลน์ และมีโอกาสต่อยอดถึงการผลิตสินค้าเพื่อแบรนด์ของ เซ็นทรัล ฟู้ด รีเทล, การขายแบบ B2B ระหว่างธุรกิจกันเอง และการส่งออก',
    content4_1: 'ความช่วยเหลือด้านใบอนุญาต ใบรับรองต่างๆ และการเงิน',
    content4_2: `
      <p>เราพร้อมเดินเคียงข้างคุณ โดยจะให้คำแนะนำเรื่องการยื่นขอ อ.ย. และกระบวนการต่างๆ ในการขอใบอนุญาต เพื่อยกระดับสินค้าของคุณ และเมื่อคุณต้องการเงินทุน (เงินกู้) เราสามารถแนะนำพารต์เนอร์สถาบันการเงินที่ไม่ได้อยู่ในกลุ่มของเซ็นทรัล กรุ๊ป ให้คุณได้</p>
      <p style="margin:20px 0 5px; font-family: PSL Kanda Extra Pro; font-weight: 400;">พาร์ทเนอร์ของเรา:</p>
      <div style="display:inline-flex;gap:20px;align-items:center;">
        <img src="/imgs/logo-scb.png" />
        <img src="/imgs/logo-ttb.png" />
        <img src="/imgs/logo-investree.png" />
      </div>
    `,
    content5_1: 'ความช่วยเหลือด้านการตลาด',
    content5_2:
      'เราช่วยคุณทำการตลาดให้แบรนด์ของคุณในช่องต่างๆ ทั้งออนไลน์และออฟไลน์ในเครือของเรา โดยเราตั้งใจจะให้สินค้าดูโดดเด่นบนชั้นวางขาย และช่วยเล่าเรื่องราวสินค้าของคุณให้ถูกกล่าวขานในกลุ่มลูกค้าเป้าหมายต่อไป'
  },
  form_agreement: {
    gratitude: 'ขอบคุณที่สมัครเข้าร่วมโครงการท็อปส์ ท้องถิ่น',
    check_again: 'กรุณาเช็กกล่องด้านล่างนี้เพื่อให้กระบวนการสมัครของคุณเสร็จสิ้นสมบูรณ์',
    agreement: `ฉันตกลงตาม <a target="_blank" href="/terms">ข้อกำหนดและเงื่อนไข</a> และรับทราบ <a target="_blank" href="/terms">นโยบายความเป็นส่วนตัว</a>`,
    receive:
      'ฉันต้องการรับสิทธิพิเศษและโปรโมชั่น รวมถึงข่าวสารจากท็อปส์ ท้องถิ่น, กลุ่มเซ็นทรัลและพันธมิตร ตามที่ระบุไว้ในนโยบายความเป็นส่วนตัว'
  },
  forgot_password: {
    title: 'ลืมรหัสผ่าน?',
    subtitle: 'ป้อนที่อยู่อีเมลของคุณด้านล่างและเราจะส่งอีเมลพร้อมลิงก์เพื่อรีเซ็ตรหัสผ่านของคุณ',
    complete: 'อ่านอีเมลของคุณ',
    error: 'ระบบผิดพลาด'
  },
  reset_password: {
    title: 'รีเซ็ตรหัสผ่าน',
    subtitle: 'รหัสผ่านของคุณถูกรีเซ็ต'
  },
  cookie_banner: {
    text: 'เว็บไซต์นี้มีการใช้คุกกี้ โปรดยอมรับนโยบายคุกกี้เพื่อประสบการณ์การใช้บริการที่ดีที่สุดของท่าน ท่านสามารถศึกษาวิธีการตั้งค่าการควบคุมคุกกี้ของท่านผ่าน<a href="/terms" target="_blank">นโยบายการใช้คุกกี้ของเราที่นี่</a>',
    accept: 'ยอมรับ',
    decline: 'ปิดหน้าต่าง'
  }
};
