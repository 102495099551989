import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import reviewService from 'api/services/ReviewService';
import CustomButton from 'components/shared/Button';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { customErrorResponse } from 'utils/customErrorResponse';

enum Process {
  RECEIVED = 'received',
  REVIEW = 'review',
  SAMPLES = 'samples_and_pricing',
  VALIDATION = 'validation',
  FINAL = 'final_decision'
}

const useStyles = makeStyles((theme: Theme) => ({
  textArea__placeholder: {
    padding: theme.spacing(2),
    fontFamily: 'PSL Kanda Pro',
    fontWeight: '700',
    fontSize: '1.5rem'
  }
}));

export const getDetails = (field: string, created_at: any, t: any) => {
  switch (field) {
    case Process.RECEIVED:
      return {
        field: `${t('application.application_status_value.received')} (${dayjs(created_at).format(
          'MM/DD/YYYY'
        )})`,
        details: `Submitted ${dayjs(created_at).format('MM/DD/YYYY')}`
      };
    case Process.REVIEW:
      return {
        field: t('application.application_status_value.review'),
        details: `Crosscheck and document verification`
      };
    case Process.SAMPLES:
      return {
        field: t('application.application_status_value.samples'),
        details: `Preliminary Samples and Pricing`
      };
    case Process.VALIDATION:
      return {
        field: t('application.application_status_value.validation'),
        details: `On site supplier validation`
      };
    case Process.FINAL:
      return {
        field: t('application.application_status_value.final'),
        details: `Final Selection Panel and Decision`
      };
    default:
      return {
        field: '',
        deatails: ''
      };
  }
};

const UpdateReviewTable = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();

  const [notes, setNotes] = useState<string>('');

  const { data, isLoading, refetch } = useQuery(
    ['review', id],
    () => reviewService.getReviewById(id),
    {
      select: (data) => {
        const { application_status, id, created_at, notes } = data;
        delete application_status.id;

        const mapData = [];
        for (const key in application_status) {
          const { field, details } = getDetails(key, created_at, t);
          mapData.push({
            key,
            field,
            details,
            value: application_status[key]
          });
        }

        return {
          id,
          reviews: mapData,
          application_status,
          notes
        };
      }
    }
  );

  const { mutate, isLoading: submitting } = useMutation(
    (body: any) => reviewService.updateStatusById(id, body),
    {
      onError: (error: any) => {
        toast.error(customErrorResponse(error));
      },
      onSuccess: () => {
        refetch();
      }
    }
  );

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="primary" />
      </Box>
    );
  }

  if (!isLoading && !data) {
    return <Navigate to="/404" />;
  }

  const handleCheckedChange = (key: string, value: boolean) => {
    mutate({
      application_status: {
        ...data.application_status,
        [key]: value
      }
    });
  };

  return (
    <>
      <Box p={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={30} align="center">
                  PROCESS
                </TableCell>
                <TableCell width={30} align="center">
                  DETAILS
                </TableCell>
                <TableCell width={30} align="center">
                  STATUS
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ||
                (submitting && (
                  <Backdrop sx={{ color: '#fff', zIndex: 20 }} open={true} invisible={true}>
                    <CircularProgress color="primary" />
                  </Backdrop>
                ))}
              {data.reviews.map((row) => (
                <TableRow key={row.key}>
                  <TableCell align="left">{row.field}</TableCell>
                  <TableCell align="left">{row.details}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={row.value}
                      onChange={() => handleCheckedChange(row.key, !row.value)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box p={3}>
        <FormControl fullWidth={true}>
          <TextareaAutosize
            placeholder={data.notes ?? 'Notes ...'}
            defaultValue={data.notes}
            minRows={5}
            className={classes.textArea__placeholder}
            onChange={(event) => setNotes(event.target.value)}
          />
        </FormControl>
      </Box>
      <Box pb={3} display="flex" justifyContent="center">
        <CustomButton
          loading={submitting}
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            color: '#fff'
          }}
          text={t('form.save')}
          onClick={() => {
            mutate({
              notes
            });
          }}
        />
      </Box>
    </>
  );
};

export default UpdateReviewTable;
