import { IAspiration } from 'types/Aspiration';
import * as Yup from 'yup';

export const initialAspirationValues: IAspiration = {
  selling_places: [],
  aspirations: '',
  assistance: [],
  questions: '',
  heard_from: '',
  other_assistance: {
    is_checked: false,
    value: null
  },
  other_selling_places: {
    is_checked: false,
    value: null
  }
};

export const aspirationSchema = Yup.object({
  selling_places: Yup.array().min(1, 'choose_option'),
  aspirations: Yup.string().required('required'),
  assistance: Yup.array().notRequired(),
  questions: Yup.string().notRequired(),
  heard_from: Yup.string().required('required'),
  other_selling_places: Yup.object({
    is_checked: Yup.boolean().nullable().notRequired(),
    value: Yup.string().max(225, 'max_characters_1').nullable().notRequired()
  })
    .nullable()
    .notRequired(),
  other_assistance: Yup.object({
    is_checked: Yup.boolean().nullable().notRequired(),
    value: Yup.string().max(225, 'max_characters_1').nullable().notRequired()
  })
    .nullable()
    .notRequired()
});

export const interestedPlaceOptions = [
  {
    id: 6,
    label: 'aspiration.place.wholeSale',
    value: 'Wholesale / HoReCa'
  },
  {
    id: 7,
    label: 'aspiration.place.privateLabel',
    value: 'Private Label'
  },
  {
    id: 8,
    label: 'aspiration.place.export',
    value: 'Export'
  }
];

export const assistanceAreaOptions = [
  { id: 1, label: 'aspiration.assistance.place.finane', value: 'Finance / Loan' },
  { id: 2, label: 'aspiration.assistance.place.license', value: 'Licensing for Production' },
  { id: 3, label: 'aspiration.assistance.place.fda', value: 'FDA Registration / Quality Assurance' }
];

export const heardFromOptions = [
  {
    id: 11,
    value: 'Tops Tongtin Facebook ad',
    label: 'aspiration.heardFromOptions.tongtin_facebook'
  },
  {
    id: 12,
    value: 'Tops Tongtin Instagram',
    label: 'aspiration.heardFromOptions.tongtin_instagram'
  },
  {
    id: 13,
    value: 'Tops Tongtin Media in Tops Market/ Central Food Hall stores',
    label: 'aspiration.heardFromOptions.tongtin_media'
  },
  {
    id: 14,
    value: 'Tops Online website',
    label: 'aspiration.heardFromOptions.online_website'
  },
  {
    id: 15,
    value: 'Tops Thailand Facebook',
    label: 'aspiration.heardFromOptions.facebook'
  },
  {
    id: 16,
    value: 'Tops Thailand Instagram',
    label: 'aspiration.heardFromOptions.instagram'
  },
  {
    id: 17,
    value: 'Google ads',
    label: 'aspiration.heardFromOptions.google'
  },
  {
    id: 18,
    value: 'Friends/ Word of mouth',
    label: 'aspiration.heardFromOptions.friends'
  },
  {
    id: 19,
    value: 'Events in Central Department store area',
    label: 'aspiration.heardFromOptions.event'
  },
  {
    id: 20,
    value: 'Roadshows in your area',
    label: 'aspiration.heardFromOptions.roadShows'
  },
  {
    id: 21,
    value: 'Radio',
    label: 'aspiration.heardFromOptions.radio'
  },
  {
    id: 22,
    value: 'Newspaper/ Magazine',
    label: 'aspiration.heardFromOptions.news'
  },
  {
    id: 23,
    value: 'Networking events, e.g. governmental events, Thai Chamber of Commerce events',
    label: 'aspiration.heardFromOptions.netWorking'
  },
  {
    id: 24,
    value: 'Tops Local Sourcing Team',
    label: 'aspiration.heardFromOptions.tops_local_sourcing'
  }
];
