import _ from 'lodash';

export const checkObjectPropertyLength = (obj: any) => {
  let found = false;
  for (const key in obj) {
    if (!_.isEmpty(obj[key])) {
      found = true;
      break;
    }
  }

  return found;
};
