export enum HeaderField {
  DATE_SUBMITTED = 'created_at',
  MODIFIED_DATE = 'updated_at',
  COMPANY_NAME = 'company_name',
  USER_NAME = 'user_name',
  EMAIL_ADDRESS = 'email',
  PHONE = 'phone_number',
  REVENUE = 'revenue',
  PROVINCE = 'province',
  PRODUCT_TYPE = 'product_type',
  FDA = 'has_fda_certification',
  STATUS = 'status'
}

export enum Operator {
  CONTAINS = 'contains',
  EQUAL = 'equals',
  IS = 'is',
  IS_ANY_OF = 'isAnyOf',
  IS_NOT = 'not',
  AFTER = 'after',
  BEFORE = 'before'
}

export enum Table {
  COMPANY = 'company',
  REGISTRAION = 'registration',
  PRODUCT = 'product',
  USER = 'user',
  LICENSE = 'license'
}
