import React, { createContext, useContext, useState } from 'react';

interface ReviewContextType {
  isReviewing: boolean;
  userReviewing: number;
  changeReviewing: (state: boolean, userId: number) => void;
  openModal: boolean;
  handleOpenModal: (state: boolean) => void;
}

const initalAuthContext: ReviewContextType = {
  isReviewing: false,
  userReviewing: -1,
  changeReviewing: () => null,
  openModal: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleOpenModal: (state: boolean) => null
};

const ReviewContext = createContext<ReviewContextType>(initalAuthContext);

export const ReviewProvider: React.FC = ({ children }) => {
  const [isReviewing, setIsReviewing] = useState<boolean>(false);
  const [userReviewing, setUserReviewing] = useState<number>(-1);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const changeReviewing = (state: boolean, userId: number) => {
    setUserReviewing(userId);
    setIsReviewing(state);
  };

  const handleOpenModal = (state: boolean) => {
    setOpenModal(state);
  };

  return (
    <ReviewContext.Provider
      value={{ isReviewing, changeReviewing, userReviewing, openModal, handleOpenModal }}>
      {children}
    </ReviewContext.Provider>
  );
};

export const useReview = () => {
  return useContext(ReviewContext);
};
