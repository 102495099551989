import { Avatar, Box, Container, Grid, Typography } from '@mui/material';
import { useValues } from 'hooks/values/useValues';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useStyles from './whatYouGetStyles';

const WhatYouGetPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [content] = useValues();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.contact__container}>
      <Helmet>
        <title>Tongtin - What You Get</title>
      </Helmet>
      <Box>
        <Container maxWidth="lg" className={classes.contact__box__container}>
          <Typography className={classes.title__text} component={'h2'} variant="h2" color="primary">
            {t('what_you_get_page.title')}
          </Typography>
          <Grid
            container
            className={classes.content__wrapper}
            columnSpacing={{ sx: 0, md: 9 }}
            rowSpacing={{ sx: 6, md: 8 }}>
            {content.map(({ title, detail, images, id }) => {
              return (
                <WhatYouGetContent title={title} detail={detail} images={images} id={id} key={id} />
              );
            })}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

interface IWhatYouGetContent {
  title: string;
  detail: string;
  images: string;
  id: string;
}

const WhatYouGetContent = ({ title, detail, images, id }: IWhatYouGetContent) => {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.images__container} item xs={12} md={4}>
        <Avatar className={classes.image} src={images} alt={id} />
        <Box className={classes.text__container__mobile} pt={4}>
          <Typography
            variant="h4"
            component={'h4'}
            color="primary"
            fontSize={'2rem'}
            lineHeight={'1.75rem'}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            component={'div'}
            maxWidth="600px"
            fontSize={'1.5rem'}
            lineHeight={'1.5rem'}>
            {detail}
          </Typography>
        </Box>
      </Grid>
      <Grid className={classes.text__container__grid} item xs={12} md={8} display={'flex'}>
        <Box className={classes.text__container}>
          <Typography variant="h4" component={'h4'} color="primary" maxWidth="600px">
            {title}
          </Typography>
          <Typography
            variant="body2"
            pt={1.5}
            fontSize={'32px'}
            lineHeight={'32px'}
            component={'div'}
            maxWidth="600px">
            {detail}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default WhatYouGetPage;
