import { Typography } from '@mui/material';
import { usePartnerlogo } from 'hooks/partnerlogo/usePartnerlogo';
import { useTranslation } from 'react-i18next';
import styles from './partnerlogo.module.css';
const [partnerLogoItems] = usePartnerlogo();

const PartnerLogoSection = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.section__wrapper}>
      <Typography
        variant="h2"
        color="primary"
        textAlign="center"
        fontSize={{ xs: '48px', md: '60px' }}
        lineHeight={{ xs: '42px', md: '60px' }}
        pb={{ md: 8, xs: 6 }}
        pt={{ md: 8, xs: 6 }}>
        {t('homepage.our_partner')}
      </Typography>
      <div className={styles.logo__container}>
        {partnerLogoItems.map((item) => (
          <div key={item.image} className={styles.logo_item}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <img className={styles.image_logo} src={item.image} alt="" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnerLogoSection;
