import productService from 'api/services/ProductService';
import { useMutation } from 'react-query';
import { IProductInitValue } from 'types/Product';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
  companyId: number;
}

export const useCreateProduct = ({ onSuccess, onError, companyId }: Props) => {
  return useMutation((body: IProductInitValue) => productService.createProduct(body, companyId), {
    onError,
    onSuccess
  });
};
