import aspirationService from 'api/services/AspirationService';
import { useMutation } from 'react-query';
import { IAspiration } from 'types/Aspiration';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
  companyId: number;
}

export const useCreateAspiration = ({ onSuccess, onError, companyId }: Props) => {
  return useMutation((body: IAspiration) => aspirationService.createAspiration(body, companyId), {
    onError,
    onSuccess
  });
};
