import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  modal__container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    padding: `${theme.spacing(2)} 0`,
    maxHeight: '80vh',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: 'none',
      borderRadius: 0,
      height: '100%',
      padding: 0
    }
  },
  modal__header: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6)
    }
  },
  modal__content: {
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(2)}`
    }
  },
  modal__footer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2)
    }
  }
}));

export default useStyles;
