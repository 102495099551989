import axiosClient from 'api/axiosClient';
import { useMutation } from 'react-query';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
}

const deleteMedia = (id: number) => {
  return axiosClient.delete(`upload/files/${id}`);
};

export const useDeleteMedia = ({ onSuccess, onError }: Props) => {
  return useMutation((id: number) => deleteMedia(id), {
    onError,
    onSuccess
  });
};
