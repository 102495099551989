import { Box, Container, Grid, Link, Typography, useTheme } from '@mui/material';
import { useAuth } from 'auth';
import ModalWrapper, { ModalContent } from 'components/Modal/ModalWrapper';
import CustomButton from 'components/shared/Button';
import Logo from 'components/shared/Logo';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavLink from '../Header/components/NavLink';
import useStyles from './footerStyles';

const Footer = () => {
  const { user, isAdmin } = useAuth();
  const { t } = useTranslation();

  const theme = useTheme();
  const classes = useStyles();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box
      className={classes.footer}
      bgcolor={'secondary.main'}
      color="white"
      display={!isAdmin ? 'block' : 'none'}>
      <Container className={classes.footer__container} maxWidth="xl">
        <Grid container className={classes.footer__content}>
          <Grid item xs={6} md={4}>
            <Box>
              <Logo theme="white" className={classes.footer__logo} style={{ maxWidth: 240 }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: {
                md: 'none'
              }
            }}>
            {!user && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end'
                }}>
                <Box>
                  <CustomButton
                    onClick={() => setOpenModal(true)}
                    variant="contained"
                    color="white"
                    text={t('navigator.register')}
                    sx={{
                      color: theme.palette.secondary.main
                    }}
                  />
                  <ModalWrapper
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    modalContent={ModalContent.QUESTIONNAIRE}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: '20px'
                  }}>
                  <CustomButton
                    onClick={() => setOpenLoginModal(true)}
                    variant="outlined"
                    color="white"
                    text={t('navigator.login')}
                    sx={{ border: '1px solid white' }}
                  />
                  <ModalWrapper
                    open={openLoginModal}
                    onClose={() => setOpenLoginModal(false)}
                    modalContent={ModalContent.LOGIN}
                  />
                </Box>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              marginTop: {
                xs: theme.spacing(5),
                sm: theme.spacing(3),
                md: 0
              }
            }}>
            <Grid container>
              <Grid item xs={6} sm={4}>
                <Box>
                  <Typography className={classes.footer__title}>
                    {t('footer.information.title')}
                  </Typography>
                </Box>
                <Box>
                  <NavLink
                    style={{
                      color: '#fff'
                    }}
                    to="/how-it-works">
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.footer__text_content}>
                      {t('footer.information.how_it_works')}
                    </Typography>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink style={{ color: '#fff' }} to="/how-it-works">
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.footer__text_content}>
                      {t('footer.information.prepare')}
                    </Typography>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink style={{ color: '#fff' }} to="/what-you-get">
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.footer__text_content}>
                      {t('footer.information.what_you_get')}
                    </Typography>
                  </NavLink>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box>
                  <Typography className={classes.footer__title}>
                    {t('footer.support.title')}
                  </Typography>
                </Box>
                <Box>
                  <NavLink style={{ color: '#fff' }} to="/contact">
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.footer__text_content}>
                      {t('footer.support.faq')}
                    </Typography>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink style={{ color: '#fff' }} to="/contact">
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.footer__text_content}>
                      {t('footer.support.contact')}
                    </Typography>
                  </NavLink>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                sx={{
                  mt: {
                    sm: 0,
                    xs: 3
                  }
                }}>
                <Box>
                  <Typography className={classes.footer__title}>
                    {t('footer.company.title')}
                  </Typography>
                </Box>
                <Box>
                  <NavLink style={{ color: '#fff' }} to="/about">
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.footer__text_content}>
                      {t('footer.company.about')}
                    </Typography>
                  </NavLink>
                </Box>
                <Box>
                  <NavLink style={{ color: '#fff' }} to="/terms">
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.footer__text_content}>
                      {t('footer.company.legal')}
                    </Typography>
                  </NavLink>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: {
                md: 'block',
                xs: 'none'
              }
            }}>
            {!user && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end'
                }}>
                <Box>
                  <CustomButton
                    onClick={() => setOpenModal(true)}
                    variant="contained"
                    color="white"
                    text={t('navigator.register')}
                    sx={{
                      color: theme.palette.secondary.main
                    }}
                  />
                  <ModalWrapper
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    modalContent={ModalContent.QUESTIONNAIRE}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: '20px'
                  }}>
                  <CustomButton
                    onClick={() => setOpenLoginModal(true)}
                    variant="outlined"
                    color="white"
                    text={t('navigator.login')}
                    sx={{ border: '1px solid white' }}
                  />
                  <ModalWrapper
                    open={openLoginModal}
                    onClose={() => setOpenLoginModal(false)}
                    modalContent={ModalContent.LOGIN}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.footer__contact}>
          <Grid item xs={12} md={8}>
            <Typography
              sx={{
                fontFamily: 'PSL Kanda Extra Pro',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '20px',
                color: '#fff'
              }}>
              {`© ${t('footer.line')}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.footer__link__container}>
            <Box>
              <Link
                target="_blank"
                href="https://www.facebook.com/topstongtin"
                className={classes.footer__link}>
                <img src={'/imgs/facebook.png'} className={classes.footer__social_icons} />
              </Link>
              {/* <Link target="_blank" href="http://fb.com" className={classes.footer__link}>
                <LinkedInIcon htmlColor="#fff" />
              </Link>
              <Link target="_blank" href="http://fb.com" className={classes.footer__link}>
                <YouTubeIcon htmlColor="#fff" />
              </Link>
              <Link target="_blank" href="http://fb.com" className={classes.footer__link}>
                <TwitterIcon htmlColor="#fff" />
              </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
