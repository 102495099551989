import Checkbox from '../Checkbox';
import CheckboxWithDetail from '../CheckboxWithDetail'
import Input from '../Input';
import RadioButton from '../RadioButton';
import SelectFormik from '../Select';
import TextArea from '../TextArea';
import Upload from '../Upload';

const Controls = {
  Input,
  SelectFormik,
  TextArea,
  RadioButton,
  Checkbox,
  CheckboxWithDetail,
  Upload
};

export default Controls;
