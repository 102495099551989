import { useAuth } from 'auth';
import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';

export enum Role {
  ADMIN = 'Admin',
  SUB_ADMIN = 'Sub Admin'
}

const Admin: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { user, isAdmin } = useAuth();

  if (!user) {
    return <Navigate to="/" state={{ path: pathname }} />;
  }

  if (!isAdmin) {
    return <Navigate to="/" state={{ path: pathname }} />;
  }

  return <>{children}</>;
};

export default Admin;
