import { Box, Container, Typography } from '@mui/material';
import useStyles from 'components/Layout/layoutStyles';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const TermsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Tongtin - Terms of use</title>
      </Helmet>
      <Container className={classes.layout__content} disableGutters>
        <Box className={classes.layout__header}>
          <Typography textAlign="center" component="h1" variant="h1">
            {t('terms.title')}
          </Typography>
        </Box>
        <Box p={3}>
          <Typography component="p" variant="body2">
            {t('terms.content')}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default TermsPage;
