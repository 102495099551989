import { useTranslation } from 'react-i18next';

export const useContact = () => {
  const { t } = useTranslation();

  /**
   * Order (as of 31st October 2022)
   * 
    What is Tops Tongtin (1)
    What qualifies as micro SME (2),
    Why should you apply? (3),
    Is there any application fee? (11),
    How is Tops Tongtin different from the standard... (4),
    What / Who are we looking for (5),
    Do you need to fill in and upload everything (6),
    Do I need to have an FDA license? (12),
    Can I apply if I am aready seeling with Tops (13)
    Who should i contact in case of technical issues (14)
    How long does it takes (7)
    How many product can you submit (8)
    Does Tops Tongtin use a consignment model? (15)
    To which sales channels (9)
    What category is my products (10)
  */
  
  const accordions = [
    1, 2, 3, 11, 4, 5, 6, 12, 13, 14, 7, 8, 15, 9, 10
  ].map(num => ({
    id: `id${num}`,
    title: t(`faq_page.content.question${num}`),
    detail: t(`faq_page.content.answer${num}`)
  }))
  
  return [accordions] as const;
};
