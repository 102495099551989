import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '..';
import LoginModal from '../LoginModal';
import QuestionnaireModal from '../QuestionnaireModal';
import RegisterModal from '../RegisterModal';

export enum ModalContent {
  QUESTIONNAIRE = 'questionnaire',
  REGISTER = 'register',
  LOGIN = 'login'
}

interface IModalWrapperProps {
  open: boolean;
  onClose: () => void;
  modalContent?: ModalContent;
}

const ModalWrapper = ({ open, onClose, modalContent }: IModalWrapperProps) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<ModalContent>(modalContent ?? ModalContent.QUESTIONNAIRE);

  useEffect(() => {
    !open && setModal(modalContent ?? ModalContent.QUESTIONNAIRE);
  }, [open]);

  const makeContent = () => {
    switch (modal) {
      case ModalContent.QUESTIONNAIRE:
        return <QuestionnaireModal setModal={setModal} />;
      case ModalContent.REGISTER:
        return <RegisterModal onClose={onClose} setModal={setModal} />;
      case ModalContent.LOGIN:
        return <LoginModal onClose={onClose} setModal={setModal} />;
      default:
        return <></>;
    }
  };

  const makeTitle = () => {
    switch (modal) {
      case ModalContent.QUESTIONNAIRE:
        return t('questionnaire.title');
      case ModalContent.REGISTER:
        return t('account_registration.account_representative.title');
      case ModalContent.LOGIN:
        return t('account_registration.account_representative.login');
      default:
        return '';
    }
  };

  const makeSubtitle = () => {
    switch (modal) {
      case ModalContent.QUESTIONNAIRE:
        return t('questionnaire.subTitle');
      case ModalContent.REGISTER:
        return t('account_registration.account_representative.subTitle');
      case ModalContent.LOGIN:
        return t('account_registration.account_representative.welcome');
      default:
        return '';
    }
  };

  return (
    <CustomModal title={makeTitle()} subTitle={makeSubtitle()} open={open} onClose={onClose}>
      {makeContent()}
    </CustomModal>
  );
};

export default ModalWrapper;
