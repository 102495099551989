import { GridSortModel } from '@mui/x-data-grid-pro';
import { converField } from './handleFilterChange';

export const handleSortChange = (model: GridSortModel) => {
  if (model.length === 0) {
    return {};
  }

  const sortModel = model[0];
  const { field, sort } = sortModel;
  const { table, field: sortField } = converField(field);

  return {
    table,
    field: sortField,
    direction: sort
  };
};
