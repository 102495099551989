import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import { useAuth } from 'auth';
import { Role } from 'components/Admin';
import { FormValues } from 'components/Stepper';
import CustomButton from 'components/shared/Button';
import TextError from 'components/shared/Form/TextError';
import { useReview } from 'contexts/review';
import { Form, Formik } from 'formik';
import LocalStorage, { LOCALSTORAGE_KEY } from 'helper/LocalStorage';
import { useCreateAspiration } from 'hooks/aspiration/useCreateAspiration';
import { useUpdateAspiration } from 'hooks/aspiration/useUpdateAspiration';
import { useCreateCompany } from 'hooks/company/useCreateCompany';
import { useUpdateCompany } from 'hooks/company/useUpdateCompany';
import { useCreateLicense } from 'hooks/license/useCreateLicense';
import { useUpdateLicense } from 'hooks/license/useUpdateLicense';
import { useCreateProduct } from 'hooks/product/useCreateProduct';
import { useUpdateProduct } from 'hooks/product/useUpdateProduct';
import { useUploadSubmission } from 'hooks/submission/useUploadSubmission';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IAspiration } from 'types/Aspiration';
import ICompany from 'types/Company';
import ILicense from 'types/License';
import { IProductInitValue } from 'types/Product';
import { isEmptyObject } from 'utils/isEmptyObject';
import makeFormContent, { makeFormContentType } from 'utils/makeForm';
import useStyles from './registrationWrapperStyles';

interface IRegistrationWrapper {
  step: number;
  handleBack: () => void;
  handleNext: () => void;
  initValues: FormValues;
  validationSchema: any;
  company: any;
}

const MAX_STEPS = 5;
const localStorage = new LocalStorage();

const RegistrationWrapper = ({
  step,
  handleBack,
  handleNext,
  initValues,
  validationSchema,
  company
}: IRegistrationWrapper) => {
  const { user, setMe } = useAuth();
  const navigate = useNavigate();
  const { isReviewing, handleOpenModal } = useReview();
  const { t } = useTranslation();
  const classes = useStyles();
  const [formContent, setFormContent] = useState<makeFormContentType>({
    header: '',
    subtitle: '',
    content: <></>
  });
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [draft, setDraft] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [agreement, setAgreement] = React.useState(false);
  const [knowledge, setKnowledge] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAgreement(false);
    setKnowledge(false);
    setOpen(false);
    if (isReviewing && step === MAX_STEPS - 1) {
      handleOpenModal(false);
    }
  };

  const onSuccess = () => {
    if (draft) {
      return toast.success('Saved Draft!');
    }

    handleNext();
    localStorage.setItemByKey(LOCALSTORAGE_KEY.STEP, step + 1);
  };

  const onError = (error: any) => {
    setErrorMsg(error.message);
  };

  const { mutate: createCompany, isLoading: companySubmitting } = useCreateCompany({
    onSuccess,
    onError,
    userId: user!.id
  });

  const { mutate: updateCompany, isLoading: companyUpdating } = useUpdateCompany({
    onSuccess,
    onError
  });

  const { mutate: createProduct, isLoading: productSubmitting } = useCreateProduct({
    onSuccess,
    onError,
    companyId: isEmptyObject(company) ? -1 : company.id
  });

  const { mutate: updateProduct, isLoading: productUpdating } = useUpdateProduct({
    onSuccess,
    onError
  });

  const { mutate: createLicense, isLoading: licenseSubmitting } = useCreateLicense({
    onSuccess,
    onError,
    companyId: isEmptyObject(company) ? -1 : company.id
  });

  const { mutate: updateLicense, isLoading: licenseUpdating } = useUpdateLicense({
    onSuccess,
    onError
  });

  const { mutate: createAspiration, isLoading: aspirationSubmitting } = useCreateAspiration({
    onSuccess,
    onError,
    companyId: isEmptyObject(company) ? -1 : company.id
  });

  const { mutate: updateAspiration, isLoading: aspirationUpdating } = useUpdateAspiration({
    onSuccess,
    onError
  });

  const { mutate: uploadSubmission, isLoading: submissionUploading } = useUploadSubmission({
    onSuccess: () => {
      setMe();
      toast.success('Upload success');
      localStorage.removeItemByKey(LOCALSTORAGE_KEY.STEP);
      setTimeRedirect();
    },
    onError
  });

  const setTimeRedirect = () => {
    setTimeout(() => navigate('/application-status'), 2000);
  };

  useEffect(() => {
    const content = makeFormContent(t, step);
    setFormContent(content);
  }, [initValues]);

  const isSubmitting =
    companySubmitting ||
    companyUpdating ||
    licenseSubmitting ||
    licenseUpdating ||
    productSubmitting ||
    productUpdating ||
    aspirationSubmitting ||
    aspirationUpdating ||
    submissionUploading;

  const handleSaveDraft = async (values: FormValues) => {
    await setDraft(true);
    if (step === MAX_STEPS - 1) {
      uploadSubmission(values);
    } else {
      switch (step) {
        case 0: {
          if (!isEmptyObject(company)) {
            return updateCompany(values as ICompany & { id: number });
          }
          return createCompany(values as ICompany);
        }
        case 1: {
          if (company && !isEmptyObject(company.license)) {
            return updateLicense(values as ILicense & { id: number });
          }
          return createLicense(values as ILicense);
        }
        case 2: {
          if (company && !isEmptyObject(company.product)) {
            return updateProduct(values as IProductInitValue & { id: number });
          }
          return createProduct(values as IProductInitValue);
        }
        case 3: {
          if (company && !isEmptyObject(company.aspiration)) {
            return updateAspiration(values as IAspiration);
          }
          return createAspiration(values as IAspiration);
        }
        default: {
          return;
        }
      }
    }
  };

  /* Handling the change of data submit */
  const handleSubmitData = (values: FormValues) => {
    let data = values as ICompany;
    switch (data.type_of_business.value) {
      case 'Company (Listed or Limited Liability)':
        data = {
          ...data,
          type_of_business: {
            ...data.type_of_business,
            unregistered: {
              id_card_number: '',
              id_card_upload: null
            },
            registered: {
              registered_number: '',
              upload: null
            },
            limited: {
              registration_number: '',
              upload: null
            },
            smce: {
              code: '',
              registration_certificate: null,
              commerial_certificate: null
            },
            individual: {
              id_card_number: '',
              upload: null
            }
          }
        };
        break;
      case 'Unregistered Partnership':
        data = {
          ...data,
          type_of_business: {
            ...data.type_of_business,
            company_affidavit: {
              registered_number: '',
              photo: null
            },
            registered: {
              registered_number: '',
              upload: null
            },
            limited: {
              registration_number: '',
              upload: null
            },
            smce: {
              code: '',
              registration_certificate: null,
              commerial_certificate: null
            },
            individual: {
              id_card_number: '',
              upload: null
            }
          }
        };
        break;
      case 'Registered Partnership':
        data = {
          ...data,
          type_of_business: {
            ...data.type_of_business,
            company_affidavit: {
              registered_number: '',
              photo: null
            },
            unregistered: {
              id_card_number: '',
              id_card_upload: null
            },
            limited: {
              registration_number: '',
              upload: null
            },
            smce: {
              code: '',
              registration_certificate: null,
              commerial_certificate: null
            },
            individual: {
              id_card_number: '',
              upload: null
            }
          }
        };
        break;
      case 'Limited Partnership':
        data = {
          ...data,
          type_of_business: {
            ...data.type_of_business,
            company_affidavit: {
              registered_number: '',
              photo: null
            },
            unregistered: {
              id_card_number: '',
              id_card_upload: null
            },
            registered: {
              registered_number: '',
              upload: null
            },
            smce: {
              code: '',
              registration_certificate: null,
              commerial_certificate: null
            },
            individual: {
              id_card_number: '',
              upload: null
            }
          }
        };
        break;
      case 'SMCE (small and micro community enterprise)':
        data = {
          ...data,
          type_of_business: {
            ...data.type_of_business,
            company_affidavit: {
              registered_number: '',
              photo: null
            },
            unregistered: {
              id_card_number: '',
              id_card_upload: null
            },
            registered: {
              registered_number: '',
              upload: null
            },
            limited: {
              registration_number: '',
              upload: null
            },
            individual: {
              id_card_number: '',
              upload: null
            }
          }
        };
        break;
      case 'Individual':
        data = {
          ...data,
          type_of_business: {
            ...data.type_of_business,
            company_affidavit: {
              registered_number: '',
              photo: null
            },
            unregistered: {
              id_card_number: '',
              id_card_upload: null
            },
            registered: {
              registered_number: '',
              upload: null
            },
            limited: {
              registration_number: '',
              upload: null
            },
            smce: {
              code: '',
              registration_certificate: null,
              commerial_certificate: null
            }
          }
        };
        break;
    }
    data.facilities.forEach((facility, index) => {
      switch (facility.has_facility) {
        case 'farm':
          data.facilities[index] = {
            ...data.facilities[index],
            production_facility: {
              front: null,
              sorting_area: null,
              packing_area: null,
              cold_storage_area: null
            },
            fishery: {
              fishery_photo: null
            }
          };
          break;
        case 'production':
          data.facilities[index] = {
            ...data.facilities[index],
            farm: {
              entrance: null,
              plot: null,
              animal_stall: null
            },
            fishery: {
              fishery_photo: null
            }
          };
          break;
        case 'fishery':
          data.facilities[index] = {
            ...data.facilities[index],
            farm: {
              entrance: null,
              plot: null,
              animal_stall: null
            },
            production_facility: {
              front: null,
              sorting_area: null,
              packing_area: null,
              cold_storage_area: null
            }
          };
          break;
      }
    });
    return data as FormValues;
  };

  const handleSubmit = async (values: FormValues) => {
    await setDraft(false);
    if (step === MAX_STEPS - 1) {
      if (isReviewing) {
        return handleOpenModal(false);
      }
      uploadSubmission(values);
    } else {
      switch (step) {
        case 0: {
          const valuesSubmit = handleSubmitData(values);
          if (!isEmptyObject(company)) {
            return updateCompany(valuesSubmit as ICompany & { id: number });
          }
          return createCompany(valuesSubmit as ICompany);
        }
        case 1: {
          if (company && !isEmptyObject(company.license)) {
            return updateLicense(values as ILicense & { id: number });
          }
          return createLicense(values as ILicense);
        }
        case 2: {
          if (company && !isEmptyObject(company.product)) {
            return updateProduct(values as IProductInitValue & { id: number });
          }
          return createProduct(values as IProductInitValue);
        }
        case 3: {
          if (company && !isEmptyObject(company.aspiration)) {
            return updateAspiration(values as IAspiration);
          }
          return createAspiration(values as IAspiration);
        }
        default: {
          return;
        }
      }
    }
  };

  if (!formContent.header) {
    return <div>Loading ...</div>;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount>
      {(formik) => {
        return (
          <Form>
            <Grid container className={classes.registration__wrapper}>
              <Grid item xs={12}>
                <Typography variant="h2" component="h2">
                  {formContent.header}
                </Typography>
                <Typography variant="body1" component="p">
                  {formContent.subtitle}
                </Typography>
                {step === 4 && (
                  <Typography variant="body2" component="p">
                    {t('document.note')}
                  </Typography>
                )}
                <Divider className={classes.registration__wrapper__divider} />
              </Grid>
              {formContent.content}
              <Box className={classes.registration__wrapper__footer}>
                <Box sx={{ flexGrow: 1 }}>
                  <CustomButton
                    startIcon={<ArrowBackIos />}
                    variant="text"
                    color="secondary"
                    text={t('form.previous')}
                    disabled={step === 0}
                    onClick={handleBack}
                  />
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                  {!isReviewing && step < MAX_STEPS - 1 && (
                    <CustomButton
                      color="secondary"
                      sx={{ mr: 1 }}
                      variant="outlined"
                      text={t('form.saveDraft')}
                      onClick={() => {
                        handleSaveDraft(formik.values);
                      }}
                      loading={isSubmitting}
                    />
                  )}

                  {step < MAX_STEPS - 1 ? (
                    <CustomButton
                      onClick={() => {
                        if (!formik.isValid) {
                          return toast.error('Please fill in all the mandatory fields!');
                        }
                        if (isReviewing && user?.role.name === Role.SUB_ADMIN) {
                          return handleNext();
                        }
                        if (isReviewing && formik.initialValues !== formik.values) {
                          return handleOpen();
                        }
                        handleSubmit(formik.values);
                      }}
                      loading={isSubmitting}
                      text={t('form.next')}
                      variant="contained"
                      color="secondary"
                      endIcon={<ArrowForwardIos />}
                      sx={{
                        color: '#fff'
                      }}
                    />
                  ) : (
                    <CustomButton
                      onClick={() => {
                        if (!formik.isValid) {
                          return toast.error('Please fill in all the mandatory fields!');
                        }
                        handleOpen();
                      }}
                      loading={isSubmitting}
                      variant="contained"
                      color="secondary"
                      sx={{
                        color: '#fff'
                      }}
                      text={t('form.submit')}
                    />
                  )}
                </Box>
              </Box>
              <TextError sx={{ textAlign: 'right' }} error={errorMsg} />
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                  '& .MuiPaper-root': {
                    maxWidth: '800px'
                  }
                }}>
                <DialogContent>
                  <DialogTitle id="alert-dialog-title" className={classes.dialog__title}>
                    <Typography
                      variant="h1"
                      component="h1"
                      fontSize={'2.5rem'}
                      fontWeight={'600'}
                      textAlign="center">
                      {isReviewing ? 'Update information' : t('form_agreement.gratitude')}
                    </Typography>
                  </DialogTitle>
                  {isReviewing ? (
                    <DialogContentText id="alert-dialog-description">
                      <Typography variant="body2_temp" component="p" textAlign="center">
                        Are you sure you want to update this item?
                      </Typography>
                    </DialogContentText>
                  ) : (
                    <DialogContentText id="alert-dialog-description">
                      <Typography variant="body2_temp" component="p" textAlign="center">
                        {t('form_agreement.check_again')}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox checked={agreement} onChange={() => setAgreement(!agreement)} />
                        }
                        label={
                          <Typography component={'p'} variant="body2_temp" textAlign="left">
                            {t('form_agreement.agreement')}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox checked={knowledge} onChange={() => setKnowledge(!knowledge)} />
                        }
                        label={
                          <Typography component={'p'} variant="body2_temp" textAlign="left">
                            {t('form_agreement.receive')}
                          </Typography>
                        }
                      />
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions className={classes.dialog__action}>
                  <CustomButton
                    sx={{ mr: 1 }}
                    variant="outlined"
                    text={t('form.cancel')}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <CustomButton
                    disabled={isReviewing ? false : !agreement}
                    loading={isSubmitting}
                    type="submit"
                    onClick={() => {
                      handleSubmit(formik.values);
                      handleClose();
                    }}
                    variant="contained"
                    color="primary"
                    sx={{
                      color: '#fff'
                    }}
                    text={t('form.confirm')}
                  />
                </DialogActions>
              </Dialog>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegistrationWrapper;
