import MuiAlert, { AlertProps } from '@mui/material/Alert';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M21.72,12.05A9.71,9.71,0,1,1,12,2.34,9.71,9.71,0,0,1,21.72,12.05ZM13.11,7.87a1.51,1.51,0,0,0,.2-1.12,1.16,1.16,0,0,0-.56-.82,1.35,1.35,0,0,0-1-.16,1.16,1.16,0,0,0-.87.62,1.59,1.59,0,0,0-.16,1.09,1.33,1.33,0,0,0,.49.85,1.14,1.14,0,0,0,1,.18A1.33,1.33,0,0,0,13.11,7.87Zm1.4,8.47L14,16h0a5.42,5.42,0,0,1-.26.6,2.41,2.41,0,0,1-.36.52.92.92,0,0,1-.48.28.21.21,0,0,1-.27-.17,1.85,1.85,0,0,1-.11-.34,3.59,3.59,0,0,1,0-1.38c.09-.51.2-1,.32-1.53s.22-1,.32-1.53a3.72,3.72,0,0,0,0-1.41,2.43,2.43,0,0,0-.6-1.17,1.22,1.22,0,0,0-1.24-.4,1.87,1.87,0,0,0-.83.42,6.49,6.49,0,0,0-.85.82,2.62,2.62,0,0,0-.22.25l.49.52a1.17,1.17,0,0,1,.37-.28,2.2,2.2,0,0,1,.49-.15c.19,0,.26.2.2.7a16.89,16.89,0,0,1-.34,1.78c-.16.68-.31,1.38-.45,2.08a4.51,4.51,0,0,0-.1,1.61,1.24,1.24,0,0,0,.88,1,2.64,2.64,0,0,0,1.43.14,2.3,2.3,0,0,0,1.56-1,6.74,6.74,0,0,0,.57-1Z" />
  </SvgIcon>
);

export default function Alert({ children, ...props }: AlertProps) {
  return (
    <MuiAlert
      {...props}
      iconMapping={{
        info: <InfoIcon />
      }}>
      {children}
    </MuiAlert>
  );
}
