import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: `${theme.palette.white.main}!important`,
    color: `${theme.palette.primary.main}!important`,
    boxShadow:
      '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)!important'
  },
  header__container: {
    [theme.breakpoints.up('xs')]: {
      padding: `0 ${theme.spacing(1)} !important`
    },
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.spacing(2)} !important`
    }
  },
  header__start: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  header__logo_container: {
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  header__logo: {
    [theme.breakpoints.up('xs')]: {
      padding: `0`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)} 0`
    }
  },
  header__menu: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  header__end: {
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  header__menu__responsive: {
    flexGrow: 0,
    [theme.breakpoints.up('xs')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  header__logo__responsive: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  header__logo__content: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  header__menu__text: {
    color: `${theme.palette.primary.main}!important`,
    fontSize: '1.5rem!important',
    fontWeight: 'bold!important',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem!important'
    }
  },
  header__menu__link: {
    color: theme.palette.primary.main
  },
  header__menu__drawer: {
    width: '100%!important'
  },
  header__menu__drawer__logo: {
    padding: `0 ${theme.spacing(2)}!important`
  },
  header__menu__drawer__item: {
    fontSize: '1.5rem',
    padding: `${theme.spacing(3)} ${theme.spacing(2)}!important`
  },
  header__menu__drawer__bottom: {
    width: '100%',
    textAlign: 'center'
  },
  header__menu__box: {
    [theme.breakpoints.up('xs')]: {
      display: 'none !important'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block !important'
    }
  },
  header__user__menu__box: {
    [theme.breakpoints.up('xs')]: {
      display: 'none !important'
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex !important'
    }
  },
  header_language_mobile: {
    display: 'inline-block'
  }
}));

export default useStyles;
