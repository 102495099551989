export interface IRoutes {
  path: string;
  text: string;
}

export const routes: IRoutes[] = [
  {
    path: '/about',
    text: 'navigator.who_we_are'
  },
  {
    path: '/what-you-get',
    text: 'navigator.what_you_get'
  },
  {
    path: '/how-it-works',
    text: 'navigator.how_it_works'
  },
  {
    path: '/contact',
    text: 'navigator.faq'
  }
];

export const stepperRoutes: IRoutes[] = [
  {
    text: 'account_registration.company_info',
    path: '/company'
  },
  {
    text: 'account_registration.sale_channels',
    path: '/licenses'
  },
  {
    text: 'account_registration.products',
    path: '/products'
  },
  {
    text: 'account_registration.aspiration',
    path: '/asd'
  },
  {
    text: 'account_registration.document',
    path: '/document'
  }
];
