import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

type Subdistrict = {
  id: number;
  zip_code: number;
  name_th: string;
  name_en: string;
  amphure_id: number;
  deleted_at: string|null;
};

type District = {
  id: number;
  name_th: string;
  name_en: string;
  province_id: number;
};

type Province = {
  id: number;
  geography_id: number;
  name_th: string;
  name_en: string;
  province_id: number;
};

const toSelect = (lang: string) => (item: Subdistrict | District | Province, index: number) => {
  if (!item) return null;
  return {
    id: index,
    label: lang === 'en' ? item.name_en : item.name_th,
    label_en: item.name_en,
    label_th: item.name_th,
    value: item.name_th // Note: In future we might change to item.id,
  };
};

const fetchSubdistricts = () => fetch(`/json/thai_subdistricts.json`)
  .then((res) => res.json())
  .then(items => items.filter((item: Subdistrict) => !item.deleted_at));
const fetchDistricts = () => fetch(`/json/thai_districts.json`).then((res) => res.json());
const fetchProvinces = () => fetch(`/json/thai_provinces.json`).then((res) => res.json());

const useThaiAddress = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [subdistricts, setSubdistricts] = useState<Subdistrict[]>([]);
  const [districts, setDistricts] = useState<District[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);

  useEffect(() => {
    Promise.all([fetchSubdistricts(), fetchDistricts(), fetchProvinces()]).then((data) => {
      const [subdistricts, districts, provinces] = data;
      setSubdistricts(subdistricts);
      setDistricts(districts);
      setProvinces(provinces);
      setLoaded(true);
    });
  }, []);

  const filterSubdistrictsByZipcode = useCallback(
    (zipCode: number): Subdistrict[] => {
      // if (!loaded) throw new Error('Data has not finished loading yet')
      if (!zipCode) return [];

      return subdistricts.filter((item) => item.zip_code === zipCode);
    },
    [loaded]
  );

  const filterDistrictByZipcode = useCallback(
    (zipCode: number): District[] => {
      if (!zipCode) return [];

      return _(filterSubdistrictsByZipcode(zipCode))
        .uniqBy((item) => item.amphure_id)
        .map((item) => districts.find((district) => district.id === item.amphure_id))
        .compact()
        .value();
    },
    [loaded]
  );

  const filterProvinceByZipcode = useCallback(
    (zipCode: number): Province[] => {
      if (!zipCode) return provinces;

      return _(filterDistrictByZipcode(zipCode))
        .uniqBy((item) => item.province_id)
        .map((item) => provinces.find((province) => province.id === item.province_id))
        .compact()
        .value();
    },
    [loaded]
  );

  const buildSubDistrict = useCallback(
    (zipCode, lang = 'th') => {
      return filterSubdistrictsByZipcode(zipCode).map(toSelect(lang));
    },
    [filterSubdistrictsByZipcode, loaded]
  );

  const buildDistrictList = useCallback(
    (zipCode, lang = 'th') => {
      return filterDistrictByZipcode(zipCode).map(toSelect(lang));
    },
    [filterSubdistrictsByZipcode]
  );

  const buildProvince = useCallback(
    (zipCode, lang = 'th') => {
      return filterProvinceByZipcode(zipCode).map(toSelect(lang));
    },
    [filterSubdistrictsByZipcode]
  );

  return {
    loaded,
    subdistricts,
    districts,
    provinces,

    buildSubDistrict,
    buildDistrictList,
    buildProvince
  };
};

export default useThaiAddress;
