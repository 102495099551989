import userService, { IUserResponse } from 'api/services/UserService';
import { Role } from 'components/Admin';
import React, { createContext, useContext, useState } from 'react';
import IUser from 'types/User';
import { emptyToken, setToken } from 'utils/token';

export type AuthContextType = {
  user: IUser | null;
  login: ({ user, jwt }: IUserResponse) => void;
  logout: () => void;
  setMe: () => void;
  isAuthReady: boolean;
  isAdmin: boolean;
};

const initalAuthContext: AuthContextType = {
  user: null,
  login: () => null,
  logout: () => null,
  setMe: async () => null,
  isAuthReady: false,
  isAdmin: false
};

const AuthContext = createContext<AuthContextType>(initalAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const guestDefaultLandingUrl = '/';
  const [user, setUser] = useState<IUser | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const roleArray: string[] = Object.values(Role);

  const login = ({ user, jwt }: IUserResponse) => {
    if (user && user.registration) {
      user.registration = (user.registration as any).id;
    }
    setToken(jwt);
    setUser(user);
    setIsAdmin(roleArray.includes(user.role.name));
  };

  const logout = () => {
    emptyToken();
    setUser(null);
    setIsAdmin(false);
    window.location.href = guestDefaultLandingUrl;
  };

  const setMe = async () => {
    try {
      const user = await userService.me();
      setUser(user);
      setIsAdmin(roleArray.includes(user.role.name));
    } catch (error) {
      setUser(null);
    } finally {
      setIsAuthReady(true);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, setMe, isAuthReady, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
