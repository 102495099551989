import { Grid } from '@mui/material';
import UploadWithHint from 'components/RegistrationForm/UploadWithHint';
import { DocumentTypes } from 'constants/documents';
import { useModal } from 'contexts/modal';
import { t } from 'i18next';
import _ from 'lodash';

export const renderLicenseSubmission = (
  farming_certification: string[],
  manufacturing_certification: string[],
  field: { text: string; value: string },
  index: number,
  key: string,
  isSubmission?: boolean,
  currentStep: number | undefined = undefined
) => {
  const { handleOpenModal, setLink } = useModal();

  switch (field.text) {
    case 'gap':
      if (_.includes(farming_certification, 'GAP (Vegetable & Fruits)')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.GAP.upload.gap.label`)}
              name={`${key}.gap`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.GAP.upload.gap.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.GAP.upload.gap.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/GAP+(Vegetable+_+Fruits).pdf`,
                    pdf: true
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.GAP.upload.gap.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'gapThai':
      if (_.includes(farming_certification, 'Thai GAP')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.GAP.upload.gap_thai.label`)}
              name={`${key}.gap_thai`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.GAP.upload.gap_thai.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.GAP.upload.gap_thai.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Thai GAP.jpg`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.GAP.upload.gap_thai.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'gapGlobal':
      if (_.includes(farming_certification, 'Global GAP')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.GAP.upload.gap_global.label`)}
              name={`${key}.gap_global`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.GAP.upload.gap_global.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.GAP.upload.gap_global.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Global+GAP.jpg`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.GAP.upload.gap_global.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'orgaThai':
      if (_.includes(farming_certification, 'Organic Thailand')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.organic.upload.organicThailand.label`)}
              name={`${key}.organic_thai`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.organic.upload.organicThailand.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.organic.upload.organicThailand.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Organic+Thailand.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.organic.upload.organicThailand.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'orgaIfoam':
      if (_.includes(farming_certification, 'IFOAM Programme')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.organic.upload.organicIFOAM.label`)}
              name={`${key}.organic_ifoam`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.organic.upload.organicIFOAM.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.organic.upload.organicIFOAM.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/IFOAM+Programme.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.organic.upload.organicIFOAM.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'orgaEuro':
      if (_.includes(farming_certification, 'European Union Programme')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.organic.upload.organicEUprogramme.label`)}
              name={`${key}.organic_european`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.organic.upload.organicEUprogramme.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.organic.upload.organicEUprogramme.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/European+Union+Programme.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t(
                'license.farmingCertification.organic.upload.organicEUprogramme.example'
              )}
            />
          </Grid>
        );
      }
      return <></>;
    case 'orgaCanada':
      if (_.includes(farming_certification, 'Canada Programme')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.organic.upload.organicCAprogramme.label`)}
              name={`${key}.organic_canada`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.organic.upload.organicCAprogramme.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.organic.upload.organicCAprogramme.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Canada+Programme.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t(
                'license.farmingCertification.organic.upload.organicCAprogramme.example'
              )}
            />
          </Grid>
        );
      }
      return <></>;
    case 'orgaUs':
      if (_.includes(farming_certification, 'US Programme')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.organic.upload.organicUSprogramme.label`)}
              name={`${key}.organic_us`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.organic.upload.organicUSprogramme.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.organic.upload.organicUSprogramme.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/US Programme.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t(
                'license.farmingCertification.organic.upload.organicUSprogramme.example'
              )}
            />
          </Grid>
        );
      }
      return <></>;
    case 'orgaLiveStock':
      if (_.includes(farming_certification, 'Organic Livestock')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.organic.upload.organicLiveStock.label`)}
              name={`${key}.organic_livestock`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.organic.upload.organicLiveStock.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.organic.upload.organicLiveStock.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Organic+Livestock.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t(
                'license.farmingCertification.organic.upload.organicLiveStock.example'
              )}
            />
          </Grid>
        );
      }
      return <></>;
    case 'pgsStandard':
      if (_.includes(farming_certification, 'PGS Standard')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.PGS.upload.pgs_standard.label`)}
              name={`${key}.pgs_standard`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.PGS.upload.pgs_standard.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.PGS.upload.pgs_standard.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/PGS Standard.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.PGS.upload.pgs_standard.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'pgsSdg':
      if (_.includes(farming_certification, 'SDGsPGS')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.PGS.upload.pgs_sdg.label`)}
              name={`${key}.pgs_sdg`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.PGS.upload.pgs_sdg.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.PGS.upload.pgs_sdg.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    pdf: true,
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/SDGsPGS.pdf`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.PGS.upload.pgs_sdg.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'gapForFarm':
      if (
        _.includes(
          farming_certification,
          'Good Agricultural Practices for Farm  (Livestock and LAYER FARM)'
        )
      ) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.GAP.upload.gap_for_livestock.label`)}
              name={`${key}.gap_for_livestock`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.GAP.upload.gap_for_livestock.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.GAP.upload.gap_for_livestock.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Good+Agricultural+Practices+for+Farm++(Livestock+and+LAYER+FARM).pdf`,
                    pdf: true
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.GAP.upload.gap_for_livestock.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'fisheries':
      if (_.includes(farming_certification, 'Good Agricultural Practices for Farm (Fisheries)')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.GAP.upload.gap_for_fisheries.label`)}
              name={`${key}.gap_for_fisheries`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.GAP.upload.gap_for_fisheries.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.GAP.upload.gap_for_fisheries.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Good+Agricultural+Practices+for+Farm+(Fisheries)+1.pdf`,
                    pdf: true
                  },
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Good+Agricultural+Practices+for+Farm+(Fisheries)+2.pdf`,
                    pdf: true
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.GAP.upload.gap_for_fisheries.example')}
            />
          </Grid>
        );
      }
      return <></>;
    case 'rspo':
      if (_.includes(farming_certification, 'Roundtable on Sustainable Palm Oil (RSPO)')) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`license.farmingCertification.RSPO.upload.label`)}
              name={`${key}.rspo`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [
                      t('license.farmingCertification.RSPO.upload.subtitle'),
                      t('form.info_msg.upload')
                    ]
                  : [t('license.farmingCertification.RSPO.upload.subtitle')]
              }
              onClickExample={() => {
                setLink([
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/farming_certifications/Roundtable+on+Sustainable+Palm+Oil+(RSPO).jpg`
                  }
                ]);
                handleOpenModal(true);
              }}
              exampleText={t('license.farmingCertification.RSPO.upload.example')}
            />
          </Grid>
        );
      }
      return <></>;
    default:
      if (
        isSubmission &&
        Array.isArray(manufacturing_certification) &&
        manufacturing_certification.includes(field.value)
      ) {
        return (
          <Grid key={index} item xs={12}>
            <UploadWithHint
              label={t(`submission.license.${field.text}`)}
              name={`${key}.${field.text}`}
              destDir={DocumentTypes.CERTIFICATE}
              hints={
                !currentStep
                  ? [t(`submission.guidance.${field.text}`), t('form.info_msg.upload')]
                  : [t(`submission.guidance.${field.text}`)]
              }
              onClickExample={() => {
                let arrayLink: any;
                switch (field.text) {
                  case 'fdaManufacturing':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/FDA+Manufacturing+License_Sor+Bor+1-1.pdf`,
                        pdf: true
                      },
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/FDA+Manufacturing+License_Sor+Bor+2.pdf`,
                        pdf: true
                      }
                    ];
                    break;
                  case 'slaughterhouse':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Slaughterhouse+License+(Pork_Chicken).pdf`,
                        pdf: true
                      }
                    ];
                    break;
                  case 'export_manufacture':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Establishment+Approved+for+Export+Manufacture.pdf`,
                        pdf: true
                      }
                    ];
                    break;

                  case 'gmp_fda':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/GMP+FDA+Row+272.pdf`,
                        pdf: true
                      }
                    ];
                    break;
                  case 'halal':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/HALAL+(Manufacturing+and+Product+Certification).jpg`
                      }
                    ];
                    break;
                  case 'kosher':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/KOSHER.pdf`,
                        pdf: true
                      }
                    ];
                    break;
                  case 'ghps':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Good+Hygiene+Practices+(GHPs)+1.jpg`
                      },

                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Good+Hygiene+Practices+(GHPs)+2.pdf`,
                        pdf: true
                      },
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Good+Hygiene+Practices+(GHPs)+3.pdf`,
                        pdf: true
                      }
                    ];
                    break;
                  case 'haccp':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Hazard+Analysis+Critical+Control+Point+(HACCP).pdf`,
                        pdf: true
                      }
                    ];
                    break;
                  case 'iso':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/ISO+22000_2005.jpg`
                      }
                    ];
                    break;
                  case 'fssc':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/FSSC+22000.jpg`
                      }
                    ];
                    break;
                  case 'brcgs':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Global+Standard+for+Food+Safety+(BRCGS).jpg`
                      }
                    ];
                    break;
                  case 'ifs':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/International+Featured+Standard+(IFS).pdf`,
                        pdf: true
                      }
                    ];
                    break;
                  case 'tis':
                    arrayLink = [
                      {
                        url: ''
                      }
                    ];
                    break;
                  case 'rspoManufacturing':
                    arrayLink = [
                      {
                        url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Roundtable+on+Sustainable+Palm+Oil+(RSPO).jpg`
                      }
                    ];
                    break;
                  default:
                    arrayLink = [
                      {
                        url: ''
                      }
                    ];
                    break;
                }
                setLink(arrayLink);
                handleOpenModal(true);
              }}
              exampleText={`(${t(`submission.example_link_label.${field.text}`)})`}
            />
          </Grid>
        );
      }
      return <></>;
  }
};

export const renderManufacturingCert = (
  manufacturing_certification: string[],
  field: { text: string; value: string },
  index: number,
  key: string
) => {
  const { handleOpenModal, setLink } = useModal();

  if (
    Array.isArray(manufacturing_certification) &&
    manufacturing_certification.includes(field.value)
  ) {
    return (
      <Grid key={index} item xs={12}>
        <UploadWithHint
          label={t(`submission.license.${field.text}`)}
          name={`${key}.${field.text}`}
          destDir={DocumentTypes.CERTIFICATE}
          hints={[t(`submission.guidance.${field.text}`), t('form.info_msg.upload')]}
          onClickExample={() => {
            let arrayLink: any;
            switch (field.text) {
              case 'fdaManufacturing':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/FDA+Manufacturing+License_Sor+Bor+1-1.pdf`,
                    pdf: true
                  },
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/FDA+Manufacturing+License_Sor+Bor+2.pdf`,
                    pdf: true
                  }
                ];
                break;
              case 'slaughterhouse':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Slaughterhouse+License+(Pork_Chicken).pdf`,
                    pdf: true
                  }
                ];
                break;
              case 'export_manufacture':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Establishment+Approved+for+Export+Manufacture.pdf`,
                    pdf: true
                  }
                ];
                break;

              case 'gmp_fda':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/GMP+FDA+Row+272.pdf`,
                    pdf: true
                  }
                ];
                break;
              case 'halal':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/HALAL+(Manufacturing+and+Product+Certification).jpg`
                  }
                ];
                break;
              case 'kosher':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/KOSHER.pdf`,
                    pdf: true
                  }
                ];
                break;
              case 'ghps':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Good+Hygiene+Practices+(GHPs)+1.jpg`
                  },

                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Good+Hygiene+Practices+(GHPs)+2.pdf`,
                    pdf: true
                  },
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Good+Hygiene+Practices+(GHPs)+3.pdf`,
                    pdf: true
                  }
                ];
                break;
              case 'haccp':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Hazard+Analysis+Critical+Control+Point+(HACCP).pdf`,
                    pdf: true
                  }
                ];
                break;
              case 'iso':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/ISO+22000_2005.jpg`
                  }
                ];
                break;
              case 'fssc':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/FSSC+22000.jpg`
                  }
                ];
                break;
              case 'brcgs':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Global+Standard+for+Food+Safety+(BRCGS).jpg`
                  }
                ];
                break;
              case 'ifs':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/International+Featured+Standard+(IFS).pdf`,
                    pdf: true
                  }
                ];
                break;
              case 'tis':
                arrayLink = [
                  {
                    url: ''
                  }
                ];
                break;
              case 'rspoManufacturing':
                arrayLink = [
                  {
                    url: `${process.env.REACT_APP_STORAGE}/examples/manufacturing_certifications/Roundtable+on+Sustainable+Palm+Oil+(RSPO).jpg`
                  }
                ];
                break;
              default:
                arrayLink = [
                  {
                    url: ''
                  }
                ];
                break;
            }
            setLink(arrayLink);
            handleOpenModal(true);
          }}
          exampleText={`(${t(`submission.example_link_label.${field.text}`)})`}
        />
      </Grid>
    );
  }
  return <></>;
};
