import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress, IconButton, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useAuth } from 'auth';
import { FieldInputProps } from 'formik';
import { useDeleteMedia } from 'hooks/upload/useDeleteMutation';
import { useUploadMutation } from 'hooks/upload/useUploadMutation';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { customErrorResponse } from 'utils/customErrorResponse';
import useStyles from './uploadStyles';
interface IUploadProps {
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  field: FieldInputProps<any>;
  uploadText: string;
  destDir?: string;
  showDestDir?: boolean;
}

export type FileUpload = {
  id: number;
  name: string;
  formats: {
    thumbnail: {
      name: string;
      url: string;
    };
  };
  url: string;
};

export type Media = FileUpload[] | null;

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#9CA3AF';
};

const StyledUpload = styled('div')((props: any) => ({
  outline: 'none',
  transition: 'border .24s ease-in-out',
  background: '#fff',
  border: `1px dashed ${getColor(props)}`,
  borderRadius: '4px',
  color: '#4B5563',
  '&:hover': {
    border: '1px dashed #000'
  }
}));

const enum ErrorType {
  FILE_SIZE = 'file-too-large'
}

const fileSizeValidator = (file: File) => {
  const MAX_SIZE = 10 * 1024 * 1024; // 10mbs
  if (file.size > MAX_SIZE) {
    return {
      code: ErrorType.FILE_SIZE,
      message: `File is too large`
    };
  }

  return null;
};

const Upload: React.FC<IUploadProps> = ({
  name,
  setFieldValue,
  field,
  uploadText,
  destDir = '',
  showDestDir = false
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [preview, setPreview] = useState<FileUpload[]>(field.value ?? []);

  useEffect(() => {
    if (!field.value) return;
    return setPreview(field.value);
  }, [field.value]);

  const {
    mutate,
    data,
    isLoading: uploading
  } = useUploadMutation({
    onSuccess: () => {
      console.log('Success');
    },
    onError: (error: any) => {
      toast.error(customErrorResponse(error));
    }
  });

  const { mutate: deleteMedia, isLoading: deleting } = useDeleteMedia({
    onSuccess: () => {
      console.log('Delete Success');
    },
    onError: (error: any) => {
      toast.error(customErrorResponse(error));
    }
  });

  useEffect(() => {
    const oldPreview = preview;
    if (data) {
      setPreview([...oldPreview, ...data]);
      setFieldValue(name, [...oldPreview, ...data]);
    }
  }, [data]);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*, application/pdf',
    validator: fileSizeValidator,
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === ErrorType.FILE_SIZE) {
            toast.error(`Error: ${file.file.name} ${err.message}`);
          }
        });
      });
    },
    onDropAccepted: (files) => {
      const body = new FormData();

      files.forEach((file) => {
        body.append('files', file as Blob, file.name);
      });
      body.append('path', `${user!.id}${destDir ? `/${destDir}` : ''}`);

      mutate(body);
    }
  });
  const classes = useStyles({ isFocused, isDragAccept, isDragReject });

  const handleRemoveImage = (file: FileUpload) => {
    deleteMedia(file.id);
    const filterPreview = preview.filter((item) => item.id !== file.id);
    setPreview(filterPreview);
    setFieldValue(name, filterPreview);
  };

  const loadingCondition = uploading || deleting;

  return (
    <>
      {preview.length === 0 && (
        <>
          {loadingCondition ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="primary" />
            </Box>
          ) : (
            <StyledUpload
              className={preview.length === 0 ? classes.upload__blank : classes.upload__active}
              {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
              <input {...getInputProps()} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <CloudUploadIcon />
                {uploadText === 'upload.attach' ? (
                  <Typography ml={2}>{t(`${uploadText}`)}</Typography>
                ) : (
                  <Typography ml={2} textAlign="center">
                    {t(`${uploadText}`)}
                  </Typography>
                )}
              </Box>
              {destDir && showDestDir && (
                <Typography
                  component="div"
                  variant="caption"
                  sx={{ fontSize: '1.2rem', textAlign: 'center' }}>
                  {destDir}
                </Typography>
              )}
            </StyledUpload>
          )}
        </>
      )}

      {preview.length > 0 && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            {preview.map((file) => (
              <Box className={classes.upload__preview} key={file.id}>
                <IconButton
                  onClick={() => handleRemoveImage(file)}
                  sx={{
                    zIndex: '10',
                    position: 'absolute',
                    padding: '4px',
                    left: '70%'
                    // right: '25%',
                    // top: '25%'
                  }}>
                  <DeleteIcon fontSize="small" color="warning" />
                </IconButton>
                <img
                  className={classes.upload__preview__img}
                  src={file.formats ? file.formats.thumbnail.url : '/imgs/pdf.svg'}
                  alt={file.name}
                />
              </Box>
            ))}

            <Box
              {...getRootProps({ isFocused, isDragAccept, isDragReject })}
              className={`${classes.upload__preview} ${classes.upload__add}`}>
              {loadingCondition ? (
                <IconButton color="primary" disableRipple={true}>
                  <CircularProgress />
                </IconButton>
              ) : (
                <IconButton color="primary" aria-label="add more button" disableRipple={true}>
                  <AddBoxRoundedIcon fontSize="large" />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Upload;
