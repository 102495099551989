import { Typography, useTheme } from '@mui/material';
import Footer from 'components/Layout/Footer';
import Header from 'components/Layout/Header';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const Layout: React.FC = ({ children }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      {children}
      <Footer />
      <CookieConsent
        style={{
          background: '#fff',
          textAlign: 'left',
          marginBottom: '20px',
          borderRadius: '10px',
          marginRight: '30px',
          boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 -8px 10px -6px rgb(0 0 0 / 0.1)',
          alignItems: 'center',
          width: '90%',
          margin: '3% auto' /* Will not center vertically and won't work in IE6/7. */,
          left: 0,
          right: 0
        }}
        buttonText={t('cookie_banner.accept')}
        buttonStyle={{
          color: '#fff',
          background: theme.palette.primary.main,
          fontWeight: 'bold',
          padding: '15px 30px'
        }}
        enableDeclineButton
        declineButtonText={t('cookie_banner.decline')}
        declineButtonStyle={{
          color: '#333333',
          background: '#fff',
          border: '1px solid #333333',
          fontWeight: 'bold',
          padding: '15px 30px'
        }}>
        <Typography
          component={'p'}
          sx={{
            color: '#333333',
            fontSize: { xs: 20, md: 18 },
            lineHeight: '1.5rem',
            fontWeight: 400
          }}>
          {t('cookie_banner.text')}
        </Typography>
      </CookieConsent>
    </>
  );
};

export default Layout;
