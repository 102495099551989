import { FormControl, FormHelperText, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FastField, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

interface IInputProps {
  label: string;
  name: string;
  type?: string;
  fullWidth?: boolean;
  required?: boolean;
  InputLabelProps?: any;
}

const useStyles = makeStyles(() => ({
  input__label: {
    fontSize: '24px!important',
    fontWeight: '700!important',
    marginTop: '-6px!important'
  },
  input__text: {
    fontSize: '24px!important',
    fontWeight: '700!important'
  },
  error_text: {
    fontSize: '20px!important',
    fontWeight: '700!important'
  }
}));

const Input = ({
  label,
  name,
  type,
  fullWidth = true,
  InputLabelProps = {},
  ...props
}: IInputProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FastField name={name}>
      {({ field, meta }: FieldProps) => {
        const errorText = meta.error && meta.touched ? meta.error : '';

        return (
          <FormControl error={!!errorText} fullWidth={fullWidth}>
            <TextField
              id={name}
              label={t(label)}
              type={type}
              error={!!errorText}
              autoComplete="new-password"
              {...field}
              {...props}
              InputLabelProps={{
                className: classes.input__label,
                ...InputLabelProps
              }}
              InputProps={{
                className: classes.input__text
              }}
            />
            {!!errorText && (
              <FormHelperText className={classes.error_text}>
                {t(`form.error_msg.${errorText}`)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default Input;
