import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  contact__container: {
    background: '#F8F8F8'
  },
  contact__sidemenu: {
    boxShadow: theme.boxShadow.static,
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(2.5)} ${theme.spacing(2)} ${theme.spacing(8)}`,
      borderRadius: `${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(
        0
      )} ${theme.spacing(0)}`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
      borderRadius: theme.spacing(5)
    }
  },
  contact__box__container: {
    [theme.breakpoints.up('xs')]: {
      padding: `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(0)} !important`
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(10)} ${theme.spacing(0)} !important`
    }
  }
}));

export default useStyles;
