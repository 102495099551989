import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import {
  BLANK_PRODUCT,
  productBarcodeOptions,
  productCategoryOptions,
  productCertificationOptions,
  productDistributionOptions,
  productDistributionTypeOptions,
  productExpirationOptions,
  productTempOptions,
  productTestingOptions,
  productTypeOptions
} from 'components/RegistrationWrapper/initialValues/product';
import { useStyles } from 'components/shared/Form/Checkbox';
import Controls from 'components/shared/Form/FormControl';
import { DocumentTypes } from 'constants/documents';
import { NoOptions, productField } from 'constants/submissionField';
import { useModal } from 'contexts/modal';
import {
  FastField,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  useFormikContext
} from 'formik';
import { useProduct } from 'hooks/product/useProduct';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct, IProductInitValue, ProductCertificateObjType } from 'types/Product';
import UploadWithHint from '../UploadWithHint';

const Product = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { productCertificate } = useProduct();
  const formik = useFormikContext<IProductInitValue>();

  const { handleOpenModal, setLink } = useModal();

  // If select NO_CERTIFICATE then reset "other_certification"
  React.useEffect(() => {
    formik.values.products.forEach((product, index) => {
      const { productCertifications } = product;
      if (productCertifications.includes(NoOptions.NO_CERTIFICATE)) {
        formik.setFieldValue(`products.${index}.other_certification.value`, '');
        formik.setFieldValue(`products.${index}.other_certification.is_checked`, false);
      }
    });
  }, [formik.values.products]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" component="p">
          {t('product.uploadDocument.subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" component="p">
          {t('submission.product.productUploadProfile')}
        </Typography>
        <Controls.Upload name="productUploadProfile" destDir={DocumentTypes.PRODUCT} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" component="p">
          {t('submission.product.productUploadCatalog')}
        </Typography>
        <Controls.Upload name="productUploadCatalog" destDir={DocumentTypes.PRODUCT} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" component="p" align="center">
          {t('product.uploadDocument.note')}
        </Typography>
        <Divider
          sx={{
            mt: 3,
            mb: 3
          }}
        />
      </Grid>

      <FieldArray name="products">
        {(fieldArray: FieldArrayRenderProps) => {
          const { push, remove, form } = fieldArray;
          const { values } = form;
          const { products } = values;
          return (
            <React.Fragment>
              {products.map((product: IProduct, index: number) => {
                const { hasCertification } = product;
                // Show options if user already pick no/yes && not pick "no"
                const showCertificationOptions = hasCertification === true;
                return (
                  <React.Fragment key={`product-${index}`}>
                    <Grid item xs={12} mt={3}>
                      <Typography variant="h3" component="div">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography flexGrow={1} variant="h3" component="h3">
                            {t('product.detail.label')}
                          </Typography>
                          <Box flexGrow={0}>
                            {index > 0 && (
                              <IconButton
                                size="small"
                                onClick={() => remove(index)}
                                aria-label="delete"
                                color="primary">
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mb={3}>
                      <Typography variant="body2" component="p">
                        {t('product.detail.subtitle')}
                      </Typography>
                      <UploadWithHint
                        label={t('product.upload.label')}
                        name={`products[${index}].photos`}
                        hints={[t('product.upload.list3')]}
                        destDir={DocumentTypes.PRODUCT}
                        onClickExample={() => {
                          setLink([
                            {
                              url: `${process.env.REACT_APP_STORAGE}/examples/products/product_picture1.webp`
                            },
                            {
                              url: `${process.env.REACT_APP_STORAGE}/examples/products/product_picture2.webp`
                            }
                          ]);
                          handleOpenModal(true);
                        }}
                        exampleText={t('product.upload.list2')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        required
                        name={`products[${index}].brandName`}
                        label="product.detail.brandName.label"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name={`products[${index}].productName`}
                        label="product.detail.productName.label"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        required
                        name={`products[${index}].weight`}
                        label="product.detail.productWeight.label"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        required
                        name={`products[${index}].shelfLife`}
                        label="product.detail.shelfLife.label"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name={`products[${index}].productCapacity`}
                        label="product.detail.capacity.label"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.SelectFormik
                        name={`products[${index}].storageTemp`}
                        label="product.detail.productStorageTemp.label"
                        InputLabelProps={{ required: true }}
                        required
                        options={productTempOptions}
                      />
                    </Grid>
                    {values.products[index].storageTemp.includes('Other') && (
                      <Grid item xs={12}>
                        <Controls.Input
                          name={`products[${index}].storageTempOther`}
                          label={'product.detail.productStorageTemp.label'}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Controls.SelectFormik
                        name={`products[${index}].productType`}
                        label="product.detail.productType.label"
                        InputLabelProps={{ required: true }}
                        required
                        options={productTypeOptions}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.SelectFormik
                        name={`products[${index}].productCategory`}
                        label="product.detail.productCategory.label"
                        options={productCategoryOptions}
                        InputLabelProps={{ required: true }}
                        required
                      />
                      <Typography fontSize={18}>{t('product.link_faq')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name={`products[${index}].retailSellingPrice`}
                        label="product.detail.retailSellingPrice.label"
                        required
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controls.RadioButton
                        row={true}
                        name={`products[${index}].barcode`}
                        label="product.detail.productBarcode.label"
                        FormLabelProps={{ required: true }}
                        options={productBarcodeOptions}
                      />
                      {formik.values.products[index].barcode && (
                        <Grid item xs={12} mt={1}>
                          <Controls.Input
                            name={`products[${index}].barcodeValue`}
                            label="product.detail.productBarcodeValue.label"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.RadioButton
                        row={true}
                        name={`products[${index}].expiryDate`}
                        label="product.detail.productExipration.label"
                        FormLabelProps={{ required: true }}
                        options={productExpirationOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.RadioButton
                        row={true}
                        name={`products[${index}].testing`}
                        label="product.detail.productTesting.label"
                        FormLabelProps={{ required: true }}
                        options={productTestingOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controls.RadioButton
                        row={true}
                        name={`products[${index}].distribution`}
                        label="product.detail.productDistribution.label"
                        FormLabelProps={{ required: true }}
                        options={productDistributionOptions}
                      />
                      {formik.values.products[index].distribution && (
                        <Grid item xs={12} mt={1}>
                          <Controls.SelectFormik
                            name={`products[${index}].distributionType`}
                            label="product.detail.productDistributionType.label"
                            InputLabelProps={{ required: true }}
                            required
                            options={productDistributionTypeOptions}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} mt={3}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h3" component="h3">
                            {t('product.detail.productCertification.label')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <RadioGroup row name="toggle-currentSaleChannel">
                            <FormControlLabel
                              value={NoOptions.NO_CERTIFICATE}
                              sx={{ flexGrow: 1 }}
                              control={
                                <Radio
                                  checked={product.hasCertification === false}
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      formik.setFieldValue(
                                        `products.${index}.hasCertification`,
                                        false
                                      );
                                      formik.setFieldValue(
                                        `products.${index}.productCertifications`,
                                        [NoOptions.NO_CERTIFICATE]
                                      );
                                    }
                                  }}
                                />
                              }
                              label={t('license.farmingCertification.noLicense.label')}
                            />
                            <FormControlLabel
                              value={'yes'}
                              sx={{ flexGrow: 1 }}
                              control={
                                <Radio
                                  checked={product.hasCertification === true}
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      formik.setFieldValue(
                                        `products.${index}.hasCertification`,
                                        true
                                      );
                                      formik.setFieldValue(
                                        `products.${index}.productCertifications`,
                                        []
                                      );
                                    }
                                  }}
                                />
                              }
                              label={t('license.farmingCertification.hasLicense.label')}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    {showCertificationOptions && (
                      <Grid item xs={12}>
                        <Controls.Checkbox
                          name={`products.${index}.productCertifications`}
                          label="product.detail.productCertification.subtitle"
                          options={productCertificationOptions}
                          isGrid={true}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} md={6}></Grid>

                    {showCertificationOptions && (
                      <Grid item xs={12} md={6} ml={{ xs: 0, md: -1 }}>
                        <FastField name={`products.${index}.other_certification.is_checked`}>
                          {({ field }: FieldProps) => {
                            return (
                              <FormControl>
                                <FormControlLabel
                                  label=""
                                  control={
                                    <Checkbox
                                      {...field}
                                      checked={field.value}
                                      onChange={(event) => {
                                        field.onChange(event);
                                        if (!event.target.checked) {
                                          formik.setFieldValue(
                                            `products[${index}]other_certification.value`,
                                            ''
                                          );
                                        }
                                      }}
                                    />
                                  }
                                  sx={{ mr: 0 }}
                                />
                              </FormControl>
                            );
                          }}
                        </FastField>
                        <Field name={`products.${index}.other_certification.value`}>
                          {({ field, meta }: FieldProps) => {
                            const errorText = meta.error && meta.touched ? meta.error : '';
                            return (
                              <>
                                <TextField
                                  focused={!!field.value}
                                  disabled={
                                    !formik.values.products[index].other_certification.is_checked
                                  }
                                  margin="none"
                                  label={t('form.other')}
                                  variant="outlined"
                                  size="small"
                                  InputLabelProps={{
                                    className: classes.input__label
                                  }}
                                  InputProps={{
                                    className: classes.input__text
                                  }}
                                  {...field}
                                  value={field.value || ''}
                                />
                                {formik.values.products[index].other_certification.is_checked &&
                                  !!errorText && (
                                    <FormHelperText className={classes.error_text} error>
                                      {t(`form.error_msg.${errorText}`)}
                                    </FormHelperText>
                                  )}
                              </>
                            );
                          }}
                        </Field>
                      </Grid>
                    )}
                    {productField.map((field, indexP) => {
                      if (product.productCertifications.includes(field.value)) {
                        return (
                          <Grid key={indexP} item xs={12}>
                            <UploadWithHint
                              label={t(`submission.product.${field.text}`)}
                              name={`products[${index}].certification_photo.${field.text}`}
                              destDir={DocumentTypes.PRODUCT}
                              hints={[
                                t(`submission.guidance.${field.text}`),
                                t('form.info_msg.upload')
                              ]}
                              onClickExample={() => {
                                setLink(
                                  productCertificate[
                                    field.text as keyof ProductCertificateObjType
                                  ] || []
                                );
                                handleOpenModal(true);
                              }}
                              exampleText={`(${t(`submission.example_link_label.${field.text}`)})`}
                            />
                            {field.value === 'FDA' && (
                              <Grid item xs={12} mt={3}>
                                <UploadWithHint
                                  label={t(`submission.product.fda_label`)}
                                  name={`products[${index}].certification_photo.fda_label`}
                                  destDir={DocumentTypes.PRODUCT}
                                  hints={[
                                    t(`submission.guidance.fda_label`),
                                    t('form.info_msg.upload')
                                  ]}
                                  onClickExample={() => {
                                    setLink(productCertificate['fda_label']);
                                    handleOpenModal(true);
                                  }}
                                  exampleText={`(${t(`submission.example_link_label.fda_label`)})`}
                                />
                              </Grid>
                            )}
                          </Grid>
                        );
                      }
                      return <></>;
                    })}
                    {formik.values.products[index].other_certification.is_checked && (
                      <Grid item xs={12}>
                        <UploadWithHint
                          label={t('product.uploadDocument.nameLabel', {
                            name: formik.values.products[index].other_certification.value
                              ? `(${formik.values.products[index].other_certification.value})`
                              : ' '
                          })}
                          name={`products.${index}.other_certification.upload`}
                          destDir={DocumentTypes.PRODUCT}
                          hints={[t('submission.guidance.other'), t('form.info_msg.upload')]}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
              <Grid item xs={12} pt={3}>
                <Divider sx={{ my: 3 }} />
                <IconButton
                  aria-label="add-more-farm"
                  edge="start"
                  color="primary"
                  size="small"
                  onClick={() => push(BLANK_PRODUCT)}>
                  <AddBoxSharpIcon />
                  <Typography pl={1} color="primary">
                    {t('product.addProduct')}
                  </Typography>
                </IconButton>
                <Divider sx={{ my: 3 }} />
              </Grid>
            </React.Fragment>
          );
        }}
      </FieldArray>
    </Grid>
  );
};

export default Product;
