export const usePartnerlogo = () => {
  const partnerLogoItems = [
    {
      image: `${process.env.REACT_APP_STORAGE}/static/homepage_partnerlogo/partner_gsb_bank.png`,
      alt: 'gsb_bank_logo',
      link: 'https://www.gsb.or.th/'
    },
    {
      image: `${process.env.REACT_APP_STORAGE}/static/homepage_partnerlogo/partner_sme.png`,
      alt: 'sme_logo',
      link: 'https://www.smeeastern.com/'
    },
    {
      image: `${process.env.REACT_APP_STORAGE}/static/homepage_partnerlogo/partner_nfi.png`,
      alt: 'nfi_logo',
      link: 'https://www.nfi.or.th'
    },
    {
      image: `${process.env.REACT_APP_STORAGE}/static/homepage_partnerlogo/partner_thai_chamber.png`,
      alt: 'thai_chamber_logo',
      link: 'https://mr.thaichamber.org/'
    },

    {
      image: `${process.env.REACT_APP_STORAGE}/static/homepage_partnerlogo/partner_martlocal.png`,
      alt: 'martlocal_logo',
      link: 'https://www.dbd.go.th/'
    }
  ];
  return [partnerLogoItems] as const;
};
