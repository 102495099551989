import { useTranslation } from 'react-i18next';

export const useValues = () => {
  const { t } = useTranslation();
  const content = [
    {
      id: 'what-you-get-1',
      title: t('what_you_get_page.content1_1'),
      detail: t('what_you_get_page.content1_2'),
      images: `${process.env.REACT_APP_STORAGE}/static/what-you-get/what-you-get-1-v2.svg`
    },
    {
      id: 'what-you-get-2',
      title: t('what_you_get_page.content2_1'),
      detail: t('what_you_get_page.content2_2'),
      images: `${process.env.REACT_APP_STORAGE}/static/what-you-get/what-you-get-2-v2.svg`
    },
    {
      id: 'what-you-get-3',
      title: t('what_you_get_page.content3_1'),
      detail: t('what_you_get_page.content3_2'),
      images: `${process.env.REACT_APP_STORAGE}/static/what-you-get/what-you-get-3-v2.svg`
    },
    {
      id: 'what-you-get-4',
      title: t('what_you_get_page.content4_1'),
      detail: t('what_you_get_page.content4_2'),
      images: `${process.env.REACT_APP_STORAGE}/static/what-you-get/what-you-get-4-v2.svg`
    },
    {
      id: 'what-you-get-5',
      title: t('what_you_get_page.content5_1'),
      detail: t('what_you_get_page.content5_2'),
      images: `${process.env.REACT_APP_STORAGE}/static/what-you-get/what-you-get-5-v2.svg`
    }
  ];

  return [content] as const;
};
