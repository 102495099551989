import { currentSaleChannelOptions } from 'components/RegistrationWrapper/initialValues/license';
import { NoOptions } from 'constants/submissionField';

export interface ILicenseResponse {
  certificates: any;
  farming_certification: string;
  manufacturing_certification: string;
  sales_channels: string;
}
/**
 * Convert stringified data into form-ready format
  @params data
    1. Old format - No sale Channel: ["No Sale Channel"]
    2. Old format - Has Channel: ["Supermarkets / Hypermarkets", "Online"]
    3. New format - No sale Channel: "No Sale Channel"
    3. New format - Has Channel: [ { type: "Supermarkets / Hypermarkets", detail: "Big C" }, { type: "Online", detail: "Lazada" }]
 * @returns { "Supermarkets / Hypermarkets": "Big C", "Online": "Lazada" }
 */
const parseSalesChannels = (data: string): null | string | any[] => {
  const parsed = JSON.parse(data);

  if (parsed === null) return null;
  if (parsed === NoOptions.NO_SALE_CHANNEL) return NoOptions.NO_SALE_CHANNEL;

  return currentSaleChannelOptions.map((option) => {
    const channelType = option.value;

    // Legacy Format
    const legacyChannelValue = parsed.find((channel: any) => channel === channelType);
    if (legacyChannelValue) return { type: channelType, selected: true, detail: '' };

    // New Format
    const channelValue = parsed.find(
      (channel: any) => channel === channelType || channel.type === channelType
    );
    return {
      type: channelType,
      selected: channelValue ? channelValue.selected : false,
      detail: channelValue ? channelValue.detail : ''
    };
  });
};

export const formatLicenseResponse = (response: ILicenseResponse) => {
  const { farming_certification, manufacturing_certification, sales_channels, certificates } =
    response;

  return {
    ...response,
    farming_certification: JSON.parse(farming_certification),
    manufacturing_certification: JSON.parse(manufacturing_certification),
    sales_channels: parseSalesChannels(sales_channels),
    certificates: certificates
  };
};
