import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FastField, FieldProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IRadioButtonProps {
  label: string;
  name: string;
  options?: any;
  FormLabelProps?: any;
  row?: boolean;
}

const useStyles = makeStyles(() => ({
  radio__label: {
    fontSize: '1.5rem!important',
    fontWeight: '700!important'
  },
  error_text: {
    fontSize: '1.5rem!important',
    fontWeight: '700!important',
    margin: '0!important'
  }
}));

const RadioButton = ({
  label,
  name,
  options,
  row,
  FormLabelProps = {},
  ...props
}: IRadioButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FastField name={name}>
      {({ form, field, meta }: FieldProps) => {
        const errorText = meta.error ? meta.error : '';
        return (
          <FormControl error={!!errorText}>
            <FormLabel className={classes.radio__label} id={name} {...FormLabelProps}>
              {t(label)}
            </FormLabel>
            <RadioGroup
              row={row}
              aria-labelledby={name}
              id={name}
              {...field}
              {...props}
              onChange={(event) => {
                const { value } = event.currentTarget;
                switch (value) {
                  case 'true':
                    return form.setFieldValue(name, true);
                  case 'false':
                    return form.setFieldValue(name, false);
                  default:
                    return form.setFieldValue(name, value);
                }
              }}>
              {options &&
                options.map((item: any) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={t(item.label)}
                  />
                ))}
            </RadioGroup>
            {!!errorText && (
              <FormHelperText className={classes.error_text}>
                {t(`form.error_msg.${errorText}`)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </FastField>
  );
};

export default RadioButton;
