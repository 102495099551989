import React from "react";

export default function FacebookFeed() {
  
  const appId = process.env.REACT_APP_FACEBOOK_APPID
  const pageUrl = encodeURIComponent(process.env.REACT_APP_FACEBOOK_PAGEURL || 'https://www.facebook.com/topstongtin')

  if (!appId || !pageUrl) return null

  const width = Math.min(window.innerWidth, 500)

  return (
    <iframe
      src={`https://www.facebook.com/plugins/page.php?href=${pageUrl}&tabs=timeline&width=${width}&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=${appId}`}
      width="100%"
      height="800"
      style={{ border: "none", overflow: "hidden" }}
      scrolling="no"
      frameBorder="0"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    >
    </iframe>
  )
}