import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '90%',
    margin: 'auto',
    '& .MuiStepLabel-label': {
      color: theme.palette.primary.main,
      fontSize: '1.5rem!important'
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: theme.palette.secondary.main
    },
    '& .Mui-completed': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontWeight: '700 !important'
    },
    '& .Mui-active': {
      fontWeight: '700 !important'
    }
  }
}));

export default useStyles;
