import productService from 'api/services/ProductService';
import { useMutation } from 'react-query';
import { IProductInitValue } from 'types/Product';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
}

export const useUpdateProduct = ({ onSuccess, onError }: Props) => {
  return useMutation(
    (body: IProductInitValue & { id: number }) => productService.updateProduct(body.id, body),
    {
      onError,
      onSuccess
    }
  );
};
