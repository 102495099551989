import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FastField, Field, FieldProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISelectProps {
  label: string;
  name: string;
  options?: any;
  required?: boolean;
  InputLabelProps?: any;
  renderField?: boolean;
}

interface IWrapperProps {
  name: string;
  renderField?: boolean;
}

const Wrapper: React.FC<IWrapperProps> = ({ children, ...props }) => {
  if (props.renderField) {
    return <Field name={props.name}>{children}</Field>;
  }

  return <FastField name={props.name}>{children}</FastField>;
};

const useStyles = makeStyles(() => ({
  input__label: {
    fontSize: '24px!important',
    fontWeight: '700!important',
    marginTop: '-6px!important'
  },
  select__text: {
    fontSize: '24px!important',
    fontWeight: '700!important'
  },
  item__text: {
    fontSize: '24px!important',
    fontWeight: '700!important'
  },
  error_text: {
    fontSize: '20px!important',
    fontWeight: '700!important'
  }
}));

const SelectFormik = ({
  label,
  name,
  options,
  renderField,
  InputLabelProps = {},
  required,
  ...props
}: ISelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Wrapper name={name} renderField={renderField}>
      {({ field, meta }: FieldProps) => {
        const errorText = meta.error && meta.touched ? meta.error : '';

        return (
          <FormControl error={!!errorText} fullWidth required={InputLabelProps.required || required}>
            <InputLabel className={classes.input__label} id={name} {...InputLabelProps}>
              {t(label)}
            </InputLabel>
            <Select
              labelId={name}
              id={name}
              label={t(label)}
              error={!!errorText}
              displayEmpty
              defaultValue=""
              className={classes.select__text}
              {...field}
              {...props}>
              {options &&
                options.map((item: any) => (
                  <MenuItem className={classes.item__text} key={item.id} value={item.value}>
                    {t(item.label)}
                  </MenuItem>
                ))}
            </Select>
            {!!errorText && (
              <FormHelperText className={classes.error_text}>
                {t(`form.error_msg.${errorText}`)}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Wrapper>
  );
};

export default SelectFormik;
