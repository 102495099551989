import { SelectOptions } from 'constants/controls';
import { Manufacturing } from 'constants/licenses';
import { NoOptions } from 'constants/submissionField';
import ILicense from 'types/License';
import * as Yup from 'yup';

export const initialValuesLicense: ILicense = {
  farming_certification: null,
  manufacturing_certification: null,
  sales_channels: null,
  certificates: {
    gap: [],
    gap_thai: [],
    gap_global: [],
    gap_for_livestock: [],
    gap_for_fisheries: [],
    pgs_standard: [],
    pgs_sdg: [],
    haccp: [],
    iso: [],
    organic_thai: [],
    organic_ifoam: [],
    organic_european: [],
    organic_canada: [],
    organic_us: [],
    organic_livestock: [],
    rspo: [],
    fda: [],
    slaughterhouse: [],
    export_manufacture: [],
    gmp_fda: [],
    halal: [],
    kosher: [],
    ghps: [],
    fssc: [],
    brcgs: [],
    ifs: [],
    tis: [],
    rspoManufacturing: [],
    fdaManufacturing: []
  },
  farming_other: {
    is_checked: false,
    value: null,
    upload: null
  },
  manufacturing_other: {
    is_checked: false,
    value: null,
    upload: null
  },
  distribution_reach: ''
};

export const licenseSchema = Yup.object({
  sales_channels: Yup.lazy((salesChannelsValue) => {
    switch (typeof salesChannelsValue) {
      case 'string':
        return Yup.string().oneOf([NoOptions.NO_SALE_CHANNEL]);
      default:
        return Yup.array()
          .min(1, 'choose_option')
          .of(
            Yup.object().shape({
              selected: Yup.boolean(),
              type: Yup.string().required(),
              detail: Yup.string().when('selected', {
                is: true,
                then: Yup.string().required('required'),
                otherwise: Yup.string().notRequired()
              })
            })
          );
    }
  }),
  distribution_reach: Yup.string().when('sales_channels', (salesChannelsValue) => {
    if (salesChannelsValue === null) return Yup.string().notRequired();
    if (salesChannelsValue === NoOptions.NO_SALE_CHANNEL) return Yup.string().notRequired();

    // if pick one, and it's "Export"
    if (
      salesChannelsValue.some((c: any) => c.selected === true && c.type === Manufacturing.EXPORT)
    ) {
      return Yup.string().notRequired();
    }
    return Yup.string().required('required');
  }),
  certificates: Yup.object({
    gap: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('GAP (Vegetable & Fruits)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_thai: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Thai GAP')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_global: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Global GAP')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    pgs_standard: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('PGS Standard')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    pgs_sdg: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('SDGsPGS')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_thai: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Organic Thailand')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_ifoam: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('IFOAM Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_european: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('European Union Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_canada: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Canada Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_us: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('US Programme')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    organic_livestock: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Organic Livestock')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_for_livestock: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Good Agricultural Practices for Farm  (Livestock and LAYER FARM)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gap_for_fisheries: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Good Agricultural Practices for Farm (Fisheries)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    rspo: Yup.array().when('farming_certification', (value) => {
      if (value) {
        return value.includes('Roundtable on Sustainable Palm Oil (RSPO)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    haccp: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Hazard Analysis Critical Control Point (HACCP)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    iso: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('ISO 22000:2005')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    fdaManufacturing: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes(Manufacturing.FDA)
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    slaughterhouse: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Slaughterhouse License (Pork / Chicken)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    export_manufacture: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Establishment Approved for Export Manufacture')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    gmp_fda: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('GMP FDA')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    halal: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('HALAL')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    kosher: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('KOSHER')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    ghps: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Good Hygiene Practices (GHPs)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    fssc: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('FSSC 22000')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    brcgs: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Global Standard for Food Safety (BRCGS)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    ifs: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('International Featured Standard (IFS)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    }),
    rspoManufacturing: Yup.array().when('manufacturing_certification', (value) => {
      if (value) {
        return value.includes('Roundtable on Sustainable Palm Oil (RSPO) (Manufacturing)')
          ? Yup.array().min(1, 'required_photo')
          : Yup.array().notRequired();
      }
      return Yup.array().notRequired();
    })
  }),
  farming_other: Yup.object({
    is_checked: Yup.boolean(),
    value: Yup.string().when('is_checked', (value) => {
      if (value) {
        return value === true
          ? Yup.string().nullable().required('required')
          : Yup.string().nullable().notRequired();
      }
      return Yup.string().nullable().notRequired();
    })
  }),
  manufacturing_other: Yup.object({
    is_checked: Yup.boolean().required(),
    value: Yup.string().when('is_checked', (value) => {
      if (value) {
        return value === true
          ? Yup.string().nullable().required('required')
          : Yup.string().nullable().notRequired();
      }
      return Yup.string().nullable().notRequired();
    })
  })
});

export const farmingCertificationOptions = {
  gapOptions: [
    {
      id: 1,
      label: 'license.farmingCertification.GAP.option1',
      value: 'GAP (Vegetable & Fruits)'
    },
    {
      id: 2,
      label: 'license.farmingCertification.GAP.option2',
      value: 'Thai GAP'
    },
    {
      id: 3,
      label: 'license.farmingCertification.GAP.option3',
      value: 'Global GAP'
    },
    {
      id: 4,
      label: 'license.farmingCertification.livestockAndLayerFarm.label',
      value: 'Good Agricultural Practices for Farm  (Livestock and LAYER FARM)'
    },
    {
      id: 5,
      label: 'license.farmingCertification.fisheries.label',
      value: 'Good Agricultural Practices for Farm (Fisheries)'
    }
  ],
  oranicOptions: [
    {
      id: 1,
      label: 'license.farmingCertification.organic.option1',
      value: 'Organic Thailand'
    },
    {
      id: 2,
      label: 'license.farmingCertification.organic.option2',
      value: 'IFOAM Programme'
    },
    {
      id: 3,
      label: 'license.farmingCertification.organic.option3',
      value: 'European Union Programme'
    },
    {
      id: 4,
      label: 'license.farmingCertification.organic.option4',
      value: 'Canada Programme'
    },
    {
      id: 5,
      label: 'license.farmingCertification.organic.option5',
      value: 'US Programme'
    },
    {
      id: 6,
      label: 'license.farmingCertification.organicLivestock.label',
      value: 'Organic Livestock'
    }
  ],
  pgsOptions: [
    {
      id: 1,
      label: 'license.farmingCertification.PGS.option1',
      value: 'PGS Standard'
    },
    {
      id: 2,
      label: 'license.farmingCertification.PGS.option2',
      value: 'SDGsPGS'
    }
  ],
  rspoOptions: [
    {
      id: 1,
      label: 'license.farmingCertification.RSPO.label',
      value: 'Roundtable on Sustainable Palm Oil (RSPO)'
    }
  ]
};

export const manufacturingCertificationOptions: SelectOptions[] = [
  {
    id: 1,
    label: 'license.manufacturingCertification.FDA',
    value: Manufacturing.FDA
  },

  {
    id: 2,
    label: 'license.manufacturingCertification.slaughter',
    value: 'Slaughterhouse License (Pork / Chicken)'
  },
  {
    id: 3,
    label: 'license.manufacturingCertification.establishment',
    value: 'Establishment Approved for Export Manufacture'
  },
  {
    id: 4,
    label: 'license.manufacturingCertification.GMP_FDA',
    value: 'GMP FDA'
  },
  {
    id: 5,
    label: 'license.manufacturingCertification.HALAL',
    value: 'HALAL'
  },
  {
    id: 14,
    label: 'license.manufacturingCertification.KOSHER',
    value: 'KOSHER'
  },
  {
    id: 6,
    label: 'license.manufacturingCertification.GHPs',
    value: 'Good Hygiene Practices (GHPs)'
  },
  {
    id: 7,
    label: 'license.manufacturingCertification.HACCP',
    value: 'Hazard Analysis Critical Control Point (HACCP)'
  },
  {
    id: 8,
    label: 'license.manufacturingCertification.ISO',
    value: 'ISO 22000:2005'
  },
  {
    id: 9,
    label: 'license.manufacturingCertification.FSSC',
    value: 'FSSC 22000'
  },
  {
    id: 10,
    label: 'license.manufacturingCertification.BRCGS',
    value: 'Global Standard for Food Safety (BRCGS)'
  },
  {
    id: 11,
    label: 'license.manufacturingCertification.IFS',
    value: 'International Featured Standard (IFS)'
  },
  {
    id: 12,
    label: 'license.manufacturingCertification.RSPO',
    value: 'Roundtable on Sustainable Palm Oil (RSPO) (Manufacturing)'
  }
];

export const currentSaleChannelOptions: SelectOptions[] = [
  {
    id: 1,
    label: 'license.currentSaleChannel.small',
    value: 'Mom & Pop Shops / Small Grocery Stores'
  },
  {
    id: 2,
    label: 'license.currentSaleChannel.convenience',
    value: 'Convenience Stores / Mini Marts'
  },
  {
    id: 3,
    label: 'license.currentSaleChannel.supermarket',
    value: 'Supermarkets / Hypermarkets'
  },
  {
    id: 4,
    label: 'license.currentSaleChannel.wetMarket',
    value: 'Wet Market / Flea Market'
  },
  {
    id: 8,
    label: 'license.currentSaleChannel.horeca',
    value: 'HoReCa / Wholesale Distribution'
  },
  {
    id: 9,
    label: 'license.currentSaleChannel.online',
    value: 'Online'
  },
  {
    id: 11,
    label: 'license.currentSaleChannel.export',
    value: 'Export'
  },
  {
    id: 12,
    label: 'license.currentSaleChannel.other',
    value: 'Others'
  }
];

export const distributionReachOptions = [
  {
    id: 1,
    label: 'license.distribution.local',
    value: 'Local'
  },
  {
    id: 2,
    label: 'license.distribution.provincial',
    value: 'Provincial'
  },
  {
    id: 3,
    label: 'license.distribution.regional',
    value: 'Regional'
  },
  {
    id: 4,
    label: 'license.distribution.national',
    value: 'National'
  }
];
