import React, { useState } from 'react';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination } from 'swiper';

import Swiper, { Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Avatar, Box, IconButton, Stack, styled } from '@mui/material';
import useStyles from './HomeCarouselStyles';

interface slideItem {
  image: string;
  alt: string;
}

interface HomeCarouselProps {
  slideItems: slideItem[];
}

const ArrowSwitcher = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  fill: theme.palette.secondary.main,
  color: theme.palette.secondary.main
}));

const AvatarCustom = styled(Avatar)(({ theme }) => ({
  width: '100%',
  paddingRight: '1px',
  borderRadius: '20px',
  [theme.breakpoints.up('xs')]: {
    height: '245px'
  },
  [theme.breakpoints.up('md')]: {
    height: '416px'
  }
}));

interface SwiperButtonProps {
  swiper: Swiper | null;
  type: 'slidePrev' | 'slideNext';
  className?: string;
}

// install Swiper modules
Swiper.use([Autoplay]);

const SwiperButton: React.FC<SwiperButtonProps> = ({ swiper, type, ...props }) => {
  return (
    <ArrowSwitcher disableRipple onClick={() => swiper && swiper[type]()} {...props}>
      {type === 'slidePrev' ? (
        <img src="/icons/carousel_previous.svg" />
      ) : (
        <img src="/icons/carousel_next.svg" />
      )}
    </ArrowSwitcher>
  );
};

const HomeCarousel: React.FC<HomeCarouselProps> = ({ slideItems }) => {
  const classes = useStyles();
  const [swiperIns, setSwiperIns] = useState<Swiper | null>(null);

  return (
    <Box className={classes.box__container}>
      <Stack direction="row" alignItems="center" sx={{ width: '100%', position: 'relative' }}>
        <Box display={'inline-block'} position="absolute" left={20} zIndex={10}>
          <SwiperButton className="custom-arrow" type="slidePrev" swiper={swiperIns} />
        </Box>
        <SwiperReact
          style={{
            borderRadius: '20px'
          }}
          modules={[Navigation, Pagination]}
          onInit={(swiper: any) => setSwiperIns(swiper)}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          loop
          autoHeight
          // navigation
          pagination={{ clickable: true }}>
          {slideItems.map(({ image, alt }, index) => (
            <SwiperSlide
              key={`${alt}-${index}`}
              style={{
                width: '100%'
              }}>
              <AvatarCustom src={image} alt={alt} />
            </SwiperSlide>
          ))}
        </SwiperReact>
        <Box display={'inline-block'} position="absolute" right={20} zIndex={10}>
          <SwiperButton className="custom-arrow" type="slideNext" swiper={swiperIns} />
        </Box>
      </Stack>
      <Box className={classes.box__background} height={{ md: '410px', xs: '245px' }}></Box>
    </Box>
  );
};

export default HomeCarousel;
