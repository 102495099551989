import { Box, Container, Typography } from '@mui/material';
import useStyles from 'components/Layout/layoutStyles';
import SubmissionTable from 'components/SubmissionTable';

const ReviewSubmissionPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.layout__container}>
      <Container
        maxWidth="lg"
        className={classes.layout__content}
        disableGutters
        sx={{
          maxWidth: '100% !important',
          my: 0,
          pt: 0
        }}>
        <Box className={classes.layout__header}>
          <Typography textAlign="center" component="h1" variant="h1">
            Partnership Application Status
          </Typography>
          <Typography textAlign="center" component="p" variant="body1">
            Your Tops Tongtin Application is currently under review
          </Typography>
          <Typography textAlign="center" component="p" variant="body1">
            Below you can see the current status of your application
          </Typography>
          <SubmissionTable />
        </Box>
      </Container>
    </Box>
  );
};

export default ReviewSubmissionPage;
