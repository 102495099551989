import { createTheme } from '@mui/material';
import {
  GRAY_BG_COLOR,
  LIGHT_PURPLE,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SUB_TITLE_COLOR,
  WHITE_COLOR
} from 'constants/colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    heading1: React.CSSProperties | { [k: string]: object };
    body1_temp: React.CSSProperties | { [k: string]: object };
    body2_temp: React.CSSProperties | { [k: string]: object };
    body1_bold: React.CSSProperties | { [k: string]: object };
    body2_bold: React.CSSProperties | { [k: string]: object };
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    heading1: React.CSSProperties | { [k: string]: object };
    body1_temp: React.CSSProperties | { [k: string]: object };
    body2_temp: React.CSSProperties | { [k: string]: object };
    body1_bold: React.CSSProperties | { [k: string]: object };
    body2_bold: React.CSSProperties | { [k: string]: object };
  }
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
    gray_bg: Palette['primary'];
    light_purple: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    white?: PaletteOptions['primary'];
    gray_bg?: PaletteOptions['primary'];
    light_purple: PaletteOptions['primary'];
  }

  interface Theme {
    boxShadow: {
      static: string;
    };
  }
  // fix the type error when calling `createTheme()` with a custom theme option
  interface ThemeOptions {
    boxShadow: {
      static: string;
    };
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading1: true;
    body1_temp: true;
    body2_temp: true;
    body1_bold: true;
    body2_bold: true;
  }
}

const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    },
    white: {
      main: WHITE_COLOR
    },
    gray_bg: {
      main: GRAY_BG_COLOR
    },
    light_purple: {
      main: LIGHT_PURPLE
    }
  },
  typography: {
    fontFamily: ['PSL Kanda Pro'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    body1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.75rem',
      color: '#4B5563'
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.5rem',
      color: '#4B5563'
    },
    h1: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontWeight: 400,
      fontSize: '80px',
      lineHeight: '64px',
      color: PRIMARY_COLOR,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '2rem',
        lineHeight: '40px'
      }
    },
    h2: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontSize: '64px',
      lineHeight: '60px',
      fontWeight: 400,
      color: '#1F2937'
    },
    h3: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontSize: '54px',
      lineHeight: '52px',
      fontWeight: 400,
      color: '#1F2937'
    },
    h4: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontSize: '48px',
      lineHeight: '42px',
      fontWeight: 400
    },
    h5: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontSize: '36px',
      lineHeight: '36px',
      fontWeight: 400
    },
    subtitle1: {
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      color: WHITE_COLOR,
      fontWeight: 400,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '14px'
      }
    },
    subtitle2: {
      fontFamily: 'PSL Kanda Pro',
      fontWeight: 700,
      fontSize: '1.75rem',
      color: SUB_TITLE_COLOR,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1.25rem'
      }
    },
    heading1: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontWeight: 400,
      fontSize: '80px',
      lineHeight: '64px',
      color: PRIMARY_COLOR
    },
    body1_temp: {
      fontFamily: 'PSL Kanda Pro',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '32px',
      color: '#4B5563' // grey
    },
    body2_temp: {
      fontFamily: 'PSL Kanda Pro',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '28px',
      color: '#4B5563' // grey
    },
    body1_bold: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '24px'
    },
    body2_bold: {
      fontFamily: 'PSL Kanda Extra Pro',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '28px',
      lineHeight: '28px'
    }
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      laptop: 1024
    }
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.5rem',
          color: '#1F2937'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#d32f2f',
          fontSize: '2.4em',
          position: 'relative',
          left: '-8px',
          top: '4px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 32,
          lineHeight: 1.2,
          fontSize: '1.75rem'
        }
      }
    }
  },
  boxShadow: {
    static: '0px 0px 4px -1px rgba(0, 0, 0, 0.1), 0px 12px 32px 2px rgba(0, 0, 0, 0.1)'
  }
});

export default theme;
