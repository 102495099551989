import HtmlParsePostProcessor from 'helper/HtmlParsePostProcessor';
import LocalStorage, { LOCALSTORAGE_KEY } from 'helper/LocalStorage';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './resources/en';
import th from './resources/thai';

const localStorage = new LocalStorage();

export enum LANGUAGES {
  ENGLISH = 'en',
  THAI = 'th'
}

export const configureLocalization = () => {
  return i18n
    .use(initReactI18next)
    .use(HtmlParsePostProcessor)
    .init({
      lng: LANGUAGES.THAI,
      fallbackLng: LANGUAGES.THAI,

      resources: {
        en: {
          translation: en
        },
        th: {
          translation: th
        }
      },

      debug: false,
      postProcess: ['htmlParserProcessor'],
      cache: {
        enabled: true
      },

      interpolation: {
        escapeValue: false // not needed for react as it does escape per default to prevent xss!
      },
      react: {
        defaultTransParent: 'div',
        transEmptyNodeValue: '',
        transKeepBasicHtmlNodesFor: ['b']
      }
    });
};

export const isValidLanguage = (language: string) => {
  if (language) {
    return Object.values(LANGUAGES).includes(language as LANGUAGES);
  }
  return false;
};

export const initialLanguage = () => {
  const language = localStorage.getItemByKey(LOCALSTORAGE_KEY.LOCALE);
  if (!isValidLanguage(language)) {
    localStorage.setItemByKey(LOCALSTORAGE_KEY.LOCALE, LANGUAGES.THAI);
  }
};

export const getLanguage = (): string => {
  return localStorage.getItemByKey(LOCALSTORAGE_KEY.LOCALE) || LANGUAGES.THAI;
};

export const setLanguageToStorage = () => {
  const language = getLanguage();
  localStorage.setItemByKey(LOCALSTORAGE_KEY.LOCALE, language);
};

export const changeLanguage = (language: string): Promise<string> => {
  if (isValidLanguage(language)) {
    localStorage.setItemByKey(LOCALSTORAGE_KEY.LOCALE, language);
    return i18n.changeLanguage(language).catch((error) => error.toString());
  }
  return Promise.reject();
};
