import { Grid, Typography } from '@mui/material';
import {
  employeesOptionsForCompany,
  revenueOptionsForCompany,
  typeOfBussinessOptions
} from 'components/RegistrationWrapper/initialValues/company';
import { productTypeOptions } from 'components/RegistrationWrapper/initialValues/product';
import Controls from 'components/shared/Form/FormControl';
import { useModal } from 'contexts/modal';
import { useFormikContext } from 'formik';
import { getLanguage } from 'localization';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ICompany from 'types/Company';

import { DocumentTypes } from 'constants/documents';
import useThaiAddress from 'hooks/thai-address';
import UploadWithHint from '../UploadWithHint';

const Company = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<ICompany>();
  const {
    loaded: thaiAddressLoaded,
    buildSubDistrict,
    buildDistrictList,
    buildProvince
  } = useThaiAddress();

  const { handleOpenModal, setLink } = useModal();
  const { subDistrictOptions, districtOptions, provinceOptions } = useMemo(() => {
    if (!thaiAddressLoaded) {
      return { subDistrictOptions: [], districtOptions: [], provinceOptions: [] };
    }

    const lang = getLanguage();

    const value = parseInt(formik.values.zip_code);
    return {
      subDistrictOptions: buildSubDistrict(value, lang),
      districtOptions: buildDistrictList(value, lang),
      provinceOptions: buildProvince(value, lang)
    };
  }, [formik.values.zip_code, formik.initialValues.zip_code, thaiAddressLoaded]);

  useEffect(() => {
    if (formik.values.zip_code === formik.initialValues.zip_code) {
      formik.setFieldValue('subdistrict', formik.initialValues.subdistrict);
      formik.setFieldValue('district', formik.initialValues.district);
      formik.setFieldValue('province', formik.initialValues.province);
      return;
    } else {
      formik.setFieldValue('subdistrict', '');
      formik.setFieldValue('district', '');
      formik.setFieldValue('province', '');
      return;
    }
  }, [formik.values.zip_code, formik.initialValues]);

  return (
    <>
      {/* Company / Head Office Address start */}
      <Typography variant="h3" component="h3">
        {t('company_information.section1.title')}
      </Typography>
      <Grid pt={3} container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Controls.Input name="address_number" label="form.addressNumber" required />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controls.Input name="building" label="form.building" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controls.Input name="floor" label="form.floor" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controls.Input name="moo" label="form.moo" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controls.Input name="soi" label="form.soi" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controls.Input name="road" label="form.road" required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input name="zip_code" label="form.zipCode" required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.SelectFormik
            name="subdistrict"
            label="form.subDistrict"
            InputLabelProps={{ required: true }}
            required
            options={subDistrictOptions}
            renderField={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.SelectFormik
            name="district"
            label="form.district"
            InputLabelProps={{ required: true }}
            required
            options={districtOptions}
            renderField={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.SelectFormik
            name="province"
            label="form.province"
            InputLabelProps={{ required: true }}
            required
            options={provinceOptions}
            renderField={true}
          />
        </Grid>
      </Grid>
      {/* Company / Head Office Address end */}

      {/* Company Information start */}
      <Typography variant="h3" component="h3" pt={5}>
        {t('company_information.section2.title')}
      </Typography>
      <Grid pt={3} container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input name="companyName" label={'company_information.name'} required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.SelectFormik
            name="product_type"
            label={t('product.detail.productType.label')}
            InputLabelProps={{ required: true }}
            required
            options={productTypeOptions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.SelectFormik
            name="type_of_business.value"
            label={t('company_information.section2.typeOfBussiness')}
            InputLabelProps={{ required: true }}
            required
            options={typeOfBussinessOptions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.SelectFormik
            name="annual_revenue"
            label={t('company_information.section2.totalRevenue')}
            InputLabelProps={{ required: true }}
            required
            options={revenueOptionsForCompany}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.SelectFormik
            name="number_of_employees"
            label={t('company_information.section2.numberOfEmployees')}
            InputLabelProps={{ required: true }}
            required
            options={employeesOptionsForCompany}
          />
        </Grid>

        {formik.values.type_of_business.value === 'Company (Listed or Limited Liability)' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" component="p">
                {t('type_of_business.company.companyRegistrationNumber')}
              </Typography>
              <Controls.Input
                name="type_of_business.company_affidavit.registered_number"
                label={'type_of_business.company.companyRegistrationNumber'}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadWithHint
                label={t('type_of_business.company.label')}
                name={'type_of_business.company_affidavit.photo'}
                hints={[t('type_of_business.company.text'), t('form.info_msg.upload')]}
                destDir={DocumentTypes.COMPANY}
                onClickExample={() => {
                  setLink([
                    {
                      pdf: true,
                      url: `${process.env.REACT_APP_STORAGE}/examples/company/Company+Affidavit.pdf`
                    }
                  ]);
                  handleOpenModal(true);
                }}
                exampleText={t('type_of_business.company.example')}
              />
            </Grid>
          </>
        )}
        {formik.values.type_of_business.value === 'Unregistered Partnership' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" component="p">
                {t('type_of_business.unregistered.idCardNumber')}
              </Typography>
              <Controls.Input
                name="type_of_business.unregistered.id_card_number"
                label={'type_of_business.unregistered.idCardNumber'}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadWithHint
                label={t('type_of_business.unregistered.idCardUpload.label')}
                name={'type_of_business.unregistered.id_card_upload'}
                hints={[
                  t('type_of_business.unregistered.idCardUpload.text'),
                  t('form.info_msg.upload')
                ]}
                destDir={DocumentTypes.COMPANY}
                onClickExample={() => {
                  setLink([
                    {
                      url: `${process.env.REACT_APP_STORAGE}/examples/company/ID+Card.jpg`
                    }
                  ]);
                  handleOpenModal(true);
                }}
                exampleText={t('type_of_business.unregistered.idCardUpload.example')}
              />
            </Grid>
          </>
        )}
        {formik.values.type_of_business.value === 'Registered Partnership' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" component="p">
                {t('type_of_business.registered.registrationNumber')}
              </Typography>
              <Controls.Input
                name="type_of_business.registered.registered_number"
                label={'type_of_business.registered.registrationNumber'}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadWithHint
                label={t('type_of_business.registered.registrationUpload.label')}
                name={'type_of_business.registered.upload'}
                hints={[
                  t('type_of_business.registered.registrationUpload.text'),
                  t('form.info_msg.upload')
                ]}
                destDir={DocumentTypes.COMPANY}
                onClickExample={() => {
                  setLink([
                    {
                      url: `${process.env.REACT_APP_STORAGE}/examples/company/Registered+and+Limited+Partnership+Affidavit.jpg`
                    }
                  ]);
                  handleOpenModal(true);
                }}
                exampleText={t('type_of_business.registered.registrationUpload.example')}
              />
            </Grid>
          </>
        )}
        {formik.values.type_of_business.value === 'Limited Partnership' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" component="p">
                {t('type_of_business.limited.registrationNumber')}
              </Typography>
              <Controls.Input
                name="type_of_business.limited.registration_number"
                label={'type_of_business.limited.registrationNumber'}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadWithHint
                label={t('type_of_business.limited.upload.label')}
                name={'type_of_business.limited.upload'}
                hints={[t('type_of_business.limited.upload.text'), t('form.info_msg.upload')]}
                destDir={DocumentTypes.COMPANY}
                onClickExample={() => {
                  setLink([
                    {
                      url: `${process.env.REACT_APP_STORAGE}/examples/company/Registered+and+Limited+Partnership+Affidavit.jpg`
                    }
                  ]);
                  handleOpenModal(true);
                }}
                exampleText={t('type_of_business.limited.upload.example')}
              />
            </Grid>
          </>
        )}
        {formik.values.type_of_business.value === 'SMCE (small and micro community enterprise)' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" component="p">
                {t('type_of_business.smce.code')}
              </Typography>
              <Controls.Input
                name="type_of_business.smce.code"
                label={'type_of_business.smce.code'}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadWithHint
                label={t('type_of_business.smce.registrationCertificate.label')}
                name={'type_of_business.smce.registration_certificate'}
                destDir={DocumentTypes.COMPANY}
                hints={[
                  t('type_of_business.smce.registrationCertificate.text'),
                  t('form.info_msg.upload')
                ]}
                onClickExample={() => {
                  setLink([
                    {
                      url: `${process.env.REACT_APP_STORAGE}/examples/company/SMCE+Tor+Vor+Chor+2.jpg`
                    }
                  ]);
                  handleOpenModal(true);
                }}
                exampleText={t(t('type_of_business.smce.registrationCertificate.example'))}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadWithHint
                label={t('type_of_business.smce.commercialCertificate.label')}
                name={'type_of_business.smce.commerial_certificate'}
                destDir={DocumentTypes.COMPANY}
                hints={[
                  t('type_of_business.smce.commercialCertificate.text'),
                  t('form.info_msg.upload')
                ]}
                onClickExample={() => {
                  setLink([
                    {
                      pdf: true,
                      url: `${process.env.REACT_APP_STORAGE}/examples/company/SMCE+Tor+Vor+Chor+3.pdf`
                    }
                  ]);
                  handleOpenModal(true);
                }}
                exampleText={t(t('type_of_business.smce.commercialCertificate.example'))}
              />
            </Grid>
          </>
        )}
        {formik.values.type_of_business.value === 'Individual' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" component="p">
                {t('type_of_business.individual.idCardNumber')}
              </Typography>
              <Controls.Input
                name="type_of_business.individual.id_card_number"
                label={'type_of_business.individual.idCardNumber'}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadWithHint
                label={t('type_of_business.individual.idCardUpload.label')}
                name={'type_of_business.individual.upload'}
                destDir={DocumentTypes.COMPANY}
                hints={[
                  t('type_of_business.individual.idCardUpload.text'),
                  t('form.info_msg.upload')
                ]}
                onClickExample={() => {
                  setLink([
                    {
                      url: `${process.env.REACT_APP_STORAGE}/examples/company/ID+Card.jpg`
                    }
                  ]);
                  handleOpenModal(true);
                }}
                exampleText={t('type_of_business.individual.idCardUpload.example')}
              />
            </Grid>
          </>
        )}
      </Grid>
      {/* Company Information end */}

      {/* Removed ProductionFacility section on July 2023 maintenance */}
      {/* <ProductionFacility /> */}
      {/* End of Removed on July 2023 maintenance */}
    </>
  );
};

export default Company;
