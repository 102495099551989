import { Box, Link, Typography } from '@mui/material';
import Controls from 'components/shared/Form/FormControl';

interface UploadWithHintProps {
  label: string | React.ReactElement;
  name: string;
  hints: string[];
  exampleText?: string;
  destDir?: string;
  onClickExample?: () => void;
}

const UploadWithHint: React.FC<UploadWithHintProps> = ({
  label,
  name = '',
  hints = [],
  onClickExample = () => undefined,
  exampleText,
  destDir = ''
}: UploadWithHintProps) => {
  return (
    <>
      <Typography variant="body1" component="p">
        {label}
      </Typography>
      <Controls.Upload name={name} destDir={destDir} />
      <Box sx={{ pt: 2, pl: 2 }}>
        <ul>
          {hints.map((item, idx) => (
            <li key={idx}>
              <Typography variant="body2" component="p">
                {item}
              </Typography>
            </li>
          ))}
          {exampleText && (
            <li>
              <Link>
                <Typography variant="body2" component="p" onClick={onClickExample}>
                  {exampleText}
                </Typography>
              </Link>
            </li>
          )}
        </ul>
      </Box>
    </>
  );
};

export default UploadWithHint;
