import axiosClient from 'api/axiosClient';
import ICompany from 'types/Company';

class CompanyService {
  getAll = (): Promise<ICompany[]> => {
    return axiosClient.get('/companies');
  };

  getCompanyById = (id: number): Promise<ICompany> => {
    return axiosClient.get(`/companies/${id}`);
  };

  getCompanyByUserId = (userId: number): Promise<any> => {
    return axiosClient.post('/getCompanyByUserId', { userId });
  };

  createCompany = (body: ICompany, userId: number) => {
    return axiosClient.post('/companies', {
      ...companyService.formatComapnyForClient(body),
      user: userId
    });
  };

  updateCompany = (body: ICompany, companyId: number) => {
    delete body.license;
    delete body.product;
    delete body.aspiration;
    delete body.registration;
    return axiosClient.put(`companies/${companyId}`, body);
  };

  formatComapnyForClient = (body: ICompany) => {
    const formatBody = {
      ...body,
      name: body.companyName
    };

    return formatBody;
  };
}

const companyService = new CompanyService();
export default companyService;
