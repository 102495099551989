import { Box, Container, Typography } from '@mui/material';
import useStyles from 'components/Layout/layoutStyles';
import CustomStepper from 'components/Stepper';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const RegistrationPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Tongtin - Registration </title>
      </Helmet>
      <Container className={classes.layout__content} disableGutters>
        <Box className={classes.layout__header}>
          <Typography textAlign="center" component="h1" variant="h1" color="secondary">
            {t('account_registration.partnership')}
          </Typography>
          <Box pt={5} pb={8}>
            <CustomStepper />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default RegistrationPage;
