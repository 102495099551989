export const useHome = () => {
  const sliderItems = [
    {
      image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/update-2024-01.png`,
      alt: 'slide-7'
    },
    {
      image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/update-2024-02.png`,
      alt: 'slide-8'
    },
    {
      image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/update-2024-03.png`,
      alt: 'slide-9'
    },
    {
      image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/update-2024-04.png`,
      alt: 'slide-10'
    },
    {
      image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/update-2024-05.png`,
      alt: 'slide-11'
    }
    // {
    //   image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/s-2.jpg`,
    //   alt: 'slide-2'
    // },
    // {
    //   image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/s-1.jpg`,
    //   alt: 'slide-1'
    // },
    // {
    //   image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/s-3.jpg`,
    //   alt: 'slide-3'
    // },
    // {
    //   image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/s-4.jpg`,
    //   alt: 'slide-4'
    // },
    // {
    //   image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/s-5.jpg`,
    //   alt: 'slide-5'
    // },
    // {
    //   image: `${process.env.REACT_APP_STORAGE}/static/hompage_sliders/s-6.jpg`,
    //   alt: 'slide-6'
    // }
  ];

  return [sliderItems] as const;
};
