import companyService from 'api/services/CompanyService';
import { useMutation } from 'react-query';
import ICompany from 'types/Company';

interface Props {
  onSuccess: () => void;
  onError: (error: any) => void;
  userId: number;
}

export const useCreateCompany = ({ onSuccess, onError, userId }: Props) => {
  return useMutation((body: ICompany) => companyService.createCompany(body, userId), {
    onError,
    onSuccess
  });
};
