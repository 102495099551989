import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  box__container: {
    position: 'relative'
  },
  box__background: {
    background: '#ED1C24',
    borderRadius: '20px',
    position: 'absolute',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      top: '3%',
      left: '2%'
    },
    [theme.breakpoints.up('md')]: {
      top: '5%',
      left: '3%'
    }
  }
}));

export default useStyles;
